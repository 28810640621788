// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/services-page/11-professional-photos-video/header-banner-serv-11.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../assets/images/services-page/11-professional-photos-video/image-5.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../assets/images/services-page/11-professional-photos-video/image-9.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serv-eleven {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.serv-eleven-middle1 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    min-height: 400px;
}

.serv-eleven-middle2 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    min-height: 400px;
}`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/shifteasy-services/virtual-conference/virtual-conference.css"],"names":[],"mappings":"AAAA;IACI,yDAAqH;AACzH;;AAEA;IACI,yDAAuG;IACvG,iBAAiB;AACrB;;AAEA;IACI,yDAAuG;IACvG,iBAAiB;AACrB","sourcesContent":[".serv-eleven {\n    background-image: url(../../../../assets/images/services-page/11-professional-photos-video/header-banner-serv-11.jpg);\n}\n\n.serv-eleven-middle1 {\n    background-image: url(../../../../assets/images/services-page/11-professional-photos-video/image-5.png);\n    min-height: 400px;\n}\n\n.serv-eleven-middle2 {\n    background-image: url(../../../../assets/images/services-page/11-professional-photos-video/image-9.png);\n    min-height: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
