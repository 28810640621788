import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import './newhome.css';
import leftarrow from '../../../assets/images/leftarrow.png';
import toparrow from '../../../assets/images/uparrow.png';
import rightarrow from '../../../assets/images/rightarrow.png';
import ItemsCarousel from 'react-items-carousel';
import props1img1 from '../../../assets/images/NewHome/propsNear/props1.png'
import props1img2 from '../../../assets/images/NewHome/propsNear/props2.png'
import props1img3 from '../../../assets/images/NewHome/propsNear/props3.png'
import props1img4 from '../../../assets/images/NewHome/propsNear/props4.png'
import props1img5 from '../../../assets/images/NewHome/propsNear/props5.png'
import trds1 from '../../../assets/images/NewHome/tradesman/trads1.png'
import trds2 from '../../../assets/images/NewHome/tradesman/trads2.png'
import trds3 from '../../../assets/images/NewHome/tradesman/trads3.png'
import trds4 from '../../../assets/images/NewHome/tradesman/trads4.png'
import trds5 from '../../../assets/images/NewHome/tradesman/trads5.png'
import car1 from '../../../assets/images/NewHome/cars/car1.png'
import car2 from '../../../assets/images/NewHome/cars/car2.png'
import car3 from '../../../assets/images/NewHome/cars/car3.png'
import car4 from '../../../assets/images/NewHome/cars/car4.png'
import car5 from '../../../assets/images/NewHome/cars/car5.png'
import more1 from '../../../assets/images/NewHome/more/more1.png'
import more2 from '../../../assets/images/NewHome/more/more2.png'
import more3 from '../../../assets/images/NewHome/more/more3.png'
import more4 from '../../../assets/images/NewHome/more/more4.png'
import more5 from '../../../assets/images/NewHome/more/more5.png'

import blog1 from '../../../assets/images/NewHome/portrait.png';
import blog2 from '../../../assets/images/NewHome/portrait2.png';
import blog3 from '../../../assets/images/NewHome/portrait3.png';

import blg1 from '../../../assets/images/NewHome/blg1.png';
import blg2 from '../../../assets/images/NewHome/blg2.png';
import blg3 from '../../../assets/images/NewHome/blg3.png';

import g0 from '../../../assets/images/NewHome/g0.svg';
import g1 from '../../../assets/images/NewHome/g1.svg';
import g2 from '../../../assets/images/NewHome/g2.svg';
import g3 from '../../../assets/images/NewHome/g3.svg';

import tenant from '../../../assets/images/tenant-referencing.png';
import deposit from '../../../assets/images/deposit-replacements.png';
import photovideo from '../../../assets/images/photo-video.png';
import inventory from '../../../assets/images/inventory.png';
import insurance from '../../../assets/images/home-content-insurance.png';
import rent from '../../../assets/images/rent-guarante-insurance.png';
import gassafety from '../../../assets/images/gas-safety.png';
import energy from '../../../assets/images/energy.png';
import banking from '../../../assets/images/open-banking.png';
import movers from '../../../assets/images/movers.png';
import propertymanagement from '../../../assets/images/property-management.png';
import facilities from '../../../assets/images/facilities.png';
import doctor from '../../../assets/images/doctor.png';
import legal from '../../../assets/images/legal.png';
import investor from '../../../assets/images/investor.png';



import Ahmedabad from '../../../assets/images/NewHome/places/Ahmedabad.png'
import banglore from '../../../assets/images/NewHome/places/banglore.png'
import chennai from '../../../assets/images/NewHome/places/chennai.png'
import delhi from '../../../assets/images/NewHome/places/delhi.png'
import Ghaziabad from '../../../assets/images/NewHome/places/Ghaziabad.png'
import hydrabad from '../../../assets/images/NewHome/places/hydrabad.png'
import kolkata from '../../../assets/images/NewHome/places/kolkata.png'
import konark from '../../../assets/images/NewHome/places/konark.png'
import mumbai from '../../../assets/images/NewHome/places/mumbai.svg'
import Noida from '../../../assets/images/NewHome/places/Noida.png'
import patna from '../../../assets/images/NewHome/places/patna.png'
import pune from '../../../assets/images/NewHome/places/pune.png'

import { useHistory } from 'react-router-dom';
import { Link, withRouter } from "react-router-dom";

import { getBlogsMain, getBlogsDetail } from "../../../services/querys/get-APIs"

const image_show = `https://cms.shifteasy.com`

const Landingpage = (props) => {
    const [homeData, setHomeData] = React.useState({
        width: 0,
        height: 0,
        count: 0
    })
    const [projectsActiveItemIndex, setProjectsActiveItemIndex] = React.useState(0);
    const [propertiesActiveItemIndex, setPropertiesActiveItemIndex] = React.useState(0);
    const [businessActiveItemIndex, setBusinessActiveItemIndex] = React.useState(0);
    const [moreActiveItemIndex, setmoreActiveItemIndex] = React.useState(0);
    const [winwidth, setwidth] = React.useState(window.innerWidth)

    const projectsChangeActiveItem = (projectsActiveItemIndex) => {
        setProjectsActiveItemIndex(projectsActiveItemIndex);
    };

    const propertiesChangeActiveItem = (propertiesActiveItemIndex) => {
        setPropertiesActiveItemIndex(propertiesActiveItemIndex);
    };

    const businessChangeActiveItem = (businessActiveItemIndex) => {
        setBusinessActiveItemIndex(businessActiveItemIndex);
    };
    const moreChangeActiveItem = (moreActiveItemIndex) => {
        setmoreActiveItemIndex(moreActiveItemIndex);
    }



    const history = useHistory();
    const hasWindow = typeof window !== 'undefined';

    function getWindowDimensions() {
        const winwidth = hasWindow ? window.innerWidth : null;
        console.log(winwidth)
        //   const height = hasWindow ? window.innerHeight : null;
        if (winwidth <= 411) {
            const width = 1
            return width
        } if (winwidth <= 414) {
            const width = 1
            return width
        }
        else if (winwidth <= 540) {
            const width = 1
            return width
        }
        if (winwidth <= 1200) {
            const width = 3
            return width
        } else {
            const width = 4
            return width
        }
    }

    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    const [blogs, setBlogs] = React.useState()
    console.log(blogs)
    // const[latitude,setLatitude]=React.useState("")
    // const[longitude,setLongitude]=React.useState("")
    const getBlogs = () => {
        getBlogsMain(3).then(res => {
            // let img = res.data.blogs && res.data.blogs.map(res => `${image_show}${res.featureImage.url}`)
            setBlogs(res.data.blogs)
        }).catch(error => {
            console.log(error)
        })
    }




    React.useEffect(() => {
        getBlogs();
    }, [])

    React.useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);



    // console.log(windowDimensions)

    return (
        <div style={{
            backgroundColor: "#F6F6F6", width: "100%",
            overflow: "hidden"
        }} >

            <Header freeValForm="#freeValForm" />

            {/* ///banner section// */}
            <div className=' row banner_section_newhome col-12 w-auto'>

                <div className='text-left col-md-12  ml-lg-5'>
                    <h1 className='font-weight-bold'>All In One Platform for <br /> Family & Society </h1>
                    <p>Shifteasy is the world's first integrated AI driven Properties management<br />and housing needs platform</p>
                    <button className="btn btn-orange" onClick={() => history.push("/register")}>Register</button>
                </div>
            </div>
            <div className='col-md-11 mx-auto mb-5'>
                <div className="top-right-nav top_right_nav justify-content-center mt-4">
                    <div className='title_style_all d-lg-flex d-md-flex justify-content-between col-md-10 col-lg-11 mt-2 pt-1  pl-0 px-0'>
                        <div className=' col-md-7 col-xs-12 px-0  mt-lg-0 mt-md-0' >
                            <h4 className=" mb-0 text-dark-blue font-weight-bold">Properties Near You</h4>
                        </div>
                        <div className='col-md-2  p-0  d-none d-sm-block '>
                            <button id="v_btn" onClick={() => window.open('https://residential.shifteasy.com/')} className='btn btn-outline-orange position-lg-absolute float-right btn-sm ml-3' >View All</button>
                        </div>
                    </div>
                    <ItemsCarousel
                        requestToChangeActive={projectsChangeActiveItem}
                        activeItemIndex={projectsActiveItemIndex}
                        numberOfCards={windowDimensions}
                        // numberOfCards={4}
                        gutter={20}
                        leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                        rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                        outsideChevron
                        width={341.5}
                        chevronWidth={40} >
                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://residential.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={props1img1} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">2BHK Appartment</h6>
                                </div>
                            </div>
                        </div>
                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://residential.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={props1img2} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">3BHK Individual House</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://residential.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={props1img3} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Villa</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://residential.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={props1img4} className="card-img-top br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Residential Plots</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://residential.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={props1img5} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Ready To Move-In</h6>
                                </div>
                            </div>
                        </div>
                    </ItemsCarousel>
                    <button className='btn btn-outline-orange btn-block d-block d-sm-none ' id="c_btn" >View All</button>
                </div>
                <div className="top-right-nav top_right_nav justify-content-center mt-lg-4">
                    <div className='title_style_all d-lg-flex d-md-flex justify-content-between col-md-10 col-lg-11 mt-2 pt-1  pl-0 px-0'>
                        <div className=' col-md-7 col-xs-12 px-0  mt-lg-0 mt-md-0' >
                            <h4 className=" mb-0 text-dark-blue font-weight-bold">Tradesmen Near You</h4>
                        </div>
                        <div className='col-md-2  p-0  d-none d-sm-block '>
                            <button id="v_btn" onClick={() => window.open('https://tradesmen.shifteasy.com/')} className='btn btn-outline-orange position-lg-absolute float-right btn-sm ml-3' >View All</button>
                        </div>
                    </div>
                    <ItemsCarousel
                        requestToChangeActive={propertiesChangeActiveItem}
                        activeItemIndex={propertiesActiveItemIndex}
                        numberOfCards={windowDimensions}
                        gutter={20}
                        leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                        rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                        outsideChevron
                        chevronWidth={40} >
                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://tradesmen.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={trds1} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Plumbers</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://tradesmen.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={trds2} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Electrician</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://tradesmen.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={trds3} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Painter</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://tradesmen.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={trds4} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Plumbers</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://tradesmen.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={trds5} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Mechanic</h6>
                                </div>
                            </div>
                        </div>


                    </ItemsCarousel>
                    <button onClick={() => window.open('https://tradesmen.shifteasy.com/')} className='btn btn-outline-orange btn-block d-block d-sm-none ' id="c_btn" >View All</button>
                </div>

                <div className="top-right-nav top_right_nav justify-content-center mt-lg-4">
                    <div className='title_style_all d-lg-flex d-md-flex justify-content-between col-md-10 col-lg-11 mt-2 pt-1  pl-0 px-0'>
                        <div className='col-md-7 col-xs-12 px-0  mt-lg-0 mt-md-0' >
                            <h4 className=" mb-0 text-dark-blue font-weight-bold">Cars</h4>
                        </div>
                        <div className='col-md-2  p-0  d-none d-sm-block'>
                            <button id="v_btn" onClick={() => window.open('https://cars.shifteasy.com/')} className='btn btn-outline-orange position-lg-absolute float-right btn-sm ml-3 ' >View All</button>
                        </div>
                    </div>
                    <ItemsCarousel
                        requestToChangeActive={businessChangeActiveItem}
                        activeItemIndex={businessActiveItemIndex}
                        numberOfCards={windowDimensions}
                        gutter={20}
                        leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                        rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                        outsideChevron
                        chevronWidth={40} >



                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://cars.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={car1} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Popular Cars</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://cars.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={car2} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">New cars</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://cars.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={car3} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Pre-Owned Cars</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://cars.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={car4} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Most Searched Cars</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://cars.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={car5} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Budget Cars</h6>
                                </div>
                            </div>
                        </div>
                    </ItemsCarousel>
                    <button onClick={() => window.open('https://cars.shifteasy.com/')} className='btn btn-outline-orange btn-block d-block d-sm-none ' id="c_btn" >View All</button>
                </div>

                <div className="top-right-nav top_right_nav justify-content-center mt-lg-4">
                    <div className='title_style_all d-lg-flex d-md-flex justify-content-between col-md-10 col-lg-11 mt-2 pt-1  pl-0 px-0'>
                        <div className='col-md-7 col-xs-12 px-0  mt-lg-0 mt-md-0' >
                            <h4 className=" mb-0 text-dark-blue font-weight-bold">More Services</h4>
                        </div>
                        <div className='col-md-2  p-0  d-none d-sm-block'>
                            <button id="v_btn" className='btn btn-outline-orange position-lg-absolute float-right btn-sm ml-lg-4' >View All</button>
                        </div>
                    </div>
                    <ItemsCarousel
                        requestToChangeActive={moreChangeActiveItem}
                        activeItemIndex={moreActiveItemIndex}
                        numberOfCards={windowDimensions}
                        gutter={20}
                        leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                        rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                        outsideChevron
                        chevronWidth={40} >


                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://doctors.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={more1} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Doctors</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://localfacilities.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={more2} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Facilities Near You</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://legals.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={more3} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Lawyers</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://tradelogistics.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={more4} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Logistics</h6>
                                </div>
                            </div>
                        </div>

                        <div id="id_width" className="col-item   mb-5 bg-white rounded">
                            <div onClick={() => window.open('https://rmi.shifteasy.com/')} className="card br-0 w-auto h-auto">
                                <img src={more5} className="card-img-top  br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h6 class="card-title mb-1 p-1">Rent Market Index</h6>
                                </div>
                            </div>
                        </div>
                    </ItemsCarousel>
                    <button className='btn btn-outline-orange btn-block d-block d-sm-none mb-5 ' id="c_btn" >View All</button>
                </div>
            </div>

            {/* <----about us---> */}
            <div className='empty_spc'></div>
            <div >
                <div className='col-md-11 mx-auto mb-lg-5 '>
                    <h3 class="text-dark-blue font-weight-bold mt-5">About Us</h3>
                </div>

                <div className='reverse_col_flex  row justify-content-center '>
                    <div className='col-md-5 col-lg-5 text_1 '>
                        <div>
                            <h5 style={{ color: "#06394C" }}>End to End Exection</h5>
                            <ul className="custom-list mb-0">
                                <li className="d-flex">End to end support for your dream property, cars, bikes, jobs and beyond</li>
                                <li className="d-flex">Listing to final possession in one platform</li>
                                <li className="d-flex">Background checks, property management, inventory check, contract management, blockchain logistics and services</li>
                                <li className="d-flex">Search legal services, doctors, medical services and facilities near you</li>
                                <li className="d-flex">ShiftEasy can provide you a wide selection of properties, cars, bikes and tradesmen in your locality</li>
                            </ul>
                        </div>
                    </div>
                    <div className=' port_img_one col-lg-5 col-md-5 p-0 '>
                        <img src={blog1} className='img-fluid' alt='no-img' />
                    </div>
                </div>

                <div className='reverse_col_flex1 row justify-content-center  mb-4'>

                    <div className=' port_img_one1 col-lg-5 col-md-5  mt-3'>
                        <img src={blog2} className='img-fluid' alt='no-img' />
                    </div>
                    <div className='col-md-5  col-lg-5  text_2'>
                        <div>
                            <h5 style={{ color: "#06394C" }}>Placement</h5>
                            <ul className="custom-list mb-0">
                                <li className="d-flex">ShiftEasy is the world’s first integrated AI driven properties management and housing needs platform</li>
                                <li className="d-flex">Get membership now for right leads for better rental, sale and service</li>
                                <li className="d-flex">In person services, virtual viewings, 3D tours, transport with movers & packers - ShiftEasy Ninjas will support you end to end</li>
                                <li className="d-flex">Property management, rental collections, Card payments, International and multi-currency support</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='reverse_col_flex  row justify-content-center '>
                    <div className='col-md-5 col-lg-5 text_1 '>
                        <div>
                            <h5 style={{ color: "#06394C" }}>Valuation</h5>
                            <ul className="custom-list mb-0">
                                <li className="d-flex">AI based real-time validation and property price data</li>
                                <li className="d-flex">ShiftEasy Rent Market Index - Best pricing service for your dream home and property investments</li>
                                <li className="d-flex">First Blockchain based property management, pricing, home loan, REIT and finance services platform</li>
                                <li className="d-flex">Watch your investments grow with ShiftEasy</li>
                                <li className="d-flex">Connect ziffy with our ShiftEasy team, we are happy to help you!!!</li>
                            </ul>
                        </div>
                    </div>
                    <div className=' port_img_one2 col-lg-5 col-md-5  '>
                        <img src={blog3} className='img-fluid' alt='no-img' />
                    </div>
                </div>



                <div className="container section-two mt-lg-0 mt-md-5 mt-0">
                    {/* section 2  */}
                    <h4 className="section-title">ShiftEasy Services</h4>
                    <p className="section-des">Sell your home with us for a fraction of the price charged by high street estate agents without compromising on the services you get. Everything you need to sell your property with ease is included.</p>
                    <div className="row mb-4 ">

                        <div className="col-md-2 col-sm-6">
                            <a href="/tenant-referencing"   >
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={tenant} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Tenant Referencing</small></h5>
                                </div>
                            </a>
                        </div>


                        <div className="col-md-2 col-sm-6">
                            <Link to={{ pathname: '/deposit-replacements', refreshpath: 'yes' }}>
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={deposit} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Deposit Replacements</small></h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <Link to={{ pathname: '/inventory-check', refreshpath: 'yes' }}>
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={inventory} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Inventory Check-In and Check-Out</small></h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <a href={'https://doctors.shifteasy.com/'} target={"_blank"}>
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={doctor} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Doctors Near You</small></h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <Link to={{ pathname: '/home-content-insurancegas', refreshpath: 'yes' }}>
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={insurance} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Home Content Insurance</small></h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <Link to="/rent-guarentee-insurance">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={rent} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Rent Guarentee Insurance</small></h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <Link to="/gas-safety">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={gassafety} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Gas Safety</small></h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <Link to="/energy-performance-certificate">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={energy} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Energy Performance Certificate (EPC)</small></h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <a href="https://legals.shifteasy.com/" target={"_blank"}>
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={legal} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Legal Services Near You</small></h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <Link to="/open-backing">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={banking} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Open Banking</small></h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <Link to="/virtual-conference">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={photovideo} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Professional Photos, 3D Videos, Virtual Conference</small></h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <a href="https://investors.shifteasy.com/" target={"_blank"}>
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={investor} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Investor Services</small></h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <Link to="/movers-packers-logistics">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={movers} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Movers, Packers & Logistics</small></h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <a href="https://propertymanagement.shifteasy.com/" target={"_blank"}>
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={propertymanagement} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Property Management</small></h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <a href="https://localfacilities.shifteasy.com/" target={"_blank"}>
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={facilities} alt="icon" className="mb-3" />
                                    <h5 className="mb-0"><small>Facilities Near You</small></h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='col-md-11 mx-auto'>
                    <div className="top-right-nav  justify-content-center mt-4">
                        <div className='title_style_all d-lg-flex d-md-flex justify-content-between col-md-10 col-lg-11 mt-2 pt-1  pl-0 px-0'>
                            <div className=' col-md-7 col-xs-12 px-0  mt-lg-0 mt-md-0' >
                                <h4 className=" mb-0 text-dark-blue font-weight-bold">Latest News & Blogs</h4>
                            </div>
                            <div className='col-md-2  p-0  d-none d-sm-block '>
                                <button id="v_btn" onClick={() => window.open('https://shifteasy.com/BlogsPage')} className='btn btn-outline-orange position-lg-absolute float-right btn-sm ml-3' >View All</button>
                            </div>
                        </div>
                        <ItemsCarousel
                            requestToChangeActive={moreChangeActiveItem}
                            activeItemIndex={moreActiveItemIndex}
                            numberOfCards={window.innerWidth >= 1024 ? 3 : windowDimensions}
                            gutter={20}
                            leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                            rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                            outsideChevron
                            chevronWidth={40} >
                            {blogs && blogs.map((item, i) =>
                                <div id="id_width1" className="col-item shadow  mb-5 bg-white rounded">
                                    <div onClick={() => {
                                        props.history.push({
                                            pathname: "/BlogDetailPage",
                                            state: { item : item }
                                        });
                                    }} className="card br-0 w-auto ">
                                        <img src={`${image_show}${item.featureImage.url}`} className="card-img-top p-1 br-0" alt="no-image" />
                                        {item.author.map(detail =>
                                            <div class="card-body card_body  p-2">
                                                <div id="title">
                                                    <h5 class="card-title mb-1 p-1">{item.title}</h5>
                                                </div>
                                                <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>{detail.name} </h6> <span className=" text-right"> by &nbsp;<span className="text_orange text-right">{detail.__typename}</span></span> </p>
                                                <p class="card-text card_disc p-1">
                                                    {item.description}
                                                </p>
                                            </div>

                                        )}
                                    </div>
                                </div>
                            )}
                            {/* <div id="id_width1" className="col-item shadow  mb-5 bg-white rounded">
                            <div onClick={() => window.open( '/BlogDetailPage')} className="card br-0 w-auto h-auto">
                                <img src={ blg2} className="card-img-top p-1 br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-right"> by &nbsp;<span className="text_orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                            </div> */}
                            {/* <div id="id_width1" className="col-item shadow  mb-5 bg-white rounded">
                            <div onClick={() => window.open( '/BlogDetailPage')} className="card br-0 w-auto h-auto">
                                <img src={ blg3} className="card-img-top p-1 br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-right"> by &nbsp;<span className="text_orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                            </div> */}
                            {/* <div id="id_width1" className="col-item shadow  mb-5 bg-white rounded">
                            <div onClick={() => window.open( '/BlogDetailPage')} className="card br-0 w-auto h-auto">
                                <img src={more4} className="card-img-top p-1 br-0" alt="no-image" />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-right"> by &nbsp;<span className="text_orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                            // </div> */}
                            {/* // <div id="id_width1" className="col-item shadow  mb-5 bg-white rounded">
                            // <div onClick={() => window.open( '/BlogDetailPage')} className="card br-0 w-auto h-auto">
                            //     <img src={more5} className="card-img-top p-1 br-0" alt="no-image" />
                            //     <div class="card-body card_body  p-2">
                            //         <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                            //         <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-right"> by &nbsp;<span className="text_orange text-right">Antim Panday</span></span> </p>
                            //         <p class="card-text p-1">
                            //             Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                            //             culture, camping: One year on, how have Australians coped with the border closures...
                            //         </p>
                            //     </div>
                            // </div>
                            // </div> */}
                        </ItemsCarousel>
                        <button onClick={() => window.open('/BlogsPage')} className='btn btn-outline-orange btn-block d-block d-sm-none mb-5' id="d_btn" >View All</button>
                    </div>
                </div>


                <div className='hand_shake col-12  p-lg-5 mb-lg-5 mb-5'>
                    <div className='col-md-11 mx-auto p-5 mb-lg-5 mb-5'>
                        <h3 className='text-center text-white mb-4'>Our Promise</h3>
                        <div className='row justify-content-around '>
                            <div className='col-md-3 mb-5 mb-lg-0'>
                                <img src={g1} alt="no-img" className='img-flud' />
                            </div>
                            <div className='col-md-3 mb-5 mb-lg-0'>
                                <img src={g0} alt="no-img" className='img-flud' />
                            </div>
                            <div className='col-md-3 mb-5 mb-lg-0'>
                                <img src={g2} alt="no-img" className='img-flud' />
                            </div>
                            <div className='col-md-3 mb-5 mb-lg-0'>
                                <img src={g3} alt="no-img" className='img-flud' />
                            </div>
                        </div>
                    </div>
                </div>


                <div className='col-lg-10 col-12 mx-auto areas_label_select  mb-5 p-0'>
                    <h3 className='text-center mb-lg-5 mb-5'>Popular Cities</h3>
                    <div className='row mb-lg-3 justify-content-around row_1 '>
                        <div className='rounded-circle py-2 mt-0 mb-3'><a><img src={Ahmedabad} alt="no-img" class="img-fluid mx-auto d-block" /><p class="text-center ">Ahmedabad </p></a></div>
                        <div className='rounded-circle py-2 mt-0 mb-3'><a><img src={chennai} alt="no-img" class=" img-fluid mx-auto d-block" /><p class="text-center ">Chennai</p></a></div>
                        <div className='rounded-circle py-2 mt-0 mb-3 mb-lg-3 '><a><img src={banglore} alt="no-img" class="img-fluid mx-auto d-block" /> <p class="text-center ">Banglore</p></a></div>
                        <div className='rounded-circle py-2 mt-0 mb-3'><a><img src={delhi} alt="no-img" class="img-fluid mx-auto d-block" /><p class="text-center ">Delhi</p></a></div>
                        <div className='rounded-circle py-2 mt-0 mb-3 '><a><img src={Ghaziabad} alt="no-img" class="img-fluid mx-auto d-block" /><p class="text-center ">Ghaziabad</p></a></div>
                        <div className='rounded-circle py-2 mt-0 mb-3 '><a><img src={hydrabad} alt="no-img" class="img-fluid mx-auto d-block" /><p class="text-center ">Hyderabad</p></a></div>
                    </div>
                    <div className='row row_2 justify-content-around '>
                        <div className='rounded-circle py-2 mt-0 mb-3 '><a><img src={kolkata} alt="no-img" class="img-fluid mx-auto d-block" /> <p class="text-center ">Kolkata</p></a></div>
                        <div className='rounded-circle py-2 mt-0 mb-3'><a><img src={konark} alt="no-img" class="img-fluid mx-auto d-block" /> <p class="text-center ">Konark</p></a></div>
                        <div className='rounded-circle py-2 mt-0 mb-3 mb-lg-3 '><a><img src={mumbai} alt="no-img" class="img-fluid mx-auto d-block" /> <p class="text-center ">Mumbai</p></a></div>
                        <div className='rounded-circle py-2 mt-0 mb-3'><a><img src={Noida} alt="no-img" class="img-fluid mx-auto d-block" /> <p class="text-center ">Noida</p></a></div>
                        <div className='rounded-circle py-2 mt-0 mb-3 '><a><img src={patna} alt="no-img" class="img-fluid mx-auto d-block" /> <p class="text-center ">Patna</p></a></div>
                        <div className='rounded-circle py-2 mt-0 mb-3'><a><img src={pune} alt="no-img" class="img-fluid mx-auto d-block" /><p class="text-center ">Pune</p></a></div>
                    </div>
                    <div class="row justify-content-center ">
                        <button class="btn btn-outline-orange mt-lg-3 ">View All</button>
                    </div>
                </div>



            </div>

            <div class="footer">
                <Footer />
            </div>
        </div>


    )
}



export default Landingpage