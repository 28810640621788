import {ApolloClient, InMemoryCache,createHttpLink} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from "apollo-upload-client";
import {
  registerMutation,
  loginMutation,
  vipAccessMutation,
  valueYourPropertyMutation,
  refreshToken,
  addPropertyStepperOne,
  addPropertyStepperTwo,
  addPropertyStepperThree,
  addPropertyStepperFour,
  addPropertyStepperFive,
  addPropertyStepperSix,
  addPropertyStepperSeven,
  imageAndVideoFileUpload,
  officeSpace,
  marriageHall,
  hospital,
  WareHouse,
  OldAgeHome,
  Creche,
  PG,
  saveFavourite,
  saveContact,
  savePropertyValuationRequestquery,
  enquiryRequest,
 } from './mutation-querys';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const registerAPI =(userData)=>{
  console.log(userData,"/////")
  var client = new ApolloClient({
    uri: `${BASE_URL}/auth`,
    cache: new InMemoryCache(),
  });
  return client.mutate({
    mutation: registerMutation,
    variables: userData,
  });
}

export const loginAPI =(userData)=>{
  var client = new ApolloClient({
    uri: `${BASE_URL}/auth`,
    cache: new InMemoryCache(),
  });
  return client.mutate({
    mutation: loginMutation,
    variables: {
      userName:userData.userName ,
       password: userData.password
    },
  });
}

  export const vipAccessAPI = (vipData) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/customer`,
      cache: new InMemoryCache(),
    });
    return client.mutate({
      mutation: vipAccessMutation,
      variables: {
        fullName: vipData.fullName,
        email: vipData.email,
        phoneNumber: vipData.phoneNumber,
        countryId: vipData.countryId,
        cityId: vipData.cityId,
        description: vipData.description
      },
    });
  };

  export const valueYourPropertyAPI = (Data) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/customer`,
      cache: new InMemoryCache(),
    });
    return client.mutate({
      mutation: valueYourPropertyMutation,
      variables: {
        fullName: Data.fullName,
        email: Data.email,
        phoneNumber: Data.phoneNumber,
        countryId: Data.countryId,
        cityId: Data.cityId,
        description: Data.description
      },
    });
  };


  export const refreshTokenAPI = (token) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/auth`,
      cache: new InMemoryCache(),
    });
    return client.mutate({
      mutation: refreshToken,
      variables: {
        refreshToken:token
      },
    });
  };
  
  export const addPropertyStepperOneAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: addPropertyStepperOne,
      variables: {
        rent:Data.rent,
        sell:Data.sell,
        lease:Data.lease,
        shortTermLit:Data.shortTermLit,
        propertyCategoryId:Data.propertyCategoryId,
        propertyTypeId:Data.propertyTypeId,
        customerId:Data.customerId,
        customerTypeId:Data.customerTypeId
      },
    });
  };

  export const addPropertyStepperTwoAPI = (Data,token) => {
    console.log("step2 data",Data)
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: addPropertyStepperTwo,
      variables: {
        propertyId: Data.propertyId,
        availableFrom: Data.availableFrom,
        noOfFloors: Data.noOfFloors,
        landmark: Data.landmark,
        detailedFeatures:Data.detailedFeatures
      },
    });
  };

  export const addPropertyStepperThreeAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: addPropertyStepperThree,
      variables: {
        propertyId: Data.propertyId,
        countryName: Data.countryName,
        stateName: Data.stateName,
        cityName: Data.cityName,
        floorNo: Data.floorNo,
        postalCode: Data.postalCode,
        areaName: Data.areaName,
        street: Data.street,
        latitude: Data.latitude,
        longitude: Data.longitude,
      },
    });
  };

  export const addPropertyStepperFourAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 

    return client.mutate({
      mutation: addPropertyStepperFour,
      variables: {
        rent:Data.rent,
        sell:Data.sell,
        lease:Data.lease,
        shortTermLit:Data.shortTermLit,
        rentAmount: Data.rentAmount,
        description:Data.description,
        furnishingType:Data.furnishingType,
        propertyId:Data.propertyId,
        noticePeriod:Data.noticePeriod,
        durationOfContract:Data.durationOfContract,
        maintenanceCharge:Data.maintenanceCharge,
        whoWillShow:Data.whoWillShow,
        depositAmount:Data.depositAmount,
      },
    });
  };

  export const addPropertyStepperFiveAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 

    return client.mutate({
      mutation: addPropertyStepperFive,
      variables: {
        propertyId:Data.propertyId,
        amenityType: Data.amenities,
       
      },
    });
  };

  export const addPropertyStepperSixAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: addPropertyStepperSix,
      variables: {
        customerId: Data.customerId,
        propertyId: Data.propertyId,
        //appointmentTypeId:Data.appointmentTypeId,
        noOfSlots: Data.noOfSlots,
        //availabilityDate: Data.availabilityDate,
        availabilityDay: Data.availabilityDay,
        availabilityStartTime: Data.availabilityStartTime,
        availabilityEndTime: Data.availabilityEndTime
      },
    });
  };

  export const addPropertyStepperSevenAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: addPropertyStepperSeven,
      variables: {
        imageUrl: Data.imageUrl,
        propertyId: Data.propertyId,
        videoUrl: Data.videoUrl,
        imageMimeType:Data.imageMimeType,
        videoMimeType: Data.videoMimeType,
      },
    });
  };

  export const imageAndVideoUploadAPI = (Data) => {
    console.log(".....Data",Data)

    // var client = new ApolloClient({
    //   uri: `${BASE_URL}/graphql`,
    //   cache: new InMemoryCache(),
    // }); 
    const client = new ApolloClient({
      link: createUploadLink({
      uri: `${BASE_URL}/graphql`,
      }),
      cache: new InMemoryCache(),
  });
    return client.mutate({
      mutation: imageAndVideoFileUpload,
      variables: {
        customer_id:Data.customer_id,
        property_id:Data.property_id,
        location_id:Data.location_id,
        is_featured:Data.is_featured,
        is_sale:Data.is_sale,
        is_rent:Data.is_rent,
        is_lease:Data.is_lease,
        category_id:Data.category_id,
        category_name:Data.category_name,
        files:Data.files
      },
    });
  };

  export const offceSpaceAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: officeSpace,
      variables: {
        propertyId:Data.propertyId,
        totalFloors: Data.totalFloors,
        superArea:Data.superArea,
        carpetArea:Data.carpetArea,
        possessionStatus:Data.possessionStatus,
        propertyAge:Data.propertyAge,
        detailedFeatures:Data.detailedFeatures
      }
    });
  };

  export const marriageHallAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: marriageHall,
      variables: {
        propertyId: 17,
        runBy:"gov",
        guestCapacity:"123",
        rooms:"22",
        budget:"2lakh",
        foundationYear:"2020",
        email:"mnajn",
        contactNumber:"345345",
        governmentLicenceNumber:"3456mmd",
        detailedFeatures: [
        {
          detailedFeatureTypeId: 2,
          detailedFeatureName: "Bathroom",
          noOfDetailedFeatures: 11,
          detailedFeatureDetails: "[]"
        }
        ]
      }
    });
  };



  export const hospitalAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: hospital,
      variables: {
        propertyId: Data.propertyId,
        runBy:Data.runBy,
        category:Data.category,
        grade:Data.grade,
        registrationNumber:Data.registrationNumber,
        email:Data.email,
        number:Data.number,
        postedBy:Data.postedBy,
        patientCheckingFacility:Data.patientCheckingFacility,
        detailedFeatures: Data.detailedFeatures
      }
    });
  };

  export const WareHouseAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: WareHouse,
      variables: {
        propertyId:Data.propertyId,
        runBy:Data.runBy,
        storageCapacity:Data.storageCapacity,
        nature:Data.nature,
        accessType:Data.accessType,
        typeOfStore:Data.typeOfStore,
        technology:Data.technology,
        detailedFeatures:Data.detailedFeatures
      }
    });
  };

  export const OldAgeHomeAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: OldAgeHome,
      variables: {
        propertyId: Data.propertyId,
        runBy: Data.runBy,
        oldAgeName: Data.oldAgeName,
        occupancyTypeId: Data.occupancyTypeId,
        oldAgeRange: Data.oldAgeRange,
        operatingStatusId: Data.operatingStatusId,
        licenseNumber: Data.licenseNumber,
        preferredGender: Data.preferredGender,
        detailedFeatures:  Data.detailedFeatures
      }
    });
  };

  export const CrecheAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: Creche,
      variables: {
        propertyId:Data.propertyId,
        runBy:Data.runBy,
        ageRange:Data.ageRange,
        crecheName:Data.crecheName,
        operatingStatusId:Data.operatingStatusId,
        licenseNumber:Data.licenseNumber,
        preferredGender:Data.preferredGender,
        inTime:Data.inTime,
        outTime:Data.outTime,
        closedOnId:Data.closedOnId,
        detailedFeatures:Data.detailedFeatures
      }
    });
  };

  export const PGAPI = (Data,token) => {
    const httpLink = createHttpLink({
      uri: `${BASE_URL}/propadd`});
      const authLink = setContext((_, { headers }) => {
      return {
      headers: {...headers,
      Authorization: `Bearer ${token}`}
      }
      }); 
      const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
      }); 
    return client.mutate({
      mutation: PG,
      variables: {
        propertyId:Data.propertyId,
        runBy:Data.runBy,
        occupancyTypeId:Data.occupancyTypeId,
        licenseNumber:Data.licenseNumber,
        typeId:Data.typeId,
        hostelName:Data.hostelName,
        detailedFeatures: Data.detailedFeatures
      }
    });
  };

  export const saveFavouriteAPI = (Data) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/lawyer`,
      cache: new InMemoryCache(),
    });
    return client.mutate({
      mutation: saveFavourite,
      variables: {
        lawyerId:Data.lawyerId,
        userId:Data.userId
      }
    });
  };

  export const saveContactUs = (Data) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/customer`,
      cache: new InMemoryCache(),
    });
    return client.mutate({
      mutation: saveContact,
      variables: {
        email :Data.email,
        firstName :Data.firstName,
        lastName :Data.lastName,
        userFrom:Data.userFrom,
        message:Data.message,
      }
    });
  };
  export const savePropertyValuationRequestAPI = (variables) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/customer`,
      cache: new InMemoryCache(),
    });
    return client.mutate({
      mutation: savePropertyValuationRequestquery,
      variables
    });
  };

  export const  enquiryRequestAPI = (variables) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.mutate({
      mutation: enquiryRequest,
      variables
    });
  };