import React,{Component} from 'react';
import './deposit-replacements.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import depositServImg1 from '../../../../assets/images/services-page/2-deposits/bar-graph.svg' ;
import depositServImg2 from '../../../../assets/images/services-page/2-deposits/deposit.svg' ;
import depositServImg3 from '../../../../assets/images/services-page/2-deposits/image-1.png' ;
import depositServImg4 from '../../../../assets/images/services-page/2-deposits/image-2.png' ;
import depositServImg5 from '../../../../assets/images/services-page/2-deposits/image-3.png' ;
import depositServImg6 from '../../../../assets/images/services-page/2-deposits/image-4.png' ;
import depositServImg7 from '../../../../assets/images/services-page/2-deposits/image-5.png' ;
import depositServImg8 from '../../../../assets/images/services-page/2-deposits/image-6.png' ;
import depositServImg9 from '../../../../assets/images/services-page/2-deposits/image-7.png' ;
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {scrollTop} from '../../common/common';

class DepositReplacements extends Component {

    componentDidMount(){
        scrollTop();
     }

    render(){
        return(
            <div>
                <Header/>
                    <div className="service-banner serv-two page-top-banner banner-with-form mb-3 mb-lg-5">
                        <div className="service-banner-content text-center">
                            <h2 className="text-white mb-3"><span className="text-orange">Deposit</span> Replacements</h2>
                            <h5>We offer choices so everyone stays protected.</h5>
                            <h5>Lower upfront costs with our Security Deposit Replacement.</h5>
                        </div>
                        <div className="banner-form">
                            <form>
                                <div className="mb-3">
                                    <input type="text" className="form-control br-0" placeholder="Full Name"/>
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control br-0" placeholder="Email"/>
                                </div>
                                <div className="mb-3">
                                    <input type="number" className="form-control br-0" placeholder="Phone number"/>
                                </div>
                                <div className="mb-3">
                                    <textarea className="form-control br-0" rows="4" placeholder="Message"></textarea>
                                </div>
                                <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                            </form>
                        </div>
                    </div>
                    <div className="container mx-auto">
                        <div className="row mt-4 mb-5 align-items-center">
                            <div className="col-lg-8 mb-2">
                                <div className="service-content-box px-0">
                                    <h5 className="page-title">Lower Move-In Costs</h5>
                                    <p className="mb-2">Lower Upfront Costs with Our Security Deposit Replacement: </p>
                                    <p>We lower the barrier to entry for tenants while still ensuring landlords get the protection they need. </p>
                                    <h6>Deposit replacement choices: The tips you need to know. </h6>
                                    <p className="mb-2">As a landlord or agent using ShiftEasy, what do I need to know? </p>
                                    <p className="mb-3">According to the latest extensive survey organised by ShiftEasy, around 30% of agents, landlords, and tenants would believe in using a deposit replacement option in the imminent future</p>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center">
                                <img src={depositServImg1} className="img-fluid"/>
                            </div>
                        </div>
                        <div className="row my-lg-4 my-5 text-center">
                            <h4 className="col-12 mb-4 page-sub-title">How it Works for Tenance:</h4>
                            <div className="col-md-4 mb-3">
                                <div className="shadow-cus service-content-box h-100">
                                    <h6 className="pt-3">1. Opt-In to Replace Your Deposit</h6>
                                    <img src={depositServImg2} alt="icon" className="my-3"/>
                                    <p className="mb-0">Let your building know you would like to replace your deposit.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="shadow-cus service-content-box h-100">
                                    <h6 className="pt-3">2. Pay a Small Fee</h6>
                                    <img src={depositServImg2} alt="icon" className="my-3"/>
                                    <p className="mb-0">Pay a small one-time fee using any major credit card. It’s just 5-10% of your security deposit!</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="shadow-cus service-content-box h-100">
                                    <h6 className="pt-3">3. Save Your Cash</h6>
                                    <img src={depositServImg2} alt="icon" className="my-3"/>
                                    <p className="mb-0">That’s it! Now, you’ll have extra cash for the things you actually want to spend your money on!</p>
                                </div>
                            </div>
                        </div>
                        <div className="row my-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="service-content-box">
                                    <h5 className="page-title mb-3">ShiftEasy's Deposit Replacement:</h5>
                                    <h6>How do we work? </h6>
                                    <p>ShiftEasy's Deposit Replacement plan guarantees the same financial protection to landlords as a traditional deposit does but eliminates property-owners legal compulsion to register a deposit, consequently detaching the risk of fines for failing to protect the deposit, which can cost a fortune. </p>
                                    <p>In Essence, ShiftEasy's Deposit Replacement Membership curtails and streamlines the rental/leasing process for landlords, agents, and tenants. </p>
                                    <p>ShiftEasy aims to remove the admin and government of shielding, housing and later issuing a cash deposit; remove the double charging issue where tenants are compelled to leave for a fresh housing deposit without getting their old one from their former rental house.</p>
                                </div>
                            </div>
                            <div className="col-md-6 mx-auto">
                                <img src={depositServImg3} className="img-fluid" alt="img"/>
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col-lg-6 order-lg-2">
                                <div className="service-content-box">
                                    <h5 className="page-title mb-3">Why prefer ShiftEasy's Deposit Replacement Plan?</h5>
                                    <h6>Major Benefits for Landlords: </h6>
                                    <ul className="orange-disc pl-4">
                                        <li>ShiftEasy removes the risk of deposit protection-related penalties, as there is no massive amount of deposit money to protect. </li>
                                        <li>ShiftEasy's Deposit Replacement plan provides equal protection to property-owners as conventional deposits. </li>
                                        <li>Deposit replacements require less administration than traditional deposits, saving time and energy for property owners. </li>
                                        <li>ShiftEasy's globe ranked mediators are incredibly experienced with resolving end of tenancy issues. </li>
                                        <li>Restructured disputes since there is no money to handle or collect, therefore owners can make a decision much quicker. </li>
                                    </ul>
                                    <h6>Major benefits for agents: </h6>
                                    <ul className="orange-disc pl-4">
                                        <li>ShiftEasy's Deposit Replacement plans provide equal protection to traditional deposits. </li>
                                        <li>To improve cash flow opportunities for the tenants to reduce possible conflicts between tenants and managing agents. </li>
                                        <li>Decreased management and judicial consequences to agents, as the absence of an upfront deposit implies no deposit to safeguard. </li>
                                    </ul>
                                    <h6>Major benefits for tenants: </h6>
                                    <ul className="orange-disc pl-4">
                                        <li>Prevent the 'double charges' linked with shifting to a new rental or leased property; the tenants are typically compelled to pay a new deposit to reclaim their old deposit from the former property. </li>
                                        <li>ShiftEasy's Deposit Replacement plans drastically ease the upfront cost of relocating to a new home. </li>
                                        <li>ShiftEasy's Deposit Replacement plans are tailored and are flexible in that payments can be split between a household or a group of people or managed by one tenant. </li>
                                        <li>The pressure of saving or carrying out a loan for a deposit is eliminated, proposing financial freedom to the tenant. Relocation Redesigned!!! Enjoy the best of class comfort with ShiftEasy. How to sign up for a plan? Shift Easy plans are tailored to your individual needs and can be set up immediately. The procedure is roughly the same for property owners and agents, though agents will also be obliged to enter the landlord's details.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 mx-auto">
                                <img src={depositServImg4} className="img-fluid resp-img-ht" alt="img"/>
                            </div>
                        </div>
                        <div className="row my-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="service-content-box">
                                    <h5 className="page-title mb-3">Simply follow the steps below to set up the plan: </h5>
                                    <ol className="text-orange pl-3 mb-2 font-weight-600">
                                        <li>Add the property. </li>
                                        <li>Add the tenancy details and specifications. </li>
                                        <li>Invite the tenant. </li>
                                    </ol>
                                    <p>Once the plan is set up, Shift Easy will then send an invitation email to tenants to set up payment for the plan, and once payment is set up, the property owner or agent will receive authorisation of the plan through email. </p>
                                    <p>If you are a tenant, wait for the invitation mail to set up your plan. The property owner is secured as soon as the first payment has been made by the tenant agreeing to their plan. How much does it cost? </p>
                                    <p>ShiftEasy's Deposit Replacement plan will approach all households a Decided INR/ POUNDS set up cost and a small monthly subscription for the duration of the tenancy – starting at just at Decided INR/ POUNDS (it can be split among the household). </p>
                                    <p>The tenants will pay a lesser amount per month. This indicates tenants have the choice of either five weeks rent equal cash deposit or just about one day's rent equal to the Deposit Replacement plan plus the small initial fee.</p>
                                </div>
                            </div>
                            <div className="col-md-6 mx-auto">
                                <img src={depositServImg5} className="img-fluid" alt="img"/>
                            </div>
                        </div>
                        <div className="row my-lg-4 my-4 text-center">
                            <h4 className="col-12 mb-3 page-sub-title">ShiftEasy's Pocket-Friendly Deposit Replacement Plans</h4>
                            <h4 className="col-12 mb-4 page-sub-title font-weight-normal">Cost of Traditional Deposit | ShiftEasy's cost per household per month</h4>
                            <div className="col-lg-3 col-md-6 mb-3">
                                <div className="shadow-cus p-3 h-100 bg-orange text-white br-5">
                                    <p className="mb-2">0-750 (INR / GBP) </p>
                                    <p className="mb-2">500 INR / 5 GBP </p>
                                    <h6>Budget plan</h6>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-3">
                                <div className="shadow-cus p-3 h-100 bg-orange text-white br-5">
                                    <p className="mb-2">751-1250 (INR/GBP) </p>
                                    <p className="mb-2">1500 INR / 10 GBP </p>
                                    <h6>Mega plan</h6>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-3">
                                <div className="shadow-cus p-3 h-100 bg-orange text-white br-5">
                                    <p className="mb-2">1251 – 1750 (INR/GBP) </p>
                                    <p className="mb-2">2500 INR /15 GBP </p>
                                    <h6>Maxx plan</h6>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-3">
                                <div className="shadow-cus p-3 h-100 bg-orange text-white br-5">
                                    <p className="mb-2">1750 - 2250 (INR/GBP) </p>
                                    <p className="mb-2">3500 INR /20 GBP </p>
                                    <h6>Ultra plan</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row my-5 align-items-center">
                            <h5 className="page-title mb-4 col-12 text-center">My responsibilities?</h5>
                            <div className="col-lg-6">
                                <div className="service-content-box">
                                    <h6>Note to Landlords and agents: </h6>
                                    <p>To give the best opportunities to our tenants, it is a lawful necessity of landlords and agents to offer traditional deposit security along with deposit replacements. </p>
                                    <p>You cannot force someone to use a deposit replacement option, or else this can be categorised as a fee, which is illegal in line along with the Model Tenancy Act (INDIA)/ Tenant Fees Act (UK) </p>
                                    <p>We also ask that you make sure your tenants meet our least referencing benchmarks and perform check-in and check-out reports prior to inviting the tenants. </p>
                                    <p>We eliminate the need to safeguard the deposit, there is no publishing of specified information within a time frame, and no risk of obtaining fines as a landlord/property owner – which can be up to three times the deposit plus the deposit itself in some cases like breaching the contract.</p>
                                </div>
                            </div>
                            <div className="col-md-6 mx-auto">
                                <img src={depositServImg6} className="img-fluid" alt="img"/>
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col-lg-6 order-lg-2">
                                <div className="service-content-box">
                                    <h6>Note to Tenants: </h6>
                                    <p>Tenants have the same concerns and duties as they would have with a traditional deposit in terms of giving all the rent and costs/bills as per the tenancy agreement. Tenants must also pay compensation to the property owner if any damage is encountered. </p>
                                    <h6>At ShiftEasy, we are big on transparency and fair-mindedness!!!</h6>
                                    <p>We believe it is fair that owners are compensated if something is broken or the tenancy agreement is violated. Our deposit replacement is not an insurance product, and the occupant must fulfil and abide with any privately agreed or granted disbursements as agreed by our mediators.</p>
                                </div>
                            </div>
                            <div className="col-md-6 mx-auto">
                                <img src={depositServImg7} className="img-fluid" alt="img"/>
                            </div>
                        </div>
                        <div className="row my-5">
                            <h5 className="page-title mb-4 col-12 text-center">Frequently Asked Questions About Deposit Replacement</h5>
                            <div className="col-lg-6">
                                <div className="service-content-box">
                                    <h6 className="mb-3">1. What makes ShiftEasy different from other deposit replacement schemes? </h6>
                                    <p>In Recent Times, deposit replacements have begun to grow popular, as residents seek flexibility and brand-new ways of renting. ShiftEasy's expert knowledge and rental welfare facilities make it a distinctive option for renters looking for a change. </p>
                                    <p>The easy DIY replacement options are frequently suggested by real estate agents themselves and have been the target of disapproval by certain tenants who feel they were mis-sold. </p>
                                    <p>The DIY deposit replacement opportunities from other competitive platforms are often priced excessive compared to other replacement options. ShiftEasy's Deposit Replacement plan is more than eight times less expensive per month than other popular agent run memberships.</p>
                                    <h6 className="mb-3">2. After your tenants move out: </h6>
                                    <p>Once your tenants move out, just let us know by closing the tenancy in the ShiftEasy portal. As soon as they have exited the property, you and your tenant will be capable of reviewing any end of tenancy agreements/settlements based on the proof in the check-out report. If you are both pleased with the suggested settlements, the tenant can reimburse you on a happy note.</p>
                                    <h6 className="mb-3">3. What if our tenant differs from our recommended end of rental settlements? </h6>
                                    <p>-Occasionally tenants and property owners strive to agree on end of tenancy agreements, which at ShiftEasy, we handle like a breeze. </p>
                                    <p>-Our ShiftEasy Pro Solutions team, with their immense expertise in this field, have been resolving deposit disagreements for ages.</p>
                                    <h6 className="mb-3">4. What if our tenant does not pay? </h6>
                                    <p>-If, after our ShiftEasy Pro Solutions team decides, the tenant be obliged to you, the owner, some or all of the suggested end of the tenancy agreement, and they do not pay, we will march in and make it to the point that the landlord, receive the money outstanding. </p>
                                    <p>-The Tenants contractually approve to compensate us should such a state arise, and hence we will take over the charge of regaining the cash from the tenant by all legal means if required. </p>
                                    <p>-Just sit back and relax while our ShiftEasy Pro Team do all the needful. </p>
                                    <p>-Connect to us on our chat or mail us at <a href="mailto:contact@shifteasy.com" className="text-orange">contact@shifteasy.com</a> our ShiftEasy Pro agent will be at your service.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img src={depositServImg8} className="img-fluid resp-img-ht" alt="img"/>
                            </div>
                        </div>
                        <div className="row my-5 refer-sec p-2 p-lg-4">
                            <div className="col-lg-4 mb-3 my-3 text-center">
                                <img src={depositServImg9} className="img-fluid" />
                            </div>
                            <div className="col-lg-8 my-3">
                                <div className="service-content-box pb-0">
                                    <ul className="pl-3 orange-disc">
                                        <li>Shift like a Pro with ShiftEasy!!</li>
                                        <li>Breathe and say ShiftEasy !! Your one-stop destination for all your Housing needs.</li>
                                        <li>Are you moving to a new place? Need a house? Need services? Stop panicking; we are right here!!! Contact ShiftEasy, your one-stop shop for all your needs services.</li>
                                        <li>Enjoy the royal services at ShiftEasy; our customers happen to be our Kings and Queens!!!</li>
                                        <li>Call us to tailor your needs and build customised plans as per your requirement.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row my-5 text-center">
                            <h4 className="col-12 mb-3 page-sub-title">Still Have Questions?</h4>
                            <button className="btn btn-orange px-3 px-lg-5 mx-auto">Contact Us</button>
                        </div>
                    </div>
                {/* </div> */}
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };
  
  export default connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(DepositReplacements));