import { gql } from '@apollo/client';


export const registerMutation = gql`
mutation createUser(
  $firstName:String!,
  $lastName:String!,
  $email:String!,
  $password:String!,
  $mobileNumber:String!
  $whatsAppNumber:Boolean!
  $originSite: String!
){
  createUser(user: {
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      mobileNumber: $mobileNumber
      whatsAppNumber:$whatsAppNumber
      originSite: $originSite
  }) 
}`

export const loginMutation = gql`
mutation login($userName:String!,$password:String!){
  login(userName:$userName , password: $password)
{
   token
   expiresIn
   refreshExpiresIn
   refreshToken
   tokenType
   idToken
   notBeforePolicy
   sessionState
   scope
}
}`

export const vipAccessMutation = gql`
  mutation saveVipCustomerAccessRequest(
    $fullName: String!,
    $email: String!,
    $phoneNumber: String!,
    $countryId: Int!,
    $cityId: Int!,
    $description: String!
  ){
    saveVipCustomerAccessRequest(vipAccessRequest:{
    fullName: $fullName,
    email: $email,
    phoneNumber: $phoneNumber,
    countryId: $countryId,
    cityId: $cityId,
    description: $description
})
}`

export const valueYourPropertyMutation = gql`
mutation savePropertyValuationRequest(
  $fullName: String!,
  $email: String!,
  $phoneNumber: String!,
  $countryId: Int!,
  $cityId: Int!,
  $description: String!
){
  savePropertyValuationRequest(propertyValuationRequest:{
    fullName: $fullName,
    email: $email,
    phoneNumber: $phoneNumber,
    countryId: $countryId,
    cityId: $cityId,
    description: $description
}) 
}`
export const refreshToken = gql`
mutation refreshAccessToken(
  $refreshToken:String!
){
  refreshAccessToken(refreshToken:$refreshToken)
  {
      token
      expiresIn
      refreshExpiresIn
      refreshToken
      tokenType
      idToken
      notBeforePolicy
      sessionState
      scope

 }
}
`

export const addPropertyStepperOne = gql`
mutation saveDetails(
  $rent: Boolean!
  $sell: Boolean!
  $lease: Boolean!
  $shortTermLit: Boolean!
  $propertyCategoryId: Int!
  $propertyTypeId: Int!
  $customerId: String!
  $customerTypeId: Int!
){
  saveDetails(details: {
  rent: $rent
  sell: $sell
  lease: $lease
  shortTermLit: $shortTermLit
  propertyCategoryId: $propertyCategoryId
  propertyTypeId: $propertyTypeId
  customerId: $customerId
  customerTypeId: $customerTypeId
  })
} `

export const addPropertyStepperTwo = gql`
mutation saveDetailedFeatures(
  $propertyId:Int!
  $availableFrom:String!
  $noOfFloors:Int!
  $landmark:String
  $detailedFeatures:[DetailedFeatureElement]!
) {
  saveDetailedFeatures (features:{
    propertyId: $propertyId
    availableFrom: $availableFrom
    noOfFloors: $noOfFloors
    landmark:$landmark
   detailedFeatures:$detailedFeatures
  })
}
`
export const addPropertyStepperThree = gql`
mutation saveAddress(

  $propertyId: Int!
  $countryName: String!
  $stateName: String!
  $cityName: String!
  $floorNo: String!
  $postalCode: String!
  $areaName: String!
  $street: String!
  $latitude: String!
  $longitude: String!
){
  saveAddress(address: {
 
  propertyId: $propertyId
  countryName: $countryName
  stateName: $stateName
  cityName: $cityName
  floorNo: $floorNo
  postalCode: $postalCode
  areaName: $areaName
  street: $street
  latitude: $latitude
  longitude: $longitude
  })
} `



export const addPropertyStepperFour = gql`
mutation savePrice(
  $rent: Boolean!
  $sell: Boolean!
  $lease: Boolean!
  $shortTermLit: Boolean!
  $rentAmount: Int!
  $description: String!
  $furnishingType: Int!
  $propertyId: Int!
  $noticePeriod: String!
  $durationOfContract: String!
  $maintenanceCharge: Int!
  $whoWillShow: String!
  $depositAmount: Int!
){
  savePrice(price: {
  rent: $rent
  sell: $sell
  lease: $lease
  shortTermLit: $shortTermLit,
  rentAmount: $rentAmount
  description: $description
  furnishingType: $furnishingType
  propertyId: $propertyId
  noticePeriod: $noticePeriod
  durationOfContract: $durationOfContract
  maintenanceCharge: $maintenanceCharge
  whoWillShow: $whoWillShow
  depositAmount: $depositAmount
  })
} `

export const addPropertyStepperFive = gql`
mutation saveAmenities(
  $propertyId: Int!
  $amenityType:[Int!]!
 
){
  saveAmenities(amenity: {
  propertyId: $propertyId
  amenityType: $amenityType
  })
} `

export const addPropertyStepperSix = gql`

mutation saveSlot(
  $propertyId:Int!
  $availabilityDay:[String!]!
  $noOfSlots:Int!
  $availabilityStartTime:String!
  $availabilityEndTime:String!
  $customerId:String!
){
  saveSlot(slot: {
  propertyId:$propertyId
  availabilityDay:$availabilityDay
  noOfSlots:$noOfSlots
  availabilityStartTime:$availabilityStartTime
  availabilityEndTime:$availabilityEndTime
  customerId:$customerId
})
}`



export const addPropertyStepperSeven = gql`
mutation saveImageVideo(
  $imageUrl:[String]!
  $propertyId: Int!
  $videoUrl: String!
  $imageMimeType: String!
  $videoMimeType: String!
){
  saveImageVideo(imageAndVideo: {
  imageUrl: $imageUrl
  propertyId: $propertyId
  videoUrl: $videoUrl
  imageMimeType:$imageMimeType
  videoMimeType: $videoMimeType
  })
} `

export const imageAndVideoFileUpload = gql`
mutation (
  $customer_id:String!
  $property_id:Int!
  $location_id:Int!
  $is_featured:Boolean!
  $is_sale:Boolean!
  $is_rent:Boolean!
  $is_lease:Boolean!
  $category_id:Int!
  $category_name:String!
  $files:[Upload!]!
  ){
  multipleFiles(input:[{
  customer_id:$customer_id
  property_id:$property_id
  location_id:$location_id
  is_featured:$is_featured
  is_sale:$is_sale
  is_rent:$is_rent
  is_lease:$is_lease
  category_id:$category_id
  category_name:$category_name
  files:$files
   }]){
  category_id
  file_path
}
}
`

export const officeSpace = gql`
mutation (
  $propertyId:Int!
  $totalFloors:Int!
  $superArea:String!
  $carpetArea:String!
  $possessionStatus:String!
  $propertyAge:String!
  $detailedFeatures:[DetailedFeatureElement]!
){
  commercialOfficeSpaceDetails(officeSpace:{
  propertyId:$propertyId
  totalFloors: $totalFloors
  superArea:$superArea
  carpetArea:$carpetArea
  possessionStatus:$possessionStatus
  propertyAge:$propertyAge
  detailedFeatures:$detailedFeatures
  })
  }
`

export const marriageHall = gql`
mutation{
  saveMarriageHall(marriageHall:{
  propertyId: 17
  runBy:"gov"
  guestCapacity:"123"
  rooms:"22"
  budget:"2lakh"
  foundationYear:"2020"
  email:"mnajn"
  contactNumber:"345345"
  governmentLicenceNumber:"3456mmd"
  detailedFeatures: [
  {
    detailedFeatureTypeId: 2,
    detailedFeatureName: "Bathroom",
    noOfDetailedFeatures: 11,
    detailedFeatureDetails: "[]"
  }
  ]
  })
}
`

export const hospital = gql`
mutation (
  $propertyId:Int!
  $runBy:String!
  $category:[String]!
  $grade:String!
  $registrationNumber:String!
  $email:String!
  $number:String!
  $postedBy:String!
  $patientCheckingFacility:String!
  $detailedFeatures:[DetailedFeatureElement]!
){
  saveHospital(hospital:{
  propertyId: $propertyId
  runBy:$runBy
  category:$category
  grade:$grade
  registrationNumber:$registrationNumber
  email:$email
  number:$number
  postedBy:$postedBy
  patientCheckingFacility:$patientCheckingFacility
  detailedFeatures: $detailedFeatures
  })
}
`
export const WareHouse = gql`
mutation saveStoreroom(
  $propertyId:Int!
  $runBy:String!
  $storageCapacity:String!
  $nature:String!
  $accessType:String!
  $typeOfStore:String!
  $technology:String!
  $detailedFeatures:[DetailedFeatureElement]!
){
saveStoreroom(warehouse:{
 propertyId:$propertyId
 runBy:$runBy
 storageCapacity:$storageCapacity
 nature:$nature
 accessType:$accessType
 typeOfStore:$typeOfStore
 technology:$technology
 detailedFeatures:$detailedFeatures
})
}
`
export const OldAgeHome = gql`
mutation (
  $propertyId: Int!
  $runBy:String!
  $oldAgeName:String!
  $occupancyTypeId:Int!
  $oldAgeRange:String!
  $operatingStatusId:Int!
  $licenseNumber:String!
  $preferredGender:String!
  $detailedFeatures:[DetailedFeatureElement]!
){
  saveOldAgeHome(olgAgeHome:{
    propertyId:$propertyId
    runBy:$runBy
    oldAgeName:$oldAgeName
    occupancyTypeId:$occupancyTypeId
    oldAgeRange:$oldAgeRange
    operatingStatusId:$operatingStatusId
    licenseNumber:$licenseNumber
    preferredGender:$preferredGender
    detailedFeatures: $detailedFeatures
})
}
`
export const Creche = gql`
mutation (
  $propertyId:Int!
  $runBy:String!
  $ageRange:String!
  $crecheName:String!
  $operatingStatusId:Int!
  $licenseNumber:String!
  $preferredGender:String!
  $inTime:String!
  $outTime:String!
  $closedOnId:Int!
  $detailedFeatures:[DetailedFeatureElement]!

){
  saveCreche(creche:{
  propertyId:$propertyId
  runBy:$runBy
  ageRange:$ageRange
  crecheName:$crecheName
  operatingStatusId:$operatingStatusId
  licenseNumber:$licenseNumber
  preferredGender:$preferredGender
  inTime:$inTime
  outTime:$outTime
  closedOnId:$closedOnId
  detailedFeatures:$detailedFeatures
  })
}
`
export const PG = gql`
mutation (
  $propertyId:Int!
  $runBy:String!
  $occupancyTypeId:Int!
  $licenseNumber:Sting!
  $typeId:Int!
  $hostelName:String!
  $detailedFeatures:[DetailedFeatureElement]!
){
savePg(pg:{
 propertyId:$propertyId
 runBy:$runBy
 occupancyTypeId:$occupancyTypeId
 licenseNumber:$licenseNumber
 typeId:$typeId
 hostelName:$hostelName
 detailedFeatures: $detailedFeatures
 })
}
`

//  export const CommercialShop = gql`
//  mutation saveCommercialShop (
//    $propertyId:Int!
//    $status:String!
//    $facingId:Int!
//    $furnishingStatus:Int!
//    $priceNegotiable:Boolean!
//    $superBuildUpArea:String!
//    $carpetArea:String!
//    $coveredArea:String!
//    $entranceWidth:String!
//    $entranceLength:String!
//    $detailedFeatures:String!
//  ){
//   saveCommercialShop(shop:{
//   propertyId:$propertyId
//   status:$status
//   facingId:$facingId
//   furnishingStatus:$furnishingStatus
//   priceNegotiable:$priceNegotiable
//   superBuildUpArea:$superBuildUpArea
//   carpetArea:$carpetArea
//   coveredArea:$coveredArea
//   entranceWidth:$entranceWidth
//   entranceLength:$entranceLength
//   detailedFeatures:[DetailedFeatureElement]!
  
//   })
// }
// `

export const saveFavourite =gql`
mutation (
  $lawyerId:Int!
  $userId:String!
){
  saveFavourite(favourite:{
  lawyerId:$lawyerId
  userId:$userId
  })
}
`


export const saveContact =gql`
mutation (
  $email :String!
  $firstName :String!
  $lastName :String!
  $userFrom:String!
  $message:String!
 
){
  saveContactUs(contactUs: {
    email :$email
   firstName :$firstName
   lastName :$lastName
   userFrom:$userFrom
   message:$message
  
   }
   )
}
`
export const savePropertyValuationRequestquery = gql`
  mutation (
    $fullName: String!
    $email: String!
    $phoneNumber: String!
    $country: String!
    $state: String!
    $city: String!
    $description: String!
  ) {
    savePropertyValuationRequest(
      propertyValuationRequest : {
        fullName: $fullName
        email: $email
        phoneNumber: $phoneNumber
        country: $country
        state: $state
        city: $city
        description: $description
      }
    )
  }
`;

export const enquiryRequest = gql`
  mutation (
    $firstName:String!
    $email:String!
    $phoneNumber:String!
    $message:String!
    $propertyId:Int
    $customerId:String
  ) {
    enquiry(
      input:{
        firstName:$firstName
        email:$email
        phoneNumber:$phoneNumber
        message:$message
        propertyId:$propertyId
        customerId:$customerId
      }
    )
  }
`;