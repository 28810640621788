import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../reducers/reducers-index';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2 
 };

const middleware = [ thunk ]
if (process.env.REACT_APP_ENV_NAME !== 'production') {
  middleware.push(createLogger())
}
const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer,applyMiddleware(...middleware));
export const persistor = persistStore(store);