// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-sec {
    width: 300px;
    position: fixed;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #777;
    z-index: 11;
}

.user-pic-sec {
    margin: 50px auto 30px;
    text-align: center;
}

.user-pic-sec .user-pic {
    border: 3px solid #E1601F;
    border-radius: 50%;
}

.change-user-pic {
    position: absolute;
    left: 165px;
}

.side-menu-list {
    list-style: none;
    padding-left: 0;
    overflow-y: auto;
    max-height: calc(100vh - 250px);
}

.side-menu-item {
    color: #B4B4B4;
    padding: 6px 16px;
    /* border-top: 1px solid rgb(255 255 255 / 16%); */
}


/* .side-menu-item:last-child {
    border-bottom: 1px solid rgb(255 255 255 / 16%);
} */

.side-menu-item.active {
    background-color: #E1601F;
    color: #fff;
}

.side-menu-item.active svg path {
    fill: #fff;
}

.side-menu-item img {
    width: 26px;
    height: auto;
    margin: 10px 14px;
}

.side-menu-item a {
    text-decoration: none;
    color: #FFF;
    display: flex;
    align-items: center;
    font-size: 16px;
}

@media only screen and (max-width:767px) {
    .left-sec {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/side-menu/side-menu.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,SAAS;IACT,SAAS;IACT,OAAO;IACP,QAAQ;IACR,0BAA0B;IAC1B,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,+BAA+B;AACnC;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kDAAkD;AACtD;;;AAGA;;GAEG;;AAEH;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".left-sec {\n    width: 300px;\n    position: fixed;\n    top: 70px;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    border-top: 1px solid #777;\n    z-index: 11;\n}\n\n.user-pic-sec {\n    margin: 50px auto 30px;\n    text-align: center;\n}\n\n.user-pic-sec .user-pic {\n    border: 3px solid #E1601F;\n    border-radius: 50%;\n}\n\n.change-user-pic {\n    position: absolute;\n    left: 165px;\n}\n\n.side-menu-list {\n    list-style: none;\n    padding-left: 0;\n    overflow-y: auto;\n    max-height: calc(100vh - 250px);\n}\n\n.side-menu-item {\n    color: #B4B4B4;\n    padding: 6px 16px;\n    /* border-top: 1px solid rgb(255 255 255 / 16%); */\n}\n\n\n/* .side-menu-item:last-child {\n    border-bottom: 1px solid rgb(255 255 255 / 16%);\n} */\n\n.side-menu-item.active {\n    background-color: #E1601F;\n    color: #fff;\n}\n\n.side-menu-item.active svg path {\n    fill: #fff;\n}\n\n.side-menu-item img {\n    width: 26px;\n    height: auto;\n    margin: 10px 14px;\n}\n\n.side-menu-item a {\n    text-decoration: none;\n    color: #FFF;\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n}\n\n@media only screen and (max-width:767px) {\n    .left-sec {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
