import React,{Component} from 'react';
import './legal-services-near-you.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import search2 from '../../../../assets/images/search-w.svg';
import heartNotFilled from '../../../../assets/images/svg-icons/heart-notfilled.svg';
import heartFilled from '../../../../assets/images/svg-icons/heart-filled.svg';
import filterIcon from '../../../../assets/images/svg-icons/filter.svg';
import searchOrange from '../../../../assets/images/svg-icons/search-orange.svg';
import {scrollTop} from '../../common/common';
import starNotFilled from '../../../../assets/images/svg-icons/star-notfilled.svg';
import starFilled from '../../../../assets/images/svg-icons/star-filled.svg';
import phone from '../../../../assets/images/services-page/9-legal-services/phone.svg';
import tags from '../../../../assets/images/services-page/9-legal-services/tags.svg';
import experiance from '../../../../assets/images/services-page/9-legal-services/experience.svg';
import locationSmall from '../../../../assets/images/svg-icons/location-small.svg';
import ItemsCarousel from 'react-items-carousel';
import leftarrow from '../../../../assets/images/leftarrow.png';
import rightarrow from '../../../../assets/images/rightarrow.png';
import Rec1 from '../../../../assets/images/services-page/9-legal-services/rectangle-1.png';
import Rec2 from '../../../../assets/images/services-page/9-legal-services/rectangle-2.png';
import Rec3 from '../../../../assets/images/services-page/9-legal-services/rectangle-3.png';
import Rec4 from '../../../../assets/images/services-page/9-legal-services/rectangle-4.png';
import card1 from '../../../../assets/images/services-page/9-legal-services/card1.png';
import card2 from '../../../../assets/images/services-page/9-legal-services/card2.png';
import card3 from '../../../../assets/images/services-page/9-legal-services/card3.png';
import card4 from '../../../../assets/images/services-page/9-legal-services/card4.png';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { saveFavouriteAPI } from '../../../../services/mutations/mutation-API';
class LegalServicesNearYouSearchList extends Component {

    constructor (props) {
        super (props) 
        this.state= {
          topLawyersItemIndex: 0,
          width: 0,
          height:0
        }
        window.addEventListener("resize", this.diviceSizeUpdate);

    }
    topLawyersActiveItem = (topLawyersItemIndex) => {
        this.setState({ topLawyersItemIndex });
    }

    componentDidMount(){
        scrollTop();
     }
     diviceSizeUpdate = () => {
        this.setState({
          height: window.innerHeight,
          width: window.innerWidth
        });
      };
      navToDetailsPage=()=>{
          this.props.history.push("/legal-services-near-you-detailed")
      }

      selectFav=(id)=>{
          console.log("click",id)
          //this.props.Login.userDa
          var data ={
            lawyerId:1,
            userId:"123"
          }
          saveFavouriteAPI(data).then((res)=>{
              console.log("res",res)
          })
          .catch((error)=>{
              console.log("error",error)
          })
      }
    render(){
        return(
            <div className="search-list-pg">
            <Header/>
                <div className="header-space">
                    <div className="container-fluid px-30 search-pg flex-wrap flex-lg-nowrap d-mob-none">
                        <nav aria-label="breadcrumb" className="w-100">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Chennai</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Criminal lawyer in chennai</li>
                            </ol>
                        </nav>
                        <div className="d-flex mt-lg-3 mb-3 w-100">
                            <input type="text" className="form-control border-0 shadow-sm bg-dark-light fs-13 mr-2" placeholder="Criminal lawyer" />
                            <button className="btn btn-orange px-4 shadow-sm">Search</button>
                        </div>
                        <div></div>
                    </div>
                    <div className="container-fluid px-0 search-list-sec1">
                        <div className="d-flex py-3 py-md-4 px-30">
                            <div className="search-filter-sec d-mob-none d-web-sm-none">
                                <div className="search-filter-sec-header">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <h5 className="text-white mb-0">Filter</h5>
                                        <a type="button" className="fs-14 text-white text-decoration-none">Reset all</a>
                                    </div>
                                </div>
                                <div className="bg-white p-3 search-filter-sec-body">
                                    <form>
                                        <select className="form-control mb-3">
                                            <option disabled>Select</option>
                                            <option selected>Chennai</option>
                                            <option>Hyderabad</option>
                                        </select>
                                        <select className="form-control mb-3">
                                            <option selected disabled>Select Practice Areas</option>
                                            <option>Practice Area 1</option>
                                            <option>Practice Area 2</option>
                                            <option>Practice Area 3</option>
                                            <option>Practice Area 4</option>
                                        </select>
                                        <select className="form-control mb-3">
                                            <option selected disabled>Select Courts</option>
                                            <option>Courts 1</option>
                                            <option>Courts 2</option>
                                            <option>Courts 3</option>
                                            <option>Courts 4</option>
                                        </select>
                                        <select className="form-control mb-3">
                                            <option selected disabled>Select Experience</option>
                                            <option>Less than 1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                        <select className="form-control mb-3">
                                            <option selected disabled>Select Language</option>
                                            <option>English</option>
                                            <option>Tamil</option>
                                            <option>Hindi</option>
                                            <option>Telugu</option>
                                        </select>
                                        <select className="form-control">
                                            <option selected disabled>Select Gender</option>
                                            <option>Male</option>
                                            <option>Female</option>
                                        </select>
                                    </form>
                                </div>
                            </div>

                            <div className="search-cards-list-sec">
                                <div className="d-web-none br-5 shadow-searchpg">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control br-5 border-0 bg-white h-auto" placeholder="Anna Nagar"/>
                                        <div className="input-group-append">
                                            <button type="button" className="btn br-5 bg-white"><img src={searchOrange} alt="Search"/></button>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="page-sub-title d-web-none text-center">Showing "200 Search Results"</h4>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="page-sub-title d-mob-none">Showing "200 Search Results"</h4>
                                    <button className="btn bg-white shadow-searchpg min-wid-200px d-flex justify-content-between align-items-center mr-3 d-web-none">
                                        <span>Filter</span>
                                        <img src={filterIcon} alt="filter" className="ml-3"/>
                                    </button>
                                    <div className="dropdown">
                                        <button className="btn fs-14 shadow-searchpg bg-white dropdown-toggle min-wid-200px d-flex justify-content-between align-items-center sort-btn" type="button" id="srtbtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sort by&nbsp;</button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="srtbtn">
                                            <span className="dropdown-item" href="">Name (A-Z)</span>
                                            <span className="dropdown-item" href="">Name (Z-A)</span>
                                            <span className="dropdown-item" href="">Location (A-Z)</span>
                                            <span className="dropdown-item" href="">Location (Z-A)</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="search-result-card py-3">
                                        <div className="fav-icon" onClick={()=>this.selectFav('id')}>
                                            <img src={heartFilled} />
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="result-card-left-sec col-6 col-lg-5 px-3">
                                                <img src={Rec1} alt="card image" className="img-fluid" />
                                                <div className="d-block d-sm-none mt-2">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2" onClick={this.navToDetailsPage}>View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                            <div className="result-card-right-sec col-6 px-2 pt-1 pt-lg-0">
                                                <div className="mob-lh-12 my-1">
                                                    <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                                </div>
                                                <div className="mb-1 mob-lh-12 d-flex align-items-center flex-wrap flex-lg-nowrap">
                                                    <span className="fs-14 mr-2">4.5</span>
                                                    <span className="add-rating">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>&nbsp;&nbsp;
                                                    <span className="fs-14">(100+ Users)</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={phone} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">+91 994052370, +91 994052370</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={tags} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Child Specialist</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={experiance} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">10 year Experiance</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={locationSmall} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2" onClick={this.navToDetailsPage}>View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="search-result-card py-3">
                                        <div className="fav-icon">
                                            <img src={heartFilled} />
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="result-card-left-sec col-6 col-lg-5 px-3">
                                                <img src={Rec2} alt="card image" className="img-fluid" />
                                                <div className="d-block d-sm-none mt-2">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                            <div className="result-card-right-sec col-6 px-2 pt-1 pt-lg-0">
                                                <div className="mob-lh-12 my-1">
                                                    <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                                </div>
                                                <div className="mb-1 mob-lh-12 d-flex align-items-center flex-wrap flex-lg-nowrap">
                                                    <span className="fs-14 mr-2">4.5</span>
                                                    <span className="add-rating">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>&nbsp;&nbsp;
                                                    <span className="fs-14">(100+ Users)</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={phone} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">+91 994052370, +91 994052370</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={tags} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Child Specialist</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={experiance} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">10 year Experiance</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={locationSmall} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="search-result-card py-3">
                                        <div className="fav-icon">
                                            <img src={heartFilled} />
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="result-card-left-sec col-6 col-lg-5 px-3">
                                                <img src={Rec3} alt="card image" className="img-fluid" />
                                                <div className="d-block d-sm-none mt-2">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                            <div className="result-card-right-sec col-6 px-2 pt-1 pt-lg-0">
                                                <div className="mob-lh-12 my-1">
                                                    <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                                </div>
                                                <div className="mb-1 mob-lh-12 d-flex align-items-center flex-wrap flex-lg-nowrap">
                                                    <span className="fs-14 mr-2">4.5</span>
                                                    <span className="add-rating">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>&nbsp;&nbsp;
                                                    <span className="fs-14">(100+ Users)</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={phone} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">+91 994052370, +91 994052370</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={tags} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Child Specialist</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={experiance} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">10 year Experiance</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={locationSmall} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="search-result-card py-3">
                                        <div className="fav-icon">
                                            <img src={heartFilled} />
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="result-card-left-sec col-6 col-lg-5 px-3">
                                                <img src={Rec4} alt="card image" className="img-fluid" />
                                                <div className="d-block d-sm-none mt-2">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                            <div className="result-card-right-sec col-6 px-2 pt-1 pt-lg-0">
                                                <div className="mob-lh-12 my-1">
                                                    <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                                </div>
                                                <div className="mb-1 mob-lh-12 d-flex align-items-center flex-wrap flex-lg-nowrap">
                                                    <span className="fs-14 mr-2">4.5</span>
                                                    <span className="add-rating">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>&nbsp;&nbsp;
                                                    <span className="fs-14">(100+ Users)</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={phone} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">+91 994052370, +91 994052370</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={tags} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Child Specialist</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={experiance} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">10 year Experiance</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={locationSmall} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="search-result-card py-3">
                                        <div className="fav-icon">
                                            <img src={heartFilled} />
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="result-card-left-sec col-6 col-lg-5 px-3">
                                                <img src={Rec1} alt="card image" className="img-fluid" />
                                                <div className="d-block d-sm-none mt-2">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                            <div className="result-card-right-sec col-6 px-2 pt-1 pt-lg-0">
                                                <div className="mob-lh-12 my-1">
                                                    <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                                </div>
                                                <div className="mb-1 mob-lh-12 d-flex align-items-center flex-wrap flex-lg-nowrap">
                                                    <span className="fs-14 mr-2">4.5</span>
                                                    <span className="add-rating">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>&nbsp;&nbsp;
                                                    <span className="fs-14">(100+ Users)</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={phone} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">+91 994052370, +91 994052370</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={tags} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Child Specialist</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={experiance} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">10 year Experiance</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={locationSmall} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="page navigation">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item nav-pg">
                                            <a className="page-link" href="#">&#60;&nbsp;Previous</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">1</a>
                                        </li>
                                        <li className="page-item active" aria-current="page">
                                            <a className="page-link" href="#">2 <span className="sr-only">(current)</span></a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">3</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">4</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">5</a>
                                        </li>
                                        <li className="page-item nav-pg">
                                            <a className="page-link" href="#">Next&nbsp;&#62;</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            
                            <div className="d-mob-none d-web-sm-none">
                                <div className="search-map-sec">
                                    <div id="map">
                                        <div  id="map-container-google-11">
                                            <iframe className="w-100 br-10" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJB5gIVWFdUjoRKdj3Nxxq6-g&key=AIzaSyA1X9MSi_O-dUs4kUu_I1dY_lXHOeZbOk0"  frameBorder="0" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="ads-sec">
                                    <h4>Run Ads</h4>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid px-4 px-lg-5 pb-3 search-list-sec2">
                        <div className="px-4 corner-nav mb-5">
                            <h5 className="text-center page-title mb-4">Other Suggested Lawyers</h5>
                            <ItemsCarousel
                                    requestToChangeActive={this.topLawyersActiveItem}
                                    activeItemIndex={this.state.topLawyersItemIndex}
                                    numberOfCards={window.innerWidth>550?4:1}
                                    gutter={20}
                                    leftChevron={<img src={leftarrow} alt="Left Arrow" style ={{width:'40px'}} className="carosel-control-left"/>}
                                    rightChevron={<img src={rightarrow} alt="Right Arrow" style ={{width:'40px'}} className="carosel-control-right" />}
                                    outsideChevron
                                    chevronWidth={40} >
                                <div className="cus-card-item">
                                    <img src={card1} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Merlyn Law Firm</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={card2} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Merlyn Law Firm</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={card3} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Merlyn Law Firm</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={card4} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Merlyn Law Firm</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={card1} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Merlyn Law Firm</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={card2} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Merlyn Law Firm</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                            </ItemsCarousel>
                        </div>
                    </div>
                    <div className="container-fluid px-2 px-lg-5 pb-3 search-list-sec3">               
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-10 offset-lg-1">
                                    <div className="search-result-card">
                                        <h5 className="page-sub-title font-weight-bold">FAQ</h5>
                                        <ol className="pl-3 pt-2 mb-0">
                                            <li>
                                                <h6>What do lawyers in Chennai do?</h6>
                                                <p className="text-dark fs-14">Lawyers advise and represent their clients in both civil and criminal cases. They explain to their clients the legal issues they might be facing or are concerned about. They research the details and gather evidence involved in cases. They also develop case strategies, prepare pleadings, and other documents like contracts, deeds, and wills. They appear in the court to defend their client’s rights and interests.</p>
                                            </li>
                                            <li>
                                                <h6>What questions should I ask a lawyer before hiring one?</h6>
                                                <p className="text-dark fs-14">The questions that you will ask will vary depending on your particular situation. Here are a few suggestions- How long have you practised law? What is your legal speciality? How often will you update me on the status of my case? How often do you bill? What are my responsibilities as a client? Do I have a strong case?</p>
                                            </li>
                                            <li>
                                                <h6>When is it time to hire a lawyer?</h6>
                                                <p className="text-dark fs-14">You should consider appointing one of the trusted lawyers in Chennai if you feel that your rights have been violated or your finances are at stake or you need help regarding a legal problem. A good lawyer can help you to effectively navigate through the process. It is also recommended to seek a lawyer’s help if you are to sign any legal document.</p>
                                            </li>
                                            <li>
                                                <h6>Can I change my lawyer in the middle of a case?</h6>
                                                <p className="text-dark fs-14">There can be multiple reasons to change a lawyer in the middle of the case. You can change your lawyer with the permission of the court by notifying the professional that you have hired a new lawyer. However, there can also be a few circumstances where the court may not permit you to change the lawyer.</p>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="search-result-card">
                                        <h5 className="page-sub-title font-weight-bold mb-3">About Lawyers Services</h5>
                                        <h6>Lawyer Services</h6>
                                        <p className="text-dark fs-14">
                                        There can be more than a thousand reasons for which people may want to consult a lawyer. Other matters which also need a lawyer’s help are insurance claims, auto accidents, bankruptcy, etc. It is always a wise decision to get legal help in all such matters because only certified lawyers in Chennai understand how laws and the legal system work.
                                        </p>
                                        <h6>You can also go through the same Lawyers Services in Chennai in other cities as well:</h6>
                                        <ul className="pl-0 mb-0 list-unstyled text-dark fs-14">
                                            <li>Lawyers in Delhi</li>
                                            <li>Lawyers in Mumbai</li>
                                            <li>Lawyers in Kolkata</li>
                                            <li>Lawyers in Pune</li>
                                            <li>Lawyers in Delhi</li>
                                            <li>Lawyers in Hydrabad</li>
                                            <li>Lawyers in Delhi</li>
                                            <li>Lawyers in Mumbai</li>
                                            <li>Lawyers in Kolkata</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        Login: state.login,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };
  
  export default connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(LegalServicesNearYouSearchList));