import React, { Component } from "react";
import './search-detail.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ItemsCarousel from 'react-items-carousel';
import leftarrow from '../../../assets/images/search-detail/leftarrow.svg';
import rightarrow from '../../../assets/images/search-detail/rightarrow.svg';
import card1 from '../../../assets/images/search-detail/similar_properties_1.png';
import card2 from '../../../assets/images/search-detail/similar_properties_2.png';
import card3 from '../../../assets/images/search-detail/similar_properties_3.png';
import card4 from '../../../assets/images/search-detail/similar_properties_4.png';
import locationOrange from '../../../assets/images/search-detail/location_pin.svg';
import eyeOrange from '../../../assets/images/search-detail/eye-orange.svg';
import call from '../../../assets/images/search-detail/phone.svg';
import layoutmap from '../../../assets/images/search-detail/layout_map.png';
import shiteasyIcon from '../../../assets/images/search-detail/shiteasy_Icon.png';
import slide1 from '../../../assets/images/search-detail/slide1.png';
import slide2 from '../../../assets/images/search-detail/slide2.png';
import slide3 from '../../../assets/images/search-detail/slide3.png';
import slide4 from '../../../assets/images/search-detail/slide4.png';
import slide5 from '../../../assets/images/search-detail/slide5.png';
import feature1 from '../../../assets/images/search-detail/blueprint.svg';
import feature2 from '../../../assets/images/search-detail/areas.svg';
import feature3 from '../../../assets/images/search-detail/furniture.svg';
import feature4 from '../../../assets/images/search-detail/availability.svg';
import feature5 from '../../../assets/images/search-detail/calendar.svg';
import play from '../../../assets/images/search-detail/play.svg';
import enquiryImage from '../../../assets/images/search-detail/enquiry-image.png';
import EnquiryForm from '../search-detail/enquiryform'
import {getSearchCommertialPropertyDetailsById} from '../../../services/querys/get-APIs'
import moment from "moment"
import Video from "../Video/Video"
import GoogleMapReact from 'google-map-react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import AppBar from '@material-ui/core/AppBar';
import Chip from '@mui/material/Chip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
const BASE_URL = process.env.REACT_APP_BASE_URL;
function FolderList(props) {
  console.log("props",props.detailFeature)
  var {detailFeature}=props
  return detailFeature !== null ? (
    <List
      // dense
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        position: "relative",
        overflow: "auto",
        maxHeight: 300,
      }}
    >
      {detailFeature.map((item) => {
        var { title, value } = item;
        return (
          <ListItem className="p-0">
            {/* <ListItemAvatar sx={{width:"20px"}}>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar> */}
            <ListItemText
              // sx={{
              //   "&.MuiListItemText-primary": {
              //     fontSize: "12px !important",
              //   },
              //   "&.MuiListItemText-secondary": {
              //     fontSize: "10px !important",
              //   },
              // }}
              primary={title}
              secondary={value}
            />
          </ListItem>
        );
      })}
    </List>
  ) : null;
}
const AnyReactComponent = () =><img src="https://img.icons8.com/color/48/000000/order-delivered.png"/>;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const AntTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#E1601F',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    // textTransform: 'none',
    minWidth: 72,
    fontWeight: "bold",
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#E1601F',
      opacity: 1,
    },
    '&$selected': {
      color: '#E1601F',
    },
    '&:focus': {
      color: '#E1601F',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
class searchDetail extends Component {

    constructor (props) {
        super (props) 
        var id = this.props.history?.location?.hash.replace( /#/g, "" )
        id = id.split('&')[0];
        this.state= {
          simiPropItemIndex: 0,
          width: 0,
          propDetails:[],
          Tab:"Property Details",
          center: {
            lat: 59.95,
            lng: 30.33
          },
          zoom: 11,
          amenity:[],
          id,
          value:0
        }
      if(id!==undefined){
        this.ApiCall(id);
        }
        else{
          alert("error")
        }
    }
    simiPropActiveItem = (simiPropItemIndex) => {
        this.setState({ simiPropItemIndex });
    }
    ApiCall=(id)=>{
      console.log("id",id)
      window.scrollTo(0, 0)
      getSearchCommertialPropertyDetailsById(id).then((res)=>{
            console.log("res",res)
            var {amenity,propertyCategoryId} = res?.data?.commercialDetails
            if(propertyCategoryId===1){
              this.props.history.push(`residential#${id}`)
            }
          var amenitytemp=amenity.split(",");
          console.log("amenitytemp",amenitytemp)
            this.setState({
                propDetails:res?.data?.commercialDetails,
                amenity:amenitytemp,
                Tab: "Property Details"
            })

        })
        .catch((error)=>{
            console.log("error",error)
        })
    }
    handleApiLoaded=(map, maps)=>{
      console.log("dhfgjfdmgb",map, maps)
    }
    handleChange= (event, newValue) => {
      this.setState({
        value:newValue
      })
    };
    render() {
      
      var {videoUrl,imageList,email,phoneNumber,type,propertyCategoryId,propertyType,layoutImageUrl}=this.state.propDetails
      var {amenity}=this.state
      var editProperty=true
      var {Tab,id}=this.state
      var propertyTypeId=null
      switch (propertyType) {
        case "Office Space":
          propertyTypeId = 2;
          break;
        case "Shops":
          propertyTypeId = 14;
          break;
        case "Marriage Hall":
          propertyTypeId = 27;
          break;
        case "Hostel":
          propertyTypeId = 28;
          break;
        case "Old Age Home":
          propertyTypeId = 29;
          break;
        case "Creche":
          propertyTypeId = 30;
          break;
        case "Hospitals":
          propertyTypeId = 18;
          break;
        case "Store Room":
          propertyTypeId = 21;
          break;
        default:
          break;
      }
      console.log("this.state.propDetails",this.state.propDetails)
        return (
          <div className="search-list-pg">
            <Header />
            {this.state.propDetails.length !== 0 ? (
              <div className="header-space">
                <div className="container py-4 px-30">
                  <div className="row">
                    <div className="col-12 col-lg-9 col-md-8">
                      <div className="row mb-4">
                        <div className="col-12 col-md-6">
                          <div className="search-result-card p-0 shadow-none br-0">
                            <div
                              id="searchResultSlider21"
                              className="carousel slide"
                              data-ride="carousel"
                            >
                              <div className="carousel-inner">
                                {/* {this.state.propDetails?.imageUrl?.map((img,indx)=>(
                                                        <div className="carousel-item active" key={indx}>
                                                            <img src={`https://api.shifteasy.com${img.imageUrl}`} className="d-block w-100" alt="Image"/>
                                                        </div>
                                                         ))} */}
                                {imageList !== undefined ? (
                                  <img
                                    src={imageList[0].imageUrl}
                                    className="d-block w-100"
                                    alt="Image"
                                  />
                                ) : null}
                                {/* <div className="carousel-item">
                                                            <img src={card2} className="d-block w-100" alt="Image"/>
                                                        </div>
                                                        <div className="carousel-item">
                                                            <img src={card3} className="d-block w-100" alt="Image"/>
                                                        </div>
                                                        <div className="carousel-item">
                                                            <img src={card4} className="d-block w-100" alt="Image"/>
                                                        </div> */}
                              </div>
                              {/* <a className="carousel-control-prev" href="#searchResultSlider21" role="button" data-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" href="#searchResultSlider21" role="button" data-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                        <span className="sr-only">Next</span>
                                                    </a> */}
                            </div>
                          </div>
                          {/* <div className="d-flex flex-nowrap overflow-auto mb-3">
                                                {this.state.propDetails?.imageUrl?.map((img,inx)=>(
                                                    <div className="slide-img-collection" key={inx}>
                                                    <img src={`https://api.shifteasy.com${img.imageUrl}`} className="img-fluid"/>
                                                </div>
                                                ))}
                                            </div> */}
                        </div>
                        <div className="col-12 col-md-6">
                          <h5 className="page-sub-title">
                          {this.state.propDetails?.projectName} &nbsp;
                            {this.state.propDetails?.cityName},
                            {this.state.propDetails?.localityName}
                          </h5>
                          <div className="d-flex align-items-baseline">
                            <h5 className="mr-5">
                              <span className="text-orange">
                                Rs.{this.state.propDetails?.price}
                              </span>
                              <small>
                                {this.state.propDetails?.type === "Sale"
                                  ? ""
                                  : "/ Month"}
                              </small>
                            </h5>
                            {/* <a className="text-orange fs-14" href="#">Get Total Price</a> */}
                          </div>
                          <p className="mob-lh-12 mb-1">
                            {this.state.propDetails?.propertyType} for{" "}
                            {this.state.propDetails?.type}
                          </p>
                          {/* <div className="mob-lh-12 mb-3">
                                                <img src={locationOrange} alt="Location pin" width="10px"/>
                                                &nbsp;<span className="fs-14">Choolaimedu, Chennai</span>
                                            </div> */}
                          <h5 className="text-orange page-sub-title">
                            Top Features
                          </h5>
                          <FolderList detailFeature={this.state.propDetails.detailFeature}/>
                        </div>
                      </div>
                      <div>
                        <AppBar
                          position="static"
                          style={{ background: "#06394C" }}
                          // className={classes.AppBar}
                        >
                          <AntTabs
                            scrollButtons="on"
                            variant="scrollable"
                            value={this.state.value}
                            onChange={this.handleChange}
                            aria-label="simple tabs example"
                            indicatorColor="secondary"
                            textColor="#fff"
                          >
                            <AntTab
                              label="Property Details"
                              {...a11yProps(0)}
                            />
                            <AntTab label="About Property" {...a11yProps(1)} />
                            <AntTab label="Video" {...a11yProps(2)} />
                            <AntTab label="Amenity" {...a11yProps(3)} />
                            <AntTab label="Enquiry" {...a11yProps(4)} />
                          </AntTabs>
                        </AppBar>
                        <TabPanel value={this.state.value} index={0}>
                          <div
                            className="tab-content serv-pg-tab-content mb-4 border-0"
                            id="pills-tabContent"
                          >
                            <div
                              className="tab-pane fade show active"
                              id="pills-property-details"
                              role="tabpanel"
                              aria-labelledby="pills-property-details-tab"
                            >
                              <table className="bg-white w-100 detail-pg-table">
                                <tbody>
                                  <tr>
                                    <td className="font-weight-bold">
                                      Landmark
                                    </td>
                                    <td>
                                      <>{this.state.propDetails?.landMark}</>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-bold">City</td>
                                    <td>
                                      <>{this.state.propDetails?.cityName}</>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-bold">State</td>
                                    <td>
                                      <>{this.state.propDetails?.stateName}</>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-bold">
                                      Country
                                    </td>
                                    <td>
                                      <>{this.state.propDetails?.countryName}</>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-bold">
                                      Pincode
                                    </td>
                                    <td>
                                      <>{this.state.propDetails?.pinCode}</>
                                    </td>
                                  </tr>
                                  {propertyCategoryId===1?<>
                                  <tr>
                                    <td className="font-weight-bold">
                                      Near By Bus Stops
                                    </td>
                                    <td>
                                      <>
                                        {this.state.propDetails?.nearbyBusStops}
                                      </>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-bold">
                                      Near By Schools
                                    </td>
                                    <td>
                                      <>
                                        {this.state.propDetails?.nearbySchools}
                                      </>
                                    </td>
                                  </tr>
                                  {/* <tr>
                                <td className="font-weight-bold">
                                  Available For
                                </td>
                                <td>{this.state.propDetails?.availableFor}</td>
                              </tr> */}
                                  <tr>
                                    <td className="font-weight-bold">
                                      Furnishing
                                    </td>
                                    <td>
                                      <>
                                        {
                                          this.state.propDetails
                                            ?.propertyFurnishing
                                        }
                                      </>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-bold">
                                      Total number of floor
                                    </td>
                                    <td>
                                      <>
                                        {this.state.propDetails?.noOfFloors}{" "}
                                        Floors
                                      </>
                                    </td>
                                  </tr>
                                  {this.state.propDetails?.type === "Rent" ? (
                                    <>
                                      <tr>
                                        <td className="font-weight-bold">
                                          Rent Agreement Duration
                                        </td>
                                        <td>
                                          <>
                                            {
                                              this.state.propDetails
                                                ?.rentAgreementDuration
                                            }{" "}
                                            Years
                                          </>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="font-weight-bold">
                                          Months of Notice
                                        </td>
                                        <td>
                                          <>
                                            {
                                              this.state.propDetails
                                                ?.noticePeriod
                                            }{" "}
                                            Months
                                          </>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="font-weight-bold">
                                          Electricity Charges
                                        </td>
                                        <td>
                                          <>
                                            {this.state.propDetails
                                              ?.electricityCharges > 0
                                              ? `Rs.${this.state.propDetails?.electricityCharges}`
                                              : "Charges not included"}
                                          </>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="font-weight-bold">
                                          Maintenance Charges
                                        </td>
                                        <td>
                                          <>
                                            {this.state.propDetails
                                              ?.maintenanceCharges > 0
                                              ? `Rs.${this.state.propDetails?.maintenanceCharges}`
                                              : "Charges not included"}
                                          </>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="font-weight-bold">
                                          Water Charges
                                        </td>
                                        <td>
                                          <>
                                            {this.state.propDetails
                                              ?.waterCharges > 0
                                              ? `Rs.${this.state.propDetails?.waterCharges}`
                                              : "Charges not included"}
                                          </>
                                        </td>
                                      </tr>
                                    </>
                                  ) : null}
                                  {/* <tr>
                                <td className="font-weight-bold">
                                  Power Backup
                                </td>
                                <td>{this.state.propDetails?.powerBackup}</td>
                              </tr>
                              <tr>
                                <td className="font-weight-bold">
                                  Property Age
                                </td>
                                <td>
                                  {this.state.propDetails?.propertyAge !== null
                                    ? `${this.state.propDetails}+ Year Old`
                                        ?.propertyAge
                                    : ""}
                                </td>
                              </tr> */}
                              </>
                              :null}
                                  <tr>
                                    <td className="font-weight-bold">
                                      Property Code
                                    </td>
                                    <td>
                                      {id
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/* <div className="tab-pane fade" id="pills-about-property" role="tabpanel" aria-labelledby="pills-about-property-tab">...</div>
                                        <div className="tab-pane fade" id="pills-videos" role="tabpanel" aria-labelledby="pills-videos-tab">...</div>
                                        <div className="tab-pane fade" id="pills-enquiry" role="tabpanel" aria-labelledby="pills-enquiry-tab">...</div> */}
                          </div>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1}>
                          <>
                            <div>
                              <h5 className="page-sub-title">About Property</h5>
                            </div>
                            <p className="fs-14 mb-4">
                              {this.state.propDetails?.description}
                            </p>
                          </>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={2}>
                          <>
                            <div>
                              <h5 className="page-sub-title">Video</h5></div>
                            {/* <div className="row mb-4">
                          <div className="col-lg-6"> */}
                            <div className="position-relative">
                              {/* <Video video={videoUrl.substring( 1, videoUrl.length - 1 )}/> */}
                              <Video video={videoUrl} />
                              {/* <img src={card4} className="w-100" alt="thumbnail picture"/>
                                                <div className="thumbnail-overlay"></div>
                                                <img src={play} className="thumbnail-play"/> */}
                              {/* </div>
                          </div> */}
                            </div>
                          </>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={3}>
                          <>
                              <h5 className="page-sub-title">Amenity</h5>
                            {/* <div className="row mb-4">
                          <div className="col-lg-6">
                            <div className="position-relative">
                              <ul> */}
                            {amenity.map((item) => {
                              return (
                                <Chip
                                  className="m-1"
                                  label={item}
                                  variant="outlined"
                                />
                              );
                            })}
                            {/* </ul>
                            </div>
                          </div>
                        </div> */}
                          </>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={4}>
                          <>
                            <h5 className="page-sub-title">Enquiry</h5>
                            <div className="row mb-4 mx-auto enquiry-box">
                              <div className="col-lg-6 mb-3 mb-lg-0">
                                <div className="position-relative">
                                  <img
                                    src={enquiryImage}
                                    className="img-fluid"
                                  />
                                  <h4 className="text-white text-item-center">
                                    Send Enquiry to Owner
                                  </h4>
                                </div>
                              </div>
                              <div className="col-lg-6">
                              <EnquiryForm
                                  id={id}
                                  userReducer={this.props.userReducer}
                                />
                                {/* <form className="px-1 px-lg-3">
                                            <input type="text" className="form-control mb-3 fs-14" placeholder="Name"/>
                                            <input type="number" className="form-control mb-3 fs-14" placeholder="Phone Number"/>
                                            <input type="email" className="form-control mb-3 fs-14" placeholder="Email Address"/>
                                            <textarea rows="4" className="form-control mb-3 fs-14" placeholder="I am interested in this property for viewing"></textarea>
                                            <div className="custom-control custom-checkbox d-inline-flex mr-3 fs-14">
                                                <input type="checkbox" className="custom-control-input" id="terms"/>
                                                <label className="custom-control-label" htmlFor="terms">I Agree to ShiftEasy' <a href="#" className="text-orange">T&C</a>, <a href="#" className="text-orange">Privacy Policy</a> &  <a href="#" className="text-orange">Cookie Policy</a></label>
                                            </div>
                                            <div className="clearfix mt-3">
                                                <button className="btn btn-orange px-md-5 float-right">
                                                    Send Message
                                                </button>
                                            </div>
                                        </form> */}
                              </div>
                            </div>
                          </>
                        </TabPanel>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4">
                      <div className="position-relative">
                        <div className="pb-5 bg-orange">
                          <h6 className="page-sub-title text-white text-center p-3">
                            Posted By Owner
                          </h6>
                        </div>
                        <img
                          src={shiteasyIcon}
                          alt="Shiteasy Icon"
                          className="contact-card-logo"
                        />
                        <div className="pt-5 bg-light">
                          <div className="align-items-center d-flex flex-column mb-1 mob-lh-12">
                            <a
                              href="#"
                              className="text-orange font-weight-bold"
                            >
                              Owner Name
                            </a>
                            <div className="fs-14">
                              {email !== undefined
                                ? email.slice(0, 2) +
                                  email.slice(2).replace(/.(?=...)/g, "*")
                                : null}
                            </div>
                            <div>
                              <img src={call} className="mr-1" width="20px" />
                              &nbsp;
                              <span className="fs-14">
                                <span className="font-weight-bold">Call:</span>{" "}
                                {phoneNumber !== undefined
                                  ? phoneNumber.slice(0, 2) +
                                    phoneNumber
                                      .slice(2)
                                      .replace(/.(?=...)/g, "*")
                                  : null}
                              </span>
                              &nbsp;
                              <img
                                src={eyeOrange}
                                className="ml-1"
                                width="20px"
                              />
                            </div>
                            <button className="btn btn-orange px-md-4 my-3">
                              Contact Owner
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="pt-4">
                                        <h6 className="page-sub-title font-weight-bold">Location Landmark</h6>
                                        <div className="align-items-center d-flex mb-1 mob-lh-12">
                                            <img src={locationOrange} className="mr-1" width="20px"/>&nbsp;
                                            <span className="fs-14 mr-2 text-orange ">Hong kong Museum</span>
                                        </div>
                                    </div> */}
                      <div className="pt-4">
                        <h6 className="page-sub-title font-weight-bold">
                          Property Location
                        </h6>
                        <div style={{ height: "250px", width: "100%" }}>
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: "AIzaSyDYxbNZ1gzKrBxrH8BIBm7umS3VkEzzRo4",
                            }}
                            //   defaultCenter={{lat:18.6780374,
                            //     lng:73.8016417}}
                            defaultCenter={{
                              lat: parseFloat(this.state.propDetails?.latitude),
                              lng: parseFloat(
                                this.state.propDetails?.longitude
                              ),
                            }}
                            defaultZoom={this.state.zoom}
                            onGoogleApiLoaded={({ map, maps }) =>
                              this.handleApiLoaded(map, maps)
                            }
                          >
                            <AnyReactComponent
                              lat={parseFloat(this.state.propDetails?.latitude)}
                              lng={parseFloat(
                                this.state.propDetails?.longitude
                              )}
                              text=""
                            />
                          </GoogleMapReact>
                        </div>
                      </div>
                      <div className="pt-4">
                          <h6 className="page-sub-title font-weight-bold">
                            Floor Layout
                          </h6>
                        <Zoom>
                          <img
                            src={
                              layoutImageUrl !== undefined
                                ? layoutImageUrl
                                : null
                            }
                            alt="plan"
                            className="img-fluid"
                          />
                        </Zoom>
                      </div>
                    </div>
                  </div>
                  {/* </div>
                        <div className="container-fluid px-2 px-lg-4"> */}
                  <div className="px-3 corner-nav-inner my-5">
                    <h5 className="page-sub-title mb-3">
                      Similar Properties For Sale{" "}
                    </h5>
                    <ItemsCarousel
                      requestToChangeActive={this.simiPropActiveItem}
                      activeItemIndex={this.state.simiPropItemIndex}
                      numberOfCards={4}
                      gutter={20}
                      leftChevron={
                        <img
                          src={leftarrow}
                          alt="Left Arrow"
                          style={{ width: "40px" }}
                          className="carosel-control-left"
                        />
                      }
                      rightChevron={
                        <img
                          src={rightarrow}
                          alt="Right Arrow"
                          style={{ width: "40px" }}
                          className="carosel-control-right"
                        />
                      }
                      outsideChevron
                      chevronWidth={40}
                    >
                      <div className="cus-card-item">
                        <img
                          src={card1}
                          alt="Card image"
                          className="img-fluid cus-card-image"
                        />
                        <div className="d-flex flex-column p-2">
                          <div className="mob-lh-12 my-1">
                            <h6 className="text-dark d-inline fs-15 font-weight-bold">
                              Thirupanjali property
                            </h6>
                          </div>
                          <div className="align-items-center d-flex justify-content-between">
                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                              <img
                                src={locationOrange}
                                className="mr-1"
                                width="10px"
                              />
                              &nbsp;
                              <span className="fs-14 mr-2">
                                Salem, Tamil Nadu
                              </span>
                            </div>
                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                              <span className="fs-14 font-weight-bold ml-2">
                                &#8377;&nbsp;100 Crores
                              </span>
                            </div>
                          </div>
                          <a
                            href="/#"
                            type="button"
                            className="btn btn-block btn-orange mt-2"
                          >
                            View
                          </a>
                        </div>
                      </div>
                      <div className="cus-card-item">
                        <img
                          src={card2}
                          alt="Card image"
                          className="img-fluid cus-card-image"
                        />
                        <div className="d-flex flex-column p-2">
                          <div className="mob-lh-12 my-1">
                            <h6 className="text-dark d-inline fs-15 font-weight-bold">
                              Thirupanjali property
                            </h6>
                          </div>
                          <div className="align-items-center d-flex justify-content-between">
                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                              <img
                                src={locationOrange}
                                className="mr-1"
                                width="10px"
                              />
                              &nbsp;
                              <span className="fs-14 mr-2">
                                Salem, Tamil Nadu
                              </span>
                            </div>
                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                              <span className="fs-14 font-weight-bold ml-2">
                                &#8377;&nbsp;100 Crores
                              </span>
                            </div>
                          </div>
                          <a
                            href="/#"
                            type="button"
                            className="btn btn-block btn-orange mt-2"
                          >
                            View
                          </a>
                        </div>
                      </div>
                      <div className="cus-card-item">
                        <img
                          src={card3}
                          alt="Card image"
                          className="img-fluid cus-card-image"
                        />
                        <div className="d-flex flex-column p-2">
                          <div className="mob-lh-12 my-1">
                            <h6 className="text-dark d-inline fs-15 font-weight-bold">
                              Thirupanjali property
                            </h6>
                          </div>
                          <div className="align-items-center d-flex justify-content-between">
                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                              <img
                                src={locationOrange}
                                className="mr-1"
                                width="10px"
                              />
                              &nbsp;
                              <span className="fs-14 mr-2">
                                Salem, Tamil Nadu
                              </span>
                            </div>
                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                              <span className="fs-14 font-weight-bold ml-2">
                                &#8377;&nbsp;100 Crores
                              </span>
                            </div>
                          </div>
                          <a
                            href="/#"
                            type="button"
                            className="btn btn-block btn-orange mt-2"
                          >
                            View
                          </a>
                        </div>
                      </div>
                      <div className="cus-card-item">
                        <img
                          src={card4}
                          alt="Card image"
                          className="img-fluid cus-card-image"
                        />
                        <div className="d-flex flex-column p-2">
                          <div className="mob-lh-12 my-1">
                            <h6 className="text-dark d-inline fs-15 font-weight-bold">
                              Thirupanjali property
                            </h6>
                          </div>
                          <div className="align-items-center d-flex justify-content-between">
                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                              <img
                                src={locationOrange}
                                className="mr-1"
                                width="10px"
                              />
                              &nbsp;
                              <span className="fs-14 mr-2">
                                Salem, Tamil Nadu
                              </span>
                            </div>
                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                              <span className="fs-14 font-weight-bold ml-2">
                                &#8377;&nbsp;100 Crores
                              </span>
                            </div>
                          </div>
                          <a
                            href="/#"
                            type="button"
                            className="btn btn-block btn-orange mt-2"
                          >
                            View
                          </a>
                        </div>
                      </div>
                      <div className="cus-card-item">
                        <img
                          src={card1}
                          alt="Card image"
                          className="img-fluid cus-card-image"
                        />
                        <div className="d-flex flex-column p-2">
                          <div className="mob-lh-12 my-1">
                            <h6 className="text-dark d-inline fs-15 font-weight-bold">
                              Thirupanjali property
                            </h6>
                          </div>
                          <div className="align-items-center d-flex justify-content-between">
                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                              <img
                                src={locationOrange}
                                className="mr-1"
                                width="10px"
                              />
                              &nbsp;
                              <span className="fs-14 mr-2">
                                Salem, Tamil Nadu
                              </span>
                            </div>
                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                              <span className="fs-14 font-weight-bold ml-2">
                                &#8377;&nbsp;100 Crores
                              </span>
                            </div>
                          </div>
                          <a
                            href="/#"
                            type="button"
                            className="btn btn-block btn-orange mt-2"
                          >
                            View
                          </a>
                        </div>
                      </div>
                      <div className="cus-card-item">
                        <img
                          src={card2}
                          alt="Card image"
                          className="img-fluid cus-card-image"
                        />
                        <div className="d-flex flex-column p-2">
                          <div className="mob-lh-12 my-1">
                            <h6 className="text-dark d-inline fs-15 font-weight-bold">
                              Thirupanjali property
                            </h6>
                          </div>
                          <div className="align-items-center d-flex justify-content-between">
                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                              <img
                                src={locationOrange}
                                className="mr-1"
                                width="10px"
                              />
                              &nbsp;
                              <span className="fs-14 mr-2">
                                Salem, Tamil Nadu
                              </span>
                            </div>
                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                              <span className="fs-14 font-weight-bold ml-2">
                                &#8377;&nbsp;100 Crores
                              </span>
                            </div>
                          </div>
                          <a
                            href="/#"
                            type="button"
                            className="btn btn-block btn-orange mt-2"
                          >
                            View
                          </a>
                        </div>
                      </div>
                      <div className="cus-card-item">
                        <img
                          src={card3}
                          alt="Card image"
                          className="img-fluid cus-card-image"
                        />
                        <div className="d-flex flex-column p-2">
                          <div className="mob-lh-12 my-1">
                            <h6 className="text-dark d-inline fs-15 font-weight-bold">
                              Thirupanjali property
                            </h6>
                          </div>
                          <div className="align-items-center d-flex justify-content-between">
                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                              <img
                                src={locationOrange}
                                className="mr-1"
                                width="10px"
                              />
                              &nbsp;
                              <span className="fs-14 mr-2">
                                Salem, Tamil Nadu
                              </span>
                            </div>
                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                              <span className="fs-14 font-weight-bold ml-2">
                                &#8377;&nbsp;100 Crores
                              </span>
                            </div>
                          </div>
                          <a
                            href="/#"
                            type="button"
                            className="btn btn-block btn-orange mt-2"
                          >
                            View
                          </a>
                        </div>
                      </div>
                    </ItemsCarousel>
                  </div>
                  {/* </div>
                        <div className="container-fluid bg-light"> */}
                  <h5 className="page-title text-center pt-4 pb-3">
                    Related To Your Search
                  </h5>
                  <div className="container">
                    <div className="row mx-3 pb-4">
                      <div className="col-md-4">
                        <a className="mb-2 docpg-search-links">
                          Builder Floor for rent in Velachery
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Builder Floor for rent in Velachery
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Builder Floor for rent in Velachery
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Builder Floor for rent in Velachery
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Builder Floor for rent in Velachery
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a className="mb-2 docpg-search-links">
                          Builder Floor for rent in Anna Nagar
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Builder Floor for rent in Anna Nagar
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Builder Floor for rent in Anna Nagar
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Builder Floor for rent in Anna Nagar
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Builder Floor for rent in Anna Nagar
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a className="mb-2 docpg-search-links">
                          Flats for Rent in Mumbai
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Flats for Rent in Mumbai
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Flats for Rent in Mumbai
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Flats for Rent in Mumbai
                        </a>
                        <a className="mb-2 docpg-search-links">
                          Flats for Rent in Mumbai
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <Footer />
          </div>
        );
    }
}

const mapStateToProps = (state) => {
  const {userReducer}=state
    return {
        Login: state.login,
        userReducer
        // PropertyAdd: state.propertyAdd,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // PropADD: (data, Props, token) => dispatch(propertyAdd(data, Props, token)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(searchDetail));