import React from "react";
import './App.css';
import { Router, Switch, Route } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-phone-input-2/lib/style.css";
//components import part

// import { AuthRoute } from './utils/Routes/AuthRoute';
// import { PrivateRoute } from './utils/Routes/PrivateRoute';
import { history } from './helpers/history';

import Home from './components/shifteasy/home/Home';
import Register from './components/shifteasy/registration/registration';
// import postProperty from './components/shifteasy/post-property/Step1/step1';
// import step2Pg from './components/shifteasy/post-property/Step2/step2';
// import step3Pg from './components/shifteasy/post-property/Step3/step3';
// import step4Pg from './components/shifteasy/post-property/Step4/step4';
// import step5Pg from './components/shifteasy/post-property/Step5/step5';
// import step6Pg from './components/shifteasy/post-property/Step6/step6';
// import step7Pg from './components/shifteasy/post-property/Step7/step7';
// import stepPostedPg from './components/shifteasy/post-property/step-posted/step-posted';
import searchListPg from './components/shifteasy/search-list/search-list';
import searchDetailPg from './components/shifteasy/search-detail/search-detail';
import searchCompanyDetail from './components/shifteasy/search-detail/search-company-detail';
import DepositReplacements from './components/shifteasy/shifteasy-services/deposit-replacements/deposit-replacements';
import DoctorsNearYou from './components/shifteasy/shifteasy-services/doctors-near-you/doctors-near-you';
import DoctorsNearYouSearchList from './components/shifteasy/shifteasy-services/doctors-near-you/doctors-near-you search-list';
import DoctorsNearYouDetailed from './components/shifteasy/shifteasy-services/doctors-near-you/doctors-near-you detailed';
import EnergyPerformanceCertificate from './components/shifteasy/shifteasy-services/energy-performance-certificate/energy-performance-certificate';
import FacilitiesNearYou from './components/shifteasy/shifteasy-services/facilities-near-you/facilities-near-you';
import GasSafety from './components/shifteasy/shifteasy-services/gas-safety/gas-safety';
import HomeContentInsurance from './components/shifteasy/shifteasy-services/home-content-insurance/home-content-insurance';
import InventoryCheckInAndCheckOut from './components/shifteasy/shifteasy-services/inventory-check/inventory-check';
import InvestorServices from './components/shifteasy/shifteasy-services/investor-services/investor-services';
import LegalServicesNearYou from './components/shifteasy/shifteasy-services/legal-services-near-you/legal-services-near-you';
import LegalServicesNearYouSearchList from './components/shifteasy/shifteasy-services/legal-services-near-you/legal-services-near-you-search-list';
import LegalServicesNearYouDetailed from './components/shifteasy/shifteasy-services/legal-services-near-you/legal-services-near-you-detailed';
import legalService from "./components/shifteasy/shifteasy-services/legal-services-near-you/legal-service-content";
import MoversPackersLogistics from './components/shifteasy/shifteasy-services/movers-packers-logistics/movers-packers-logistics';
import OpenBacking from './components/shifteasy/shifteasy-services/open-banking/open-banking';
import PropertyManagement from './components/shifteasy/shifteasy-services/property-management/property-management';
import RentGuarenteeInsurance from './components/shifteasy/shifteasy-services/rent-guarentee-insurance/rent-guarentee-insurance';
import TenantReferencing from './components/shifteasy/shifteasy-services/tenant-referencing/tenant-referencing';
import VirtualConference from './components/shifteasy/shifteasy-services/virtual-conference/virtual-conference';
import TradesLogistics from "./components/shifteasy/shifteasy-services/trades-logistics/trades-logistics";
import rentMarketIndex from "./components/shifteasy/rent-market-index/rent-market-index";

// import ChangePassword from './components/shifteasy/change-password/change-password';
// import Dashboard from './components/shifteasy/dashboard/dashboard';
// import Enquiry from './components/shifteasy/enquiry/enquiry';
// import Favourites from './components/shifteasy/favourites/favourites';
import Membership from './components/shifteasy/membership/membership';
// import MyEnquiry from './components/shifteasy/my-enquiry/my-enquiry';
// import MyProfile from './components/shifteasy/my-profile/my-profile';
// import PropertyDetails from './components/shifteasy/property-details/property-details';
// import PropertyList from './components/shifteasy/property-list/property-list';
// import SlotManagement from './components/shifteasy/slot-management/slot-management';
import Blogs from "./components/shifteasy/shifteasy-services/Blogs/Blogs";
import BlogDetailPage from "./components/shifteasy/shifteasy-services/Blogs/BlogDetailPage";
import CookiePolicy from './components/shifteasy/cookie-policy/cookie-policy';
import TermsConditions from './components/shifteasy/terms-conditions/terms-conditions';
import TermsConditionsAffiliate from './components/shifteasy/terms-conditions-affiliate/terms-conditions-affiliate';
// import Step2Office from './components/shifteasy/post-property/Step2/step2-office-space';
// import Step2Hospital from './components/shifteasy/post-property/Step2/step2-hospital';
// import Step2MarriageHall from './components/shifteasy/post-property/Step2/step2-marriage-halls';
import ImageUploading from "./components/shifteasy/image-uploader/imageuploader";
import ContactUs from "./components/shifteasy/FooterPages/ContactUs";
import Landingpage from "./components/shifteasy/home/newhome";
toast.configure();

function App() {
  var Homepage=Home

  switch (window.location.host) {
    case "shifteasy.com":
      Homepage = Landingpage;
      break;
    case "residential.shifteasy.com":
      Homepage = Home;
      break;
    case "commercial.shifteasy.com":
      Homepage = Home;
      break;
    case "tradelogistics.shifteasy.com":
      Homepage = TradesLogistics;
      break;
    case "rmi.shifteasy.com":
      Homepage = rentMarketIndex;
      break;
    case "doctors.shifteasy.com":
      Homepage = DoctorsNearYou;
      break;
    case "legals.shifteasy.com":
      Homepage = LegalServicesNearYou;
      break;
    case "localfacilities.shifteasy.com":
      Homepage = FacilitiesNearYou;
      break;
    default:
      break;
  }
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/register" component={Register} />
        {/* <PrivateRoute path="/post-property" component={postProperty} />
        <PrivateRoute path="/step2" component={step2Pg} />
        <PrivateRoute path="/step3" component={step3Pg} />
        <PrivateRoute path="/step4" component={step4Pg} />
        <PrivateRoute path="/step5" component={step5Pg} />
        <PrivateRoute path="/step6" component={step6Pg} />
        <PrivateRoute path="/step7" component={step7Pg} />
        <PrivateRoute path="/step-posted" component={stepPostedPg} /> */}
        <Route path="/search-list" component={searchListPg} />
        <Route
          path="/search-detail/residential"
          component={searchDetailPg}
          // key={key}
        />
        <Route
          path="/search-detail/commercial"
          component={searchCompanyDetail}
          // key={key}
        />{" "}
        <Route path="/deposit-replacements" component={DepositReplacements} />
        <Route path="/doctors-near-you" component={DoctorsNearYou} />
        <Route
          path="/doctors-near-you-search-list"
          component={DoctorsNearYouSearchList}
        />
        <Route
          path="/doctors-near-you-detailed"
          component={DoctorsNearYouDetailed}
        />
        <Route
          path="/energy-performance-certificate"
          component={EnergyPerformanceCertificate}
        />
        <Route path="/facilities-near-you" component={FacilitiesNearYou} />
        <Route path="/gas-safety" component={GasSafety} />
        <Route
          path="/home-content-insurancegas"
          component={HomeContentInsurance}
        />
        <Route
          path="/inventory-check"
          component={InventoryCheckInAndCheckOut}
        />
        <Route path="/investor-services" component={InvestorServices} />
        <Route
          path="/legal-services-near-you"
          component={LegalServicesNearYou}
        />
        <Route path="/legal-services-content" component={legalService} />
        <Route
          path="/legal-services-near-you-search-list"
          component={LegalServicesNearYouSearchList}
        />
        <Route
          path="/legal-services-near-you-detailed"
          component={LegalServicesNearYouDetailed}
        />
        <Route
          path="/movers-packers-logistics"
          component={MoversPackersLogistics}
        />
        <Route path="/open-backing" component={OpenBacking} />
        <Route path="/property-management" component={PropertyManagement} />
        <Route
          path="/rent-guarentee-insurance"
          component={RentGuarenteeInsurance}
        />
        <Route path="/tenant-referencing" component={TenantReferencing} />
        <Route path="/virtual-conference" component={VirtualConference} />
        <Route path="/BlogsPage" component={Blogs} />
        <Route path="/imgUploader" component={ImageUploading} />
        <Route path="/BlogDetailPage" component={BlogDetailPage} />
        {/* ///footer page /// */}
        <Route path="/ContactUs" component={ContactUs} />
        <Route path="/trades-logistics" component={TradesLogistics} />
        <Route path="/rent-market-index" component={rentMarketIndex} />
        <Route path="/LandingPage" component={Landingpage} />
        {/* <PrivateRoute path="/dashboard" component={Dashboard} /> */}
        {/* <PrivateRoute path="/change-password" component={ChangePassword} /> */}
        {/* <Route path="/enquiry" component={Enquiry} /> */}
        {/* <PrivateRoute path="/favourites" component={Favourites} /> */}
        <Route path="/membership" component={Membership} />
        {/* <PrivateRoute path="/my-enquiry" component={MyEnquiry} /> */}
        {/* <PrivateRoute path="/my-profile" component={MyProfile} /> */}
        {/* <Route path="/property-details" component={PropertyDetails} /> */}
        {/* <Route path="/property-list" component={PropertyList} /> */}
        {/* <PrivateRoute path="/slot-management" component={SlotManagement} /> */}
        <Route path="/cookie-policy" component={CookiePolicy} />
        <Route
          path="/terms-conditions-affiliate"
          component={TermsConditionsAffiliate}
        />
        <Route path="/terms-conditions" component={TermsConditions} />
        {/* <Route  path="/header" component={Header} /> */}
        {/* <PrivateRoute path="/step2-office" component={Step2Office} />
        <PrivateRoute path="/step2-hospital" component={Step2Hospital} />
        <PrivateRoute
          path="/step2-marriage-hall"
          component={Step2MarriageHall}
        /> */}
      </Switch>
    </Router>
  );
}

export default App;
