import React, { Component } from 'react';
import './blogs_new.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import blog1 from '../../../../assets/images/blogs/post1.png';
import blog2 from  '../../../../assets/images/blogs/post2.png';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {getBlogsDetail} from '../../../../services/querys/get-APIs'

const image_show = `https://cms.shifteasy.com`

class BlogDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
         blog:""
        };

    }
   

    
   selectedBlog = () => {
      var id = this.props?.location?.state?.item.id
        getBlogsDetail(id).then(res => {
            this.setState({
                blog:res.data.blog
            })
        }).catch(error => {
            console.log(error)
        })
    }

    componentDidMount() {
      this.selectedBlog();
        window.scrollTo(0, 0)

    
    }

    render() {
     console.log(this.state.blog)
     const {blog} = this.state;
        return (
            <div>

                <div className="header">
                    <Header />
                </div>
               
                <br />
                <br />
                {blog && blog.author.map(auth=>
                <div className="row justify-content-center mx-auto d-lg-flex container-fluid ">
                    <div className="col-lg-7">
                        
                        <h5>{blog.title}</h5>
                        <p>By <span className="text-orange">{auth.name}</span>&nbsp;&nbsp;{blog.publishDate}</p>
                        
                        <img src={`${image_show}${blog.featureImage.url}`} alt="no-image" className="img-fluid mb-5" />
                         <p className='text-justify'>{blog.description}</p>
                    </div>
                    <div className="col-lg-4  justify-content-end">
                        <div className="col-md-8 col-lg-8 mx-auto ">
                            <input type="text" className="form-control mt-2 " placeholder="search" alt="search" />
                            <div className="col-12 mt-4 border p-4 mb-5">
                                <div className="d-flex justify-content-around">
                                    <div className="col-4"><img src={blog1} className="img-fluid mt-2" alt="no-img"/></div>
                                    <div className="p-1 col-7">
                                   <p style={{fontSize:"10px"}}> by <span className="text-orange" style={{fontSize:"12px"}}>Layerdrops</span><br/> <br/> New Interior Trends of Office Spaces in DLF</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <div className="col-4"><img src={blog1} className="img-fluid mt-2" alt="no-img"/></div>
                                    <div className="p-1 col-7">
                                   <p style={{fontSize:"10px"}}> by <span className="text-orange" style={{fontSize:"12px"}}>Layerdrops</span><br/> <br/> New Interior Trends of Office Spaces in DLF</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-around">
                                    <div className="col-4"><img src={blog2} className="img-fluid mt-2" alt="no-img"/></div>
                                    <div className="p-1 col-7">
                                   <p style={{fontSize:"10px"}}> by <span className="text-orange" style={{fontSize:"12px"}}>Layerdrops</span><br/> <br/> New Interior Trends of Office Spaces in DLF</p>
                                    </div>
                                </div>
                            </div>
                            <div className=" blog_list col-12 mt-4 border mx-auto p-5 mb-5">
                               <h5>CATEGORIES</h5>    
                               <ul>
                                   <li>Real-estate</li>
                                   <li>Cars & Bikes</li>
                                   <li>Business/Agencies</li>
                                   <li>Property</li>
                                   <li>Logistics</li>
                                   <li>Facilities</li>
                               </ul>
                            </div>
                            <div className="col-12 mt-4 border p-4 mb-5">
                               <h5>TAGS</h5>    
                              <p><span className="text-orange">Residential</span>, commercial, 2bhk, 1bhk, Rent, Meeting, Business, Amenities, cars</p>
                            </div>
                        </div>
                    </div>
                </div>

                )}
         


                <br /><br />
                <div className="footer"> 
                <Footer />
                </div>
                
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BlogDetailPage));