import React,{Component} from 'react';
import './inventory-check.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import inventoryServImg1 from '../../../../assets/images/services-page/3-inventory-check/image-1.png' ;
import inventoryServImg2 from '../../../../assets/images/services-page/3-inventory-check/image-2.png' ;
import inventoryServImg3 from '../../../../assets/images/services-page/3-inventory-check/image-3.png' ;
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {scrollTop} from '../../common/common';

class InventoryCheckInAndCheckOut extends Component {

    componentDidMount(){
        scrollTop();
     }
     
    render(){
        return(
            <div>
                <Header/>
                    <div className="service-banner serv-three page-top-banner banner-with-form mb-3 mb-lg-5">
                        <div className="service-banner-content text-center">
                            <h2 className="text-white mb-3">Inventory, <span className="text-orange">Check-In</span> &amp; <span className="text-orange">Check-Out</span></h2>
                            <h5>We offer professional referencing and credit checks for all tenants to give you peace of mind before letting your property.</h5>
                        </div>
                        <div className="banner-form">
                            <form>
                                <div className="mb-3">
                                    <input type="text" className="form-control br-0" placeholder="Full Name"/>
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control br-0" placeholder="Email"/>
                                </div>
                                <div className="mb-3">
                                    <input type="number" className="form-control br-0" placeholder="Phone number"/>
                                </div>
                                <div className="mb-3">
                                    <textarea className="form-control br-0" rows="4" placeholder="Message"></textarea>
                                </div>
                                <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                            </form>
                        </div>
                    </div>
                    <div className="container mx-auto">
                        <div className="row my-lg-5 my-4 ">
                            <div className="col-md-6">
                                <div className="service-content-box">
                                    <h5 className="page-title">Tenancy Checks: Check-In Inventory Reports</h5>
                                    <p>At the start of the tenancy, Inventory House will carry out tenancy checks and thoroughly document and photograph the cleanliness and condition of the property whilst ensuring the inventory check list is correct. If there is no tenancy inventory checklist then we would suggest you take full advantage of our range of London inventory services and ensure an inventory list and check-in is undertaken.</p>
                                    <p>The tenancy inventory checklist is a complete record of decoration, fixtures and fittings and furniture content and carried out by one of our inventory clerks. The report will list each and every item that makes up the property from the colour of the ceiling to the number of plug sockets. When accurate and detailed, the property inventory service will ensure the condition of the property and all its contents are recorded thoroughly at both check-in and check-out, of course it will also reduce the possibility of a dispute over missing items.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={inventoryServImg1} className="img-fluid" alt="icon"/>
                            </div>
                        </div>
                        <div className="row my-lg-5 my-4 ">
                            <div className="col-md-8 order-md-2">
                                <div className="service-content-box">
                                    <h5 className="page-title">Tenancy Checks: Check-Out Reports</h5>
                                    <p>At the end of the tenancy we return to the property for the tenancy check-out reports. Using the check-in report, we document and photograph any discrepancies and changes in the condition and report the cleanliness of the property and its contents.</p>
                                    <p>This is one of the most important parts of our property inventory services across London, because our clerk will determine and clearly note if the tenant is responsible for any damages or deterioration or whether it is fair wear and tear.</p>
                                    <p>The property inventory clerk will collect the keys from the tenant and return them to the agents or landlord and take final utility meter readings. The clerk and tenant will agree the condition and sign the check-out report, all part of the London inventory services provided.</p> 
                                </div>
                            </div>
                            <div className="col-md-4 order-md-1">
                                <img src={inventoryServImg2} className="img-fluid" alt="icon"/>
                            </div>
                        </div>
                        <div className="row my-lg-5 my-4 ">
                            <div className="col-md-6">
                                <div className="service-content-box">
                                    <h5 className="page-title">Mid Tenancy Inspections</h5>
                                    <p>Another of our very useful London inventory services is quarterly, yearly or ‘one-off’ mid tenancy inspections, where one of our inventory clerks will record the cleanliness and condition of the property during a tenancy.</p>
                                    <p>These reports include lots of digital photographs and provide an insight in to how the property is being looked after during a tenancy. We find this particularly useful to any landlord unable to visit their property on a regular basis as it provides peace of mind that the property is being looked after and should there be any problems, they can be rectified before the issue gets out of hand through our inventory services in London.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={inventoryServImg3} className="img-fluid" alt="icon"/>
                            </div>
                        </div>
                        <div className="row my-lg-5 my-4 ">
                            <div className="col-md-12">
                                <div className="service-content-box">
                                    <p>Although speak to any member of our team and they will tell you we do a little more than that. Since we started back in 2004 we have conducted more than 50,000 property inventories in London and inspections as part of our inventory services , so we like to think we’ve had plenty of practice and a good deal of experience in providing a great inventory service, thorough reports and great property inventories in London.</p>
                                    <p>Why do our clients choose Inventory House over other providers of London inventory services? Well when we ask them they simply say, because they trust us! They trust us to provide reliable and friendly property inventory services in and around the city of London and they trust us to provide thorough and accurate inventory reports produced by an independent inventory clerk in London for their letting inventory which can be relied upon 100% for any tenancy dispute.</p>
                                    <p>And that’s not all; Inventory House not only focus on providing the best home inventory in London, we also employ a team of energy performance surveyors in London. Experienced Inventory Clerks help to provide the best house inventory service in London. So if you require an Energy Performance Certificate (EPC), we can help with that too.</p>
                                    <h4 className="page-title text-center">Call Us On <span className="text-orange">020 7231 0095</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };
  
  export default connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(InventoryCheckInAndCheckOut));