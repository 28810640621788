// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/services-page/8-energy-performance/header-banner-serv-8.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serv-eight {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.position-right {
    position: absolute;
    right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/shifteasy-services/energy-performance-certificate/energy-performance-certificate.css"],"names":[],"mappings":"AAAA;IACI,yDAA4G;AAChH;;AAEA;IACI,kBAAkB;IAClB,QAAQ;AACZ","sourcesContent":[".serv-eight {\n    background-image: url(../../../../assets/images/services-page/8-energy-performance/header-banner-serv-8.png);\n}\n\n.position-right {\n    position: absolute;\n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
