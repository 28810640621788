import React, { Component } from 'react';
import './property-management.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import propMgmtServImg1 from '../../../../assets/images/services-page/14-property-management/image-1.png';
import propMgmtServImg2 from '../../../../assets/images/services-page/14-property-management/image-2.png';
import propMgmtServImg3 from '../../../../assets/images/services-page/14-property-management/image-3.png';
import propMgmtServImg4 from '../../../../assets/images/services-page/14-property-management/image-4.png';
import propMgmtServImg5 from '../../../../assets/images/services-page/14-property-management/image-5.png';
import propMgmtServImg6 from '../../../../assets/images/services-page/14-property-management/image-6.png';
import { scrollTop } from '../../common/common';
import { 
     withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
class PropertyManagement extends Component {

    componentDidMount() {
        scrollTop();
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>Best Property Management Design Company | ShiftEasy</title>
                    <meta name="description" content="We at ShiftEasy are here to Manage Your Property with haslefree. You can be relaxed and Manage your property with ShiftEasy" />
                    <meta name="keywords" content="Property Management Design" />
                </Helmet>
                <Header />
                <div className="service-banner serv-fourteen page-top-banner banner-with-form mb-3 mb-lg-5">
                    <div className="service-banner-content text-center">
                        <h2 className="text-white mb-3">Property <span className="text-orange">Management </span></h2>
                        <h5>cloud-based property management platform with a functionally rich suite of apps, designed with your business in mind. Boost efficiency, save time and focus on what really matters.</h5>
                    </div>
                    <div className="banner-form">
                        <form>
                            <div className="mb-3">
                                <input type="text" className="form-control br-0" placeholder="Full Name" />
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control br-0" placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Phone number" />
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control br-0" rows="4" placeholder="Message"></textarea>
                            </div>
                            <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                        </form>
                    </div>
                </div>
                <div className="container mx-auto">
                    <div className="my-4">
                        <div className="service-content-box">
                            <h5 className="page-title">What is Property Management? </h5>
                            <p>Property Management makes maintaining and managing your property portfolio, oversight of real estate and task workflow easy. We help manage all types of residential, commercial, Agri and other real estates. </p>
                            <p className="mb-0">Our clients include residential management companies, freeholders, and property developers. We take a personalised methodology to residential property management, excellent professionalism, and delivering high-quality customer service for our clients.</p>
                        </div>
                    </div>
                    <div className="row my-lg-5 my-4 ">
                        <div className="col-md-6">
                            <div className="service-content-box">
                                <h5 className="page-title">Shifteasy Property Management: </h5>
                                <p>We are enthusiastic about helping the customer find what they need and helping them achieve these services. We introduce our new branding, website and a host of new powerful features for our professionals to manage their leads.</p>
                                <h5 className="page-title">Shifteasy services</h5>
                                <h6>We help hundreds of thousands of businesses get clients.</h6>
                                <ul className="orange-disc pl-lg-4">
                                    <li>We provide an exquisite quality property management key that deals with investment, documentation, property survey, relocation, and finding the right resident in less time.</li>
                                    <li>We provide expert management skills and 24/7 services to our clients. ShiftEasy will complete detailed monitoring and evaluation of properties.</li>
                                    <li>Shifteasy emphasises complete and total workforce security of all our clients and is skilled at securing life and property. Legal documentation, trouble-free property selection</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={propMgmtServImg1} className="img-fluid" alt="img" />
                        </div>
                    </div>
                    <div className="mx-0 p-4 row bg-orange">
                        <div className="col-md-8 order-md-2 d-flex align-items-center">
                            <div className="service-content-box text-white">
                                <h5 className="page-title text-white">Tell us! What you need?</h5>
                                <p>We will help you find a specialist. Help us improve your search by telling us your requirements, and we will contact service providers in your area to help you.</p>
                            </div>
                        </div>
                        <div className="col-md-4 order-md-1 px-0">
                            <img src={propMgmtServImg2} className="img-fluid shadow-searchpg" alt="img" />
                        </div>
                    </div>
                    <div className="my-4">
                        <div className="service-content-box">
                            <h5 className="page-title">Searching for Property Management for your Rental Home?</h5>
                            <p>From Finding Tenant to House Maintenance, we deal with everything and guarantee your month to month lease. From Rental Agreement to Rental Guarantee, we make leasing your home a breeze. </p>
                            <ol className="pl-4">
                                <li>Confirmed Tenants</li>
                                <li>On-Time Rent</li>
                                <li>Alternating Home Maintenance</li>
                                <li>Complimentary housing Agreement </li>
                            </ol>
                            <p>The administration of your property is imperative to us. We put esteem into the expedition experience we advance, which is why we take such extraordinary consideration of your get-away rental. </p>
                            <h6 className="mb-0">The key administrators that are given inside our run of the mill property the executives are: </h6>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <ul className="fs-15 orange-disc pl-5">
                                    <li>Address the Owner </li>
                                    <li>Resident Finding </li>
                                    <li>Historical verifications </li>
                                    <li>Lease Negotiation </li>
                                    <li>Tenant contract </li>
                                    <li>Stock rundown </li>
                                    <li>Occupant Registration </li>
                                    <li>Gathering Security Deposit </li>
                                    <li>Gathering Rent </li>
                                    <li>Any Repairs and maintenance </li>
                                    <li>Lawful Support </li>
                                    <li>Move-in and out examination </li>
                                    <li>Consumption Control </li>
                                    <li>Acquisition Management </li>
                                    <li>Reestablishment </li>
                                    <li>Property Visits </li>
                                    <li>Property Eviction </li>
                                    <li>Service Bills </li>
                                    <li>Redesign and Interior Designing </li>
                                    <li>Resale Promotions </li>
                                    <li>Augmenting Rent </li>
                                    <li>Emigrant Rentals </li>
                                    <li>Corporate Accommodations </li>
                                    <li>Temporary Rentals </li>
                                    <li>Moderate Services </li>
                                    <li>Plots Monitoring </li>
                                </ul>
                            </div>
                            <div id="img" className="col-md-8 h-100">
                                <div style={{width:"900px",position:"relative",marginLeft:"-110px",marginTop:"33px"}} className="row h-100">
                                    <div className="col-6 px-0">
                                        <img src={propMgmtServImg3} className="img-fluid" alt="img" />
                                    </div>
                                    <div className="col-6 px-0">
                                        <img src={propMgmtServImg4} className="img-fluid" alt="img" />
                                    </div>
                                    <div className="col-6 px-0">
                                        <img src={propMgmtServImg5} className="img-fluid" alt="img" />
                                    </div>
                                    <div className="col-6 px-0">
                                        <img src={propMgmtServImg6} className="img-fluid" alt="img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="service-content-box">
                            <h6>Residential Property Management:</h6>
                            <p>We provide cutting edge technology to oversee Residential properties in India. We give expert property advice to our clients. We provide services to manage any properties, including executive private property, corporate flats, apartments, Flats, Bungalows, Villas, Individual Houses, Beach Houses and Farmhouses. </p>
                            <h6>Commercial Property Management: </h6>
                            <p>We deal with all kinds of business properties like Office Spaces, Workspaces, Shops, Showrooms, Industries, Factories, Banks, ATMs, Hotels, Resorts, Hospitals, Clinics, Lands and Plots. We give quality property administrations for business properties at a reasonable charge. </p>
                            <h6>Other Property Management: </h6>
                            <p>Shifteasy has the best property, administrative group. We offer a start to finish property the executives answer for all kinds of properties. We help the landowners to purchase, sell, lease, keep up with and deal with a property. Other property, for Care home, Land, Holiday letting, Marriage Hall, Conference Call, Hospital management and Office management. Would you please discuss this with our Shifteasy property administrations specialists?</p>
                        </div>
                    </div>
                    <div className="row bg-dark-light mb-4">
                        <div className="col-md-12 mt-3">
                            <div className="service-content-box text-center">
                                <h5 className="page-title text-capitalize">Property Management Services:</h5>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-2">
                            <div className="px-4 py-3 h-100">
                                <h6 className="text-orange">Finding a new tenant: </h6>
                                <p className="fs-13 mb-0">Shifteasy provides a better chance of finding a great tenant for your rental. Shifteasy follows the law, chooses a tenant with good credit, runs a credit check, plays a criminal background check, and glances at the tenant's rental history.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-2">
                            <div className="px-4 py-3 h-100">
                                <h6 className="text-orange">Switching Lettings Agency: </h6>
                                <p className="fs-13 mb-0">Shifteasy helps you to determine who is the best letting agent to manage your property needs. Termination Clauses vary agent-to-agent. If you are still uncertain, we are happy to have a look for you, and we will check through it and let you know exactly where you stand.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-2">
                            <div className="px-4 py-3 h-100">
                                <h6 className="text-orange">Tenant referencing: </h6>
                                <p className="fs-13 mb-0">ShiftEasy tenant referencing is your way of discovering out about a prospective tenant, so you can make an advised decision on whether to rent your property to that person/individual.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-2">
                            <div className="px-4 py-3 h-100">
                                <h6 className="text-orange">Inventory and Check-in / Check-out / Inspections: </h6>
                                <p className="fs-13 mb-0">ShiftEasy safeguards yourself against loss/damage caused during the tenancy, and it is essential to carry out a detailed inventory before new tenants move in. To help protect you and your property, ShiftEasy has consulted unbelievably low pricing for professional-category reports authorized by our ShiftEasy.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-2">
                            <div className="px-4 py-3 h-100">
                                <h6 className="text-orange">Rent Guarantee Insurance: </h6>
                                <p className="fs-13 mb-0">ShiftEasy Rent Guarantee Insurance is designed to protect you if your tenant fails to pay the rent. Rent guarantee insurance becomes an essential requirement for all the property owners who are not staying where their house has been rented out. Rent guarantee insurance is a form of insurance where landlords can protect against loss of rent for a reasonably small fee.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 my-2">
                            <div className="px-4 py-3 h-100">
                                <h6 className="text-orange">Maintenance and Repairs: </h6>
                                <p className="fs-13 mb-0">ShiftEasy home maintenance and repairs are so important that they save you, the homeowner, and the money. Shifteasy maintenance and repairs take some money, dedication, and hard work. In the long run, you save much more money than if you did not do maintenance.</p>
                            </div>
                        </div>
                    </div>
                    <div className="my-4">
                        <div className="service-content-box">
                            <h5 className="page-title text-center">Shifteasy 360 Property Management:</h5>
                            <p className="mb-0 text-center">We are specialized in Professional Residential Property Management, Real estate Home and land setting, letting out rental properties with no brokerage to tenants and architect-designed custom construction. Decades of experience, an efficient team and a wide-ranging network helps you save time and cost.</p>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-12 mt-3">
                            <div className="service-content-box text-center">
                                <h5 className="page-title text-capitalize">Property Management – Package</h5>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div id="gray_list"  className="br-5 bg-dark-blue p-3 p-lg-4 h-100">
                                <h5 className="text-orange">Basic Plan: XXXX.INR</h5>
                                <ul className="pl-4 text-white fs-15 mb-0">
                                    <li>Finding tenant</li>
                                    <li>Finalizing agreement</li>
                                    <li>Rent collection</li>
                                    <li>Deposit rent in owner's account</li>
                                    <li>Paying property tax</li>
                                    <li>Paying water tax</li>
                                    <li>Paying apartment maintenance</li>
                                    <li>Representation as an agent of owners</li>
                                    <li>One visit per year and on request</li>
                                </ul>
                            </div>
                        </div>
                        <div  className="col-md-4 mb-3">
                            <div id="gray_list" className="br-5 bg-dark-blue p-3 p-lg-4 h-100">
                                <h5  className="text-orange">Golden Plan: XXXX.INR</h5>
                                <ul className="pl-4 text-white fs-15 mb-0">
                                    <li>Finding tenant (One month free)</li>
                                    <li>Finalizing agreement</li>
                                    <li>Rent collection</li>
                                    <li>Deposit rent in owner's account</li>
                                    <li>Paying property tax</li>
                                    <li>Paying water tax</li>
                                    <li>Paying apartment maintenance</li>
                                    <li>Representation as an agent of owners</li>
                                    <li>Two visit per year and on request</li>
                                    <li>Periodic AMC service & follow up</li>
                                </ul>
                            </div>
                        </div>
                        <div id="gray_list" className="col-md-4 mb-3">
                            <div className="br-5 bg-dark-blue p-3 p-lg-4 h-100">
                                <h5 className="text-orange">Platinum Plan: XXXX.INR</h5>
                                <ul className="pl-4 text-white fs-15 mb-0">
                                    <li>Finding tenant (One month free)</li>
                                    <li>Finalizing agreement</li>
                                    <li>Rent collection</li>
                                    <li>Deposit rent in owner's account</li>
                                    <li>Paying property tax</li>
                                    <li>Paying water tax</li>
                                    <li>Paying apartment maintenance</li>
                                    <li>Representation as an agent of owners</li>
                                    <li>Two visit per year and on request</li>
                                    <li>Periodic AMC service & follow up</li>
                                    <li>Yearly valuation (all incl.)</li>
                                    <li>Consultancy on investment</li>
                                    <li>Yearly EC (all incl.)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="my-4">
                        <div className="service-content-box">
                            <h5 className="page-title text-center mb-4">What Shifteasy Offers:</h5>
                            <div id="gray_list1" className="row">
                                <div  className="col-lg-4 offset-lg-2">
                                    <ul className="orange-disc pl-5">
                                        <li>Lettings and Management</li>
                                        <li>Sales Element</li>
                                        <li>Windows Cards</li>
                                        <li>Training</li>
                                        <li>Landlord/ Tenant Statement</li>
                                        <li>Mobile Access</li>
                                        <li>Full Integration</li>
                                        <li>Property identical</li>
                                        <li>Maintenance and Repair management software</li>
                                        <li>Bank Reconciliation</li>
                                        <li>Websites and Apps</li>
                                        <li>Legal documents</li>
                                    </ul>
                                </div>
                                <div  className="col-lg-4">
                                    <ul className="orange-disc pl-5">
                                        <li>SMS/ Texting</li>
                                        <li>360 Viewing</li>
                                        <li>Property Expenditures</li>
                                        <li>Key Tags</li>
                                        <li>Utility Reading</li>
                                        <li>Multi-lingual apps</li>
                                        <li>Complementary Development work</li>
                                        <li>Payment option on site</li>
                                        <li>Task management</li>
                                        <li>Maintenance APP</li>
                                        <li>Referencing</li>
                                        <li>E-sign compatible</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PropertyManagement));