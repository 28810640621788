import { gql } from '@apollo/client';

export const GET_CUSTOMER_QUERY = gql`
  query getCustomers($customerId: ID!) {
    getCustomers(customerId: $customerId) {
      customerId
      keycloakUserId
      firstName
      lastName
      email
      primaryContactNumber
      secondaryContactNumber
      whatsappNumber
      landlineNumber
      dateOfBirth
      lastLoginDate
      isFeaturedMember
      profileImageLink
      roleId
      statusId
      statusMessage
      termsConditionsAgreement
      termsConditionsDate
      panNumber
      aadhaarNumber
      nationalInsuranceNumber
      socialSecurityNumber
      passportNumber
      drivingLicenceNumber
      createdAt
      updatedBy
      updatedAt
    }
  }
`;