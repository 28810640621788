import React from "react";
import { toast } from 'react-toastify';
import { savePropertyValuationRequestAPI } from '../../services/mutations/mutation-API';
import {  getCountriesAPI, getStatesByCountryAPI, getCityByStateAPI } from '../../services/querys/get-APIs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PropertyForm =()=>{
    const [propertyCountry, setPropertyCountry] = React.useState({
        country: []
    })
    const [propertyState, setPropertyState] = React.useState({
        state: []
    })
    const [propertyCity, setPropertyCity] = React.useState({
        city: []
    })

  
    
    const getCountriesLocal = () => {
        getCountriesAPI('').then(res => {
            setPropertyCountry({
                country: res.data.getCountries
            })
        }).catch(error => {
            console.log(error)
        })
    }

    const formik = useFormik({
        initialValues: {
            fullName: "",
            email: "",
            country: "",
            state: "",
            city: "",
            description: "",


        },
        //    validate,
        validationSchema: Yup.object({
            fullName: Yup.string()
                // .max(15, 'Must be 15 characters or less')
                .required('Enter Your Name'),
            email: Yup.string().email('Invalid email address').required('Enter Valid Email'),
            // phoneNumber: Yup.number()
            //     // .max(20, 'Must be 20 characters or less')
            //     .required('Title'),
            country: Yup.string().required("Enter Your Message"),
            state: Yup.string().required("Enter Your Message"),
            city: Yup.string().required("Enter Your Message"),
            description: Yup.string().required("enter your message"),
        }),
        onSubmit: (value) => {
                savePropertyValuationRequestAPI(value).then(res => {
                    toast.success("Property valuvation registation successfull", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                    console.log(res)
                }).catch((error) => {
                    console.log("error", error)
                    toast.error("VIP Registation failed", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                })
        }
    })
    const [phonestate ,setphoneState]=React.useState(false)
    const handleMobileNumberChange = (value, data, event, formattedValue) => {
        // formik.setFieldValue('countryCode', data.dialCode);
        formik.setFieldValue('phoneNumber', formattedValue);
        if(formattedValue.length <9){
            setphoneState(true)
        }
    };
    React.useEffect(() => {
        if (formik.values.country) {
            let selectObj = propertyCountry.country && propertyCountry.country.find((item, i) => {
                return item.countryName.toLowerCase() === formik.values.country.toLowerCase()
            })
            console.log(selectObj)
            if (selectObj) {
                getStatesByCountryAPI(selectObj.countryId).then(res => {
                    setPropertyState({
                        state: res.data.getStatesByCountry
                    })
                }).catch(error => {
                    console.log(error)
                })
            }
        } else {
            console.log("hi")
        }

    }, [formik.values.country])

    React.useEffect(() => {
        if (formik.values.state) {
            let selectObj = propertyState.state && propertyState.state.find((item, i) => {
                return item.stateName.toLowerCase() === formik.values.state.toLowerCase()
            })
            if (selectObj) {
                getCityByStateAPI(selectObj.stateId).then(res => {
                    setPropertyCity({
                        city: res.data.getCityByState
                    })
                }).catch(error => {
                    console.log(error)
                })
            }
        } else {
            console.log("hi")
        }

    }, [formik.values.state])

    React.useEffect(() => {
        getCountriesLocal();

    }, []);
    return(
        <div>
             {/* Modal for property valuation*/}
             <div className="modal fade" id="freeValForm" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="freeValFormLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-orange border-0">
                            <h5 className="modal-title text-white mx-auto" id="freeValFormLabel">Get Your Free Property Valuation</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="text-white" aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body modal_body">
                            <form className="px-1 px-lg-3" onSubmit={formik.handleSubmit}>
                                <input type="text" className={formik.errors.fullName ? " form-control border_get_color is-invalid  br-0 mb-3 bg-white" : `form-control bg-white  border-0 br-0 mb-3  ${formik.values.fullName === "" ? null : "is-valid "}`} id="fullName" name="fullName" placeholder="Full Name" value={formik.values.fullName} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                <input type="email" className={formik.errors.email ? " form-control border_get_color is-invalid  br-0 mb-3 bg-white" : `form-control bg-white  border-0 br-0 mb-3 ${formik.values.email === "" ? null : "is-valid "}`} name="email" placeholder="Email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                {/* <input type="number" className={formik.errors.phoneNumber ?" form-control border_get_color is-invalid  br-0 mb-3":`form-control  border-0 br-0 mb-3 ${formik.values.phoneNumber === "" ? null : "is-valid " }`} name="phoneNumber" placeholder="Phone Number" value={formik.values.phoneNumber} onBlur={formik.handleBlur} onChange={formik.handleChange} /> */}
                                <PhoneInput
                                    inputClass={!phonestate ?`form-control ${formik.values.phoneNumber?"border_get_color bg-white":null} is-invalid  br-0 mb-3 bg-white`:`form-control bg-white  border-0 br-0 mb-3 ${formik.values.phoneNumber === "" ? null : "is-valid " }`}
                                    country={'in'}
                                    value={formik.values.phoneNumber}
                                    onChange={handleMobileNumberChange}
                                    onBlur={() => formik.setTouched({ 'phoneNumber': true, ...formik.touched })}
                                    dropdownClass="phoneinput"
                                />

                                <input type="text" className={formik.errors.country ? " mt-3 form-control border_get_color is-invalid  bg-white br-0 mb-3" : `mt-3 form-control  bg-white border-0 br-0 mb-3 ${formik.values.country === "" ? null : "is-valid "}`} name="country" list="country_list" placeholder="Country" value={formik.values.country} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                <datalist id="country_list">
                                    {propertyCountry.country && propertyCountry.country.map((country, i) =>
                                        <option key={i}>{country.countryName}</option>
                                    )}
                                </datalist>
                                <input type="text" className={formik.errors.state ? " form-control border_get_color is-invalid bg-white  br-0 mb-3" : `form-control bg-white  border-0 br-0 mb-3 ${formik.values.state === "" ? null : "is-valid "}`} list="state_list" name="state" placeholder="State" value={formik.values.state} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                <datalist id="state_list" dir="rtl">
                                    {propertyState.state && propertyState.state.map((state, i) =>
                                        <option key={i}>{state.stateName}</option>
                                    )}
                                </datalist>
                                <input type="text" className={formik.errors.city ? " form-control border_get_color is-invalid  bg-white br-0 mb-3" : `form-control bg-white  border-0 br-0 mb-3 ${formik.values.city === "" ? null : "is-valid "}`} list="city_list" name="city" placeholder="City" value={formik.values.city} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                <datalist id="city_list">
                                    {propertyCity.city && propertyCity.city.map((city, i) =>
                                        <option key={i}>{city.cityName}</option>
                                    )}
                                </datalist>
                                <textarea rows="4" className={formik.errors.description ? " form-control border_get_color is-invalid bg-white br-0 mb-2" : `form-control bg-white border-0 br-0 mb-2 ${formik.values.description === "" ? null : "is-valid "}`} name="description" placeholder="Description" value={formik.values.description} onBlur={formik.handleBlur} onChange={formik.handleChange}></textarea>

                                <div className="modal-footer p-0 border-0">
                                    {/* <button type="button" className="btn" data-dismiss="modal">Close</button> */}
                                    <button type="submit" className="btn btn-orange">Register</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PropertyForm