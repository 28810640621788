
import { Types } from '../actions/actionTypes';
const initialState = {
    user: "",
    loginSuccessData: [],
    loggedIn: false,
    loading: false,
    loginError: null,
    errorMessage: null,
    userDetails: [],
    token: null,
    refreshToken: null
}
export const login = (state = initialState, action) => {
    switch (action.type) {

        case Types.LOGIN_REQUEST:
            return {
                ...state,
                user: action.user,
                loading: true,
            };
        case Types.LOGIN_SUCCESS:
            return {
                ...state,
                loginSuccessData: action.payload,
                loggedIn: true,
                loading: false,
                errorMessage: null
            };
        case Types.LOGIN_FAILURE:
            return {
                ...state,
                loggedIn: false,
                loginError: action.error
            };
        case Types.LOGIN_SUCCESS_REST:
            return {
                ...state,
                loggedIn: action.params,
                loading: false,
                loginError: null,
                errorMessage: null
            }
        case Types.GET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,

            }
        case Types.LOGIN_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            }
        case Types.GET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case Types.GET_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.payload
            }
        case Types.USERS_LOGOUT:
            return {
                ...state,
                user: "",
                loginSuccessData: [],
                loggedIn: false,
                loading: false,
                loginError: null,
                errorMessage: null,
                token: null,
                refreshToken:null,
                userDetails: []

            }

        default:
            return state
    }
}




