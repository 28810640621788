import React, { Component } from 'react';
import './registration.css';
import info from '../../../assets/images/info.svg';
import editDB from '../../../assets/images/edit-db.svg';
import mobileOTP from '../../../assets/images/smartphone.svg';
import verified from '../../../assets/images/checkCircle.svg';
import $ from 'jquery';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { registerAPI ,refreshTokenAPI} from '../../../services/mutations/mutation-API';
import { userLogin,getToken,getRefreshToken } from '../../../redux/actions/login-action';
import PhoneInput from "react-phone-input-2";


class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registerTapClicked: true,
            loginTabClicked: false,
            isLoginClicked: false,
            isRegisterButtonClicked: true,
            isOTP: false,
            varificationCompleted: false,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            isWhatsappEnabled: false,
            password: "",
            isAgreed: false,
            otp: "",
            isVerified: false,
            loginEmail: "",
            loginPassword: "",
            editMobileNumber: false,
            phone: ""

        }
    }
    registerTabClicked = () => {
        this.setState({
            registerTapClicked: true,
            loginTabClicked: false,
            isRegisterButtonClicked: true,
            isOTP: false,
            varificationCompleted: false
        })
    }
    loginTabClick = () => {
        console.log("aaaa")
        this.setState({
            registerTapClicked: false,
            loginTabClicked: true
        })

    }

    RegisterButtonClick = (e) => {
        e.preventDefault();
        this.setState({
            isRegisterButtonClicked: false,
            // isOTP: true,
            varificationCompleted: true
        });
        // toast.success("OTP sent to given phone number", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 3000
        // });
        toast.success("User created successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
        });

    }
    verifyButtonClick = (e) => {
        e.preventDefault();
        this.setState({
            isRegisterButtonClicked: false,
            isOTP: false,
            varificationCompleted: true
        });
        toast.success("OTP verified and User created successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
        });

    }

    goToLogin = () => {
        this.setState({
            registerTapClicked: false,
            loginTabClicked: true
        })
    }

    infoMouseOver = () => {
        $(() => {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }

    submitRegistation = (e) => {
        const validateEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const validatePhone = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
        let userData = {
            "firstName": this.state.firstName,
            "lastName": this.state.lastName,
            "email": this.state.email,
            "password": this.state.password,
            "mobileNumber": this.state.phoneNumber,
            "whatsAppNumber": this.state.isWhatsappEnabled,
            "originSite":"https://shifteasy.com"
        }
        console.log("userData", userData)
        if (this.state.firstName !== "" && this.state.lastName !== "" && this.state.email !== "" &&
            this.state.password !== "" && this.state.phoneNumber !== "" && this.state.isAgreed === true) {
            if (validateEmail.test(this.state.email) === true) {
                if (validatePhone.test(this.state.phoneNumber) === true) {
                    registerAPI(userData).then((res) => {
                        console.log("res", res);
                        e.preventDefault();
                        this.setState({
                            isRegisterButtonClicked: false,
                            // isOTP: true,
                            varificationCompleted: true,
                            firstName:"",
                            lastName:"",
                            email:"",
                            password:"",
                            phoneNumber:"",
                            isWhatsappEnabled:"",
                            isAgreed:false
                        });
                        toast.success("User created successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000
                        });
                        

                    })
                        .catch((error) => {
                            console.log("error", error)
                            toast.error("registration failed", {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 4000
                            });
                        })
                } else {
                    toast.error("Enter Valid Phone Number", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 4000
                    });
                }

            } else {
                toast.error("Enter Valid Email", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 4000
                });
            }

        } else {
            toast.error("please fill the mandatory fields", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 4000
            });
        }
    }

    submitLogin = () => {
        console.log("log props", this.props)
        var userData = {
            "userName": this.state.loginEmail,
            "password": this.state.loginPassword
        }
        if (this.state.loginEmail !== "" && this.state.loginPassword !== "") {
            this.props.userLogin(userData, this.props);
            setInterval(() => {
                console.log("refresh token api ")
                refreshTokenAPI(this.props.Login?.refreshToken).then((res) => {
                    console.log("referesh res", res)
                    this.props.getUserToken(res.data?.refreshAccessToken?.token);
                    this.props.getUserRefreshToken(res.data?.refreshAccessToken?.refreshToken);

                })
                    .catch((error) => {
                        console.log("refresh token error", error)
                    })

            },240000);

        } else {
            toast.error("please fill the mandatory fields", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 4000
            });
        }
    }
    handleOnChange = (value) => {
        console.log(value);
        this.setState({ phoneNumber: value }, () => {
            console.log(this.state.phoneNumber);
        });
    };

    render() {
        return (
            <div className="regis-pg">
                <Header />
                <div className="header-space">
                    <div className="container-fluid bg-dark-blue px-0">
                        <div className="d-flex justify-content-center align-items-md-center py-4 py-md-0 min-vh-100">
                            <div className="row cover-box m-3 m-md-5 w-100 shadow-lg">
                                <div className="align-items-center col-12 col-md-7 cover-image d-flex d-mob-none">
                                    <div className="text-white p-5 my-3">
                                        <h1 className="font-weight-bold">ShiftEasy Property</h1>
                                        <h5>Find a recommended trades person</h5>
                                        <div>
                                            <span>already registered?</span>
                                            <button className="btn btn-dark-blue ml-2 ml-lg-3">Signin</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-12 bg-white px-xl-5 p-0">
                                    <div className="p-3 p-xl-4">

                                        {/* Register & Login form */}
                                        <div>
                                            {!this.state.isOTP && (
                                                <ul className="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <a onClick={this.registerTabClicked} className={this.state.registerTapClicked ? "nav-link active" : "nav-link"} id="pills-register-tab" data-toggle="pill" href="#pills-register" role="tab" aria-controls="pills-register" aria-selected="true">Register</a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a onClick={this.loginTabClick} className={this.state.loginTabClicked ? "nav-link active" : "nav-link"} id="pills-login-tab" data-toggle="pill" href="#pills-login" role="tab" aria-controls="pills-login" aria-selected="false">Login</a>
                                                    </li>
                                                </ul>
                                            )}
                                            <div className="tab-content" id="pills-tabContent">
                                                {this.state.isRegisterButtonClicked && this.state.registerTapClicked && (
                                                    <div className="tab-pane fade show active" id="pills-register" role="tabpanel" aria-labelledby="pills-register-tab">
                                                        <form>
                                                            <div className="form-group">
                                                                <input type="text" className="form-control br-0" placeholder="First Name" onChange={(e) => this.setState({ firstName: e.target.value })} />
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="text" className="form-control br-0" placeholder="Last Name" onChange={(e) => this.setState({ lastName: e.target.value })} />
                                                            </div>
                                                            <div className="form-group position-relative">
                                                                <input type="email" className="form-control br-0" placeholder="Email Address" onChange={(e) => this.setState({ email: e.target.value })} />
                                                                <img src={info} alt="Info" width="25px" onMouseEnter={this.infoMouseOver} data-toggle="tooltip" data-placement="right" title="Email will sent once confirmed account will be activated" className="info-tooltip" />
                                                            </div>
                                                
                                                            <div className="form-group position-relative">
                                                                {/* <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                        <div class="dropdown" id="basic-addon1">
                                                                            <button class="btn h-100 bg-light br-0 dropdown-toggle" type="button" id="dropdownMenu9" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            +91
                                                                            </button>
                                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenu9">
                                                                                <button class="dropdown-item" type="button">+91</button>
                                                                                <button class="dropdown-item" type="button">+92</button>
                                                                                <button class="dropdown-item" type="button">+93</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <input type="number" class="form-control br-0" placeholder="Mobile Number" onChange={(e) => this.setState({ phoneNumber: e.target.value })} aria-label="MobileNumber" aria-describedby="basic-addon1"/>
                                                                </div> */}
                                                               
                                                                <PhoneInput
                                                                    country={'in'}
                                                                    value={this.state.phoneNumber}
                                                                    onChange={this.handleOnChange}
                                                                />
                                                                <img src={info} alt="Info" width="25px" onMouseEnter={this.infoMouseOver} data-toggle="tooltip" data-placement="right" title="update OTP to verify mobile" className="info-tooltip" />
                                                            </div>
                                                            <div className="d-flex my-3">
                                                                <span className="mr-2">Enable Whats App</span>
                                                                <div className="custom-control custom-switch">
                                                                    <input type="checkbox" className="custom-control-input" id="EnableWhatsApp" onChange={(e) => this.setState({ isWhatsappEnabled: e.target.checked })} />
                                                                    <label className="custom-control-label" htmlFor="EnableWhatsApp"></label>
                                                                </div>
                                                                <span className="ml-3 text-black-50">(Updates)</span>
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="password" className="form-control br-0" placeholder="Password" onChange={(e) => this.setState({ password: e.target.value })} />
                                                            </div>
                                                            <div className="custom-control custom-checkbox d-inline-flex mr-3">
                                                                <input type="checkbox" className="custom-control-input" id="terms" onChange={(e) => this.setState({ isAgreed: e.target.checked })} />
                                                                <label className="custom-control-label" htmlFor="terms">I agree to all the <a href="/terms-conditions">Terms & Conditions</a></label>
                                                            </div>
                                                            <button onClick={this.submitRegistation} type="button" className="btn btn-orange shadow my-3 d-block ml-auto">Register</button>
                                                        </form>
                                                    </div>
                                                )}
                                                {this.state.loginTabClicked && (
                                                    <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="pills-login-tab">
                                                        <form>
                                                            <div className="form-group">
                                                                <input type="email" className="form-control br-0" placeholder="Email Address" onChange={(e) => this.setState({ loginEmail: e.target.value })} />
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="password" className="form-control br-0 form-check-input" placeholder="Password" onChange={(e) => this.setState({ loginPassword: e.target.value })} />
                                                            </div>
                                                            <a href="#" className="float-md-none float-right mb-3 text-orange">Forgot Password?</a>
                                                            <button type="button" className="btn btn-orange shadow my-3 d-block ml-auto" onClick={this.submitLogin}>Login</button>
                                                        </form>
                                                    </div>
                                                )}
                                            </div>

                                        </div>

                                        {/* OTP Verification */}
                                        {/* {this.state.isOTP && this.state.registerTapClicked && (
                                            <div>
                                                <ul className="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <a className={this.state.isOTP ? "nav-link active " : "nav-link"} id="pills-otp-tab" data-toggle="pill" href="#pills-otp" role="tab" aria-controls="pills-otp" aria-selected="true">OTP Verification</a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" id="pills-tabContent">
                                                    <div className="tab-pane fade show active" id="pills-otp" role="tabpanel" aria-labelledby="pills-otp-tab">
                                                        <img src={mobileOTP} alt="Mobile" height="120px" className="d-block mb-3 mx-auto" />
                                                        <p className="text-center text-black-50">Enter the verification code that we have sent to your mobile number.</p>
                                                        <form>
                                                            <div className="input-group">
                                                                {this.state.editMobileNumber === false && (
                                                                    <input type="text" className="form-control br-0" placeholder="Mobile Number" value={this.state.phoneNumber} readOnly />
                                                                )}
                                                                {this.state.editMobileNumber === true && (
                                                                    <input type="text" className="form-control br-0" placeholder="Mobile Number" value={this.state.phoneNumber} onChange={(e) => this.setState({ phoneNumber: e.target.value })} />
                                                                )}

                                                                <div className="input-group-append">
                                                                    <button className="btn bg-transparent" type="button" onClick={() => this.setState({ editMobileNumber: !this.state.editMobileNumber })}>
                                                                        <img src={editDB} alt="Edit" width="18px" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex my-3">
                                                                <input type="text" maxlength="1" className="form-control mr-2 text-center" />
                                                                <input type="text" maxlength="1" className="form-control mx-2 text-center" />
                                                                <input type="text" maxlength="1" className="form-control mx-2 text-center" />
                                                                <input type="text" maxlength="1" className="form-control ml-2 text-center" />
                                                            </div>
                                                            <div className="d-flex justify-content-between my-3 align-items-center">
                                                                <a href={() => false} className="text-orange mr-2">Resend</a>
                                                                <button className="btn btn-orange shadow ml-2" type="button" onClick={this.verifyButtonClick}>Verify</button>
                                                            </div>
                                                            <div className="text-center"><a href={() => false} onClick={this.registerTabClicked} className="text-dark-blue">Go back</a></div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        )} */}

                                        {/* Verification Completed */}
                                        {this.state.varificationCompleted && this.state.registerTapClicked && (
                                            <div>
                                                {/* <ul className="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link" id="pills-vc-tab" data-toggle="pill" href="#pills-vc" role="tab" aria-controls="pills-vc" aria-selected="true">Verification Completed</a>
                                                    </li>
                                                </ul> */}
                                                <div className="tab-content" id="pills-tabContent">
                                                    <div className="tab-pane fade show active" id="pills-vc" role="tabpanel" aria-labelledby="pills-vc-tab">
                                                        <img src={verified} alt="Verified" height="200px" className="d-block mb-3 mx-auto" />
                                                        <p className="text-center text-black-50">Your account has been created successfully!!</p>
                                                        <button type="button" onClick={this.goToLogin} className="btn btn-orange shadow my-3 d-block mx-auto">Go to Login</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        Login: state.login,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        userLogin: (login, loginProps) => dispatch(userLogin(login, loginProps)),
        getUserToken: (token)=>dispatch(getToken(token)),
        getUserRefreshToken: (refreshToken)=>dispatch(getRefreshToken(refreshToken))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Registration));