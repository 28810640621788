import React from "react";
import './drawer.css'
import clsx from "clsx";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/styles";
import closeGoogle from "../../../assets/images/closeGoogle.svg"
const styles = {
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
  nested: {
    // paddingLeft: them.spacing(4),
  },
};

function MenuDrawer(props) {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     drawerSide: { left: true },
  //     openCollapse: false,
  //   };
  // }
  const [openCollapse, setopenCollapse] = React.useState(false);
  const {open, setOpen} = props

  const handleClick = () => {
    console.log("hand click");
    setopenCollapse(!openCollapse)
  };

  // const handleLogOut = () =>{
  //     this.props.setMainCustomer([]);
  //     this.props.setCustomer([]);
  //     this.props.history.push("/register")}
  const { submenu, keycloak, history } = props
  console.log("keyclock", keycloak.idTokenParsed?.name)
  return (
    <div>


      <Drawer
        anchor={"left"}
        open={open}
        onClose={(e) => setOpen(false)}
      >
        <div
          className={clsx(props.classes.list)}
          role="presentation"
          onKeyDown={(e) => setOpen(false)} >
                      <div className="bg-dark ">
         
          </div>
          {/* <div className="draweHeader"> */}
          {/* {keycloak && !keycloak.authenticated ?
            <div className="draweHeader ">
              <button
                type="button"
                className="btn mr-1 mr-lg-1 btn-blue"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={login}
              >
                <LazyLoadImage src={usericon} alt="icon" className="mr-1" />
                Login
              </button>
              <button
                type="button"
                className="btn mr-2 mr-lg-3 btn-blue"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => history.push("/register")}
              >
                Register
              </button>
            </div> : null} */}
          {/* {keycloak.authenticated ?
            <div className="draweHeader">
              <button
                type="button"
                className="btn  btn-blue"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <LazyLoadImage src={usericon} alt="icon" className="mr-1" />
                {keycloak.idTokenParsed?.name}
              </button>
              <button
                className="btn btn-blue"
                type="button"
                onClick={logout}
              >
                Logout
              </button>
            </div> : null} */}
          {/* <button  type="button" className="btn btn-blue left-arrow" onClick={() => this.toggleDrawer("left", false)}>
                <i className="fas fa-arrow-circle-left"></i>
                </button> */}
          {/* </div> */}
          <div className="draweHeader justify-content-around">
          <button type="button" onClick={(e) => setOpen(false)} class="close" aria-label="Close">
            <span aria-hidden="true"><LazyLoadImage src={closeGoogle} alt="no-img"/></span>
          </button>
            <Button href="https://propertymanagement.shifteasy.com/admin/post-property" className="headerPostButton col-9">
              Post your property free
            </Button>
          </div>
          <List>
            <ListItem>
              <ListItemText primary="ShiftEasy Index" />
            </ListItem>
            <Divider />
            <ListItem>
              <a href="" className="prop_form text-dark"  onClick={(e) => setOpen(false)} style={{textDecoration:"none"}}  data-toggle="modal" data-target="#freeValForm" ><ListItemText primary="Value Your Property" /></a>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="About Us" />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleClick}>
              <ListItemText primary="Services" />
              {openCollapse ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={openCollapse}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {submenu.map((item,key) => {
                  if (item.submenuList !== undefined) {
                    return (
                      <Submenus item={item} key={key} />
                    );
                  }
                  else {
                    return (
                      <a href={item.link} target={"_blank"} rel="noreferrer" key={key} className="nav-link"  >
                        <ListItem button className={props.classes.nested}>
                          <LazyLoadImage
                            className="servicesImg"
                            src={item.icon}
                            alt={item.name}
                          />
                          <ListItemText secondary={item.name} />
                        </ListItem>
                      </a>
                    )
                  }
                })}
              </List>
            </Collapse>
            <Divider />
            <ListItem>
              <ListItemText primary="Blog" />
            </ListItem>
            <Divider />
          </List>
        </div>
      </Drawer>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
function Submenus(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const { item ,key} = props
  const { submenuList } = item
  console.log(submenuList)
  return (
    <List
      component="nav"
      key={key}
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <a onClick={handleClick} target={"_blank"} rel="noreferrer" className="nav-link"  >
        <ListItem button >
          <LazyLoadImage
            className="servicesImg"
            src={item.icon}
            alt={item.name}
          />
          <ListItemText secondary={item.name} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </a>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {submenuList.map((items,key) =>
          <List component="div" disablePadding>
            <ListItemLink button className={classes.nested} key={key} href={items.link} target={"_blank"} rel="noreferrer">
              <LazyLoadImage
                className="servicesImg"
                src={items.icon}
                alt={items.name}
              />
              <ListItemText secondary={items.name} />
            </ListItemLink>
          </List>)}
      </Collapse>
    </List>
  );
}
function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}
export default withStyles(styles)(MenuDrawer);
