import React,{Component} from 'react';
import './membership.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SideMenuOne from '../side-menu/side-menu';

class Membership extends Component {

    render(){
        return(
            <div>
                <div className="bottom-header-none">
                    <Header/>
                </div>
                <div className="header-space">
                    <div className="d-flex">
                        <SideMenuOne/>    
                        <div className="right-sec container-fluid px-0"> 
                            <div className="mb-4 container">
                                <div className="text-center text-dark-blue my-4 px-3">
                                    <h3 className="dash-page-title">Membership</h3>
                                </div>
                                <h5 className="text-center page-sub-title">Start working more efficiently</h5>
                                <h6 className="text-center">Choose your plan that right for your business</h6>
                                <div className="col-md-10 offset-md-1 mb-5">
                                    <div className="bg-light p-2 my-3 membership-toggle">
                                        <div className="d-flex my-3 justify-content-center">
                                            <span className="mr-2 fs-14">Annual</span>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="membership"/>
                                                <label className="custom-control-label" htmlFor="membership"></label>
                                            </div>
                                            <span className="ml-2 fs-14">Monthly</span>
                                        </div>
                                    </div>
                                    <div className="row membership-plans">
                                        <div className="col-md-4 mb-3">
                                            <div className="h-100">
                                                <h5 className="membership-plans-title py-3 mb-0">Owner</h5>
                                                <div className="bg-dark-blue d-flex justify-content-center align-items-center py-2">
                                                    <h4 className="text-orange mx-2 mb-0">£9.99</h4>
                                                    <span className="text-white"> / month</span>
                                                </div>
                                                <div className="p-4 text-center">
                                                    <p className="fs-13">10 Properties per month</p>
                                                    <p className="fs-13">100 enquiries</p>
                                                    <p className="fs-13">Unlimited Contacts</p>
                                                    <button className="btn btn-orange px-md-5 px-3">Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="h-100">
                                                <h5 className="membership-plans-title py-3 mb-0">Owner</h5>
                                                <div className="bg-dark-blue d-flex justify-content-center align-items-center py-2">
                                                    <h4 className="text-orange mx-2 mb-0">£11.99</h4>
                                                    <span className="text-white"> / month</span>
                                                </div>
                                                <div className="p-4 text-center">
                                                    <p className="fs-13">15 Properties per month</p>
                                                    <p className="fs-13">100 enquiries</p>
                                                    <p className="fs-13">Unlimited Contacts</p>
                                                    <button className="btn btn-orange px-md-5 px-3">Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="h-100">
                                                <h5 className="membership-plans-title py-3 mb-0">Owner</h5>
                                                <div className="bg-dark-blue d-flex justify-content-center align-items-center py-2">
                                                    <h4 className="text-orange mx-2 mb-0">£20.99</h4>
                                                    <span className="text-white"> / month</span>
                                                </div>
                                                <div className="p-4 text-center">
                                                    <p className="fs-13">20 Properties per month</p>
                                                    <p className="fs-13">1000 enquiries</p>
                                                    <p className="fs-13">Unlimited Contacts</p>
                                                    <button className="btn btn-orange px-md-5 px-3">Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer/>
                        </div>   
                    </div>
                </div>
            </div>
        )
    }
}
export default Membership;