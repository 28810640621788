import React, { Component } from 'react';
import './open-banking.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import openBankServImg1 from '../../../../assets/images/services-page/10-open-banking/image-1.png';
import openBankServImg2 from '../../../../assets/images/services-page/10-open-banking/image-2.png';
import openBankServImg4 from '../../../../assets/images/services-page/10-open-banking/banking.png';
import { scrollTop } from '../../common/common';
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
class OpenBacking extends Component {

    componentDidMount() {
        scrollTop();
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Everything Need to Know Open Banking Services & Solutions</title>
                    <meta name="description" content="Here Everything about Open Banking Services & Solutions| Open Banking is a secure way to give access to your financial information electronically, securely." />
                    <meta name="keywords" content="Open Banking Services" />
                </Helmet>
                <Header />
                <div className="service-banner serv-ten page-top-banner banner-with-form mb-3 mb-lg-5">
                    <div className="service-banner-content text-center">
                        <h2 className="text-white mb-3">Open<span className="text-orange"> Banking</span> Services</h2>
                        <h5>Open banking is a disruptive force in the financial services industry.</h5>
                    </div>
                    <div className="banner-form">
                        <form>
                            <div className="mb-3">
                                <input type="text" className="form-control br-0" placeholder="Full Name" />
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control br-0" placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Phone number" />
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control br-0" rows="4" placeholder="Message"></textarea>
                            </div>
                            <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                        </form>
                    </div>
                </div>
                <div className="container mx-auto">
                    <div className="row my-lg-5 my-4 ">
                        <div className="col-md-12">
                            <div className="service-content-box">
                                <ul>
                                    <li>At Shifteasy, for transparency and customer privacy protection, we offer a revolution model in which banking and insurance needs are shared between two or more affiliated parties to deliver enhanced capabilities to the customers in our marketplace.</li>
                                    <li>Open finance is a secure way of taking care of both your money, banking details, insurance and financial data; the combination of these two elements gives clients more personalized services.</li>
                                    <li>With open banking tools, you can manage your bank account credit and debit better yourself, without requiring third-party interference.</li>
                                    <li>Open insurance tools can provide you better alternates at a cheaper cost, thereby saving money spent on unnecessary protections.</li>
                                    <li>For both personal and business, it provides an excellent opportunity to offer different and innovative services such as money management, price comparison tools etc. To make this shift from traditional cumbersome process more accessible and safer comes into action our Shifteasy.</li>
                                </ul>
                                <img src={openBankServImg1} className="img-fluid mb-3" alt="img" />
                            </div>
                        </div>
                    </div>
                    <h4 className="text-center">Open Banking works primarily in three directions</h4>
                    <br />
                    <div style={{ fontSize: "13px" }} className=" color-box service-content-box col-xs-12">
                        <div className="pad-cont  text-justify rounded  p-4   d-inline-block col-xs-12 col-md-3">
                            <p className="text-left" style={{fontSize:"14px",    margin: "19px"}}><span style={{ fontSize: "16px" }} className="text-orange h5">Aggregation Services :</span><br /> Which simply access and display your financial data from multiple providers offering greater convenience and efficiency.</p>
                        </div>
                        <div className="pad-cont  text-justify rounded  p-4  d-inline-block col-xs-12 col-md-3">
                            <p className="text-left" style={{fontSize:"14px",    margin: "19px"}}><span style={{ fontSize: "16px" }} className="text-orange h5"> Budgeting and Debt Management Services : </span><br />It can help you analyse your financial data.</p>
                        </div>
                        <div className="pad-cont  text-justify rounded  p-4  d-inline-block col-xs-12 col-md-3">
                            <p className="text-left" style={{fontSize:"14px",    margin: "8px"}}><span style={{ fontSize: "16px" }} className="text-orange text-justify h5">Product Comparison and personal recommendation :</span><br /> We are offering personalized recommendations using data to provide new high-value products or services.</p>
                        </div>
                    </div>
                    <div className="row my-lg-5 my-4 align-items-center service-content-box">
                        <div className="col-md-8 col-lg-6">
                            <div className="service-content-box">
                                <h5 className="page-sub-title">Open Financing</h5>
                                <p> In crisp Open Finance is the next step in the open banking voyage. The advantages that promote us to a quick start with this phenomenon
                                    are gaining access to a broader range of financial products and services, having greater control over their data, engaging with their finances, and empowering better financial decisions.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-6">
                            <img src={openBankServImg2} className="img-fluid" alt="img" />
                        </div>
                    </div>
                    <div className="row my-lg- my-4 align-items-center ">
                        <div className="col-md-12">
                            <div className="service-content-box">
                                <h5 className="page-title">What do We do For You at ShiftEasy ?</h5>
                                <p> Our disruptive technology initiative will make it easy for the customer to manage financial services.
                                    It will change how consumers engage with their banks and financial services providers.<br /><br /> We are introducing
                                    new channels and promoting innovation and fast payments. Early days Open Banking API was targeted towards banking
                                    transparency. With our unique and enhanced Open Finance features, we bring more integrated payment services and tracking
                                    tools to ease your life.<br /><br /> We provide enriched to track all your finance needs transparently. We at ShiftEasy provide all
                                    the services starting from Household invoices to Split wise, making your financial environment stressful, safe, and secure.
                                    From easily uploading receipts and documents, submitting tax returns, getting notifications about rental payments, view
                                    local rental insights etc.</p>
                            </div>
                            <div className="service-content-box">
                                <h5 className="page-title">Further Briefing on Open Banking and Open Financing :</h5>
                                <p> Reliance on API is a critical factor to expose to third party data sets held by
                                    banks; not every third party can have access to your financial information; indeed, PSD2 has identified two types of third-party providers,
                                    are Account Information Service Provider or AISP and payment initiation service provider or PISP.<br /><br /> An AISP is a business that uses customer
                                    data to provide services such as aggregating financial information in one place, tracking their spending or planning their finance.
                                    PISP is a company that initiates additional payment on behalf of the user directly from their bank account, offering an alternative to
                                    the use of cards.</p>
                            </div>
                            <div className="service-content-box">
                                <h5 className="page-title">What if Open Banking and Open Finance were never Constructed ? </h5>
                                <p>The traditional Value chain was strictly vertical and was regularly referred to as
                                    a pipeline business model. Banks offer the customer a variety of servicing products in exchange, the customer paid the fee and authorized banks
                                    to access their personal and transactional data now; however, with open banking, value can be created outside of traditional bank allowing third
                                    parties to own the primary customer relationship, open finance tracks all the transaction which is completed and notify all the pending transaction
                                    bills to be made.</p>
                            </div>
                        </div>
                    </div>
                    <div className="service-content-box">
                        <h5 className="page-sub-title">Common Assumptions on Open Banking</h5>
                        <p>Platform Economy and open baking are technically two distinctly different events, even though they share a lot. Platform strategies are mostly mulling to tech companies, while open banking is very much related to financial services.</p>

                    </div>
                    <div className="row img-cont align-items-center service-content-box ">
                        <div className="col-md-8 col-lg-6">
                            <div className="text-justify">
                                <p><h6>Shifeasy</h6> has a wide-ranging experience supporting a range of customers from FinTechs to large multinational organizations across financial services and other sectors.</p>
                                <h6>ShiftEasy facilities:</h6>
                                <ul>
                                    <li><h6>Payment Gateway</h6> is a software application that payment service providers use to process online purchases originating on a merchant website. It acts as an interface between the merchant website and a payment processing bank known as an acquirer.</li>
                                    <li><h6>The Second Payment Services Directive or PSP2</h6> is a European law that has been introduced across the European Union and will make it more secure for you to make electronic payments.</li>
                                    <li><h6>Stronger Customer Authentication</h6> is how more security is applied when you make an electronic payment or access your online banking service.</li>
                                    <li><h6>Application Programming Interface (API)</h6> is the messenger that takes requests and tells a system what you want to do, and then returns the response to you.</li>
                                    <li><h6>Data analytics :</h6>Leveraging data as a resource, from customer understanding to operating and end-to-end performance MI (management information)</li>
                                    <li><h6>Security Risk configuration :</h6> It Incorporated with the operational risk configuration to identify security risks, actions and consequences, including privacy and payment fraud issues.</li>
                                    <li><h6>Cyber risk valuation :</h6> Complete review of cyber defence awareness, including incident identification and reaction and compulsory reporting requirements.</li>
                                </ul>
                            </div>
                        </div>
                        <div className=" col-md-4 col-lg-6 sevice-content-box">
                            <img src={openBankServImg4} className=" img-fluid p-5" alt="img" />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OpenBacking));