// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/services-page/14-property-management/header-banner-serv-14.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serv-fourteen {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
#img img{
    height: 300px;
}
#img{
    margin-top: 20px;
}
#gray_list{
    line-height: 2;
    padding-right: 3px;
}
#gray_list1{
   margin-left: 120px;
}
@media only screen and (max-width:767px) {
    #img{
        display: none
    }
    #gray_list1{
        margin:0px
     }
}
`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/shifteasy-services/property-management/property-management.css"],"names":[],"mappings":"AAAA;IACI,yDAA+G;AACnH;AACA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,kBAAkB;AACtB;AACA;GACG,kBAAkB;AACrB;AACA;IACI;QACI;IACJ;IACA;QACI;KACH;AACL","sourcesContent":[".serv-fourteen {\n    background-image: url(../../../../assets/images/services-page/14-property-management/header-banner-serv-14.jpg);\n}\n#img img{\n    height: 300px;\n}\n#img{\n    margin-top: 20px;\n}\n#gray_list{\n    line-height: 2;\n    padding-right: 3px;\n}\n#gray_list1{\n   margin-left: 120px;\n}\n@media only screen and (max-width:767px) {\n    #img{\n        display: none\n    }\n    #gray_list1{\n        margin:0px\n     }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
