// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact_input_height form .form-control{

    height: 43px;
}
.contact_input_height form  button{
    float: right;
    width: 130px;
}
#split_input{
    width: 257px;
}

.contact_input_height form.custom_border{
    border: 2px solid  rgb(240, 120, 120);;
}
.contact_input_height form .is_valid_true{
    border:2px solid #abeebe
}`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/FooterPages/contactus.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;AAChB;AACA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,qCAAqC;AACzC;AACA;IACI;AACJ","sourcesContent":[".contact_input_height form .form-control{\n\n    height: 43px;\n}\n.contact_input_height form  button{\n    float: right;\n    width: 130px;\n}\n#split_input{\n    width: 257px;\n}\n\n.contact_input_height form.custom_border{\n    border: 2px solid  rgb(240, 120, 120);;\n}\n.contact_input_height form .is_valid_true{\n    border:2px solid #abeebe\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
