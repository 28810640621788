import React, { useEffect } from "react"
import './search-detail.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {enquiryRequestAPI} from '../../../services/mutations/mutation-API';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { useKeycloak } from '@react-keycloak/web';




const EnquiryForm = (props) => {
    const { keycloak } = useKeycloak();

console.log("pops",props)
    const formik = useFormik({
        initialValues: {
            firstName: "",
            email: "",
            message: "",
            phoneNumber: "",
            checked: "",
            propertyId:"",
            customerId:"",


        },
        //    validate,
        validationSchema: Yup.object({
            firstName: Yup.string()
                // .max(15, 'Must be 15 characters or less')
                .required('Enter Your Name'),
            email: Yup.string().email('Invalid email address').required('Enter Valid Email'),
            message: Yup.string()
                //.max(20, 'Must be 20 characters or less')
                .required('Title'),
            phoneNumber: Yup.string()
            .min(8)
            .required('A phone number is required'),
            checked : Yup.boolean()
            .required(true)
        }),
        onSubmit: (value) => {
            console.log(value)
           
            enquiryRequestAPI(value).then(res => {

                $('.modal').modal('hide')
                toast.success("Enquiry mail Sent successfull", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
                console.log(res)
            }).catch((error) => {
                console.log("error", error)
                toast.error("Enquiry Mail successfull failed", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            })
        }
    })
    console.log(formik.values)
    const handleMobileNumberChange = (value, data, event, formattedValue) => {
        const id = props.id
        console.log("ddd",id)
        // if(props.userReducer.user.keycloakUserId===undefined){
        //     alert("login is need")
        //     keycloak.login(); 
        // }
        // else{
        const customerid = props.userReducer.user.keycloakUserId
        formik.setFieldValue('propertyId', parseInt(id));
        formik.setFieldValue('phoneNumber', formattedValue);
        formik.setFieldValue('customerId', customerid);
        // }
        
    };
    useEffect(() => {
        console.log(props.userReducer.user)
        if(props.userReducer.user===null){
            alert("login is need")
            keycloak.login(); 
        }
    }, []);
    // console.log(props.customerId)
    return (
        <div>

            <form onSubmit={formik.handleSubmit} className="px-1 px-lg-3 enq_form">
                <input
                    style={{backgroundColor:"#f2f2f2"}}
                    type="text"
                    className={formik.errors.firstName ? `form-control red_border mb-3 fs-14` : `form-control  ${formik.values.firstName ?  "green_border" : null}  mb-3 fs-14`}
                    placeholder="Name"
                    name='firstName'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                />
                <PhoneInput
                    // inputClass={formik.errors.phoneNumber ?`form-control is-invalid   `:`form-control    ${formik.values.phoneNumber ? "green_border " :null }`}
                    containerClass="bg-white"
                    inputClass=""
                    country={'in'}
                    value={formik.values.phoneNumber}
                    onChange={handleMobileNumberChange}
                    onBlur={() => formik.setTouched({ ['phoneNumber']: true, ...formik.touched })}
                    dropdownClass="phoneinput bg-white"
                    containerStyle={formik.errors.phoneNumber ?{ boxShadow: 'rgb(223, 120, 120) 0px 1px 2px 0px, rgb(223, 120, 120) 0px 1px 3px 1px'} : formik.values.phoneNumber ? { boxShadow: 'rgb(13, 150, 13) 0px 1px 2px 0px, rgb(13, 150, 13) 0px 1px 3px 1px'} : null }
                />
                <input
                    style={{backgroundColor:"#f2f2f2"}}
                    type="email"
                    className={formik.errors.email ? `form-control red_border mt-3 mb-3 fs-14` : `form-control ${formik.values.email ?  "green_border" : null} mt-3 mb-3 fs-14`}
                    placeholder="Email Address"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
                <textarea
                    style={{backgroundColor:"#f2f2f2"}}
                    rows="4"
                    className={formik.errors.message ? `form-control red_border mb-3 fs-14` : `form-control ${formik.values.message ?  "green_border" : null}  mb-3 fs-14`}
                    placeholder="I am interested in this property for viewing"
                    name="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                ></textarea>
                <div className="custom-control custom-checkbox d-inline-flex mr-3 fs-14">
                    <input type="checkbox"
                        className="custom-control-input"
                        id="terms"
                        name="checked"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.checked}
                    />
                    <label className="custom-control-label" htmlFor="terms">I Agree to ShiftEasy' <a href="#" className="text-orange">T&C</a>, <a href="#" className="text-orange">Privacy Policy</a> &  <a href="#" className="text-orange">Cookie Policy</a></label>
                </div>
                <div className="clearfix mt-3">
                   {formik.values.checked ? <button  className="btn btn-orange px-md-5 float-right">
                        Send Message
                    </button> : <button disabled className="btn btn-orange px-md-5 float-right">
                        Send Message
                    </button>}
                </div>
            </form>
        </div>
    )
}



export default EnquiryForm;