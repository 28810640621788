// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p{
    font-size: 14px;
}
.Legal_txt{
    padding-top: 100px
}
.LegalServices img{
    border-radius: 5px;
}
.bg-silver{
    background-color: #E8E8E8;
    height: auto;
}
.silver_box h5{
    position: absolute;
    padding-top: 38px;
    padding-left: 401px;
}
.silver_box ul li{
    text-align: initial;
    padding-left: 1.5em; 
}
.silver_box ul{
    list-style: none;
    position: relative;
    padding: 0;
    margin-top: 30px;
}



.silver_box ul li:before {
    position: absolute;
    content: "•";
    color: #E1601F;
    left: 0;
    font-size: 29px;
}
.legal_para p{
    width: 480px;
}
@media only screen and (max-width:767px) {
    .Legal_ser{
        display: flex;
        flex-direction: column-reverse;
    }
    .provi_law{
        display: flex;
        flex-direction: column-reverse;
    }
    .legal_para{
        padding: 0px;
    }
    .legal_para p{
        width: 100%;
    }
    .silver_box h5{
        padding: 0;
        margin-top: 25px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/shifteasy-services/legal-services-near-you/legal-content.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI;AACJ;AACA;IACI,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,gBAAgB;AACpB;;;;AAIA;IACI,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,OAAO;IACP,eAAe;AACnB;AACA;IACI,YAAY;AAChB;AACA;IACI;QACI,aAAa;QACb,8BAA8B;IAClC;IACA;QACI,aAAa;QACb,8BAA8B;IAClC;IACA;QACI,YAAY;IAChB;IACA;QACI,WAAW;IACf;IACA;QACI,UAAU;QACV,gBAAgB;IACpB;AACJ","sourcesContent":["p{\n    font-size: 14px;\n}\n.Legal_txt{\n    padding-top: 100px\n}\n.LegalServices img{\n    border-radius: 5px;\n}\n.bg-silver{\n    background-color: #E8E8E8;\n    height: auto;\n}\n.silver_box h5{\n    position: absolute;\n    padding-top: 38px;\n    padding-left: 401px;\n}\n.silver_box ul li{\n    text-align: initial;\n    padding-left: 1.5em; \n}\n.silver_box ul{\n    list-style: none;\n    position: relative;\n    padding: 0;\n    margin-top: 30px;\n}\n\n\n\n.silver_box ul li:before {\n    position: absolute;\n    content: \"•\";\n    color: #E1601F;\n    left: 0;\n    font-size: 29px;\n}\n.legal_para p{\n    width: 480px;\n}\n@media only screen and (max-width:767px) {\n    .Legal_ser{\n        display: flex;\n        flex-direction: column-reverse;\n    }\n    .provi_law{\n        display: flex;\n        flex-direction: column-reverse;\n    }\n    .legal_para{\n        padding: 0px;\n    }\n    .legal_para p{\n        width: 100%;\n    }\n    .silver_box h5{\n        padding: 0;\n        margin-top: 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
