import React, { Component } from "react";
import "./search-list.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import searchOrange from "../../../assets/images/svg-icons/search-orange.svg";
import bedIcon from "../../../assets/images/svg-icons/bedroom.svg";
import bathIcon from "../../../assets/images/svg-icons/bathroom.svg";
import stairIcon from "../../../assets/images/svg-icons/stairs.svg";
import parkingIcon from "../../../assets/images/svg-icons/parking-area.svg";
import locationSmall from "../../../assets/images/svg-icons/location-small.svg";
import heartFilled from "../../../assets/images/svg-icons/heart-filled.svg";
import filterIcon from "../../../assets/images/svg-icons/filter.svg";
import { listFeaturedPropertiesAPI } from "../../../services/querys/get-APIs";
import moment from "moment";
import CardMedia from "@mui/material/CardMedia";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import { getAutoSuggestions } from "../../../services/querys/get-APIs";
import { TextField, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
function PaginationComponet(props) {
  let { propertyCount, page, limit, listFeaturedProperties } = props;
  var limitchange;
  if (propertyCount % limit === 0) {
    limitchange = propertyCount / limit;
  } else {
    var mod = propertyCount % limit;
    var mod2 = propertyCount - mod;
    var mod3 = mod2 / limit;
    limitchange = mod3 + 1;
  }
  const handleChange = (event, value) => {
    listFeaturedProperties(undefined, value);
  };
  return <Pagination count={limitchange} page={page} onChange={handleChange} />;
}
function Modal (props){
  const matches = useMediaQuery('(min-width:768px)');
  const {handleClose,filter}=props
  const {open}=props.state
  // console.log("props",props)
return(
  <Dialog
  fullScreen={!matches}
  open={!matches && open}
  onClose={handleClose}
  aria-labelledby="responsive-dialog-title"
>
  <DialogContent className="p-1">
    {filter()}
  </DialogContent>
  <DialogActions>
    <Button
      variant="contained"
      onClick={handleClose}
      sx={{
        backgroundColor: "#e1601f",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#0069d9",
          borderColor: "#0062cc",
          boxShadow: "none",
        },
      }}
      className="px-2 px-lg-4 mr-2 mt-2"
    >
      Submit
    </Button>
  </DialogActions>
</Dialog>
)
}
const PrettoSlider = withStyles({
  root: {
    color: "#E1601F",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#E1601F",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
class searchList extends Component {
  constructor(props) {
    super(props);
    // console.log("propsstate", this.props.location.state,selectedLoction.cityName);
    var categoryId =
      window.location.host === "residential.shifteasy.com" ? 1 : 2;
    var checkboxData =
      categoryId === 1
        ? [
            {
              id: 4,
              name: "Studio Apartment",
              checked: false,
            },
            {
              id: 1,
              name: "Pent House",
              checked: false,
            },
            {
              id: 5,
              name: "Service Apartment",
              checked: false,
            },
            {
              id: 7,
              name: "Independent House/Villa",
              checked: false,
            },
          ]
        : [
            {
              id: 8,
              name: "Office Space",
              checked: false,
            },
            {
              id: 14,
              name: " Commercial Shop",
              checked: false,
            },
            {
              id: 27,
              name: "Marriage Hall",
              checked: false,
            },
            {
              id: 28,
              name: "Hotals/PG/Co-living",
              checked: false,
            },
            {
              id: 29,
              name: "Old Age Homes",
              checked: false,
            },
            {
              id: 30,
              name: "Creche",
              checked: false,
            },
            {
              id: 18,
              name: "Hospitals",
              checked: false,
            },
            {
              id: 21,
              name: "Storeroom",
              checked: false,
            },
          ];
    var featuredProperty = {};
    var CountryDetails =
      this.props.location.state !== undefined
        ? this.props.location.state.CountryDetails
        : [];
    var selectedLoction = JSON.parse(
      window.sessionStorage.getItem("selectedLoction")
    );

    var statedata =
    selectedLoction!==null && selectedLoction.cityName !== undefined
        ? selectedLoction.cityName
        : "";
    statedata =
      this.props.location.state !== undefined && CountryDetails.length !== 0
        ? CountryDetails.name
        :statedata;
        console.log(
          "propsstate",
          this.props.location.state,
          selectedLoction
        );
        featuredProperty.latitude=selectedLoction!==null && selectedLoction.latitude !== undefined
        ? selectedLoction.latitude
        : "";
        featuredProperty.longitude=selectedLoction!==null && selectedLoction.longitude !== undefined
        ? selectedLoction.longitude
        : "";
    featuredProperty.latitude =
    this.props.location.state !== undefined && CountryDetails.length !== 0 ? CountryDetails.latitude : featuredProperty.latitude;
    featuredProperty.longitude =
    this.props.location.state !== undefined && CountryDetails.length !== 0 ? CountryDetails.longitude : featuredProperty.longitude;
    var lease =
      this.props.location.state !== undefined
        ? this.props.location.state.lease
        : false;
    var preferredFor =
      this.props.location.state !== undefined
        ? this.props.location.state.preferredFor
        : [];
    var rent =
      this.props.location.state !== undefined
        ? this.props.location.state.rent
        : false;
    var sell =
      this.props.location.state !== undefined
        ? this.props.location.state.sell
        : false;
    console.log("wertyuiosdfghj", rent, lease, sell,featuredProperty);
    var PropertyType = "Rent";
    var price = [1000, 37000];
    var minlimit = 0;
    var maxLimit = 1000000;
    preferredFor.forEach((element) => {
      let index2 = checkboxData.findIndex((el) => el.id === Number(element));
      checkboxData[index2].checked = true;
    });
    if (lease) {
      PropertyType = "Lease";
      price = [100000, 10000000];
      minlimit = 100000;
      maxLimit = 1000000;
    }
    if (rent) {
      PropertyType = "Rent";
      price = [1000, 100000];
      minlimit = 1000;
      maxLimit = 10000;
    }
    if (sell) {
      PropertyType = "Sell";
      price = [100000, 10000000];
      minlimit = 100000;
      maxLimit = 1000000;
    }
    console.log("buy", PropertyType);
    this.state = {
      searchPropertyList: [],
      areaSerachText: "",
      PropertyType,
      price,
      minlimit,
      maxLimit,
      preferredFor: [],
      categoryId,
      propertyCount: 0,
      checkData: [
        {
          id: 12,
          name: "Lift",
          checked: false,
        },
        {
          id: 61,
          name: "Intercom",
          checked: false,
        },
        {
          id: 55,
          name: "Air Conditioner",
          checked: false,
        },
        {
          id: 83,
          name: "Children Play Area",
          checked: false,
        },
        {
          id: 84,
          name: "Rain Water Harvesting",
          checked: false,
        },
        {
          id: 25,
          name: "Club House",
          checked: false,
        },
        {
          id: 26,
          name: "Internet Services",
          checked: false,
        },
        {
          id: 34,
          name: "House keeping",
          checked: false,
        },
        {
          id: 85,
          name: "Visitor Parking",
          checked: false,
        },
        // {
        //   id:86,
        //   name:"Shopping Center",
        // checked:false
        // },
        {
          id: 23,
          name: "Swimming Pool",
          checked: false,
        },
        {
          id: 30,
          name: "Fire Safety",
          checked: false,
        },
        {
          id: 13,
          name: "Power Backup",
          checked: false,
        },
      ],
      bedroom: [],
      bedData: [
        {
          id: 1,
          name: "1BHK",
          checked: false,
        },
        {
          id: 2,
          name: "2BHK",
          checked: false,
        },
        {
          id: 3,
          name: "3BHK",
          checked: false,
        },
        {
          id: 4,
          name: "4BHK",
          checked: false,
        },
        {
          id: 5,
          name: "5BHK",
          checked: false,
        },
        //   ,{
        //     id:6,
        //     name:"6BHK",
        //     checked:false
        //   },{
        //     id:7,
        //     name:"7BHK",
        //     checked:false
        //   },{
        //     id:8,
        //     name:"8BHK",
        //     checked:false
        //   },{
        //     id:9,
        //     name:"9BHK",
        //     checked:false
        //   },
      ],
      amenityId: [],
      furnishingId: [],
      furnishingArray: [
        {
          id: 1,
          name: "Furnished",
          checked: false,
        },
        {
          id: 2,
          name: "Semi – furnished",
          checked: false,
        },
        {
          id: 3,
          name: "Unfurnished",
          checked: false,
        },
      ],
      checkboxData,
      featuredProperty,
      page: 1,
      limit: 4,
      statedata,
      CountryDetails,
      AutoSuggestions: [],
      open: false,
      geoLocation: {
        latitude: "",
        longitude: "",
        cityName: "",
      },
    };
    console.log("checkboxData",checkboxData,preferredFor)
    if (this.props.location.state !== undefined) {
      this.listFeaturedProperties();
    }
  }

  selectPreferedFor = (e) => {
    var temp = this.state.checkboxData;
    var copyData = this.state.preferredFor;
    console.log(">>temp:copyData",temp,copyData)
    console.log("Index2",e.target.value,e.target.checked)
    let index2 = temp.findIndex((el) => el.id === Number(e.target.value));
    console.log(" position",index2)

    if (e.target.checked === true) {
      if(Number(e.target.value)===8){
        copyData.push(9)
        copyData.push(10)
      }
      temp[index2].checked = true;
      copyData.push(Number(e.target.value));
    } else {
      if(Number(e.target.value)===8){
      let index = copyData.findIndex((el)=>el === Number(9))
      copyData.splice(index, 1);
      index = copyData.findIndex((el)=>el === Number(10))
      copyData.splice(index, 1);
      }
      let index = copyData.findIndex((el)=>el === Number(e.target.value))
      copyData.splice(index, 1);
      temp[index2].checked = false;
    }
    // setCheckData(temp);
    //     setamenitiesList( copyData )
    //     SetToggle(!toggle);
    console.log("temp:copyData",temp,copyData)
    this.setState(
      {
        checkboxData: temp,
        preferredFor: copyData,
      },
      () => {
        this.listFeaturedProperties();
      }
    );
  };
  selectFurnishing = (e) => {
    var temp = this.state.furnishingArray;
    var copyData = this.state.furnishingId;
    let index2 = temp.findIndex((el) => el.id === Number(e.target.value));
    if (e.target.checked === true) {
      temp[index2].checked = true;
      copyData.push(Number(e.target.value));
    } else {
      let index = copyData.findIndex((el)=>el === Number(e.target.value))
      copyData.splice(index, 1);
      temp[index2].checked = false;
    }
    // setCheckData(temp);
    //     setamenitiesList( copyData )
    //     SetToggle(!toggle);
    this.setState(
      {
        furnishingArray: temp,
        furnishingId: copyData,
      },
      () => {
        this.listFeaturedProperties();
      }
    );
  };
  setAmenitiesList = (e) => {
    var temp = this.state.checkData;
    var copyData = this.state.amenityId;
    let index2 = temp.findIndex((el) => el.id === Number(e.target.value));
    if (e.target.checked === true) {
      temp[index2].checked = true;
      copyData.push(Number(e.target.value));
    } else {
      let index = copyData.findIndex((el)=>el === Number(e.target.value))
      copyData.splice(index, 1);
      temp[index2].checked = false;
    }
    // setCheckData(temp);
    //     setamenitiesList( copyData )
    //     SetToggle(!toggle);
    this.setState(
      {
        checkData: temp,
        amenityId: copyData,
      },
      () => {
        this.listFeaturedProperties();
      }
    );
  };
  setBedroom = (e) => {
    var temp = this.state.bedData;
    var copyData = this.state.bedroom;
    let index2 = temp.findIndex((el) => el.id === Number(e.target.value));
    if (e.target.checked === true) {
      temp[index2].checked = true;
      copyData.push(Number(e.target.value));
    } else {
      let index = copyData.findIndex((el)=>el === Number(e.target.value))
      copyData.splice(index, 1);
      temp[index2].checked = false;
    }
    // setCheckData(temp);
    //     setamenitiesList( copyData )
    //     SetToggle(!toggle);
    this.setState(
      {
        bedData: temp,
        bedroom: copyData,
      },
      () => {
        this.listFeaturedProperties();
      }
    );
  };
  handleChange = (event, price, activeThumb) => {
    this.setState({ price });
  };
  onChangeCommitted = (event, price) => {
    this.setState(
      {price,},() => {this.listFeaturedProperties();
      }
    );
  };
  handleChange2 = (e) => {
    console.log(e.target);
  };
  listFeaturedProperties = (data, page) => {
    // console.log("data",data)

    var { featuredProperty } = this.state;
    const {
      PropertyType,
      price,
      limit,
      preferredFor,
      categoryId,
      furnishingId,
      amenityId,
      bedroom,
    } = this.state;
    // console.log("preferredFor", preferredFor);
    featuredProperty = {
      categoryId: categoryId.length !== 0 ? categoryId : null,
      propertyTypeId: preferredFor.length !== 0 ? preferredFor : null,
      rent: PropertyType === "Rent" ? true : false,
      sell: PropertyType === "Sell" ? true : false,
      lease: PropertyType === "Lease" ? true : false,
      bedroom: bedroom.length !== 0 ? bedroom : null,
      furnishingId: furnishingId.length !== 0 ? furnishingId : null,
      priceStart: price[0],
      priceEnd: price[1],
      amenityId: amenityId.length !== 0 ? amenityId : null,
      sortId: null,
      userId: null,
      limit,
      page: page !== undefined ? page : 1,
      latitude: data !== undefined ? data.latitude : featuredProperty.latitude,
      longitude:
        data !== undefined ? data.longitude : featuredProperty.longitude,
    };
    console.log("featuredProperty", featuredProperty);
    listFeaturedPropertiesAPI(featuredProperty)
      .then(({ data }) => {
        // console.log("data", data);
        this.setState({
          searchPropertyList: data.listFeaturedProperty.response,
          featuredProperty,
          propertyCount: data.listFeaturedProperty.propertyCount,
          page: page !== undefined ? page : 1,
        });
        if (page !== undefined) {
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => console.log("Error", error));
  };
  enterSearch = (value) => {
    console.log("value", value);
    const { featuredProperty } = this.state;
    this.setState({
      statedata: value,
    });
    getAutoSuggestions({
      latitude: featuredProperty.latitude,
      longitude: featuredProperty.longitude,
      cityName: value,
    })
      .then((data) => {
        var array1 = []
          data.data.getAutoSuggestions.locations.forEach(element => {
            const {latitude,longitude}=element
            var object ={
              name:element.locationName1,
              name2:element.locationName2,
              latitude,longitude,
              type:"Location"
            }
            array1.push(object)
          });
          data.data.getAutoSuggestions.projects.forEach(element => {
            const {latitude,longitude}=element
            var object ={
              name:element.projectName,
              name2:element.projectName,
              latitude,longitude,
              type:"Projects"
            }
            array1.push(object)
          });
        this.setState({
          AutoSuggestions: array1,
        });
      })
      .catch((error) => console.log("error", error));
  };
  filter = () => {
    const {
      price,
      page,
      limit,
      AutoSuggestions,
      checkboxData,
      CountryDetails,
      maxLimit,
      minlimit,
      PropertyType,
      furnishingArray,
      checkData,
      categoryId,
      bedData,
      propertyCount,
    } = this.state;
    return (
      <div className="bg-white p-4 search-filter-sec-body">
        <h6>Property Type</h6>
        <div
          className="btn-group btn-group-toggle w-100 choose-prop-type mb-3"
          data-toggle="buttons"
        >
          <label
            className={`btn btn-outline-orange my-2 br-0 ${
              PropertyType === "Rent" ? "active" : ""
            }`}
          >
            <input
              type="radio"
              name="Rent"
              id="optionPT1"
              checked={PropertyType === "Rent" ? true : false}

              onClick={() =>
                this.setState(
                  {
                    PropertyType: "Rent",
                    price: [1000, 100000],
                    minlimit: 1000,
                    maxLimit: 100000,
                  },
                  () => {
                    this.listFeaturedProperties();
                  }
                )
              }
            />{" "}
            Rent
          </label>
          <label
            className={`btn btn-outline-orange m-2 ${
              PropertyType === "Sell" ? "active" : ""
            }`}
          >
            <input
              type="radio"
              name="Sell"
              id="optionPT2"
              onClick={() =>
                this.setState(
                  {
                    PropertyType: "Sell",
                    price: [100000, 10000000],
                    minlimit: 100000,
                    maxLimit: 10000000,
                  },
                  () => {
                    this.listFeaturedProperties();
                  }
                )
              }
            />{" "}
            Buy
          </label>
          <label
            className={`btn btn-outline-orange my-2 br-0 ${
              PropertyType === "Lease" ? "active" : ""
            }`}
          >
            <input
              type="radio"
              name="Lease"
              id="optionPT3"
              onClick={() =>
                this.setState(
                  {
                    PropertyType: "Lease",
                    price: [100000, 10000000],
                    minlimit: 100000,
                    maxLimit: 10000000,
                  },
                  () => {
                    this.listFeaturedProperties();
                  }
                )
              }
            />{" "}
            Lease
          </label>
        </div>
        <div className="mb-4">
          <div className="row mb-4">
            <div className="col-12 mb-2">
              {checkboxData.map((item) => {
                return (
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`checkboxData${item.id}`}
                      checked={item.checked}
                      value={item.id}
                      onChange={this.selectPreferedFor}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`checkboxData${item.id}`}
                    >
                      {item.name}
                    </label>
                  </div>
                );
              })}{" "}
            </div>
          </div>
        </div>
        <div className="mb-3">
          <div className="d-flex align-item-center">
            <div className="slider__left-value">₹ {price[0]}</div>
            <span className="mx-2">-</span>
            <div className="slider__right-value">₹ {price[1]}</div>
          </div>
          <PrettoSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            getAriaLabel={() => "Temperature range"}
            value={price}
            onChange={this.handleChange}
            onChangeCommitted={this.onChangeCommitted}
            min={minlimit}
            max={maxLimit}
            // valueLabelDisplay="auto"
            // getAriaValueText={valuetext}
          />
          {/* <MultiRangeSlider min={0} max={10000} handleChange={this.handleChange}/> */}
        </div>
        <h6>Amenities</h6>
        <div className="mb-4">
          <div className="row mb-4">
            <div className="col-12 mb-2">
              {checkData.map((item) => {
                return (
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`Amenities${item.id}`}
                      checked={item.checked}
                      value={item.id}
                      onChange={this.setAmenitiesList}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`Amenities${item.id}`}
                    >
                      {item.name}
                    </label>
                  </div>
                );
              })}{" "}
            </div>
          </div>
        </div>
        {categoryId === 1 ? (
          <>
            <h6>Bedroom</h6>
            <div className="mb-4">
              <div className="row mb-4">
                <div className="col-12 mb-2">
                  {bedData.map((item) => {
                    return (
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`bed${item.id}`}
                          checked={item.checked}
                          value={item.id}
                          onChange={this.setBedroom}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`bed${item.id}`}
                        >
                          {item.name}
                        </label>
                      </div>
                    );
                  })}{" "}
                </div>
              </div>
            </div>
          </>
        ) : null}
        {categoryId === 1 ? (
          <>
            <h6>Furnished Status</h6>
            <div className="mb-4">
              <div className="row mb-4">
                <div className="col-12 mb-2">
                  {furnishingArray.map((item) => {
                    return (
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`furnishingArray${item.id}`}
                          checked={item.checked}
                          value={item.id}
                          onChange={this.selectFurnishing}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`furnishingArray${item.id}`}
                        >
                          {item.name}
                        </label>
                      </div>
                    );
                  })}{" "}
                </div>
              </div>
            </div>
          </>
        ) : null}
        {/* <h6>Facing</h6>
          <div className="mb-4">
            <div className="row mb-4">
              <div className="col-12 mb-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="faccheck1"
                    checked=""
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="faccheck1"
                  >
                    East
                  </label>
                </div>
              </div>
              <div className="col-12 mb-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="faccheck2"
                    checked="checked"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="faccheck2"
                  >
                    North
                  </label>
                </div>
              </div>
              <div className="col-12 mb-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="faccheck3"
                    checked="checked"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="faccheck3"
                  >
                    North-East
                  </label>
                </div>
              </div>
              <div className="col-12 mb-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="faccheck4"
                    checked="checked"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="faccheck4"
                  >
                    North-West
                  </label>
                </div>
              </div>
              <div className="col-12 mb-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="faccheck5"
                    checked="checked"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="faccheck5"
                  >
                    South
                  </label>
                </div>
              </div>
              <div className="col-12 mb-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="faccheck6"
                    checked="checked"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="faccheck6"
                  >
                    South-East
                  </label>
                </div>
              </div>
            </div>
          </div> */}
      </div>
    );
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  render() {
    const {
      open,
      price,
      page,
      limit,
      AutoSuggestions,
      checkboxData,
      CountryDetails,
      maxLimit,
      minlimit,
      PropertyType,
      furnishingArray,
      checkData,
      categoryId,
      bedData,
      propertyCount,
    } = this.state;

    // console.log("search list props",this.props.location.state.CountryDetails.length!==0 )
    return (
      <div className="search-list-pg">
        <Header
          listFeaturedProperties={
            this.props.location.state !== undefined &&
            this.props.location.state.CountryDetails.length !== 0 && this.props.location.state.CountryDetails.latitude!==""
              ? undefined
              : this.listFeaturedProperties
          }
        />
        <div className="header-space">
          <div className="container-fluid px-30 search-pg d-mob-none">
            <nav aria-label="breadcrumb" className="w-100">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                {/* <li className="breadcrumb-item">
                      <a href="#">Chennai</a>
                    </li> */}
                <li className="breadcrumb-item active" aria-current="page">
                  Property for {PropertyType==="Sell"?"Buy":PropertyType}{" "}
                  {CountryDetails.length !== 0
                    ? `in ${CountryDetails.name}`
                    : ""}
                </li>
              </ol>
            </nav>
            <div className="d-flex my-3 w-100">
              {/* <input
                    type="text"
                    className="form-control border-0 shadow-sm bg-dark-light fs-13"
                    placeholder="Anna Nagar, Nungambakkam, Egmore, ..."
                    onChange={this.areaSerach}
                  /> */}
              <Autocomplete
                autoSelect
                inputValue={this.state.statedata}
                onInputChange={(event, newInputValue) => {
                  if (event !== null && newInputValue !== null) {
                    this.enterSearch(newInputValue);
                  }
                }}
                onChange={(event, newValue) => {
                  console.log("value", newValue);
                  var { featuredProperty } = this.state;

                  if (newValue != null) {
                    featuredProperty.latitude = newValue.latitude;
                    featuredProperty.longitude = newValue.longitude;
                    this.setState(
                      {
                        featuredProperty,
                        CountryDetails: newValue,
                      },
                      () => {
                        this.listFeaturedProperties();
                      }
                    );
                  } else {
                    //   CountryDetails("")
                    //   setState({ ...state, state: "" });
                  }
                }}
                id="combo-box-demo"
                className="form-control border-0 p-0 shadow-sm bg-dark-light fs-13"
                options={AutoSuggestions}
                size="small"
                autoHighlight
                groupBy={(option) => option.type}
                getOptionLabel={(option) => option.name}
                sx={{
                  "& .MuiInputBase-root": {
                    // paddingBottom:"3px"
                  },
                }}
                renderOption={(props, option) => {
                  // const matches = option.structured_formatting.main_text_matched_substrings;
                  // const parts = parse(
                  //   option.structured_formatting.main_text,
                  //   matches.map((match) => [match.offset, match.offset + match.length]),
                  // );
                  var icon = LocationOnIcon
                  switch(option.type){
                    case "Location":
                      icon=LocationOnIcon
                      break;
                    case "Projects":
                      icon=HomeIcon
                      break;
                    case "builders":
                      icon=HomeIcon
                      break;
                  }
          
                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Box
                            component={icon}
                            sx={{ color: 'text.secondary', mr: 2 }}
                          />
                        </Grid>
                        <Grid item xs>
                          {/* {parts.map((part, index) => ( */}
                            <span
                              // key={index}
                              // style={{
                              //   fontWeight: part.highlight ? 700 : 400,
                              // }}
                            >
                              {option.name}
                            </span>
                          {/* ))} */}
          
                          <Typography variant="body2" color="text.secondary">
                            {option.name2}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Enter Location, Project or Landmark & Hit enter"
                  />
                )}
              />
              <button className="btn btn-orange px-4 shadow-sm">Search</button>
            </div>
            <div></div>
          </div>
          <div className="container-fluid px-0 search-list-sec1">
            <div className="d-flex py-3 py-md-4 px-30">
              {/* <div className="search-filter-sec d-mob-none d-web-sm-none"> */}
              <div className="search-filter-sec d-mob-none ">
                <div className="search-filter-sec-header">
                  <div className="d-flex justify-content-between align-items-end">
                    <h5 className="text-white mb-0">Filter</h5>
                    <button
                      type="button"
                      className="fs-14 text-white cus-button-style"
                    >
                      Reset all
                    </button>
                  </div>
                </div>
                {this.filter()}
              </div>

              <div className="search-cards-list-sec">
                <div className="d-web-none br-5 shadow-searchpg">
                  <div className="input-group mb-3">
                    {/* <input
                      type="text"
                      className="form-control br-5 border-0 bg-white h-auto"
                      placeholder="Anna Nagar"
                    /> */}
                    <Autocomplete
                      autoSelect
                      inputValue={this.state.statedata}
                      onInputChange={(event, newInputValue) => {
                        if (event !== null && newInputValue !== null) {
                          this.enterSearch(newInputValue);
                        }
                      }}
                      onChange={(event, newValue) => {
                        console.log("value", newValue);
                        var { featuredProperty } = this.state;

                        if (newValue != null) {
                          featuredProperty.latitude = newValue.latitude;
                          featuredProperty.longitude = newValue.longitude;
                          this.setState(
                            {
                              featuredProperty,
                              CountryDetails: newValue,
                            },
                            () => {
                              this.listFeaturedProperties();
                            }
                          );
                        } else {
                          //   CountryDetails("")
                          //   setState({ ...state, state: "" });
                        }
                      }}
                      id="combo-box-demo"
                      className="form-control br-5 p-0 border-0 bg-white h-auto"
                      options={AutoSuggestions}
                      size="small"
                      autoHighlight
                      groupBy={(option) => option.type}
                      getOptionLabel={(option) => option.name}
                      sx={{
                        "& .MuiInputBase-root": {
                          // paddingBottom:"3px"
                        },
                      }}
                      renderOption={(props, option) => {
                        // const matches = option.structured_formatting.main_text_matched_substrings;
                        // const parts = parse(
                        //   option.structured_formatting.main_text,
                        //   matches.map((match) => [match.offset, match.offset + match.length]),
                        // );
                        var icon = LocationOnIcon
                        switch(option.type){
                          case "Location":
                            icon=LocationOnIcon
                            break;
                          case "Projects":
                            icon=HomeIcon
                            break;
                          case "builders":
                            icon=HomeIcon
                            break;
                        }
                
                        return (
                          <li {...props}>
                            <Grid container alignItems="center">
                              <Grid item>
                                <Box
                                  component={icon}
                                  sx={{ color: 'text.secondary', mr: 2 }}
                                />
                              </Grid>
                              <Grid item xs>
                                {/* {parts.map((part, index) => ( */}
                                  <span
                                    // key={index}
                                    // style={{
                                    //   fontWeight: part.highlight ? 700 : 400,
                                    // }}
                                  >
                                    {option.name}
                                  </span>
                                {/* ))} */}
                
                                <Typography variant="body2" color="text.secondary">
                                  {option.name2}
                                </Typography>
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter Location, Project or Landmark & Hit enter"
                        />
                      )}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn br-5 bg-white">
                        <img src={searchOrange} alt="Search" />
                      </button>
                    </div>
                  </div>
                </div>
                <h4 className="page-sub-title d-web-none text-center">
                  Showing "{propertyCount} Search Results"
                </h4>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4 className="page-sub-title d-mob-none">
                    Showing "{propertyCount} Search Results"
                  </h4>
                  <button
                    onClick={() => this.setState({ open: true })}
                    className="btn bg-white shadow-searchpg min-wid-200px d-flex justify-content-between align-items-center mr-3 d-web-none"
                  >
                    <span>Filter</span>
                    <img src={filterIcon} alt="filter" className="ml-3" />
                  </button>
                    <Modal {...this}/>
                  <div className="dropdown">
                    <button
                      className="btn fs-14 shadow-searchpg bg-white dropdown-toggle min-wid-200px d-flex justify-content-between align-items-center sort-btn"
                      type="button"
                      id="srtbtn"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sort by&nbsp;
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="srtbtn"
                    >
                      <span className="dropdown-item" href="">
                        Name (A-Z)
                      </span>
                      <span className="dropdown-item" href="">
                        Name (Z-A)
                      </span>
                      <span className="dropdown-item" href="">
                        Location (A-Z)
                      </span>
                      <span className="dropdown-item" href="">
                        Location (Z-A)
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.searchPropertyList.map((property, indx) => (
                    <div className="search-result-card" key={indx}>
                      <div className="badge-new">New</div>
                      <div className="fav-icon">
                        <img src={heartFilled} />
                      </div>
                      <div className="row mx-auto">
                        <div className="result-card-left-sec col-sm-6 ">
                          <div
                            id="searchResultSlider1"
                            className="carousel slide"
                            data-ride="carousel"
                          >
                            <div className="carousel-inner">
                              {property.propertyImageUrl.map((img, ind) => (
                                <div className="carousel-item active" key={ind}>
                                  <CardMedia
                                    component="img"
                                    height="194"
                                    image={img.imageUrl}
                                    alt={property.projectName}
                                  />
                                  {/* <img
                                        src={img.imageUrl}
                                        className="d-block w-100"
                                        alt="Image"
                                      /> */}
                                </div>
                              ))}
                              {/* <div className="carousel-item">
                                                                <img src={image2} className="d-block w-100" alt="Image" />
                                                            </div> */}
                            </div>
                            {/* <a className="carousel-control-prev" href="#searchResultSlider1" role="button" data-slide="prev">
                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span className="sr-only">Previous</span>
                                                        </a>
                                                        <a className="carousel-control-next" href="#searchResultSlider1" role="button" data-slide="next">
                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span className="sr-only">Next</span>
                                                        </a> */}
                          </div>
                          {/* <div className="d-block d-sm-none mt-2">
                                <Link
                                  type="button"
                                  to={{
                                    pathname: `/search-detail/${property.propertyId}`,
                                    state: {
                                      propertyId: property.propertyId,
                                    },
                                  }}
                                  className="btn btn-orange px-2 px-lg-4 mr-2 mt-2"
                                >
                                  View{" "}
                                  <span className="d-mob-none">Details</span>
                                </Link>
                                <button
                                  type="button"
                                  className="btn btn-blue px-2 px-lg-4 mr-2 mt-2"
                                >
                                  Enquire{" "}
                                  <span className="d-mob-none">Now</span>
                                </button>
                              </div> */}
                        </div>
                        <div className="result-card-right-sec col-sm-6 px-2 pt-2 pt-lg-0">
                          <h6>
                            {" "}
                            {categoryId === 1
                              ? `${property.bedroom} BHK`
                              : null}{" "}
                            {property.propertyTypeName} for {property.type} in{" "}
                            {property.cityName},{property.localityName}
                          </h6>
                          <div className="mb-2 mob-lh-12">
                            <h5 className="text-orange d-inline font-weight-bold">
                              &#8377;&nbsp;{property.price}
                            </h5>
                            &nbsp;&nbsp;
                            {/* <span className="fs-14">Per Sq. ft. &#8377;100</span> */}
                          </div>
                          {/* <div className="mob-lh-12 mb-1"><h6 className="text-dark d-inline fs-15 font-weight-bold">3BHK Flat 2100 sqft,</h6>&nbsp;&nbsp;<span className="fs-14">{property.area}, {property.cityName}</span></div> */}
                          <div className="mob-lh-12 mb-1">
                            <img src={locationSmall} />
                            &nbsp;
                            <span className="fs-14">
                              Based in {property.cityName}
                            </span>
                          </div>
                          {/* <div className="mb-1 mob-lh-12 d-flex">
                                                        <span className="add-rating">
                                                            <img src={starFilled} className="mr-1" />
                                                            <img src={starFilled} className="mr-1" />
                                                            <img src={starFilled} className="mr-1" />
                                                            <img src={starFilled} className="mr-1" />
                                                            <img src={starNotFilled} className="mr-1" />
                                                        </span>
                                                        &nbsp;&nbsp;<span className="fs-14">800 Reviews</span>
                                                    </div> */}
                          <div>
                            <Button
                              variant="contained"
                              href={`/search-detail/${
                                categoryId === 2
                                  ? "commercial#"
                                  : "residential#"
                              }${property.propertyId}`}
                              sx={{
                                backgroundColor: "#e1601f",
                                textTransform: "none",
                                "&:hover": {
                                  backgroundColor: "#0069d9",
                                  borderColor: "#0062cc",
                                  boxShadow: "none",
                                },
                              }}
                              className="px-2 px-lg-4 mr-2 mt-2"
                            >
                              View &nbsp;
                              <span className="d-mob-none">Details</span>
                            </Button>
                            <button
                              type="button"
                              className="btn btn-blue px-2 px-lg-4 mr-2 mt-2"
                            >
                              Enquire <span className="d-mob-none">Now</span>
                            </button>
                          </div>
                          {window.location.host ===
                          "commercial.shifteasy.com" ? (
                            <div className="d-flex flex-nowrap mt-3 mb-2 facilities d-mob-none">
                              <div className="d-flex flex-column mr-4">
                                <img src={bedIcon} alt="Icon" />
                                <div className="fs-13">
                                  {property.bedroom} BHK
                                </div>
                              </div>
                              <div className="d-flex flex-column mr-4">
                                <img src={bathIcon} alt="Icon" />
                                <div className="fs-13">
                                  {property.bathroom} BR
                                </div>
                              </div>
                              <div className="d-flex flex-column mr-4">
                                <img src={parkingIcon} alt="Icon" />
                                <div className="fs-13">
                                  {property.garage} Parking
                                </div>
                              </div>
                              <div className="d-flex flex-column mr-4">
                                <img src={stairIcon} alt="Icon" />
                                <div className="fs-13">
                                  {property.floorNo} Floor
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* {window.location.host === 'commercial.shifteasy.com'?
                                            <div className="d-flex flex-nowrap mt-3 mb-2 facilities d-web-none mx-2">
                                                <div className="d-flex flex-column mx-2">
                                                    <img src={bedIcon} alt="Icon" />
                                                    <div className="fs-13">{property.bedroom}BHK</div>
                                                </div>
                                                <div className="d-flex flex-column mx-2">
                                                    <img src={bathIcon} alt="Icon" />
                                                    <div className="fs-13">{property.bathroom}BR</div>
                                                </div>
                                                <div className="d-flex flex-column mx-2">
                                                    <img src={parkingIcon} alt="Icon" />
                                                    <div className="fs-13">{property.garage}Parking</div>
                                                </div>
                                                <div className="d-flex flex-column mx-2">
                                                    <img src={stairIcon} alt="Icon" />
                                                    <div className="fs-13">{property.floorNo} Floor</div>
                                                </div>
                                            </div>:null} */}
                      <div className="d-flex justify-content-between mt-2 px-2 d-mob-none">
                        <div className="fs-13">
                          <span className="text-black-50">Posted On : </span>
                          <span className="text-dark">
                            {" "}
                            {moment(property.createdTime).format("DD/MM/YYYY")}
                          </span>
                        </div>
                        <div className="fs-13">
                          <span className="text-black-50">Owner : </span>
                          <span className="text-dark">
                            {property.clientName}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <nav aria-label="page navigation">
                  <ul className="pagination justify-content-center">
                    {/* <li className="page-item nav-pg">
                          <a className="page-link" >
                            &#60;&nbsp;Previous
                          </a>
                        </li> */}
                    <PaginationComponet
                      limit={limit}
                      page={page}
                      listFeaturedProperties={this.listFeaturedProperties}
                      propertyCount={propertyCount}
                    />
                    {/* <li className="page-item">
                          <a className="page-link" >
                            1
                          </a>
                        </li>
                        <li className="page-item active" aria-current="page">
                          <a className="page-link" >
                            2 <span className="sr-only">(current)</span>
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" >
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" >
                            4
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" >
                            5
                          </a>
                        </li> */}
                    {/* <li className="page-item nav-pg">
                          <a className="page-link" >
                            Next&nbsp;&#62;
                          </a>
                        </li> */}
                  </ul>
                </nav>
              </div>

              <div className="search-map-sec d-mob-none d-web-sm-none">
                <div id="map">
                  <div id="map-container-google-11">
                    <iframe
                      className="w-100 br-10"
                      src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJB5gIVWFdUjoRKdj3Nxxq6-g&key=AIzaSyA1X9MSi_O-dUs4kUu_I1dY_lXHOeZbOk0"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container-fluid search-list-sec2">
            <div className="row px-30">
              <div className="col-md-12 px-0 mb-3">
                <div className="row">
                  <div className="col-7">
                    <h4 className="mt-2 mt-md-3 mb-0 text-dark-blue font-weight-bold">
                      Featured Properties
                    </h4>
                  </div>
                  <div className="col-5 text-right">
                    <div className="controls pull-right hidden-xs">
                      <a
                        className="ml-2"
                        href="#carousel-example"
                        data-slide="prev"
                      >
                        <img
                          src={leftarrow}
                          alt="Left arrow"
                          style={{ width: "40px" }}
                        />
                      </a>
                      <a
                        className="ml-2"
                        href="#carousel-example"
                        data-slide="next"
                      >
                        <img
                          src={rightarrow}
                          alt="Right arrow"
                          style={{ width: "40px" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 px-0 mb-5">
                <div
                  id="carousel-example"
                  className="carousel slide hidden-xs"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="item active">
                      <div className="row flex-nowrap overflow-auto">
                        <div className="col-sm-6 col-md-3 col-10">
                          <div className="col-item">
                            <div className="photo">
                              <img
                                src={featured1}
                                className="img-fluid"
                                alt="Featured Image"
                              />
                            </div>
                            <div className="property-info">
                              <p className="fs-13 mb-1">
                                3 BHK Villa for rent in Chennai
                              </p>
                              <p className="font-weight-bold fs-14 mb-0">
                                Rs. 3000
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-10">
                          <div className="col-item">
                            <div className="photo">
                              <img
                                src={featured2}
                                className="img-fluid"
                                alt="Featured Image"
                              />
                            </div>
                            <div className="property-info">
                              <p className="fs-13 mb-1">
                                3 BHK Villa for rent in Chennai
                              </p>
                              <p className="font-weight-bold fs-14 mb-0">
                                Rs. 3000
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-10">
                          <div className="col-item">
                            <div className="photo">
                              <img
                                src={featured3}
                                className="img-fluid"
                                alt="Featured Image"
                              />
                            </div>
                            <div className="property-info">
                              <p className="fs-13 mb-1">
                                3 BHK Villa for rent in Chennai
                              </p>
                              <p className="font-weight-bold fs-14 mb-0">
                                Rs. 3000
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-10">
                          <div className="col-item">
                            <div className="photo">
                              <img
                                src={featured4}
                                className="img-fluid"
                                alt="Featured Image"
                              />
                            </div>
                            <div className="property-info">
                              <p className="fs-13 mb-1">
                                3 BHK Villa for rent in Chennai
                              </p>
                              <p className="font-weight-bold fs-14 mb-0">
                                Rs. 3000
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-list-sec3 pb-4">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-10 offset-lg-1">
                  <div className="search-result-card">
                    <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
                      <h5 className="page-sub-title font-weight-bold">
                        Reviews
                      </h5>
                      <div className="dropdown ml-3">
                        <button
                          className="btn fs-14 shadow-searchpg bg-white dropdown-toggle min-wid-200px d-flex justify-content-between align-items-center sort-btn"
                          type="button"
                          id="srtbtn"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Sort by
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="srtbtn"
                        >
                          <span className="dropdown-item" href="">
                            Name (A-Z)
                          </span>
                          <span className="dropdown-item" href="">
                            Name (Z-A)
                          </span>
                          <span className="dropdown-item" href="">
                            Location (A-Z)
                          </span>
                          <span className="dropdown-item" href="">
                            Location (Z-A)
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mb-4 align-items-center">
                      <span className="rating-avg mr-2">4.5</span>
                      <span>
                        <span className="text-black-50 mr-2">
                          out of 5 based on
                        </span>
                        <span className="text-orange">(800 reviews)</span>
                      </span>
                    </div>
                    <div>
                      <div className="review-card">
                        <h6>Plumbing</h6>
                        <div className="mb-2 d-flex align-items-center">
                          <img src={verifiedUser} alt="Verified User" />
                          <span className="text-dark ml-1 mr-3">Abhishek</span>
                          <span className="d-flex align-items-center">
                            <img src={starFilled} className="mr-1" />
                            <img src={starFilled} className="mr-1" />
                            <img src={starFilled} className="mr-1" />
                            <img src={starFilled} className="mr-1" />
                            <img src={starNotFilled} className="mr-1" />
                          </span>
                        </div>
                        <p className="fs-13 mb-1">
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page when
                          looking at its layout. The point of using Lorem Ipsum
                          is that it has a more-or-less normal distribution of
                          letters, as opposed to using 'Content here, content
                          here', making it look like readable English. Many
                          desktop publishing packages and web page editors now
                          use Lorem Ipsum as their default model text, and a
                          search for 'lorem ipsum' will uncover many web sites
                          still in their infancy.
                        </p>
                      </div>
                      <div className="review-card">
                        <h6>Plumbing</h6>
                        <div className="mb-2 d-flex align-items-center">
                          <img src={verifiedUser} alt="Verified User" />
                          <span className="text-dark ml-1 mr-3">Abhishek</span>
                          <span className="d-flex align-items-center">
                            <img src={starFilled} className="mr-1" />
                            <img src={starFilled} className="mr-1" />
                            <img src={starFilled} className="mr-1" />
                            <img src={starFilled} className="mr-1" />
                            <img src={starNotFilled} className="mr-1" />
                          </span>
                        </div>
                        <p className="fs-13 mb-1">
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page when
                          looking at its layout. The point of using Lorem Ipsum
                          is that it has a more-or-less normal distribution of
                          letters, as opposed to using 'Content here, content
                          here', making it look like readable English. Many
                          desktop publishing packages and web page editors now
                          use Lorem Ipsum as their default model text, and a
                          search for 'lorem ipsum' will uncover many web sites
                          still in their infancy.
                        </p>
                      </div>
                      <div className="review-card">
                        <h6>Plumbing</h6>
                        <div className="mb-2 d-flex align-items-center">
                          <img src={verifiedUser} alt="Verified User" />
                          <span className="text-dark ml-1 mr-3">Abhishek</span>
                          <span className="d-flex align-items-center">
                            <img src={starFilled} className="mr-1" />
                            <img src={starFilled} className="mr-1" />
                            <img src={starFilled} className="mr-1" />
                            <img src={starFilled} className="mr-1" />
                            <img src={starNotFilled} className="mr-1" />
                          </span>
                        </div>
                        <p className="fs-13 mb-1">
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page when
                          looking at its layout. The point of using Lorem Ipsum
                          is that it has a more-or-less normal distribution of
                          letters, as opposed to using 'Content here, content
                          here', making it look like readable English. Many
                          desktop publishing packages and web page editors now
                          use Lorem Ipsum as their default model text, and a
                          search for 'lorem ipsum' will uncover many web sites
                          still in their infancy.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="search-result-card">
                    <h5 className="page-sub-title font-weight-bold">FAQ</h5>
                    <ol className="pl-3 pt-2 mb-0">
                      <li>
                        <h6>
                          What about the new materials or fixtures that may be
                          needed?{" "}
                        </h6>
                        <p className="text-dark fs-14">
                          It totally lies at the client’s discretion. You can
                          yourself get the material according to your choice or
                          ask the professional to procure the same. The time
                          taken by the professional to get the material will
                          also be included in the working hours.{" "}
                        </p>
                      </li>
                      <li>
                        <h6>
                          What if no services are availed, how much will I be
                          entitled to pay?{" "}
                        </h6>
                        <p className="text-dark fs-14">
                          If in case no services were availed, you just need to
                          pay the professional a visiting charge.
                        </p>
                      </li>
                      <li>
                        <h6>
                          Is there any guarantee on the service provided?{" "}
                        </h6>
                        <p className="text-dark fs-14">
                          Yes, a 30-day guarantee is provided on all plumbing
                          services.
                        </p>
                      </li>
                    </ol>
                  </div>
                  <div className="search-result-card">
                    <h5 className="page-sub-title font-weight-bold mb-3">
                      About Tradesmen Plumbers
                    </h5>
                    <h6>Plumbing Services</h6>
                    <p className="text-dark fs-14">
                      A well-functioning household is essential for
                      effectiveness in life. Kitchens and bathrooms are an
                      essential fragment of a house. Any kind of obstruction,
                      such as a clogged pipe or running toilet, can bring your
                      entire schedule to a standstill, while at the same time
                      disrupting your normalcy of everyday life. Given that
                      materials such as pipes and cisterns, which are used in
                      building your living space, come with an expiry date, you
                      need to keep them updated and in a working condition. Some
                      materials or machines require constant maintenance while
                      others require replacements and fixes. It could be a
                      leaking tap that you might be ignoring or a condition that
                      needs to be dealt with immediately, such as a blockage in
                      the toilet trap.
                    </p>
                    <h6>
                      You can also go through the same Plumbing Services in
                      Chennai in other cities as well:
                    </h6>
                    <ul className="pl-0 mb-0 list-unstyled text-dark fs-14">
                      <li>Best Plumbing services in Delhi</li>
                      <li>Best Plumbing services in Mumbai</li>
                      <li>Best Plumbing services in Kolkata</li>
                      <li>Best Plumbing services in Pune</li>
                      <li>Best Plumbing services in Delhi</li>
                      <li>Best Plumbing services in Hydrabad</li>
                      <li>Best Plumbing services in Delhi</li>
                      <li>Best Plumbing services in Mumbai</li>
                      <li>Best Plumbing services in Kolkata</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <Footer />
      </div>
    );
  }
}

export default searchList;
