// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/services-page/1-tenant/header-banner-serv-1.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serv-one {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.mem-plans li {
    line-height: 1.6;
    padding-bottom: 6px;
}

.refer-sec {
    background-color: #EEEEEE;
}

.refer-sec li {
    padding-left: 0;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/shifteasy-services/tenant-referencing/tenant-referencing.css"],"names":[],"mappings":"AAAA;IACI,yDAAgG;AACpG;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".serv-one {\n    background-image: url(../../../../assets/images/services-page/1-tenant/header-banner-serv-1.jpg);\n}\n\n.mem-plans li {\n    line-height: 1.6;\n    padding-bottom: 6px;\n}\n\n.refer-sec {\n    background-color: #EEEEEE;\n}\n\n.refer-sec li {\n    padding-left: 0;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
