export const Types = {
    SHOW_REGISTER:'SHOW_REGISTER',
    HIDE_REGISTER:'HIDE_REGISTER',
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
    REGISTER_SUCCESS_REST:'REGISTER_SUCCESS_REST',
    SHOW_LOGIN:'SHOW_LOGIN',
    HIDE_LOGIN:'HIDE_LOGIN',
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGIN_SUCCESS_REST:'LOGIN_SUCCESS_REST',
    LOGIN_FAILURE_REST: 'USERS_LOGIN_FAILURE_REST',
    USERS_LOGOUT: 'USERS_LOGOUT',
    GET_REPOT:'GET_REPOT',
    UUID_SUCCESS:'UUID_SUCCESS',
    UUID_FAILURE:'UUID_FAILURE',
    GET_REPORT_FAILURE:'GET_REPORT_FAILURE',
    GET_REPORT_SUCCESS:'GET_REPORT_SUCCESS',
    GET_CHART_DATA:'GET_CHART_DATA',
    CHART_DATA_FAILURE:'CHART_DATA_FAILURE',
    GET_PROPERTY_LIST_SUCCESS:'GET_PROPERTY_LIST_SUCCESS',
    GET_PROPERTY_LIST_FAILURE:'GET_PROPERTY_LIST_FAILURE',
    GET_USER_DETAILS:'GET_USER_DETAILS',
    GET_USER_DETAILS_ERROR:'GET_USER_DETAILS_ERROR',
    GET_USER_LOCATION:'GET_USER_LOCATION',
    GET_USER_LOCATION_ERROR:'GET_USER_LOCATION_ERROR',
    ADD_PROPERTY_SUCCESS:'ADD_PROPERTY_SUCCESS',
    ADD_PROPERTY_FAILURE:'ADD_PROPERTY_FAILURE',
    PROPERTY_TYPE:'PROPERTY_TYPE',
    GET_TOKEN:'GET_TOKEN',
    GET_REFRESH_TOKEN:'GET_REFRESH_TOKEN',
    REMOVE_PROPERTY_ID:'REMOVE_PROPERTY_ID',
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGOUT: 'USER_LOGOUT',
    SET_MESSAGE: 'SET_MESSAGE',
  }