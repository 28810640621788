// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/services-page/9-legal-services/header-banner-serv-9.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../assets/images/services-page/9-legal-services/header-banner-serv-9-detail.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serv-nine {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: bottom;
}

.serv-nine-detailed {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

#bookAppointment .close {
    color: #E1601F;
    font-size: 30px;
    opacity: .8;
}`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/shifteasy-services/legal-services-near-you/legal-services-near-you.css"],"names":[],"mappings":"AAAA;IACI,yDAAwG;IACxG,2BAA2B;AAC/B;;AAEA;IACI,yDAA+G;AACnH;;AAEA;IACI,cAAc;IACd,eAAe;IACf,WAAW;AACf","sourcesContent":[".serv-nine {\n    background-image: url(../../../../assets/images/services-page/9-legal-services/header-banner-serv-9.png);\n    background-position: bottom;\n}\n\n.serv-nine-detailed {\n    background-image: url(../../../../assets/images/services-page/9-legal-services/header-banner-serv-9-detail.png);\n}\n\n#bookAppointment .close {\n    color: #E1601F;\n    font-size: 30px;\n    opacity: .8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
