import React, { Component } from 'react';
import './doctors-near-you.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import search2 from '../../../../assets/images/search-w.svg';
import doctorInPhone from '../../../../assets/images/services-page/4-doctors-near-you/doctor-in.png';
import doctorOnline from '../../../../assets/images/services-page/4-doctors-near-you/doctor-online.png';
import findDoctor from '../../../../assets/images/services-page/4-doctors-near-you/find-doctor.png';
import appStore from '../../../../assets/images/services-page/4-doctors-near-you/apple-store.png';
import playStore from '../../../../assets/images/services-page/4-doctors-near-you/google-store.png';
import { scrollTop } from '../../common/common';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ItemsCarousel from 'react-items-carousel';
import leftarrow from '../../../../assets/images/leftarrow.png';
import rightarrow from '../../../../assets/images/rightarrow.png';
import dentistIcon from '../../../../assets/images/services-page/4-doctors-near-you/popular_doctor_icons/dentist.svg';
import physicianIcon from '../../../../assets/images/services-page/4-doctors-near-you/popular_doctor_icons/general_physician.svg';
import pediaricianIcon from '../../../../assets/images/services-page/4-doctors-near-you/popular_doctor_icons/pediatrician.svg';
import ayurvedaIcon from '../../../../assets/images/services-page/4-doctors-near-you/popular_doctor_icons/ayurveda.svg';
import orthopedistIcon from '../../../../assets/images/services-page/4-doctors-near-you/popular_doctor_icons/orthopedist.svg';
import dermatolofistIcon from '../../../../assets/images/services-page/4-doctors-near-you/popular_doctor_icons/dermatologist.svg';
import gynecologistIcon from '../../../../assets/images/services-page/4-doctors-near-you/popular_doctor_icons/gynecologist.svg';
import starNotFilled from '../../../../assets/images/svg-icons/star-notfilled.svg';
import starFilled from '../../../../assets/images/svg-icons/star-filled.svg';
import phone from '../../../../assets/images/services-page/4-doctors-near-you/phone.svg';
import tags from '../../../../assets/images/services-page/4-doctors-near-you/tags.svg';
import experiance from '../../../../assets/images/services-page/4-doctors-near-you/experiance.svg';
import locationSmall from '../../../../assets/images/svg-icons/location-small.svg';
import card1 from '../../../../assets/images/services-page/4-doctors-near-you/card1.png';
import card2 from '../../../../assets/images/services-page/4-doctors-near-you/card2.png';
import card3 from '../../../../assets/images/services-page/4-doctors-near-you/card3.png';
import card4 from '../../../../assets/images/services-page/4-doctors-near-you/card4.png';
import card5 from '../../../../assets/images/services-page/4-doctors-near-you/card5.png';
import card6 from '../../../../assets/images/services-page/4-doctors-near-you/card6.png';
import card7 from '../../../../assets/images/services-page/4-doctors-near-you/card7.png';
import card8 from '../../../../assets/images/services-page/4-doctors-near-you/card8.png';
import Rec1 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-1.png';
import Rec2 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-2.png';
import Rec3 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-3.png';
import Rec4 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-4.png';
import Rec5 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-5.png';
import { Helmet } from "react-helmet";
class DoctorsNearYou extends Component {

    constructor(props) {
        super(props)
        this.state = {
            popularServicesItemIndex: 0,
            topDoctorsItemIndex: 0,
            bookAppointItemIndex: 0,
            width: 0,
            height: 0
        }
        console.log("this.props", this.props)

        // window.addEventListener("resize", this.diviceSizeUpdate);
    }
    popularServicesActiveItem = (popularServicesItemIndex) => {
        this.setState({ popularServicesItemIndex });
    }
    topDoctorsActiveItem = (topDoctorsItemIndex) => {
        this.setState({ topDoctorsItemIndex });
    }
    bookAppointActiveItem = (bookAppointItemIndex) => {
        this.setState({ bookAppointItemIndex });
    }

    componentDidMount() {
        scrollTop();
    }
    diviceSizeUpdate = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    navToListPage = () => {
        this.props.history.push("/doctors-near-you-search-list")
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Doctors Near You | Consult with Best Doctors in India | ShiftEasy</title>
                    <meta name="description" content="ShiftEasy helps you to find the India's Best Doctors Near You. Just Visit the ShiftEasy and Find India's Top Rated & Trusted Doctors." />
                    <meta name="keywords" content="Doctors Near Me, Best Doctors Near You, Doctors In India" />
                </Helmet>
                <Header />
                <div className="page-top-banner service-banner mb-3 mb-lg-5 serv-four">
                    <h1 className="text-center text-white mb-3">Doctor <span className="text-orange">Services </span></h1>
                    <h5 className="text-center text-white mb-3">We are fast, we are digital, and will be proactive in progressing your property transaction</h5>
                    <h4 className="text-center text-white mb-3">Shifteasy <span className="text-orange">Platform </span>| Shifteasy <span className="text-orange">Partner App </span></h4>
                    <div className="row justify-content-center mb-0 mb-lg-4">
                        <div className="btn-group col-md-6" role="group" aria-label="Button group with nested dropdown">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control br-0 border-0 bg-white" placeholder="Search Doctor by Street/location or Type" />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-orange br-0" onClick={this.navToListPage}><img src={search2} alt="Search" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid px-4 px-lg-5">
                    <div className="px-4 corner-nav mb-5">
                        <h5 className="text-center page-title mb-4">Popular Services</h5>
                        <ItemsCarousel
                            requestToChangeActive={this.popularServicesActiveItem}
                            activeItemIndex={this.state.popularServicesItemIndex}
                            numberOfCards={window.innerWidth > 550 ? 7 : 2}
                            gutter={20}
                            leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                            rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                            outsideChevron
                            chevronWidth={40} >
                            <div className="popular-services-item">
                                <img src={dentistIcon} alt="Dentist Icon" height="50px" />
                                <span className="mt-2 text-center">Dentist</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={physicianIcon} alt="General Physician Icon" height="50px" />
                                <span className="mt-2 text-center">General Physician</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={pediaricianIcon} alt="Pediatrician Icon" height="50px" />
                                <span className="mt-2 text-center">Pediatrician</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={ayurvedaIcon} alt="Ayurveda Icon" height="50px" />
                                <span className="mt-2 text-center">Ayurveda</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={orthopedistIcon} alt="Orthopedist Icon" height="50px" />
                                <span className="mt-2 text-center">Orthopedist</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={dermatolofistIcon} alt="Dental Surgeon Icon" height="50px" />
                                <span className="mt-2 text-center">Dental Surgeon</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={gynecologistIcon} alt="Immigration Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Immigration Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={dentistIcon} alt="Dentist Icon" height="50px" />
                                <span className="mt-2 text-center">Dentist</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={physicianIcon} alt="General Physician Icon" height="50px" />
                                <span className="mt-2 text-center">General Physician</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={pediaricianIcon} alt="Pediatrician Icon" height="50px" />
                                <span className="mt-2 text-center">Pediatrician</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={ayurvedaIcon} alt="Ayurveda Icon" height="50px" />
                                <span className="mt-2 text-center">Ayurveda</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={orthopedistIcon} alt="Orthopedist Icon" height="50px" />
                                <span className="mt-2 text-center">Orthopedist</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={dermatolofistIcon} alt="Dental Surgeon Icon" height="50px" />
                                <span className="mt-2 text-center">Dental Surgeon</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={gynecologistIcon} alt="Immigration Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Immigration Lawyers</span>
                            </div>
                        </ItemsCarousel>
                        <button className="btn btn-outline-orange mt-3 mb-5 w-auto mx-auto d-block">View All</button>
                    </div>
                </div>
                <div className="container-fluid px-4 px-lg-5">
                    <div className="px-4 corner-nav mb-5">
                        <h5 className="text-center page-title mb-4">Top Doctors in India</h5>
                        <ItemsCarousel
                            requestToChangeActive={this.topDoctorsActiveItem}
                            activeItemIndex={this.state.topDoctorsItemIndex}
                            numberOfCards={window.innerWidth > 550 ? 4 : 1}
                            gutter={20}
                            leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                            rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                            outsideChevron
                            chevronWidth={40} >
                            <div className="cus-card-item">
                                <img src={Rec1} alt="Card " className="img-fluid cus-card-image" />
                                <div className="d-flex flex-column p-2">
                                    <div className="mob-lh-12 my-1">
                                        <h6 className="text-dark d-inline fs-15 font-weight-bold">Dipanshu Clinic</h6>
                                    </div>
                                    <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                        <span className="fs-14 mr-2">4.5</span>
                                        <span className="add-rating">
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starNotFilled} className="mr-1" alt="icon" />
                                        </span>&nbsp;&nbsp;
                                        <span className="fs-14">(100+ Users)</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={phone} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">+91 994052370, +91 994052370</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={tags} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">Child Specialist</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={experiance} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">10 year Experiance</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={locationSmall} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                    </div>
                                    <a href="/doctors-near-you-detailed" type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                </div>
                            </div>
                            <div className="cus-card-item">
                                <img src={Rec2} alt="Card image" className="img-fluid cus-card-image" />
                                <div className="d-flex flex-column p-2">
                                    <div className="mob-lh-12 my-1">
                                        <h6 className="text-dark d-inline fs-15 font-weight-bold">Christopher's Fertility Clinic</h6>
                                    </div>
                                    <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                        <span className="fs-14 mr-2">4.5</span>
                                        <span className="add-rating">
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starNotFilled} className="mr-1" alt="icon" />
                                        </span>&nbsp;&nbsp;
                                        <span className="fs-14">(100+ Users)</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={phone} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">+91 994052370, +91 994052370</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={tags} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">Gynecologist</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={experiance} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">10 year Experiance</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={locationSmall} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                    </div>
                                    <a href="/doctors-near-you-detailed" type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                </div>
                            </div>
                            <div className="cus-card-item">
                                <img src={Rec3} alt="Card image" className="img-fluid cus-card-image" alt="icon" />
                                <div className="d-flex flex-column p-2">
                                    <div className="mob-lh-12 my-1">
                                        <h6 className="text-dark d-inline fs-15 font-weight-bold">Children's Clinic</h6>
                                    </div>
                                    <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                        <span className="fs-14 mr-2">4.5</span>
                                        <span className="add-rating">
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starNotFilled} className="mr-1" alt="icon" />
                                        </span>&nbsp;&nbsp;
                                        <span className="fs-14">(100+ Users)</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={phone} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">+91 994052370, +91 994052370</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={tags} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">Child Specialist</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={experiance} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">10 year Experiance</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={locationSmall} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                    </div>
                                    <a href="/doctors-near-you-detailed" type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                </div>
                            </div>
                            <div className="cus-card-item">
                                <img src={Rec4} alt="Card image" className="img-fluid cus-card-image" alt="icon" />
                                <div className="d-flex flex-column p-2">
                                    <div className="mob-lh-12 my-1">
                                        <h6 className="text-dark d-inline fs-15 font-weight-bold">Gynaecologist Clinic</h6>
                                    </div>
                                    <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                        <span className="fs-14 mr-2">4.5</span>
                                        <span className="add-rating">
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starNotFilled} className="mr-1" alt="icon" />
                                        </span>&nbsp;&nbsp;
                                        <span className="fs-14">(100+ Users)</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={phone} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">+91 994052370, +91 994052370</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={tags} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">Gynaecologist</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={experiance} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">10 year Experiance</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={locationSmall} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                    </div>
                                    <a href="/doctors-near-you-detailed" type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                </div>
                            </div>
                            <div className="cus-card-item">
                                <img src={Rec5} alt="Card image" className="img-fluid cus-card-image" />
                                <div className="d-flex flex-column p-2">
                                    <div className="mob-lh-12 my-1">
                                        <h6 className="text-dark d-inline fs-15 font-weight-bold">Dipanshu Clinic</h6>
                                    </div>
                                    <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                        <span className="fs-14 mr-2">4.5</span>
                                        <span className="add-rating">
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starFilled} className="mr-1" alt="icon" />
                                            <img src={starNotFilled} className="mr-1" alt="icon" />
                                        </span>&nbsp;&nbsp;
                                        <span className="fs-14">(100+ Users)</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={phone} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">+91 994052370, +91 994052370</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={tags} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">Child Specialist</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={experiance} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">10 year Experiance</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={locationSmall} className="mr-1" alt="icon" />&nbsp;
                                        <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                    </div>
                                    <a href="/doctors-near-you-detailed" type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                </div>
                            </div>
                        </ItemsCarousel>
                        <button className="btn btn-outline-orange mt-3 mb-5 w-auto mx-auto d-block">View All</button>
                    </div>
                </div>
                <div className="container-fluid px-4 px-lg-5">
                    <div className="px-4 corner-nav mb-5">
                        <h5 className="text-center page-title mb-3">Book an appointment for an in-clinic consultation </h5>
                        <h5 className="text-center page-sub-title mb-3">Find experienced doctors across all specialties</h5>
                        <ItemsCarousel
                            requestToChangeActive={this.bookAppointActiveItem}
                            activeItemIndex={this.state.bookAppointItemIndex}
                            numberOfCards={window.innerWidth > 550 ? 4 : 1}
                            gutter={20}
                            leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                            rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                            outsideChevron
                            chevronWidth={40} >
                            <div className="col-item">
                                <div className="photo">
                                    <img src={card1} className="img-fluid" alt="Featured Image" alt="icon" />
                                </div>
                                <div className="property-info">
                                    <p className="font-weight-bold fs-14 mb-1">Sugar</p>
                                    <p className="fs-13 mb-0">Teething troubles? Schedule a dental checkup</p>
                                </div>
                            </div>
                            <div className="col-item">
                                <div className="photo">
                                    <img src={card2} className="img-fluid" alt="Featured Image" alt="icon" />
                                </div>
                                <div className="property-info">
                                    <p className="font-weight-bold fs-14 mb-1">Cardiologist</p>
                                    <p className="fs-13 mb-0">Explore for women’s health, pregnancy and infertility treatments</p>
                                </div>
                            </div>
                            <div className="col-item">
                                <div className="photo">
                                    <img src={card5} className="img-fluid" alt="Featured Image" />
                                </div>
                                <div className="property-info">
                                    <p className="font-weight-bold fs-14 mb-1">BP</p>
                                    <p className="fs-13 mb-0">Get guidance on eating right, weight management and sports nutrition</p>
                                </div>
                            </div>
                            <div className="col-item">
                                <div className="photo">
                                    <img src={card6} className="img-fluid" alt="Featured Image" />
                                </div>
                                <div className="property-info">
                                    <p className="font-weight-bold fs-14 mb-1">Pediatrician</p>
                                    <p className="fs-13 mb-0">Child Specialists and Doctors for Infant</p>
                                </div>
                            </div>
                            <div className="col-item">
                                <div className="photo">
                                    <img src={card3} className="img-fluid" alt="Featured Image" />
                                </div>
                                <div className="property-info">
                                    <p className="font-weight-bold fs-14 mb-1">Dentist</p>
                                    <p className="fs-13 mb-0">Teething troubles? Schedule a dental checkup</p>
                                </div>
                            </div>
                            <div className="col-item">
                                <div className="photo">
                                    <img src={card4} className="img-fluid" alt="Featured Image" />
                                </div>
                                <div className="property-info">
                                    <p className="font-weight-bold fs-14 mb-1">Gynecologist/Obstetrician</p>
                                    <p className="fs-13 mb-0">Teething troubles? Schedule a dental checkup</p>
                                </div>
                            </div>
                            <div className="col-item">
                                <div className="photo">
                                    <img src={card7} className="img-fluid" alt="Featured Image" />
                                </div>
                                <div className="property-info">
                                    <p className="font-weight-bold fs-14 mb-1">Dietitian/Nutrition</p>
                                    <p className="fs-13 mb-0">Teething troubles? Schedule a dental checkup</p>
                                </div>
                            </div>
                            <div className="col-item">
                                <div className="photo">
                                    <img src={card8} className="img-fluid" alt="Featured Image" />
                                </div>
                                <div className="property-info">
                                    <p className="font-weight-bold fs-14 mb-1">Gastroenterologist</p>
                                    <p className="fs-13 mb-0">Teething troubles? Schedule a dental checkup</p>
                                </div>
                            </div>
                        </ItemsCarousel>
                        <button className="btn btn-outline-orange mt-4 mb-5 w-auto mx-auto d-block">View All</button>
                    </div>
                </div>
                <div className="container">
                    <h5 className="text-center page-title mb-4">Find Doctor's by</h5>
                    <ul className="nav nav-pills serv-pg-tab" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="pills-city-tab" data-toggle="pill" href="#pills-city" role="tab" aria-controls="pills-city" aria-selected="true">City</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="pills-category-tab" data-toggle="pill" href="#pills-category" role="tab" aria-controls="pills-category" aria-selected="false">Category</a>
                        </li>
                    </ul>
                    <div className="tab-content serv-pg-tab-content p-3 py-md-5 mb-4" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-city" role="tabpanel" aria-labelledby="pills-city-tab">
                            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="btn btn-outline-default bg-white btn-city active">
                                    <input type="radio" name="options" id="optionA1" /> Chennai</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA2" /> Bengaluru</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA3" /> Jaipur</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA4" /> Kolkata</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA1" /> Chennai</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA2" /> Bengaluru</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA3" /> Jaipur</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA4" /> Kolkata</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA1" /> Chennai</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA2" /> Bengaluru</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA3" /> Jaipur</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA4" /> Kolkata</label>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-category" role="tabpanel" aria-labelledby="pills-category-tab">...</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-5 offset-md-1 col-lg-4 offset-lg-2 px-3 mb-3">
                            <div className="h-100 p-3 bg-dark-blue br-5">
                                <img src={doctorOnline} alt="Doctor Online Image" className="img-fluid" />
                                <h6 className="my-2 text-white">Doctors online now</h6>
                                <p className="my-2 fs-14 text-white-50">Tell about your health concern and we will assign you doctor in 60sec</p>
                                <button className="btn btn-orange btn-block">Start Consulting</button>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-4 px-3 mb-3">
                            <div className="h-100 p-3 bg-dark-blue br-5">
                                <img src={findDoctor} alt="Doctor Online Image" className="img-fluid" />
                                <h6 className="my-2 text-white">Doctors online now</h6>
                                <p className="my-2 fs-14 text-white-50">Tell about your health concern and we will assign you doctor in 60sec</p>
                                <button className="btn btn-orange btn-block">Start Consulting</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid docpg-appDownload">
                    <div className="docpg-appDownload-bg"></div>
                    <div className="container my-5">
                        <div className="row my-4 align-items-center">
                            <div className="col-12 col-lg-3 col-md-3 offset-lg-2 offset-md-1 d-mob-none">
                                <img src={doctorInPhone} alt="Doctor" className="img-fluid docpg-inphone-img" />
                            </div>
                            <div className="col-12 col-lg-5 col-md-7 offset-lg-1 offset-md-1 py-5 text-white">
                                <h5 className="mb-3">Download the Shifteasy App</h5>
                                <p className="fs-14">Access video consultation with India’s top doctors on the Shifteasy app. Connect with doctors online, available 24/7, from the comfort of your home.</p>
                                <div>
                                    <img src={playStore} className="img-fluid mr-2 mb-2" alt="Google Play Store" />
                                    <img src={appStore} className="img-fluid mr-2 mb-2" alt="App Store" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-light">
                    <h5 className="page-title text-center pt-4 pb-3">Related To Your Search</h5>
                    <div className="container">
                        <div className="row mx-3 pb-4">
                            <div className="col-md-4">
                                <a className="mb-2 docpg-search-links">Doctor in Chennai</a>
                                <a className="mb-2 docpg-search-links">Doctor in Jaipur</a>
                                <a className="mb-2 docpg-search-links">Doctor in Bhopal</a>
                                <a className="mb-2 docpg-search-links">Doctor in Pune</a>
                            </div>
                            <div className="col-md-4">
                                <a className="mb-2 docpg-search-links">Doctor in Chennai</a>
                                <a className="mb-2 docpg-search-links">Doctor in Jaipur</a>
                                <a className="mb-2 docpg-search-links">Doctor in Bhopal</a>
                                <a className="mb-2 docpg-search-links">Doctor in Pune</a>
                            </div>
                            <div className="col-md-4">
                                <a className="mb-2 docpg-search-links">Doctor in Chennai</a>
                                <a className="mb-2 docpg-search-links">Doctor in Jaipur</a>
                                <a className="mb-2 docpg-search-links">Doctor in Bhopal</a>
                                <a className="mb-2 docpg-search-links">Doctor in Pune</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DoctorsNearYou));