import React, { Component } from 'react';
import './doctors-near-you.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import search2 from '../../../../assets/images/search-w.svg';
import appStore from '../../../../assets/images/services-page/4-doctors-near-you/apple-store.png';
import playStore from '../../../../assets/images/services-page/4-doctors-near-you/google-store.png';
import {scrollTop} from '../../common/common';
import heartNotFilled from '../../../../assets/images/svg-icons/heart-notfilled.svg';
import heartFilled from '../../../../assets/images/svg-icons/heart-filled.svg';
import filterIcon from '../../../../assets/images/svg-icons/filter.svg';
import searchOrange from '../../../../assets/images/svg-icons/search-orange.svg';
import Rec1 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-1.png';
import Rec2 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-2.png';
import Rec3 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-3.png';
import Rec4 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-4.png';
import Rec5 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-5.png';
import starNotFilled from '../../../../assets/images/svg-icons/star-notfilled.svg';
import starFilled from '../../../../assets/images/svg-icons/star-filled.svg';
import phone from '../../../../assets/images/services-page/4-doctors-near-you/phone.svg';
import tags from '../../../../assets/images/services-page/4-doctors-near-you/tags.svg';
import experiance from '../../../../assets/images/services-page/4-doctors-near-you/experiance.svg';
import locationSmall from '../../../../assets/images/svg-icons/location-small.svg';
import ItemsCarousel from 'react-items-carousel';
import leftarrow from '../../../../assets/images/leftarrow.png';
import rightarrow from '../../../../assets/images/rightarrow.png';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
class DoctorsNearYouSearchList extends Component {

    constructor (props) {
        super (props) 
        this.state= {
          topDoctorsItemIndex: 0,
          width: 0,
          height:0
        }
        window.addEventListener("resize", this.diviceSizeUpdate);
    }
    topDoctorsActiveItem = (topDoctorsItemIndex) => {
        this.setState({ topDoctorsItemIndex });
    }
    diviceSizeUpdate = () => {
        this.setState({
          height: window.innerHeight,
          width: window.innerWidth
        });
      };
   componentDidMount(){
    scrollTop();
   }
    render() {
        return (
            <div className="search-list-pg">
                <Header/>
                <div className="header-space">
                    <div className="container-fluid px-30 search-pg flex-wrap flex-lg-nowrap d-mob-none">
                        <nav aria-label="breadcrumb" className="w-100">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Chennai</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Child Specialist In Chennai</li>
                            </ol>
                        </nav>
                        <div className="d-flex mt-lg-3 mb-3 w-100">
                            <input type="text" className="form-control border-0 shadow-sm bg-dark-light fs-13 mr-2" placeholder="Child Specialist/Doctor" />
                            <button className="btn btn-orange px-4 shadow-sm">Search</button>
                        </div>
                        <div></div>
                    </div>
                    <div className="page-top-banner service-banner serv-four-search-list">
                        <h1 className="text-center text-white text-shadow mb-3"><span className="text-orange">Child Specialist in chennai</span></h1>
                    </div>
                    <div className="container-fluid px-0 search-list-sec1">
                        <div className="d-flex py-3 py-md-4 px-30">
                            <div className="search-filter-sec d-mob-none d-web-sm-none">
                                <div className="search-filter-sec-header">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <h5 className="text-white mb-0">Filter</h5>
                                        <a type="button" className="fs-14 text-white text-decoration-none">Reset all</a>
                                    </div>
                                </div>
                                <div className="bg-white p-3 search-filter-sec-body">
                                    <form>
                                        <select className="form-control mb-3">
                                            <option disabled>Select</option>
                                            <option selected>Chennai</option>
                                            <option>Hyderabad</option>
                                        </select>
                                        <select className="form-control mb-3">
                                            <option selected disabled>Select Area</option>
                                            <option>Area 1</option>
                                            <option>Area 2</option>
                                            <option>Area 3</option>
                                            <option>Area 4</option>
                                        </select>
                                        <select className="form-control mb-3">
                                            <option selected disabled>Select Category</option>
                                            <option>Category 1</option>
                                            <option>Category 2</option>
                                            <option>Category 3</option>
                                            <option>Category 4</option>
                                        </select>
                                        <select className="form-control mb-3">
                                            <option selected disabled>Select Experience</option>
                                            <option>Less than 1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                        <select className="form-control mb-3">
                                            <option selected disabled>Select Language</option>
                                            <option>English</option>
                                            <option>Tamil</option>
                                            <option>Hindi</option>
                                            <option>Telugu</option>
                                        </select>
                                        <select className="form-control">
                                            <option selected disabled>Select Gender</option>
                                            <option>Male</option>
                                            <option>Female</option>
                                        </select>
                                    </form>
                                </div>
                            </div>

                            <div className="search-cards-list-sec">
                                <div className="d-web-none br-5 shadow-searchpg">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control br-5 border-0 bg-white h-auto" placeholder="Anna Nagar"/>
                                        <div className="input-group-append">
                                            <button type="button" className="btn br-5 bg-white"><img src={searchOrange} alt="Search"/></button>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="page-sub-title d-web-none text-center">Showing "200 Search Results"</h4>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="page-sub-title d-mob-none">Showing "200 Search Results"</h4>
                                    <button className="btn bg-white shadow-searchpg min-wid-200px d-flex justify-content-between align-items-center mr-3 d-web-none">
                                        <span>Filter</span>
                                        <img src={filterIcon} alt="filter" className="ml-3"/>
                                    </button>
                                    <div className="dropdown">
                                        <button className="btn fs-14 shadow-searchpg bg-white dropdown-toggle min-wid-200px d-flex justify-content-between align-items-center sort-btn" type="button" id="srtbtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sort by&nbsp;</button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="srtbtn">
                                            <span className="dropdown-item" href="">Name (A-Z)</span>
                                            <span className="dropdown-item" href="">Name (Z-A)</span>
                                            <span className="dropdown-item" href="">Location (A-Z)</span>
                                            <span className="dropdown-item" href="">Location (Z-A)</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="search-result-card py-3">
                                        <div className="fav-icon">
                                            <img src={heartFilled} />
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="result-card-left-sec col-6 col-lg-5 px-3">
                                                <img src={Rec1} alt="card image" className="img-fluid" />
                                                <div className="d-block d-sm-none mt-2">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                            <div className="result-card-right-sec col-6 px-2 pt-1 pt-lg-0">
                                                <div className="mob-lh-12 my-1">
                                                    <h6 className="text-dark d-inline fs-15 font-weight-bold">Dipanshu Clinic</h6>
                                                </div>
                                                <div className="mb-1 mob-lh-12 d-flex align-items-center flex-wrap flex-lg-nowrap">
                                                    <span className="fs-14 mr-2">4.5</span>
                                                    <span className="add-rating">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>&nbsp;&nbsp;
                                                    <span className="fs-14">(100+ Users)</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={phone} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">+91 994052370, +91 994052370</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={tags} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Child Specialist</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={experiance} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">10 year Experiance</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={locationSmall} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <a href="/doctors-near-you-detailed"type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></a>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="search-result-card py-3">
                                        <div className="fav-icon">
                                            <img src={heartFilled} />
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="result-card-left-sec col-6 col-lg-5 px-3">
                                                <img src={Rec2} alt="card image" className="img-fluid" />
                                                <div className="d-block d-sm-none mt-2">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                            <div className="result-card-right-sec col-6 px-2 pt-1 pt-lg-0">
                                                <div className="mob-lh-12 my-1">
                                                    <h6 className="text-dark d-inline fs-15 font-weight-bold">Dipanshu Clinic</h6>
                                                </div>
                                                <div className="mb-1 mob-lh-12 d-flex align-items-center flex-wrap flex-lg-nowrap">
                                                    <span className="fs-14 mr-2">4.5</span>
                                                    <span className="add-rating">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>&nbsp;&nbsp;
                                                    <span className="fs-14">(100+ Users)</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={phone} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">+91 994052370, +91 994052370</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={tags} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Child Specialist</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={experiance} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">10 year Experiance</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={locationSmall} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="search-result-card py-3">
                                        <div className="fav-icon">
                                            <img src={heartFilled} />
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="result-card-left-sec col-6 col-lg-5 px-3">
                                                <img src={Rec3} alt="card image" className="img-fluid" />
                                                <div className="d-block d-sm-none mt-2">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                            <div className="result-card-right-sec col-6 px-2 pt-1 pt-lg-0">
                                                <div className="mob-lh-12 my-1">
                                                    <h6 className="text-dark d-inline fs-15 font-weight-bold">Dipanshu Clinic</h6>
                                                </div>
                                                <div className="mb-1 mob-lh-12 d-flex align-items-center flex-wrap flex-lg-nowrap">
                                                    <span className="fs-14 mr-2">4.5</span>
                                                    <span className="add-rating">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>&nbsp;&nbsp;
                                                    <span className="fs-14">(100+ Users)</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={phone} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">+91 994052370, +91 994052370</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={tags} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Child Specialist</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={experiance} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">10 year Experiance</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={locationSmall} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="search-result-card py-3">
                                        <div className="fav-icon">
                                            <img src={heartFilled} />
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="result-card-left-sec col-6 col-lg-5 px-3">
                                                <img src={Rec4} alt="card image" className="img-fluid" />
                                                <div className="d-block d-sm-none mt-2">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                            <div className="result-card-right-sec col-6 px-2 pt-1 pt-lg-0">
                                                <div className="mob-lh-12 my-1">
                                                    <h6 className="text-dark d-inline fs-15 font-weight-bold">Dipanshu Clinic</h6>
                                                </div>
                                                <div className="mb-1 mob-lh-12 d-flex align-items-center flex-wrap flex-lg-nowrap">
                                                    <span className="fs-14 mr-2">4.5</span>
                                                    <span className="add-rating">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>&nbsp;&nbsp;
                                                    <span className="fs-14">(100+ Users)</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={phone} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">+91 994052370, +91 994052370</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={tags} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Child Specialist</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={experiance} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">10 year Experiance</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={locationSmall} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="search-result-card py-3">
                                        <div className="fav-icon">
                                            <img src={heartFilled} />
                                        </div>
                                        <div className="row mx-auto">
                                            <div className="result-card-left-sec col-6 col-lg-5 px-3">
                                                <img src={Rec5} alt="card image" className="img-fluid" />
                                                <div className="d-block d-sm-none mt-2">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                            <div className="result-card-right-sec col-6 px-2 pt-1 pt-lg-0">
                                                <div className="mob-lh-12 my-1">
                                                    <h6 className="text-dark d-inline fs-15 font-weight-bold">Dipanshu Clinic</h6>
                                                </div>
                                                <div className="mb-1 mob-lh-12 d-flex align-items-center flex-wrap flex-lg-nowrap">
                                                    <span className="fs-14 mr-2">4.5</span>
                                                    <span className="add-rating">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>&nbsp;&nbsp;
                                                    <span className="fs-14">(100+ Users)</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={phone} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">+91 994052370, +91 994052370</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={tags} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Child Specialist</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={experiance} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">10 year Experiance</span>
                                                </div>
                                                <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                    <img src={locationSmall} className="mr-1"/>&nbsp;
                                                    <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <button type="button" className="btn btn-orange px-2 px-lg-4 mr-2 mt-2">View <span className="d-mob-none">Details</span></button>
                                                    <button type="button" className="btn btn-blue px-2 px-lg-4 mr-2 mt-2">Enquire <span className="d-mob-none">Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="page navigation">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item nav-pg">
                                            <a className="page-link" href="#">&#60;&nbsp;Previous</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">1</a>
                                        </li>
                                        <li className="page-item active" aria-current="page">
                                            <a className="page-link" href="#">2 <span className="sr-only">(current)</span></a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">3</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">4</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">5</a>
                                        </li>
                                        <li className="page-item nav-pg">
                                            <a className="page-link" href="#">Next&nbsp;&#62;</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            
                            <div className="d-mob-none d-web-sm-none">
                                <div className="search-map-sec">
                                    <div id="map">
                                        <div  id="map-container-google-11">
                                            <iframe className="w-100 br-10" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJB5gIVWFdUjoRKdj3Nxxq6-g&key=AIzaSyA1X9MSi_O-dUs4kUu_I1dY_lXHOeZbOk0"  frameBorder="0" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="ads-sec">
                                    <h4>Run Ads</h4>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid px-4 px-lg-5 pb-3 search-list-sec2">
                        <div className="px-4 corner-nav mb-5">
                            <h5 className="text-center page-title mb-4">Top Doctors in India</h5>
                            <ItemsCarousel
                                 requestToChangeActive={this.topDoctorsActiveItem}
                                 activeItemIndex={this.state.topDoctorsItemIndex}
                                 numberOfCards={window.innerWidth>550?4:1}
                                 gutter={20}
                                 leftChevron={<img src={leftarrow} alt="Left Arrow" style ={{width:'40px'}} className="carosel-control-left"/>}
                                 rightChevron={<img src={rightarrow} alt="Right Arrow" style ={{width:'40px'}} className="carosel-control-right" />}
                                 outsideChevron
                                 chevronWidth={40} >
                                <div className="cus-card-item">
                                    <img src={Rec1} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Dipanshu Clinic</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Child Specialist</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={Rec2} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Christopher's Fertility Clinic</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Gynecologist</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={Rec3} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Children's Clinic</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Child Specialist</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={Rec4} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Gynaecologist Clinic</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Gynaecologist</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={Rec5} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Dipanshu Clinic</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Child Specialist</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                            </ItemsCarousel>
                        </div>
                    </div>
                    <div className="container-fluid px-2 px-lg-5 pb-3 search-list-sec3">               
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-10 offset-lg-1">
                                    <div className="search-result-card">
                                        <h5 className="page-sub-title font-weight-bold">FAQ</h5>
                                        <ol className="pl-3 pt-2 mb-0">
                                            <li>
                                                <h6>What about the new materials or fixtures that may be needed? </h6>
                                                <p className="text-dark fs-14">It totally lies at the client’s discretion. You can yourself get the material according to your choice or ask the professional to procure the same. The time taken by the professional to get the material will also be included in the working hours. </p>
                                            </li>
                                            <li>
                                                <h6>What if no services are availed, how much will I be entitled to pay? </h6>
                                                <p className="text-dark fs-14">If in case no services were availed, you just need to pay the professional a visiting charge.</p>
                                            </li>
                                            <li>
                                                <h6>Is there any guarantee on the service provided? </h6>
                                                <p className="text-dark fs-14">Yes, a 30-day guarantee is provided on all plumbing services.</p>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="search-result-card">
                                        <h5 className="page-sub-title font-weight-bold mb-3">About Doctor Services</h5>
                                        <h6>Doctor Services</h6>
                                        <p className="text-dark fs-14">
                                        A well-functioning household is essential for effectiveness in life. Kitchens and bathrooms are an essential fragment of a house. Any kind of obstruction, such as a clogged pipe or running toilet, can bring your entire schedule to a standstill, while at the same time disrupting your normalcy of everyday life. Given that materials such as pipes and cisterns, which are used in building your living space, come with an expiry date, you need to keep them updated and in a working condition. Some materials or machines require constant maintenance while others require replacements and fixes. It could be a leaking tap that you might be ignoring or a condition that needs to be dealt with immediately, such as a blockage in the toilet trap.
                                        </p>
                                        <h6>You can also go through the same Plumbing Services in Chennai in other cities as well:</h6>
                                        <ul className="pl-0 mb-0 list-unstyled text-dark fs-14">
                                            <li>Best Plumbing services in Delhi</li>
                                            <li>Best Plumbing services in Mumbai</li>
                                            <li>Best Plumbing services in Kolkata</li>
                                            <li>Best Plumbing services in Pune</li>
                                            <li>Best Plumbing services in Delhi</li>
                                            <li>Best Plumbing services in Hydrabad</li>
                                            <li>Best Plumbing services in Delhi</li>
                                            <li>Best Plumbing services in Mumbai</li>
                                            <li>Best Plumbing services in Kolkata</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };
  
  export default connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(DoctorsNearYouSearchList));