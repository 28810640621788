import React, { Component } from 'react';
import './legal-services-near-you.css';
import './legal-content.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import search2 from '../../../../assets/images/search-w.svg';
import { scrollTop } from '../../common/common';
import svg1 from '../../../../assets/images/services-page/9-legal-services/image-1.png';
import svg2 from '../../../../assets/images/services-page/9-legal-services/image-2.png';
import svg3 from '../../../../assets/images/services-page/9-legal-services/image-3.png';
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { searchLawyerAPI } from '../../../../services/querys/get-APIs';
class LegalServices extends Component {

    constructor(props) {
        super(props)
        this.state = {
            popLawyersServicesItemIndex: 0,
            topLawyersItemIndex: 0,
            width: 0,
            height: 0
        }
        window.addEventListener("resize", this.diviceSizeUpdate);
    }
    popLawyersServicesActiveItem = (popLawyersServicesItemIndex) => {
        this.setState({ popLawyersServicesItemIndex });
    }
    topLawyersActiveItem = (topLawyersItemIndex) => {
        this.setState({ topLawyersItemIndex });
    }

    componentDidMount() {
        scrollTop();
    }
    diviceSizeUpdate = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    navToListPage = () => {
        this.props.history.push("/legal-services-near-you-search-list")
    }

    searchLawyer = (searchText) => {
        searchLawyerAPI(searchText).then((res) => {
            console.log("res", res)
        })
            .catch((error) => {
                console.log('error', error)
            })
    }
    render() {
        return (
            <div className="LegalServices">
                <Helmet>
                    <title>Legal Services Near You | Online Legal Services in India | ShiftEasy</title>
                    <meta name="description" content="ShiftEasy offers a platform, where you can find Online Legal Services in India. Visit the Website and find India's best Legal Services Near you" />
                    <meta name="keywords" content="Legal Services Near You, Legal Services in India" />
                </Helmet>
                <Header />
                <div className="page-top-banner service-banner  mb-lg-5 serv-nine">
                    <h1 className="text-center text-white mb-4">Lawyer <span className="text-orange">Services </span></h1>
                    <h5 className="text-center text-white mb-4">We Are Fast, We Are Digital, And Will Be Proactive In Progressing<br /> Your Property Transaction</h5>
                    {/* <h4 className="text-center text-white mb-3">Shifteasy <span className="text-orange">Platform </span>| Shifteasy <span className="text-orange">Partner App </span></h4> */}
                    <div className="row justify-content-center mb-0 mb-lg-4">
                        <div className="btn-group col-md-6" role="group" aria-label="Button group with nested dropdown">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control br-0 border-0 bg-white" placeholder="Search lawyers by Street/location or Type" />
                                <div className="input-group-append">
                                    <button onClick={this.navToListPage} type="button" className="btn btn-orange br-0"><img src={search2} alt="Search" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container mx-auto">
                    <div className="row my-lg-5 my-4 ">
                        <div className="col-md-12">
                            <div className="service-content-box">
                                <p className="text-center  ">Legal service involves support or assistance or free service in the field of law. Legal service includes interpretation of any service in the performance of any other<br /> proceedings before anycourt
                                    or other authority or committee and the giving of any advice of any legal matter.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row Legal_ser  align-items-center service-content-box">
                        <div className="col-md-8 col-lg-6">
                            <div className="service-content-box p-0">
                                <h5>What are Legal services?</h5>
                                <p>Legal Services incorporates giving Free Legal Aid to those more fragile parts of the public who fall inside the domain of Section 12 of the lawful Services Authority Act, 1987. Lawful administrations also envelop working with the recipients to get their qualifications under different government plans, arrangements, and enactments.<br /><br /> India has one of the biggest legitimate calling frameworks globally that has stood the trial of time and worked with the presence of probably the biggest majority rule government of the world. As far as we might be concerned today, the lawful calling is just a new truth that was molded by the previous British overall set of laws.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-6 ">
                            <img src={svg1} className="img-fluid p-4" alt="img" />
                        </div>
                    </div>
                    <div className="row align-items-center ">
                        <div className="col-md-6 ">
                            <img src={svg2} className="img-fluid p-4" alt="img" />
                        </div>
                        <div className="col-md-6 ">
                            <div className="legal_para service-content-box p-3">
                                <h5>Shifteasy Legal Services :</h5>
                                <p>ShiftEasy legal services, we are fast, digital, and proactive in progressing your property transaction. ShiftEasy is an intelligent virtual platform that makes it quicker and simpler to discover and employ Top Rated Lawyers in any city in India since you merit admittance to top-notch, proficient legitimate counsel from Top Rated Lawyers out there.<br /><br /> ShiftEasy Partners are set to make the legitimate experience striking by making lawful administrations top caliber, practical and on-interest for each need.<br /><br /> ShiftEasy Partners assist you with counseling and recruit the best legal advisors in India. Use channels to limit your hunt and track down the best supporter in India for your legitimate matter. Get top legal advisors in India for family question or separation, property, business or work court, criminal, recuperation or check bob, tax collection or corporate issue, or a legal counselor master in some other field of law.</p>
                            </div>
                        </div>
                    </div>
                    <div className="service-content-box">
                        <h5 className="page-sub-title">Why Choose ShiftEasy Legal services?</h5>
                        <p>ShiftEasy lists Top Rated Legal Experts in the country to help you get practical Legal Advice & help. We accept that the correct data assists you with settling on better choices. At Shifteasy, we furnish you with actual data on Lawyers, their subject matters, and their experience so you can settle on the decisions that are appropriate for you. We make it simple to counsel Top Rated Lawyers. Examine your issue over an email, plan an office meeting, or talk with any of the recorded Lawyers at your home/office. We are prepared to help you, according to your comfort.<br /><br /> We acquaint you with exceptionally experienced Lawyers. We accept that law represents force, nobility, and uniformity. We additionally help you fabricate relations with top of the line proficient at helping you all through your expert and individual excursion.</p>
                    </div>
                    <div className="row provi_law align-items-center service-content-box">
                        <div className="col-md-8 col-lg-6">
                            <div className="service-content-box p-0">
                                <h5>ShiftEasy Provides Expert Legal advice:</h5>
                                  <ul>
                                  <li>100% Confidentiality</li>
                                  <li>Checked Lawyers</li>
                                  <li>Contact Immediately</li>
                                  </ul>
                                  <p>Offer your legitimate issue to get associated with master legal counselors</p>
                                  </div>
                        </div>
                        <div className="col-md-4 col-lg-6 ">
                            <img src={svg3} className="img-fluid p-4" alt="img" />
                        </div>
                    </div>
                   
                    <div className=" silver_box mb-5 col-md-12 col-lg-12 bg-silver align-items-center  service-content-box">
                    <div>
                        <h5 className="text-center">Legal Services, which are covered</h5>
                        </div>
                        <div className=" row  align-items-center  "> 
                            <div className="text-justify col-md-3 p-5">
                              <ul>
                                  <li>Property registry</li>
                                  <li>Registry E-challan submission</li>
                                  <li>Mutation work</li>
                                  <li>Partition of property</li>
                                  <li>Developers claim.</li>
                                  <li>Adverse possession</li>
                                  <li>Landlord-tenant dispute</li>
                                  <li>Rent agreement!</li>
                                  <li>Sale agreement</li>
                                  <li>Legal Notice</li>
                              </ul>
                            </div>
                            <div className="text-justify col-md-3 p-5">
                            <ul>
                                  <li>Lease Agreement Drafting</li>
                                  <li>Lease Registration</li>
                                  <li>Documentation</li>
                                  <li>MoU</li>
                                  <li>Name Change</li>
                                  <li>Sale Deed Registration</li>
                                  <li>Gift Deed Registration</li>
                                  <li>Will Registration</li>
                                  <li>Power of Attorney</li>
                              </ul>

                            </div>
                            <div className="text-justify col-md-3 p-5">
                            <ul>
                                  <li>Refund of Security Notice</li>
                                  <li>Faulty Product Notice</li>
                                  <li>Cheque Bounce Notice</li>
                                  <li>Recovery Notice of Dues</li>
                                  <li>Duplicate Certified Copy Issue</li>
                                  <li>Issue of Trade license</li>
                                  <li>Issue of New GST</li>
                                  <li>Issue CIN Certificate</li>
                                  <li>Partnership Deed</li>
                              </ul>

                            </div>
                            <div className="text-justify col-md-3 p-5">
                            <ul>
                                  <li>GST litigation services</li>
                                  <li>Commercial lands litigation services</li>
                                  <li>Residential land legal services</li>
                                  <li>Civil Law / Property</li>
                                  <li>Succession Certificate</li>
                                  <li>Property Verification</li>
                                  <li>Property Registration</li>
                                  <li>Gift Deed Drafting</li>
                                  <li>Tenant Eviction Notice</li>   
                              </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LegalServices));