import React, { Component } from 'react';
import './side-menu.css';
import userPic from '../../../assets/images/side-menu-one/user-pic.jpeg';
import {Link,withRouter} from 'react-router-dom';
import { connect } from "react-redux";
class sideMenu extends Component {

    render() {
        return (
            <div className="left-sec bg-dark-blue">
                <div className="user-pic-sec">
                    <div className="position-relative">
                        <img src={userPic} alt="User Pic" width="auto" height="100px" className="user-pic" />
                        {/* <a href={() => false} type="button" className="bg-white br-50p btn p-1 shadow-sm ml-2 change-user-pic">
                            <img src={userEdit} alt="edit"/>
                        </a> */}
                    </div>
                </div>
                <ul className="side-menu-list">
                    <li className={this.props.location.pathname==='/dashboard' ?"side-menu-item active":"side-menu-item"}>
                        <Link to="/dashboard" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mx-3 my-2" width="20" height="20" viewBox="0 0 30 30">
                                <path id="Dashboard_2" data-name="Dashboard 2" d="M4.5,21.167H17.833V4.5H4.5ZM4.5,34.5H17.833v-10H4.5Zm16.667,0H34.5V17.833H21.167Zm0-30v10H34.5V4.5Z" transform="translate(-4.5 -4.5)" fill="#b4b4b4" />
                            </svg>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className={this.props.location.pathname==='/post-property' ?"side-menu-item active":"side-menu-item"}>
                        <Link to="/post-property" type="button">
                            <svg id="Post_Property" data-name="Post Property" xmlns="http://www.w3.org/2000/svg" className="mx-3 my-2" width="20" height="20" viewBox="0 0 30 30">
                                <g id="Group_1917" data-name="Group 1917">
                                    <rect id="Rectangle_594" data-name="Rectangle 594" width="20" height="20" fill="none" />
                                </g>
                                <g id="Group_1918" data-name="Group 1918">
                                    <path id="Path_1632" data-name="Path 1632" d="M26.333,16.333V3H9.667V9.667H3V33H16.333V26.333h3.333V33H33V16.333ZM9.667,29.667H6.333V26.333H9.667Zm0-6.667H6.333V19.667H9.667Zm0-6.667H6.333V13H9.667ZM16.333,23H13V19.667h3.333Zm0-6.667H13V13h3.333Zm0-6.667H13V6.333h3.333ZM23,23H19.667V19.667H23Zm0-6.667H19.667V13H23Zm0-6.667H19.667V6.333H23Zm6.667,20H26.333V26.333h3.333Zm0-6.667H26.333V19.667h3.333Z" transform="translate(-3 -3)" fill="#b4b4b4" />
                                </g>
                            </svg>
                            <span>Post Property</span>
                        </Link>
                    </li>
                    <li className={this.props.location.pathname ==='/property-list' ?"side-menu-item active":"side-menu-item"}>
                        <Link to="/property-list" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mx-3 my-2" width="20" height="15.553" viewBox="0 0 30 23.333">
                                <g id="Property_list" data-name="Property list" transform="translate(-2 0.556)">
                                    <path id="Path_1639" data-name="Path 1639" d="M3,20H9.667V13.333H3Zm0,8.333H9.667V21.667H3ZM3,11.667H9.667V5H3ZM11.333,20H33V13.333H11.333Zm0,8.333H33V21.667H11.333ZM11.333,5v6.667H33V5Z" transform="translate(-1 -5.556)" fill="#b4b4b4" />
                                </g>
                            </svg>
                            <span>Property List</span>
                        </Link>
                    </li>
                    <li className={this.props.location.pathname ==='/my-profile' ?"side-menu-item active":"side-menu-item"}>
                        <Link to="/my-profile" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mx-3 my-2" width="20" height="20" viewBox="0 0 30 30">
                                <path id="my_profile" data-name="my profile" d="M15,16.875A8.438,8.438,0,1,0,6.563,8.438,8.44,8.44,0,0,0,15,16.875Zm7.5,1.875H19.271a10.2,10.2,0,0,1-8.543,0H7.5A7.5,7.5,0,0,0,0,26.25v.938A2.813,2.813,0,0,0,2.813,30H27.188A2.813,2.813,0,0,0,30,27.188V26.25A7.5,7.5,0,0,0,22.5,18.75Z" transform="translate(0 0)" fill="#b4b4b4" />
                            </svg>
                            <span>My Profile</span>
                        </Link>
                    </li>
                    <li className={this.props.location.pathname ==='/enquiry' ?"side-menu-item active":"side-menu-item"}>
                        <Link to="/enquiry" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mx-3 my-2" width="20" height="20" viewBox="0 0 30 30">
                                <g id="Enquiry" transform="translate(3 1)">
                                    <path id="Path_1634" data-name="Path 1634" d="M29,2H5A3,3,0,0,0,2.015,5L2,32l6-6H29a3.009,3.009,0,0,0,3-3V5A3.009,3.009,0,0,0,29,2ZM8,12.5H26v3H8ZM20,20H8V17H20Zm6-9H8V8H26Z" transform="translate(-5 -3)" fill="#b4b4b4" />
                                </g>
                            </svg>
                            <span>Enquiry</span>
                        </Link>
                    </li>
                    <li className={this.props.location.pathname ==='/favourites' ?"side-menu-item active":"side-menu-item"}>
                        <Link to="/favourites" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mx-3 my-2" width="20" height="18.35" viewBox="0 0 30 27.525">
                                <g id="favorites" transform="translate(0 1.763)">
                                    <path id="Path_1635" data-name="Path 1635" d="M0,0H24V24H0Z" fill="none" />
                                    <path id="Path_1636" data-name="Path 1636" d="M17,30.525l-2.175-1.98C7.1,21.54,2,16.92,2,11.25A8.17,8.17,0,0,1,10.25,3,8.983,8.983,0,0,1,17,6.135,8.983,8.983,0,0,1,23.75,3,8.17,8.17,0,0,1,32,11.25c0,5.67-5.1,10.29-12.825,17.31Z" transform="translate(-2 -4.763)" fill="#b4b4b4" />
                                </g>
                            </svg>
                            <span>Favourites</span>
                        </Link>
                    </li>
                    <li className={this.props.location.pathname ==='/membership' ?"side-menu-item active":"side-menu-item"}>
                        <Link to="/membership" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mx-3 my-2" width="20" height="20" viewBox="0 0 30 30">
                                <path id="Icon_material-card-membership" data-name="Icon material-card-membership" d="M30,3H6A2.99,2.99,0,0,0,3,6V22.5a2.99,2.99,0,0,0,3,3h6V33l6-3,6,3V25.5h6a2.99,2.99,0,0,0,3-3V6A2.99,2.99,0,0,0,30,3Zm0,19.5H6v-3H30ZM30,15H6V6H30Z" transform="translate(-3 -3)" fill="#b4b4b4" />
                            </svg>
                            <span>Membership</span>
                        </Link>
                    </li>
                    <li className={this.props.location.pathname ==='/change-password' ?"side-menu-item active":"side-menu-item"}>
                        <Link to="/change-password" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mx-3 my-2" width="20" height="22.86" viewBox="0 0 30 34.286">
                                <path id="Chnage_Password" data-name="Chnage Password" d="M26.786,17.143H10.179v-6.9a4.822,4.822,0,1,1,9.643-.06V11.25a1.6,1.6,0,0,0,1.607,1.607h2.143a1.6,1.6,0,0,0,1.607-1.607V10.179a10.179,10.179,0,1,0-20.357.1v6.864H3.214A3.215,3.215,0,0,0,0,20.357V31.071a3.215,3.215,0,0,0,3.214,3.214H26.786A3.215,3.215,0,0,0,30,31.071V20.357A3.215,3.215,0,0,0,26.786,17.143ZM17.679,27.321a2.679,2.679,0,0,1-5.357,0V24.107a2.679,2.679,0,0,1,5.357,0Z" transform="translate(0 0)" fill="#b4b4b4" />
                            </svg>
                            <span>Change Password</span>
                        </Link>
                    </li>
                    <li className={this.props.location.pathname ==='/slot-management' ?"side-menu-item active":"side-menu-item"}>
                        <Link to="/slot-management" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mx-3 my-2" width="20" height="23.33" viewBox="0 0 30 35">
                                <path id="Slot" d="M24.5,1.5h-10V4.833h10ZM17.833,23.167h3.333v-10H17.833ZM31.217,12.15l2.367-2.367a18.414,18.414,0,0,0-2.35-2.35L28.867,9.8a15,15,0,1,0,2.35,2.35ZM19.5,33.167A11.667,11.667,0,1,1,31.167,21.5,11.658,11.658,0,0,1,19.5,33.167Z" transform="translate(-4.5 -1.5)" fill="#b4b4b4" />
                            </svg>
                            <span>Slot Management</span>
                        </Link>
                    </li>
                    <li className={this.props.location.pathname ==='/my-enquiry' ?"side-menu-item active":"side-menu-item"}>
                        <Link to="/my-enquiry" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mx-3 my-2" width="20" height="20" viewBox="0 0 30 30">
                                <g id="my_enquiry" data-name="my enquiry" transform="translate(0 1.5)">
                                    <path id="Path_1631" data-name="Path 1631" d="M30.5,8h-3V21.5H8v3A1.5,1.5,0,0,0,9.5,26H26l6,6V9.5A1.5,1.5,0,0,0,30.5,8Zm-6,9V3.5A1.5,1.5,0,0,0,23,2H3.5A1.5,1.5,0,0,0,2,3.5v21l6-6H23A1.5,1.5,0,0,0,24.5,17Z" transform="translate(-2 -3.5)" fill="#b4b4b4" />
                                </g>
                            </svg>
                            <span>My Enquiry</span>
                        </Link>
                    </li>
                </ul>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {

    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(sideMenu));