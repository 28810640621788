import React, { Component } from "react";
import './Footer-style.css';
import shifteasylogo from '../../../assets/images/newlogose.svg';
// import twitter from '../../../assets/images/twitter.png';
// import facebook from '../../../assets/images/facebook.png';
// import linkedin from '../../../assets/images/linkedin.png';
// import youtube from '../../../assets/images/youtube.png';
import playstore from '../../../assets/images/gp_badge.png';
import appstore from '../../../assets/images/as_badge.png';
import { Link } from "react-router-dom";
import { BsFacebook , BsTwitter, BsLinkedin} from "react-icons/bs";
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
      return (
        <div>
          {/* <!-- Footer --> */}
          <footer>
            <div className="bg-dark-blue">
              <div className="px-30">
                <div className="row mx-auto py-4 text-white">
                  <div className="col-md-3 col-sm-6">
                    <h6 className="font-weight-bold text-orange">Services</h6>
                    <hr className="border-white borderline mx-0" />
                    <ul className="footer-list mb-0">
                      <li>Property for Sale</li>
                      <li>Property for Rent</li>
                      <li>Commercial Properties</li>
                      <li>Property Services</li>
                      <li>Rent Market Index</li>
                      <li>Valuations</li>
                      <li>Tradesman Services</li>
                      <li>Cars for Sale</li>
                      <li>Bike for Sale</li>
                      <li>Verify Trader</li>
                    </ul>
                  </div>
                  <div className="col-md-3 col-sm-6 ">
                    <h6 className="font-weight-bold text-orange">
                      Quick Links
                    </h6>
                    <hr className="border-white borderline mx-0" />
                    <ul className="footer-list mb-0">
                      <li>Home</li>
                      <li>About Us</li>
                      <li>Investor Services</li>
                      <li>Real Estate Partners</li>
                      <li>
                        <Link
                          to={{
                            pathname: "/ContactUs",
                            refreshpath: "yes",
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Contact Us
                        </Link>
                      </li>
                      <li>Cookies Policy</li>
                      <li>Privacy Policy</li>
                      <li>Terms and Conditions</li>
                      <li>Careers</li>
                      <li>Sitemap</li>
                      <li>FAQ</li>
                    </ul>
                  </div>
                  <div className="col-md-3 col-sm-6 ">
                    <h6 className="font-weight-bold text-orange">Address</h6>
                    <hr className="border-white borderline mx-0" />
                    <h6 className="font-weight-bold text-white mt-3">India Office </h6>
                    <p className="fs-13 lineHeight">
                      EPK Business Centre No;
                      <br /> 624, 4th Floor, Anna Salai,
                      <br /> Chennai-600006
                    </p>
                    <h6 className="font-weight-bold text-white mt-3">UK Office</h6>
                    <p className="fs-13 lineHeight">
                    Kemp House, 160 City Road,<br /> London, United Kingdom,<br /> EC1V 2NX
                    </p>
                    <h6 className="font-weight-bold text-white mt-3">GSTN</h6>
                    <p className="fs-13 lineHeight">34ABGCS5539N1ZS</p>
                  </div>
                  <div className="col-md-3 col-sm-6 mb-3 ">
                    <a href="https://shifteasy.com/">
                    <img
                      src={shifteasylogo}
                      alt="Logo"
                      style={{maxWidth:"175px"}}
                      className="img-fluid mb-3"
                      
                    />
                    </a>
                    <h6 className="text-white">Follow Us On:</h6>
                    <div className="my-4 row ">
                    <a
                        href="https://www.facebook.com/shifteasy2020"
                        target="_blank"
                        className="col-2 col-md-3 col-lg-2"
                        rel="noreferrer"
                      >
                      <BsTwitter
                       style={{color:"white",
                       height:"30px",
                       width:'30px'}}
                      />
                      </a>
                      <a
                        href="https://www.facebook.com/shifteasy2020"
                        target="_blank"
                        className="col-2 col-md-3 col-lg-2"
                        rel="noreferrer"
                      >
                       <BsFacebook
                        style={{color:"white",
                        height:"30px",
                        width:'30px'
                      }}
                       
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/shifteasy-ai/"
                        target="_blank"
                        className="col-2 col-md-3 col-lg-2"
                        rel="noreferrer"
                      >
                       <BsLinkedin
                         style={{color:"white",
                         height:"30px",
                         width:'30px'}}
                        />
                      </a>
                      {/* <img
                        src={youtube}
                        alt="YouTube"
                        style={{width:"20px"}}
                        className="ml-lg-3"
                      /> */}
                    </div>
                    <h6 className="text-white">Download App from:</h6>
                    <div className="my-3 pl-2 row">
                      <img
                        src={playstore}
                        alt="Google Play Store"
                        className="img-fluid  p-1 col-5"
                      />
                      <img
                        src={appstore}
                        alt="App Store"
                        className="img-fluid  p-1 col-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-white text-center fs-13 py-3 px-5 border-top">
                Copyright @ 2021. All rights reserved by RMILLC & ShiftEasy LTD
              </div>
            </div>
          </footer>
        </div>
      );
  }
}
export default Footer;