import {ApolloClient, InMemoryCache} from '@apollo/client';
import {
  shifteasyServicesQuery,
  // searchPropertiesByCity,
  searchPropertiesByCityQuery,
  getCountryQuery,
  getStatesByCountryQuery,
  getCityByStateQuery,
  listFeaturedPropertiesQuery,
  EnquiryQuery,
  myEnquiryQuery,
  getUserProfileQuery,
  getCustomersQuery,
  addPropertyStepperOneQuery,
  addPropertyStepperTwoQuery,
  addPropertyStepperThreeQuery,
  addPropertyStepperFourQuery,
  addPropertyStepperFiveQuery,
  addPropertyStepperSixQuery,
  addPropertyStepperSevenQuery,
  propertyListByUser,
  searchPropertList,
  commercialDetailsAPI,
  searchPropDetailsById,
  creche,
  // hospital,
  // marriageHall,
  // OldAgeHome,
  // wareHouse,
  // officeSpace,
  // hostel,
  searchList,
  AutoSuggestions,
  NearByPlaces,
  AutoCitySuggestions,
  CitySearchResult,
  BlogsMain,
  BlogsDetail,
} from "./get-querys";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const G_URL   = "https://cms.shifteasy.com/api/graphql" 


export const shifteasyServicesAPI = (txt) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: shifteasyServicesQuery,
      variables: {
        text: txt,
      },
    });
  };

  export const searchPropertiesByCityAPI = (cityName) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: searchPropertiesByCityQuery,
      variables: {
        city: cityName,
      },
    });
  };
  
export const getCountriesAPI =(id)=>{
  var client = new ApolloClient({
    uri: `${BASE_URL}/location`,
    cache: new InMemoryCache(),
  });
  return client.query({
    query: getCountryQuery,
  });
}
export const getStatesByCountryAPI =(countryId)=>{
  var client = new ApolloClient({
    uri: `${BASE_URL}/location`,
    cache: new InMemoryCache(),
  });
  return client.query({
    query: getStatesByCountryQuery,
    variables: {
      countryId
    },
  });
}
export const getCityByStateAPI =(stateId)=>{
  var client = new ApolloClient({
    uri: `${BASE_URL}/location`,
    cache: new InMemoryCache(),
  });
  return client.query({
    query: getCityByStateQuery,
    variables: {
      stateId
    },
  });
}
  export const listFeaturedPropertiesAPI = (variables) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: listFeaturedPropertiesQuery,
      variables
    });
  };
  export const enquiryQueryAPI = (proId) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: EnquiryQuery,
      variables: {
        propertyId: proId,
      },
    });
  };

  export const myEnquiryQueryAPI = (custId) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: myEnquiryQuery,
      variables: {
        customerId: custId,
      },
    });
  };

  export const getUserProfileAPI = (token) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/auth`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: getUserProfileQuery,
      variables: {
        accessToken: token,
      },
    });
  };
  export const getCustomersAPI = (customerId) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/customer`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: getCustomersQuery,
      variables: {
        customerId
      },
    });
  };
  export const getAddPropertyStepperOneAPI = (propId) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: addPropertyStepperOneQuery,
      variables: {
        propertyId: propId,
      },
    });
  };

  export const getAddPropertyStepperTwoAPI =(propId)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: addPropertyStepperTwoQuery,
      variables: {
        propertyId: propId,
      },
    });
  }

  export const getAddPropertyStepperThreeAPI = (propId) => {
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: addPropertyStepperThreeQuery,
      variables: {
        propertyId: propId,
      },
    });
  };

  export const getAddPropertyStepperFourAPI =(propId)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: addPropertyStepperFourQuery,
      variables: {
        propertyId: propId,
      },
    });
  }
  export const getAddPropertyStepperFiveAPI =(propId)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: addPropertyStepperFiveQuery,
      variables: {
        propertyId: propId,
      },
    });
  }

  export const getAddPropertyStepperSixAPI =(propId,cusId)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: addPropertyStepperSixQuery,
      variables: {
        propertyId: propId,
        customerId:cusId
      },
    });
  }

  export const getAddPropertyStepperSevenAPI =(propId)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: addPropertyStepperSevenQuery,
      variables: {
        propertyId: propId,
      },
    });
  }

  export const getPropertyListByUser =(userId)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: propertyListByUser,
      variables: {
        customerId: userId,
      },
    });
  }

  export const getSearchPropertyList =(variables)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: searchPropertList,
      variables,
    });
  }

  export const getSearchPropertyDetailsById =(id)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: searchPropDetailsById,
      variables: {
        propertyId:id
      },
    });
  }
  export const getSearchCommertialPropertyDetailsById =(id)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/proplist`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: commercialDetailsAPI,
      variables: {
        propertyId:id
      },
    });
  }
  export const getCountListAPI =()=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/location`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: getCountryQuery,
      variables: {
      },
    });
  }

  export const getStateByCountryIdAPI =(id)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/location`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: getCountryQuery,
      variables: {
        countryId:id
      },
    });
  }

  export const crecheQueryAPI =(id)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/location`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: creche,
      variables: {
        countryId:id
      },
    });
  }

  export const searchLawyerAPI =(searchText)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/lawyer`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: searchList,
      variables: {
       text:searchText
      },
    });
  }

  export const getNearByPlaces =(details)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/location`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: NearByPlaces,
      variables: {
       locationRequest:details
      },
    });
  }
  export const getAutoSuggestions =(locationRequest)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/location`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: AutoSuggestions,
      variables: {
       locationRequest
      },
    });
  }
  export const getCityAutoSuggestion =(details)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/location`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: AutoCitySuggestions,
      variables: {
       text:details
      },
    });
  }

  export const getCitySearchResult =(details)=>{
    var client = new ApolloClient({
      uri: `${BASE_URL}/location`,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: CitySearchResult,
      variables: {
        placeSearchRequest:details
      },
    });
  }

  export const getBlogsMain =(details)=>{
    var client = new ApolloClient({
      uri: G_URL,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: BlogsMain,
      variables: {
        take:details
      },
    });
  }
  export const getBlogsDetail =(id)=>{
    var client = new ApolloClient({
      uri: G_URL,
      cache: new InMemoryCache(),
    });
    return client.query({
      query: BlogsDetail,
      variables: {
        id:id
      },
    });
  }

