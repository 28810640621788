import { useState, useEffect } from "react"
import './imageuploader.css';
import Upload from "../../../assets/images/Upload.png"
import { BsCloudArrowUp, BsFillXCircleFill } from "react-icons/bs";

const Imageuploader = () => {
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState()
   

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        setSelectedFile(preview)
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }
    const handleExit = () => {
        setSelectedFile(!selectedFile)
    }
    return (
        <div>
            <div className="container main_container justify-content-center ">
                <div className="service-content-box content_box  lg-mx-5">
                    <div className="inner_container content-justify-center boder " >
                        <div className="x_button">
                        {!selectedFile ? "" : <BsFillXCircleFill
                            onClick={handleExit}
                            style={{
                                position: "absolute",
                                marginLeft: "451px",
                                marginTop: "-43px"
                            }} />}
                          </div>  
                        <label  className={!selectedFile ?"icon_style" :"icon_style1"} htmlFor="file-input" >
                            {!selectedFile ? <div > <BsCloudArrowUp
                            
                            size={150} /> <h6 className="page-title m-0">Upload Photo</h6>
                                <p className="text-center h6 mt-1 text-dark">Upload a slider image</p></div> : selectedFile && <img className="img-fluid justify-content-center mt-2" height="500px" width="700px" src={preview} />}
                        </label>

                    </div>
                </div>

                {/* <label htmlFor="file-input">
                    <img src={Upload} />
                </label> */}
                <input type='file' id="file-input" className="d-none" onChange={onSelectFile} />
            </div>
        </div>
    )
}
export default Imageuploader;