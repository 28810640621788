import { gql } from '@apollo/client';

export const shifteasyServicesQuery = gql`
 query services($text:String!){
    services(text: $text){
         servicesId
         services
    }
}
`

export const searchPropertiesByCityQuery = gql`
query popularCities($city:String!){
    popularCities(popularCities:{city:$city}){
   propertyId
   noOfFloors
   propertyName
   propertyPrice
   propertyAge
   city
 }
}
`
export const getCountryQuery = gql`
query getCountries {
  getCountries {
      countryId
      countryName
      countryCode
      isdCode
  }
}
`
export const getStatesByCountryQuery = gql`
  query getStatesByCountry($countryId: ID!) {
    getStatesByCountry(countryId: $countryId) {
      stateId
      countryId
      stateName
    }
  }
`;
export const getCityByStateQuery = gql`
  query getCityByState($stateId: Int!) {
    getCityByState(stateId: $stateId) {
      cityName
      cityId
    }
  }
`;
export const listFeaturedPropertiesQuery = gql`
  query listFeaturedProperty(
    $latitude: String!
    $longitude: String!
    $categoryId: Int!
    $propertyTypeId: [Int]
    $rent: Boolean!
    $sell: Boolean!
    $lease: Boolean!
    $bedroom: [Int]
    $furnishingId: [Int]
    $priceStart: String
    $priceEnd: String
    $amenityId: [Int]
    $sortId: Int
    $userId: String
    $limit: Int
    $page: Int
  ) {
    listFeaturedProperty(
      featuredPropertyRequest: {
        latitude: $latitude
        longitude: $longitude
        categoryId: $categoryId
        propertyTypeId: $propertyTypeId
        rent: $rent
        sell: $sell
        lease: $lease
        bedroom: $bedroom
        furnishingId: $furnishingId
        priceStart: $priceStart
        priceEnd: $priceEnd
        amenityId: $amenityId
        sortId: $sortId
        userId: $userId
        limit: $limit
        page: $page
      }
    ) {
      propertyCount
        response{
         propertyId
         propertyName
         favourite
         propertyTypeName
         localityName
         area
         floorNo
         street
         clientName
         garage
         bedroom
         bathroom
         type
         price
         cityName
         createdTime
         propertyImageUrl{
            imageUrl
         }
         detailFeature{
            title
            value
         }
    } 
    }
  }
`;
export const EnquiryQuery = gql`
query enquiryList($propertyId:Int!){
    enquiryList(enquiry:{propertyId:$propertyId}){
 firstName
 lastName
 email
 phoneNumber
 message
 createdAt
}
}
`

export const myEnquiryQuery = gql`
query myEnquiries($customerId:Int!){
  myEnquiries(myEnquiry:{customerId:$customerId}){
     message
     createdAt
     propertyId
     city
     price
  }
}
`

export const getUserProfileQuery = gql`
query getUserProfile($accessToken:String!){
  getUserProfile(accessToken: $accessToken){
              userId
              firstName
              lastName
              email
              mobileNumber
              city
              aboutMe
              state
              country
              address
              pinCode
           }
  }
`
export const getCustomersQuery = gql`
query getCustomers($customerId:ID!){
  getCustomers(customerId: $customerId){
    customerId
    keycloakUserId
    firstName
    lastName
    email
    primaryContactNumber
    secondaryContactNumber
    whatsappNumber
    landlineNumber
    dateOfBirth
    lastLoginDate
    isFeaturedMember
    profileImageLink
    roleId
    statusId
    statusMessage
    termsConditionsAgreement
    termsConditionsDate
    panNumber
    aadhaarNumber
    nationalInsuranceNumber
    socialSecurityNumber
    passportNumber
    drivingLicenceNumber
    createdAt
    updatedBy
    updatedAt
  }
}
`
export const addPropertyStepperOneQuery = gql`
query ($propertyId:Int!){
  getDetails(propertyId:$propertyId){
  rent
  sell
  lease
  shortTermLit
  propertyCategoryId
  propertyTypeId
  customerTypeId
  }
}
`

export const addPropertyStepperTwoQuery = gql`
query (
  $propertyId:Int!
){
  getDetailedFeatures(propertyId: $propertyId) {
    availableFrom
    detailedFeatureDetails {
      detailedFeatureName
      noOfDetailedFeatures
      detailedFeatureTypeId
      detailedFeatureDetails
    }
    noOfFloors
  }
}`

export const addPropertyStepperThreeQuery = gql`
query ($propertyId:Int!){
  getLocation(propertyId:$propertyId){
  countryName
  stateName
  cityName
  floorNo
  postalCode
  areaName
  street
  latitude
  longitude
  propertyId
 }
}`

export const addPropertyStepperFourQuery = gql`
query ($propertyId:Int!){
  getPrice(propertyId:$propertyId){
    propertyId
    rentAmount
    noticePeriod
    durationOfContract
    maintenanceCharge
    whoWillShow
    depositAmount
    description
    furnishingType
  }
}`

export const addPropertyStepperFiveQuery = gql`
query ($propertyId:Int!){
  getAmenity(propertyId:$propertyId)
  {
  availableFrom
  amenity{
      amenityName
      noOfAmenities
      }
  noOfFloors
}
}`

export const addPropertyStepperSixQuery = gql`
query (
  $propertyId:Int!
  $customerId:String!
){
  getSlot(input:{ 
     propertyId:$propertyId
    customerId:$customerId
  }){
  propertyId
  propertySlotList{
  availabilityDay
  noOfSlots
  availabilityStartTime
  availabilityEndTime
  }
  }
  }
`
export const addPropertyStepperSevenQuery = gql`
query ($propertyId:Int!){
  getImageVideo(propertyId:$propertyId){
  imageUrl{
  imageUrl
       }
 propertyId
 videoUrl
  }
}
`
export const propertyListByUser = gql`
query ($customerId:String!){
  propertyListByCustomerId(input:{
  customerId: $customerId
  })
  {
    propertyId
    propertyType
    area
    projectName
    cityName
    price
    type
    bedroom
    bathroom
    imageList{
        imageUrl
    }
  }
}
`
export const searchPropertList = gql`
query propertyList(
   $propertyTypeId: [Int]
   $categoryId: Int!
   $rent: Boolean!
   $sell: Boolean!
   $lease: Boolean!
   $cityId: Int
   $localityId: Int
   $propertyId: Int
   $priceStart: String
   $priceEnd: String
   $amenityId: [Int]
   $bedroom: [Int]
   $furnishingId: [Int]
   $sortId: Int
   $userId: String
   $page: Int
){
  propertyList(list :{
   propertyTypeId: $propertyTypeId
   categoryId: $categoryId
   rent: $rent
   sell: $sell
   lease: $lease
   cityId: $cityId
   localityId:  $localityId
   propertyId: $propertyId
   priceStart: $priceStart
   priceEnd: $priceEnd
   amenityId: $amenityId
   bedroom: $bedroom
   furnishingId: $furnishingId
   sortId: $sortId
   userId: $userId
   page: $page
   limit: 10
 }){
  propertyCount
  response{
  propertyId
  propertyName
  favourite
   propertyTypeName
  localityName
  area
  floorNo
  street
  clientName
  garage
  bedroom
  bathroom
  type
  price
  cityName
  createdTime
     propertyImageUrl{
          imageUrl
      }
      detailFeature{
          title
          value
      }
   }
  }
 
}
`
export const searchPropDetailsById = gql`
  query ($propertyId: Int!) {
    searchDetails(searchDetails: { propertyId: $propertyId }) {
      projectName
    propertyType
    propertyCategoryId
    description
    cityName
    localityName
    published
    address1
    address2
    countryName
    pinCode
    stateName
    landMark
    cityName
    price
    noticePeriod
    type
    imageList{
        imageUrl
      }

    amenity
     videoUrl
     layoutImageUrl
    propertyAge
    noOfFloors
    propertyFurnishing
    createdAt
     availableFrom
     area
     availableFor
     bedroom
     balconies
    bathroom
     hall
    kitchen
    nearbySchools
    nearbyBusStops
    waterSource
    gardenRoom
    parking
    servantRoom
    rentAgreementDuration
    powerBackup
     whoWillShow
    waterCharges
    depositAmount
    electricityCharges
    maintenanceCharges
    latitude
    longitude
    ownerName
    email
    phoneNumber
    }
  }
`;
export const commercialDetailsAPI = gql`
  query commercialDetails($propertyId: Int!) {
    commercialDetails(propertyId: $propertyId) {
      projectName
    propertyType
    propertyCategoryId
    propertyTypeId
    description
    cityName
    localityName
    published
    address1
    address2
    countryName
    pinCode
    stateName
    landMark
    cityName
    price
    
    type
    imageList{
        imageUrl
      }

    amenity
     videoUrl
     layoutImageUrl
    createdAt
    latitude
    longitude
    ownerName
    email
    phoneNumber
 detailFeature{
            title
            value
        }
        }
  }
`;
export const creche = gql`
query{
getCreche(propertyId:17){
runBy
ageRange
crecheName
operatingStatusId
licenseNumber
preferredGender
inTime
outTime
closedOnId
detailedFeatureDetails{
detailedFeatureName
noOfDetailedFeatures
detailedFeatureTypeId
detailedFeatureDetails
}
  }
}
`
export const hospital = gql`
query{
  getHospital(propertyId:17){
  runBy
  category
  grade
  email
  number
  registrationNumber
  patientCheckingFacility
  detailedFeatureDetails{
  detailedFeatureName
  noOfDetailedFeatures
  detailedFeatureTypeId
  detailedFeatureDetails
  }
  }
} 
`

export const marriageHall = gql`
query{
  getMarriageHall(propertyId:17){
  runBy
  budget
  foundationYear
  guestCapacity
  rooms
  email
  contactNumber
  governmentLicenceNumber
  detailedFeatureDetails {
  detailedFeatureName
  noOfDetailedFeatures
  detailedFeatureTypeId
  detailedFeatureDetails
  }
  }
}
`
export const OldAgeHome = gql`
query{
  getOldAgeHome(propertyId:17){
  runBy
  occupancyTypeId
  oldAgeName
  oldAgeRange
  operatingStatusId
  licenseNumber
  preferredGender
  detailedFeatureDetails{
  detailedFeatureName
  noOfDetailedFeatures
  detailedFeatureTypeId
  detailedFeatureDetails 
  }
  }
}
`
export const wareHouse = gql`
query{
 getWareHouse(propertyId:17){
 runBy
 storageCapacity
 nature
 typeOfStore
 accessType
 technology
 detailedFeatureDetails{
 detailedFeatureName
 noOfDetailedFeatures
 detailedFeatureTypeId
 detailedFeatureDetails 
 }
}
}
`

export const officeSpace = gql`
query{
 getOfficeSpace(propertyId:17){
 totalFloors
 superArea
 carpetArea
 possessionStatus
 propertyAge
 detailedFeatureDetails{
 detailedFeatureName
 noOfDetailedFeatures
 detailedFeatureTypeId
 detailedFeatureDetails 
 }
  }
  
}
`

export const hostel = gql`
query{
  getHostel(propertyId:17){
  typeId
  runBy
  occupancyTypeId
  licenseNumber
  hostelName
  detailedFeatureDetails{
  detailedFeatureName
  noOfDetailedFeatures
  detailedFeatureTypeId
  detailedFeatureDetails 
  }
  }
}
`
export const searchList = gql`
query ($text:String!){
  searchList(text:$text){
  lawyerId
  lawyerFirstName
  lawyerLastName
  phoneNumber1
  phoneNumber2
  specialization
  experience
  locality
  city
  image
  }
}
`
export const NearByPlaces = gql`
query ($locationRequest: LocationRequest!){
  getNearByPlaces(locationRequest: $locationRequest){
       popularCities{
           id
           cityName
       }
       topLocalities{ 
           id
           cityName
       }
       nearByCities{
           id
           cityName
       }
  }
}
`


export const AutoSuggestions = gql`
query getAutoSuggestions ($locationRequest: LocationRequest!){
   getAutoSuggestions(locationRequest: $locationRequest){ 
    locations{
     id
     locationName1
    locationName2
     typeId
     latitude
     longitude
   }
   projects{
     projectId
     projectName
     latitude
     longitude
   }
   builders{
       builderId
       builderName
   }
   }
}
`;

export const AutoCitySuggestions = gql`
query ($text: String!){
  getCityAutoSuggestion(text: $text){
    placeName
    latitude
    longitude
}

}
`


export const CitySearchResult = gql`
query ($placeSearchRequest: PlaceSearchRequest!){
  getCitySearchResult(placeSearchRequest: $placeSearchRequest){
    popularCities{
          id
          cityName
          latitude
          longitude
      }
      topLocalities{ 
          id
          cityName
          latitude
          longitude
      }
      nearByCities{
          id
          cityName
          latitude
          longitude
      }
}

}
`

export const BlogsMain = gql`
query blogs($take: Int){
  blogs(take: $take){
      id
      title
      author{
          name
      }
      description
      content{
          document
      }
      tags
      featureImage{
          url
      }
      articleType
      publishDate
  }
}
`
export const BlogsDetail = gql`
query blog($id: ID!){
  blog(where:{id: $id}){
    id
    title
      author{
      name
    }
    description
      content{
      document
    }
    tags
      featureImage{
      url
    }
    articleType
    publishDate
  }
}
`

