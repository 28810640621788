import React, { Component } from 'react';
import './tenant-referencing.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import tenantServImg1 from '../../../../assets/images/services-page/1-tenant/image-1.png';
import tenantServImg2 from '../../../../assets/images/services-page/1-tenant/image-2.png';
import tenantServImg3 from '../../../../assets/images/services-page/1-tenant/image-3.png';
import tenantServImg4 from '../../../../assets/images/services-page/1-tenant/image-4.png';
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { scrollTop } from '../../common/common';
import { Helmet } from "react-helmet";
class TenantReferencing extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        scrollTop();
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Tennat Referencing</title>
                    <meta name="description" content="Tennat Referencing" />
                </Helmet>
                <Header />
                <div className="service-banner serv-one page-top-banner banner-with-form mb-lg-5">
                    <div className="service-banner-content text-center">
                        <h2 className="text-white"><span className="text-orange">Tenant</span> Referencing for Landlords</h2>
                        <h5 className="text-center">Helping our customers mitigate the risks associated with being a landlord for 25 years</h5>
                    </div>
                    <div className="banner-form">
                        <form>
                            <div className="mb-3">
                                <input type="text" className="form-control br-0" placeholder="Full Name" />
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control br-0" placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Phone number" />
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control br-0" rows="4" placeholder="Message"></textarea>
                            </div>
                            <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                        </form>
                    </div>
                </div>
                <div className="container mx-auto">
                    <div className="row my-md-4 my-lg-5">
                        <div className="col-12 bg-dark-blue">
                            <div className="py-4 px-2 p-lg-5">
                                <div className="text-center">
                                    <h2 className="text-orange">Rent Guarantee</h2>
                                    <h4 className="text-white">Tenant Referencing with Rent Guarantee</h4>
                                    <p className="text-white mb-4">Designed to protect your income if your tenant fails to pay their rent</p>
                                    <button className="btn btn-orange px-3 px-lg-5">Rent Guarantee</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="align-items-center mb-5 mt-3 my-lg-5 row">
                        <div className="col-md-8 col-lg-6">
                            <div className="service-content-box px-0">
                                <h5 className="page-title mb-3">What is Tenant referencing mean to us?</h5>
                                <p>At ShiftEasy, Tenant referencing is your way of discovering out about a prospective tenant, so you can make an advised decision on whether to rent your property to that person/individual.</p>
                                <p>You want to believe and trust that a tenant will pay their rent on time, take care of the property with regard and not be a concern or a problem to the neighbours or others. </p>
                                <p>So, Tenant referencing becomes a bridging key factor between the landlord and the Tenant in property management. And at ShiftEasy, we handle it with 100% commitment and care towards each profile.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-6">
                            <img src={tenantServImg1} className="img-fluid" alt="img" />
                        </div>
                    </div>
                    <div className="align-items-center mb-5 my-lg-5 row">
                        <div className="col-md-8 order-md-2 col-lg-6">
                            <div className="service-content-box px-0">
                                <h5 className="page-title mb-3">Why is Tenant Referencing Essential? </h5>
                                <h6 className="mb-3">Tenant Referencing for Property-owners:</h6>
                                <p>Tenant Referencing for Property-owners: Tenant referencing is an exceptionally important part of the process when you allow out your investment property for leasing or rent. Understanding and knowing the facts about who is moving into your house/property will help you to make an informed decision about the tenants you choose - and who better to trust than a leading referencing provider? </p>
                                <p>ShiftEasy will serve as your One-Stop Point for all your Tenant referencing and property management needs.</p>
                                <p>With our fast and thorough checks, you can rest assured that with our ShiftEasySure reference, you have done as much as you can to make sure you have the best possible tenants for your property. ShiftEasy, with over 25 years of experience, we are here to give you peace of mind.</p>
                            </div>
                        </div>
                        <div className="col-md-4 order-md-1 col-lg-6">
                            <img src={tenantServImg2} className="img-fluid" alt="img" />
                        </div>
                    </div>
                    <div>
                        <p className="fs-14 mb-3">Our ShiftEasy team members can help you find the right Tenant by taking the responsibility of referencing for you and protect the legitimate right to rent checks are carried out. </p>
                        <h6>Best of Quality assurance on services: </h6>
                        <p className="fs-14 mb-2">By utilizing one of our ShiftEasySure services, you are assured to be consulting with a skilled agent who will give you up-to-date advice and guidance. </p>
                        <p className="fs-14 mb-3">We also undertake Legal services and provide handholding assistance to our esteemed members on any legal and property management services or requirements. </p>
                        <h6>Our Vision </h6>
                        <p className="fs-14 mb-2">Our Motto is to bring a glorious vision in Real Estate, Property management and end-to-end home service in a hugely populous and diversified country like India by incorporating best practices in Property management, legal and end-to-end services. </p>
                        <p className="fs-14 mb-3">We are on the verge of achieving a dream of being the One-Stop Point for all your services and requirements. ShiftEasy will cover all your day-to-day needs, including Automobiles, Insurance, Property Management, Rental Assurance, Tradesmen services, Investments, Logistics, Real Estate, and more; You name it, we deliver!!! </p>
                        <h6>Order With Trust: </h6>
                        <p className="fs-14 mb-2">Roughly about 90% of &#123;UK/Indian&#125; landlords reference their tenants with ShiftEasy and the same kind of service model that will adapt the &#123;UK/Indian&#125; Real Estate market, creating an idealistic future. </p>
                        <p className="fs-14 mb-2">ShiftEasy with our property/ profile assurance plan ShiftEasySure has made it simple to get an in-depth overview of potential tenants and their guarantors, with a thorough look at their local referencing, financial status, credit score and risk assessment. </p>
                        <p className="fs-14 mb-3">Tenant referencing helps you make sure each applicant is suitable for your property. At ShiftEasy, we cater tailor-made customized plans as per our customer's requirements. ShiftEasy Your One-Stop portal for all your Real Estate and Property management needs!!! </p>
                        <h6>Think Home Think ShiftEasy!!!</h6>
                    </div>
                    <div className="row my-lg-5 my-4 mem-plans">
                        <h5 className="page-title text-lg-center w-100 p-3">Our Pocket-Friendly Membership Plans:</h5>
                        <div className="col-lg-10 offset-lg-1">
                            <div className="row">
                                <div className="col-md-6 col-lg-4 mb-3">
                                    <div className="br-5 bg-dark-blue p-3 p-lg-4 h-100">
                                        <h5 className="text-orange">Basic Plan: 4999.INR</h5>
                                        <ul className="pl-4 text-white fs-15 mb-0">
                                            <li>1 Working Day (on average)</li>
                                            <li>Credit Check</li>
                                            <li>Linked Address</li>
                                            <li>Identity</li>
                                            <li>Fraud Information</li>
                                            <li>CCJs</li>
                                            <li>Decrees</li>
                                            <li>courts/tribunal information</li>
                                            <li>Right to Rent Check Advice</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-8 mb-3">
                                    <div className="br-5 bg-dark-blue p-3 p-lg-4 h-100">
                                        <h5 className="text-orange">Basic Plan: 4999.INR</h5>
                                        <ul className="pl-4 text-white fs-15 mb-0">
                                            <li>3-5 Working Days (on average)</li>
                                            <li>A maximum of three properties shall be covered.</li>
                                            <li>Rental Certificate Registration- assistance at District collector office/Registrar office</li>
                                            <li>Credit Check</li>
                                            <li>Call follow up and assistance from a dedicated customer care executive on progress.</li>
                                            <li>Valid up to 1 year</li>
                                            <li>Linked Address, Identity & Fraud Information</li>
                                            <li>CCJs, Decrees, and other courts/tribunal information</li>
                                            <li>Right to Rent Check Advice</li>
                                            <li>Affordability Rating</li>
                                            <li>Previous Landlord Reference</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-lg-5 my-4">
                        <h5 className="page-title text-center w-100 pb-3">Frequently Asked Questions</h5>
                        <h6>Why will a tenant fail a Reference check? </h6>
                        <p className="fs-14 mb-2">In Recent Times, deposit replacements have begun to grow popular, as residents seek flexibility and brand-new ways of renting. ShiftEasy's expert knowledge and rental welfare facilities make it a distinctive option for renters looking for a change. </p>
                        <p className="fs-14 mb-2">The easy DIY replacement options are frequently suggested by real estate agents themselves and have been the target of disapproval by certain tenants who feel they were mis-sold. </p>
                        <p className="fs-14 mb-3">The DIY deposit replacement opportunities from other competitive platforms are often priced excessive compared to other replacement options. ShiftEasy's Deposit Replacement plan is more than eight times less expensive per month than other popular agent run memberships.</p>
                        <h5 className="mb-3 mt-5">Suggestions from our Checklist</h5>
                        <h6>Banking: </h6>
                        <p className="fs-14 mb-2">Negative feedback typically means somebody has been shifting bank accounts too often, which can hint at or sign of poor money management or credit problems. </p>
                        <p className="fs-14 mb-2">If the Tenant cannot provide a reasonable explanation, in that case, one simple solution to this dilemma is to request a higher than usual deposit and 3-month bank statements to review how they prioritize their rent vs. their Lifestyle. </p>
                        <p className="fs-14 mb-3">We suggest the best of Rental Assurance at ShiftEasySure by providing a rental assurance mark to certify all the qualified profiles through our portal.</p>
                        <h6>Affordability: </h6>
                        <p className="fs-14 mb-3">If the Tenant's revenue is less than two and half times the rental amount, they will typically fail to reference. Nevertheless, the Tenant may have considerable savings, which they intend to use to complement/supplement their rental income.</p>
                        <h6>Rental: </h6>
                        <p className="fs-14 mb-3">If a tenant cannot give at least one former landlord/ parents / local guardians who will vouch for their decent conduct/behaviour and rent payment history, it is time to start looking for another tenant.</p>
                        <h6>Credit history: </h6>
                        <p className="fs-14 mb-2">If a tenant with a poor 'credit history' can also avail of deposit replacements or a rent guarantee scheme for securing accommodation of their choice. </p>
                        <p className="fs-14 mb-2">Often lowers people's credit scores for comparatively minor breaches of lending agreements and sometimes because a person has not taken out a loan for a while, which some might see as a virtue. </p>
                        <p className="fs-14 mb-3">So instead, perhaps either ask them to explain why they think their score is low or ask for a slightly larger deposit or rent guarantor such as a parent / local guardian instead.</p>
                    </div>
                    <div className="align-items-center mb-5 my-lg-5 row">
                        <div className="col-md-8 col-lg-6">
                            <div className="service-content-box px-0">
                                <h5 className="page-title mb-3">ShiftEasy Ninjas: (India /UK) </h5>
                                <p>The New Model Tenancy Act mentions rental agreement is to be registered with the District Collectors office / Registrar office. It could be a tedious process for our customers, so we provide hassle-free exclusive custom-made services for registration and guidance from our ShiftEasy Ninjas. </p>
                                <p>Our Ninjas are trained professionals who will take you through the rental agreement/registration / rental management procedures with end-to-end guidance and make it look like a cakewalk.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-6">
                            <img src={tenantServImg4} className="img-fluid" alt="img" />
                        </div>
                    </div>
                    <div className="row my-lg-5 mb-md-4 refer-sec p-2 p-lg-4">
                        <div className="col-lg-5 my-3 mb-3 col-12 text-center">
                            <img src={tenantServImg3} className="img-fluid" alt="tenantServImg3" />
                        </div>
                        <div className="col-lg-7 my-3">
                            <div className="service-content-box pb-0">
                                <ul className="pl-3 orange-disc">
                                    <li>Enroll with our premium membership plans to make your Real Estate Dreams come true.</li>
                                    <li>Enjoy hassle-free property management with ShiftEasy.</li>
                                    <li>Sit Back and Relax. We will take care of your properties. With our Shift Easy Rental assurance program, we only bring the best to the table.</li>
                                    <li>We cater to a variety of Tailor-made custom packages to suit your property management needs.</li>
                                    <li>Please feel free to connect to our customer care executive to address your queries about our platform.</li>
                                    <li>Also, check out our other services section to avail of end-to-end care from Tradesmen service to Insurance, you name it, we have it all.</li>
                                </ul>
                                <button className="btn btn-orange px-3 px-lg-5">Start a Reference</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TenantReferencing));