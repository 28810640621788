import React, { useState, useEffect, useWindowDimensions } from 'react'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './imageuploader.css';
//important for getting nice style.
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { EditorState } from "draft-js";
import Imageuploader from "./imgUpload"






class ImageUploading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            uploadedImages: [],
            pictures: []
        };
        this._uploadImageCallBack = this._uploadImageCallBack.bind(this);

    }

    componentDidMount(){
        window.scrollTo(0,0)
    }

    onEditorStateChange: Function = (editorState) => {
        this.setState({
            editorState,
        });
    };
    _uploadImageCallBack(file) {
        // long story short, every time we upload an image, we
        // need to save it to the state so we can get it's data
        // later when we decide what to do with it.

        // Make sure you have a uploadImages: [] as your default state
        let uploadedImages = this.state.uploadedImages;

        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }

        uploadedImages.push(imageObject);



        // We need to return a promise with the image src
        // the img src we will use here will be what's needed
        // to preview it in the browser. This will be different than what
        // we will see in the index.md file we generate.
        return new Promise(
            (resolve, reject) => {
                resolve({ data: { link: imageObject.localSrc } });
            }
        );
    }
    onDrop(picture) {
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
    }
    render() {
        const maxNumber = 69;
        const { editorState } = this.state;
        return (

            <div>

                <Header />

                <br />
                <br />
                <h2 className="text-center mb-4">Create Blog</h2>
                <div className="container-fluid text-center mx-auto   pb-3">
                    <div className="container pt-2"></div>
                    <div className="row d-flex justify-content-center mx-auto col-lg-12 col-md-12 col-xs-12">
                        <div className="col-md-12 col-xs-12 mb-5 img-up">
                           <Imageuploader/>
                        </div>
                    </div>


                    <div className="row mx-auto col-lg-12 col-xs-12 col-md-12 about_blog  mb-5">
                        <h3 className="mb-3 text-left">Author Name :</h3>
                        <input type="text" class="form-control " name="About" />
                    </div>
                    <div className="row col-lg-12 col-xs-12 col-md-12 about_blog mx-auto mb-5">
                        <h3 className="mb-3 text-left"> Blog Title :</h3>
                        <input type="text" class="form-control " name="About" />
                    </div>
                    <div className="row col-lg-12 col-xs-12 col-md-12 about_blog mx-auto mb-5">
                        <h3 className="mb-3 text-left">  Description :</h3>
                        <input type="text" class="form-control custom-disc" name="About" />
                    </div>
                    <div className="col-md-12 mx-auto  text-center mb-5">
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName mx-auto"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.onEditorStateChange}
                            toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                image: { uploadCallback: this._uploadImageCallBack },
                                inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel'
                            }}
                        />
                    </div>

                   
                    <div className="row col-lg-12 col-xs-12 col-md-12 about_blog mx-auto mb-2">
                        <h3 className="mb-3">Tags</h3>
                        <input type="text" class="form-control " name="About" />
                    </div>
                </div>



                <div class="foot justify-content-end">
                    <div class="container text-right">
                        <button class="btn btn-orange">Reset</button>
                        <button class="btn btn-orange">Post Blog</button>
                    </div>
                </div>

                <Footer />
            </div>




        )
    }
}

export default ImageUploading


// https://www.npmjs.com/package/image-upload-react
// https://www.npmjs.com/package/react-draft-wysiwyg
//https://stackoverflow.com/questions/55417190/issue-in-showing-file-upload-for-image-in-draft-js