import React, { Component, useState } from 'react';
import { ReactVideo } from 'react-player';

function Videoapp(props) {
    
    const [player ,setPlayer]=useState({})
 
    const [state ,setState]=useState({
    video: {
        src: props.video,
        poster: "http://www.example.com/path/to/video_poster.jpg"
    }
    })
 
    const onPlayerReady=(players)=>{
        console.log("Player is ready: ", player);
        setPlayer(players);
    }
 
    // onVideoPlay(duration){
    //     console.log("Video played at: ", duration);
    // }
 
    // onVideoPause(duration){
    //     console.log("Video paused at: ", duration);
    // }
 
    // onVideoTimeUpdate(duration){
    //     console.log("Time updated: ", duration);
    // }
 
    // onVideoSeeking(duration){
    //     console.log("Video seeking: ", duration);
    // }
 
    // onVideoSeeked(from, to){
    //     console.log(`Video seeked from ${from} to ${to}`);
    // }
 
    // onVideoEnd(){
    //     console.log("Video ended");
    // }
 
    return (
        <div>
           <ReactVideo
           className="player"
           url={state.video.src}
           width="100%"
           height="100%"
           playing={true}
           muted={true}
           />
             {/*
            <ReactVideo
            src={state.video.src}
            poster={state.video.poster}
            primaryColor="red"
            autoPlay
           />
            */}
            {/* <VideoPlayer
                controls={true}
                src={state.video.src}
                poster={state.video.poster}
                width="720"
                height="420"
                onReady={onPlayerReady}
                // onPlay={this.onVideoPlay.bind(this)}
                // onPause={this.onVideoPause.bind(this)}
                // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                // onSeeking={this.onVideoSeeking.bind(this)}
                // onSeeked={this.onVideoSeeked.bind(this)}
                // onEnd={this.onVideoEnd.bind(this)}
            /> */}
        </div>
    );
}
export default Videoapp;