import React, { Component } from 'react';
import './gas-safety.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import gasSafetyServImg1 from '../../../../assets/images/services-page/7-gas-safety/image-1.png';
import gasSafetyServImg2 from '../../../../assets/images/services-page/7-gas-safety/image-2.png';
import gasSafetyServImg3 from '../../../../assets/images/services-page/7-gas-safety/image-3.png';
import gasSafetyServImg4 from '../../../../assets/images/services-page/7-gas-safety/image-4.png';
import gasSafetyServImg5 from '../../../../assets/images/services-page/7-gas-safety/image-5.png';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { scrollTop } from '../../common/common';
import { Helmet } from "react-helmet";
class GasSafety extends Component {

    componentDidMount() {
        scrollTop();
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Gas Safety Checking, Advice, Certifacate, and Guidelines | ShiftEasy </title>
                    <meta name="description" content="Gas appliances can produce the carbon monoxide, Thus you should also focus on Gas safety checking, Advice, Certificate, and guidelines. We all know Prevention is better than cure." />
                    <meta name="keywords" content="Gas Safety Checking, Gas Safety Advice, Gas Safety Certidacte, and Gas Safety Guidelines" />
                </Helmet>
                <Header />
                <div className="service-banner serv-seven page-top-banner banner-with-form mb-3 mb-lg-5">
                    <div className="service-banner-content text-center">
                        <h2 className="text-white mb-3">Gas<span className="text-orange"> Safety</span></h2>
                        <h5>GET YOUR APPLIANCES CHECKED: KEEP YOUR FAMILY SAFE</h5>
                    </div>
                    <div className="banner-form">
                        <form>
                            <div className="mb-3">
                                <input type="text" className="form-control br-0" placeholder="Full Name" />
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control br-0" placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Phone number" />
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control br-0" rows="4" placeholder="Message"></textarea>
                            </div>
                            <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                        </form>
                    </div>
                </div>
                <div className="container mx-auto">
                    <div className="row my-4 align-items-center">
                        <div className="col-md-7">
                            <div className="service-content-box">
                                <h5 className="page-title">About Gas Appliances</h5>
                                <p>Gas appliances, such as ovens, cookers, and boilers, are not correctly installed and maintained; fire, explosion, gas leaks, and carbon monoxide (CO) poisoning are dangerous. Business and property companies need to fulfil the appropriate regulations to help ensure worker and public safety. Apartments built in cramped quarters are susceptible to Caron Monoxide risks due to lack of ventilation. Lack of maintenance of regular gas pipelines increases the risk of fire in residential and commercial areas.</p>
                            </div>
                        </div>
                        <div className="col-md-5 p-5">
                            <img src={gasSafetyServImg1} className="img-fluid" alt="icon" />
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col-md-12">
                            <div className="service-content-box pb-0">
                                <h5 className="page-title">Shifteasy Gas safety service?</h5>
                                <p className="text-justify">Shifteasy partner and franchise provide Gas safety services; this includes cleaning parts of the
                                    appliance and examining how the gas is burning. Shifteasy safety check package includes annual boiler
                                    services and half-yearly boiler services.<span className="h6"> Shifteasy gas safety engineers</span> will do these services; they
                                    are legally allowed to work on the gas heating system, boilers, cookers, and other gas appliances.</p>

                            </div>
                        </div>
                    </div>
                    <div id="swap" className="row my-4 justify-content-end">
                        <div className="col-md-6">
                            <div className=" text-left">
                                <h5 className="page-title ml-0">Which checks are made?</h5>
                                <p className="ml-0">There are different types of gas safety checks.</p>
                                <h6 className="ml-0">An Appliance Safety Check includes checking.</h6>
                                <ul className="ml-4" >
                                    <li>The operating pressure and heat input of the appliance</li>
                                    <li>Any flue or chimney connected to the appliance is working properly.</li>
                                    <li>The appliance is suitable for the room.</li>

                                    <li>The appliance is suitable for the room.</li>
                                    <li>The appliance is properly fitted and connected to the gas pipes.</li>
                                    <li>There is an adequate, permanent air supply.</li>
                                </ul>
                                <h6 className="ml-0">A Gas Equipment Safety Check includes all the above appliance checks.</h6>
                                <ul className="ml-4">
                                    <li>All accessible gas pipework is inspected to check it is in good condition.</li>
                                    <li>The gas pipework is tested for leaks.</li>
                                </ul>
                                <p className="ml-4 ">Subsequently, the engineer may give a report showing the checks they have conducted out. The only paper
                                    engineers must provide by law is a Gas Safety Record for Owners.</p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src={gasSafetyServImg4} className="img-fluid serv-svg mb-5" alt="icon" />
                            <img src={gasSafetyServImg2} className="img-fluid serv-svg" alt="icon" />
                        </div>
                    </div>
                    <div className="row my-4 align-items-center">
                        <div className="col-md-6">
                            <img src={gasSafetyServImg3} className="img-fluid p-5" alt="icon" />
                        </div>
                        <div className="col-md-6">
                            <div className="service-content-box">
                                <h5 className="page-title">Gas safety check advice for owners</h5>
                                <p>It is a legal requirement to have a valid Energy Performance Certificate (EPC) when a building is sold, rented, or constructed.
                                    The property's EPC needs to be available to potential buyers as soon as they market the property for sale or rent. It will
                                    inform people how energy efficient the property is, including any potential savings on fuel payments. ShiftEasy partners help
                                    you get an approved /certified Domestic Energy Assessor to produce the EPC, providing information about a property's energy use
                                    and average energy costs. Failure to have a valid EPC may result in a penalty, although there are exemptions, e.g.,
                                    listed properties. Shifteasy notifies to renew the EPC before the terminated period.</p>
                            </div>
                        </div>
                    </div>
                    <div id="invol" className="row my-4 align-items-center">
                        <div className="col-md-6 col-xs-12">
                            <div className="pb-0">
                                <h5 className="page-title ml-4">What is involved?</h5>
                                <p className="ml-4">While requiring an EPC, you will need a certified energy assessor to conduct an energy assessment. Shifteasy
                                    Energy assessor will access all apartments as well as the roof space. They examine heating systems and controls,
                                    take measurements, and take photographs of all essential information included in the analysis. ShiftEasy Energy
                                    assessors input all the information on-site or at home to create an Energy Performance Certificate accommodated
                                    to the main register.</p>
                                <h6 className="ml-4">According to law, certain Homes do not need EPC.</h6>
                                <ul className="ml-5">
                                    <li>Temporary buildings that will be used for less than two years.</li>
                                    <li>Stand-alone building with a total useful floor space of less than 50m.</li>
                                    <li>Industrial sites, workshops and non-residential agricultural buildings that do not use a lot of energy.</li>
                                    <li>Some buildings that are due to be demolished</li>
                                    <li>Holiday accommodation is rented out for less than four months per year or is let under a licence to occupy.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={gasSafetyServImg5} className="img-fluid p-5" alt="icon" />
                        </div>
                    </div>
                </div>
                {/* </div> */}
                <Footer />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(GasSafety));