import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Helmet } from "react-helmet";
import "./contactus.css"
import svg1 from "../../../assets/images/footer/a.svg";
import svg2 from "../../../assets/images/footer/b.svg";
import svg3 from "../../../assets/images/footer/c.svg";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {saveContactUs} from '../../../services/mutations/mutation-API'
import $ from 'jquery';
import { toast } from 'react-toastify';



//  const validate =(values)=>{
//     var errors = {};
//     if(!values.name){
//         errors.name="Name is Required"
//     }
//     if(!values.email){
//         errors.email="Email Is Required"
//     }
//     else if (!values.title){
//         errors.title="Enter The Title"
//     }else if(!values.message){
//         errors.message="Please Write The Message"
//     }else{
//         console.log(errors)
//     }
//    return errors;
// }


const ContactUs = () => {

    const formik = useFormik({
        initialValues: {
            firstName: '',
            email: '',
            lastName: '',
            message: '',
            userFrom:"Shifteasy"

        },
        //    validate,
        validationSchema: Yup.object({
            firstName: Yup.string()
                // .max(15, 'Must be 15 characters or less')
                .required('Enter Your Name'),
            email: Yup.string().email('Invalid email address').required('Enter Valid Email'),
            lastName: Yup.string()
                // .max(20, 'Must be 20 characters or less')
                .required('Title'),
            message: Yup.string().required("Enter Your Message")
                .max(100, 'Must be 20 characters or less')
        }),
        onSubmit: (value) => {
            
            saveContactUs(value).then((res)=>{
                $('.modal').modal('hide')
                toast.success("Message sent successfull", {
                     position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
             });
            console.log(res)
        }).catch((error)=>{
            toast.error(" failed to Send Message", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
                console.log(error)
            })
            
        }

    })

   
  React.useEffect(()=>{
      window.scrollTo(120 ,120)
  })



    return (

        <div>
            <Helmet>
                <title>Gas Safety Checking, Advice, Certifacate, and Guidelines | ShiftEasy </title>
                <meta name="description" content="Gas appliances can produce the carbon monoxide, Thus you should also focus on Gas safety checking, Advice, Certificate, and guidelines. We all know Prevention is better than cure." />
                <meta name="keywords" content="Gas Safety Checking, Gas Safety Advice, Gas Safety Certidacte, and Gas Safety Guidelines" />
            </Helmet>
            <div class="header">
                <Header />
            </div>
            <div style={{ backgroundColor: "#11698bf2" }} className="  container-fluid mx-auto">
                <h3 className="text-center text-white pt-5 pb-3 potision-relative  ">Contact Us</h3>
                <div class="d-flex row justify-content-center w-100 mx-auto p-lg-5">
                    <div class=" col-md-10 col-lg-3 col-xs-12 col-sm-10 bg-white  mb-5 p-lg-4  br-5  ">
                        <div className="row mt-md-5 mt-3">
                            <div className="col-3 d-flex justify-content-end h-25 p-0">
                                <img src={svg1} class="img-fluid " alt="shifteassy contact us" />
                            </div>
                            <div className="col-9 ">
                                <h5>Location</h5>
                                <p>EPK Business Centre No; 624, 4th Floor, Anna Salai, Chennai-600006</p>
                            </div>
                        </div>
                        <div className=" row mt-md-4">
                            <div className="col-3 d-flex justify-content-end h-25 p-0">
                                <img src={svg3} class="img-fluid mr-1" alt="shifteassy contact us" />
                            </div>
                            <div className="col-9">
                                <h5>Customer Care</h5>
                                <p>+61 3 8376 6284<br /> +800 2345 6789</p>
                            </div>
                        </div>
                        <div className=" row mt-md-4">
                            <div className="col-3 d-flex justify-content-end h-25 p-0">
                                <img src={svg2} class="img-fluid " alt="shifteassy contact us" />
                            </div>
                            <div className="col-9">
                                <h5>Email Us</h5>
                                <p>info@shifteasy.com support@shifteasy.com</p>
                            </div>
                        </div>
                    </div>
                    <div class=" row  bg-white col-md-5 ml-lg-4 mb-5  p-lg-3 br-5 col-md-10 col-lg-6 col-xs-12 col-sm-10">
                        <div className="container contact_input_height ">
                            <h4 className="pt-4">Get In Touch</h4>
                            <p class="text-dark">Feel free to drop us a line below!</p>
                            <div class="flex_fields justify-content-between mb-4">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='row'>
                                        <div class="col-md-6">
                                            <input type="text"
                                                class={formik.errors.firstName ? "form-control mb-4 is-invalid bg-light custom_border" : `form-control mb-4 bg-light  ${formik.values.firstName === "" ? null : "is-valid is_valid_true"}`}
                                                id="firstName"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="firstName"
                                                value={formik.values.firstName}
                                                placeholder={!formik.values.firstName ? " First name" : formik.errors.firstName} />
                                        </div>
                                        <div className='col-md-6'>
                                        <input type="text"
                                                class={formik.errors.lastName ? "form-control mb-4 is-invalid bg-light custom_border" : `form-control mb-4 bg-light  ${formik.values.lastName === "" ? null : "is-valid is_valid_true"}`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="lastName"
                                                id='lastName'
                                                value={formik.values.lastName}
                                                placeholder={!formik.values.lastName ? " Last name" : formik.errors.lastName} />
                                           
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className='col-md-12'>
                                        <input type="text"
                                                class={formik.errors.email ? "form-control mb-4 is-invalid bg-light custom_border" : `form-control mb-4 bg-light  ${formik.values.email === "" ? null : "is-valid is_valid_true"}`}
                                                onChange={formik.handleChange}
                                                name="email"
                                                id='email'
                                                value={formik.values.email}
                                                onBlur={formik.handleBlur}
                                                placeholder="Email" />
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-12">                                 <textarea
                                            class={formik.errors.message ? "form-control mb-4 is-invalid bg-light custom_border h-100" : `form-control mb-4 h-100 bg-light  ${formik.values.message === "" ? null : "is-valid is_valid_true"}`}
                                            placeholder="Message"
                                            name="message"
                                            id='message'
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            rows="3"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-xs-12"> <button type='submit' className="btn btn-orange float-right mb-4 mt-2">Send</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* </div> */}
            <div className="footer">
                <Footer />
            </div>
        </div>


    )

}


export default ContactUs