import React, { Component } from 'react';
import './doctors-near-you.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import {scrollTop} from '../../common/common';
import ItemsCarousel from 'react-items-carousel';
import leftarrow from '../../../../assets/images/leftarrow.png';
import rightarrow from '../../../../assets/images/rightarrow.png';
import starNotFilled from '../../../../assets/images/svg-icons/star-notfilled.svg';
import starFilled from '../../../../assets/images/svg-icons/star-filled.svg';
import tagsWhite from '../../../../assets/images/services-page/4-doctors-near-you/tags-w.svg';
import experianceWhite from '../../../../assets/images/services-page/4-doctors-near-you/experience-w.svg';
import phone from '../../../../assets/images/services-page/4-doctors-near-you/phone.svg';
import tags from '../../../../assets/images/services-page/4-doctors-near-you/tags.svg';
import experiance from '../../../../assets/images/services-page/4-doctors-near-you/experiance.svg';
import locationSmall from '../../../../assets/images/svg-icons/location-small.svg';
import Rec1 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-1.png';
import Rec2 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-2.png';
import Rec3 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-3.png';
import Rec4 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-4.png';
import Rec5 from '../../../../assets/images/services-page/4-doctors-near-you/rectangle-5.png';
import verifiedUser from '../../../../assets/images/check_circle_orange.svg';
import contactPersonImage from '../../../../assets/images/userimage.png';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

class DoctorsNearYouDetailed extends Component {

    constructor (props) {
        super (props); 
        this.state= {
          otherDoctorsItemIndex: 0,
          width: 0,
          height:0
          
        }
      
    }

    otherDoctorsActiveItem = (otherDoctorsItemIndex) => {
        this.setState({ otherDoctorsItemIndex });
    }

    componentDidMount(){
        scrollTop();
        window.addEventListener("resize", this.diviceSizeUpdate);
     }

     diviceSizeUpdate = () => {
        this.setState({
          width: window.innerWidth
        },()=>{});
      };
    render() {
        console.log("width",this.state.width)
        return (
            <div>
                <Header/>
                    <div className="page-top-banner service-banner mb-3 mb-lg-5 serv-four-detailed">
                        <h1 className="text-center text-white text-shadow mb-3"><span className="text-orange">Christopher's Fertility Clinic </span></h1>
                        <h5 className="text-center text-white text-shadow mb-3"><a href="www.christopher.com" className="text-white">www.christopher.com</a>&nbsp;&nbsp;&nbsp;<a href="mailto:email.com" className="text-white">Email Doctor</a>&nbsp;&nbsp;&nbsp;<a href="/#" className="text-white">Chennai Based</a></h5>
                        <div className="text-center d-flex align-items-center flex-column">
                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                <div className="mx-3">
                                    <img src={tagsWhite} className="mr-1"/>&nbsp;
                                    <span className="fs-14 text-white">Fertility</span>
                                </div>
                                <div className="mx-3">
                                    <img src={experianceWhite} className="mr-1"/>&nbsp;
                                    <span className="fs-14 text-white">10 year Experiance</span>
                                </div>
                            </div>
                            <div className="mb-1 mob-lh-12 d-flex align-items-center flex-wrap flex-lg-nowrap">
                                <span className="fs-14 mr-2 text-white">4.5</span>
                                <span className="add-rating">
                                    <img src={starFilled} className="mr-1" />
                                    <img src={starFilled} className="mr-1" />
                                    <img src={starFilled} className="mr-1" />
                                    <img src={starFilled} className="mr-1" />
                                    <img src={starNotFilled} className="mr-1" />
                                </span>&nbsp;&nbsp;
                                <span className="fs-14 text-white">(100+ Users)</span>
                            </div>
                        </div>
                        <div className="text-center my-4">
                            <button className="btn btn-orange px-md-5" data-toggle="modal" data-target="#bookAppointment">Book Appointment</button>
                        </div>
                    </div>
                    <div className="container-fluid px-2 px-lg-5 pb-3">
                        <div className="container pt-2">
                            <div className="row">
                                <div className="col-12 col-lg-8 px-2">
                                    <div className="search-result-card p-3">
                                        <h5 className="page-sub-title font-weight-bold">About Christopher's Fertility Clinic</h5>
                                        <p className="text-dark fs-14 mb-0">Andrews began in the property business in 1946, and we’ve been operating in the Morden area since 1948. The Andrews philosophy is all about making life easier for you and we’re here to guide you through every step of your property journey. We are a full service agency, sharing knowledge and expertise across our whole branch network in order to create exceptional customer service. Whether you are selling or letting a property or looking for advice on mortgages, conveyancing or surveys, our team are able to help you through the whole process with confidence. We also care about giving back by creating opportunities and opening doors. Find out about how we try to benefit the society we operate in by visiting our site.</p>
                                    </div>
                                    <div className="search-result-card p-3">
                                        <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
                                            <h5 className="page-sub-title font-weight-bold">Reviews</h5>
                                            <div className="dropdown ml-3">
                                                <button className="btn fs-14 shadow-searchpg bg-white dropdown-toggle min-wid-200px d-flex justify-content-between align-items-center sort-btn" type="button" id="srtbtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sort by</button>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="srtbtn">
                                                    <span className="dropdown-item" href="/">Name (A-Z)</span>
                                                    <span className="dropdown-item" href="/">Name (Z-A)</span>
                                                    <span className="dropdown-item" href="/">Location (A-Z)</span>
                                                    <span className="dropdown-item" href="/">Location (Z-A)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-4 align-items-center">
                                            <span className="rating-avg mr-2">4.5</span>
                                            <span>
                                                <span className="text-black-50 mr-2">out of 5 based on</span>
                                                <span className="text-orange">(800 reviews)</span>
                                            </span>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 mb-3">
                                                    <input className="form-control" type="text" placeholder="Title" />
                                                </div>
                                            </div>
                                            <span className="d-flex align-items-center mb-3">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>
                                            <form className="clearfix">
                                                <textarea className="form-control mb-3"></textarea>
                                                <button className="btn btn-orange mb-4 float-right">Submit</button>
                                            </form>
                                        </div>
                                        <div>
                                            <div className="review-card">
                                                <h6>Plumbing</h6>
                                                <div className="mb-2 d-flex align-items-center">
                                                    <img src={verifiedUser} alt="Verified User" />
                                                    <span className="text-dark ml-1 mr-3">Abhishek</span>
                                                    <span className="d-flex align-items-center">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>
                                                </div>
                                                <p className="fs-13 mb-1">
                                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. 
                                                </p>
                                            </div>
                                            <div className="review-card">
                                                <h6>Plumbing</h6>
                                                <div className="mb-2 d-flex align-items-center">
                                                    <img src={verifiedUser} alt="Verified User" />
                                                    <span className="text-dark ml-1 mr-3">Abhishek</span>
                                                    <span className="d-flex align-items-center">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>
                                                </div>
                                                <p className="fs-13 mb-1">
                                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. 
                                                </p>
                                            </div>
                                            <div className="review-card">
                                                <h6>Plumbing</h6>
                                                <div className="mb-2 d-flex align-items-center">
                                                    <img src={verifiedUser} alt="Verified User" />
                                                    <span className="text-dark ml-1 mr-3">Abhishek</span>
                                                    <span className="d-flex align-items-center">
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starFilled} className="mr-1" />
                                                        <img src={starNotFilled} className="mr-1" />
                                                    </span>
                                                </div>
                                                <p className="fs-13 mb-1">
                                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. 
                                                </p>
                                            </div>
                                        </div>  
                                    </div> 
                                </div>
                                <div className="col-12 col-lg-4 px-2">
                                    <div className="search-result-card p-3">
                                        <h5 className="page-sub-title font-weight-bold">Contact details</h5>
                                        <div className="d-flex justify-content-center mt-3">
                                            <div className="contact-dtl">
                                                <div className="form-group">
                                                    <label className="font-weight-bold mb-0">Name:</label>
                                                    <div>Dr.John Smith</div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="font-weight-bold mb-0">Email: </label>
                                                    <div>abhishek1234@gmail.com</div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="font-weight-bold mb-0">Phone No: </label>
                                                    <div>+44 20 3463 2681</div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="font-weight-bold mb-0">Address:</label>
                                                    <div>4Th Main Road, Besant Nagar, Chennai- 600090, Opp Anna School (Map)</div>
                                                </div>
                                            </div>
                                            <div className="contact-person">
                                                <img src={contactPersonImage} alt="Contact Person Image"/>
                                            </div>
                                        </div>
                                        <button className="btn btn-orange px-md-4 mt-2">Enquire Now</button>
                                    </div>
                                    <div className="search-result-card p-3">
                                        <h5 className="page-sub-title font-weight-bold">Doctors Clinic</h5>
                                        <div id="map">
                                            <div  id="map-container-google-11">
                                                <iframe className="w-100 br-10" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJB5gIVWFdUjoRKdj3Nxxq6-g&key=AIzaSyA1X9MSi_O-dUs4kUu_I1dY_lXHOeZbOk0"  frameBorder="0" allowFullScreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search-result-card p-3">
                                        <h5 className="page-sub-title font-weight-bold">Opening Hours</h5>
                                        <ul className="open-hrs-li">
                                            <li><span className="li-icon-box"><i className="li-icon"></i></span><span>Monday</span><span>9.00am - 6.00pm</span></li>
                                            <li><span className="li-icon-box"><i className="li-icon"></i></span><span>Tuesday</span><span>9.00am - 6.00pm</span></li>
                                            <li><span className="li-icon-box"><i className="li-icon"></i></span><span>Wednesday</span><span>9.00am - 6.00pm</span></li>
                                            <li><span className="li-icon-box"><i className="li-icon"></i></span><span>Thursday</span><span>9.00am - 6.00pm</span></li>
                                            <li><span className="li-icon-box"><i className="li-icon"></i></span><span>Friday</span><span>9.00am - 6.00pm</span></li>
                                            <li><span className="li-icon-box"><i className="li-icon"></i></span><span>Saturday</span><span>9.00am - 6.00pm</span></li>
                                            <li><span className="li-icon-box"><i className="li-icon"></i></span><span>Sunday</span><span>9.00am - 6.00pm</span></li>
                                        </ul>
                                    </div>
                                    <div className="search-result-card p-3">
                                        <h5 className="page-sub-title font-weight-bold">Website</h5>
                                        <a href="/#" className="text-blue">Visit Andrews - Morden's website</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid px-4 pb-3">
                        <div className="px-4 corner-nav pt-4">
                            <h5 className="text-center page-title mb-4">Other Suggested Doctors</h5>
                            <ItemsCarousel
                                 requestToChangeActive={this.otherDoctorsActiveItem}
                                 activeItemIndex={this.state.otherDoctorsItemIndex}
                                 numberOfCards={ window.innerWidth>550?4:1}
                                 gutter={20}
                                 leftChevron={<img src={leftarrow} alt="Left Arrow" style ={{width:'40px'}} className="carosel-control-left"/>}
                                 rightChevron={<img src={rightarrow} alt="Right Arrow" style ={{width:'40px'}} className="carosel-control-right" />}
                                 outsideChevron
                                 chevronWidth={40} >
                                <div className="cus-card-item">
                                    <img src={Rec1} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Dipanshu Clinic</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Child Specialist</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={Rec2} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Christopher's Fertility Clinic</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Gynecologist</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={Rec3} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Children's Clinic</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Child Specialist</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={Rec4} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Gynaecologist Clinic</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Gynaecologist</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                                <div className="cus-card-item">
                                    <img src={Rec5} alt="Card image" className="img-fluid cus-card-image"/>
                                    <div className="d-flex flex-column p-2">
                                        <div className="mob-lh-12 my-1">
                                            <h6 className="text-dark d-inline fs-15 font-weight-bold">Dipanshu Clinic</h6>
                                        </div>
                                        <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                            <span className="fs-14 mr-2">4.5</span>
                                            <span className="add-rating">
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starFilled} className="mr-1" />
                                                <img src={starNotFilled} className="mr-1" />
                                            </span>&nbsp;&nbsp;
                                            <span className="fs-14">(100+ Users)</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={phone} className="mr-1"/>&nbsp;
                                            <span className="fs-14">+91 994052370, +91 994052370</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={tags} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Child Specialist</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={experiance} className="mr-1"/>&nbsp;
                                            <span className="fs-14">10 year Experiance</span>
                                        </div>
                                        <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                            <img src={locationSmall} className="mr-1"/>&nbsp;
                                            <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                    </div>
                                </div>
                            </ItemsCarousel>
                        </div>
                    </div>
                    <div className="container-fluid px-2 px-lg-5 pb-3">               
                        <div className="container pt-5">
                            <div className="row">
                                <div className="col-12 col-lg-10 offset-lg-1">
                                    <div className="search-result-card p-3">
                                        <h5 className="page-sub-title font-weight-bold">FAQ</h5>
                                        <ol className="pl-3 pt-2 mb-0">
                                            <li>
                                                <h6>What about the new materials or fixtures that may be needed? </h6>
                                                <p className="text-dark fs-14">It totally lies at the client’s discretion. You can yourself get the material according to your choice or ask the professional to procure the same. The time taken by the professional to get the material will also be included in the working hours. </p>
                                            </li>
                                            <li>
                                                <h6>What if no services are availed, how much will I be entitled to pay? </h6>
                                                <p className="text-dark fs-14">If in case no services were availed, you just need to pay the professional a visiting charge.</p>
                                            </li>
                                            <li>
                                                <h6>Is there any guarantee on the service provided? </h6>
                                                <p className="text-dark fs-14">Yes, a 30-day guarantee is provided on all plumbing services.</p>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="search-result-card p-3">
                                        <h5 className="page-sub-title font-weight-bold mb-3">About Doctor Services</h5>
                                        <h6>Doctor Services</h6>
                                        <p className="text-dark fs-14">
                                        A well-functioning household is essential for effectiveness in life. Kitchens and bathrooms are an essential fragment of a house. Any kind of obstruction, such as a clogged pipe or running toilet, can bring your entire schedule to a standstill, while at the same time disrupting your normalcy of everyday life. Given that materials such as pipes and cisterns, which are used in building your living space, come with an expiry date, you need to keep them updated and in a working condition. Some materials or machines require constant maintenance while others require replacements and fixes. It could be a leaking tap that you might be ignoring or a condition that needs to be dealt with immediately, such as a blockage in the toilet trap.
                                        </p>
                                        <h6>You can also go through the same Plumbing Services in Chennai in other cities as well:</h6>
                                        <ul className="pl-0 mb-0 list-unstyled text-dark fs-14">
                                            <li>Best Plumbing services in Delhi</li>
                                            <li>Best Plumbing services in Mumbai</li>
                                            <li>Best Plumbing services in Kolkata</li>
                                            <li>Best Plumbing services in Pune</li>
                                            <li>Best Plumbing services in Delhi</li>
                                            <li>Best Plumbing services in Hydrabad</li>
                                            <li>Best Plumbing services in Delhi</li>
                                            <li>Best Plumbing services in Mumbai</li>
                                            <li>Best Plumbing services in Kolkata</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                     {/* Modal */}
                     <div className="modal fade" id="bookAppointment" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="bookAppointmentLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="clearfix">
                                        <button type="button" className="close float-right" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="banner-form py-4">
                                                <h5 className="page-sub-title font-weight-bold mb-3">Book Appointment</h5>
                                                <form>
                                                    <div className="mb-3">
                                                        <input type="text" className="form-control" placeholder="Patient Name"/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <input type="email" className="form-control" placeholder="Email Address"/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <input type="number" className="form-control" placeholder="Contact Number"/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <select className="form-control">
                                                            <option selected disabled>Select Category</option>
                                                            <option>Category 1</option>
                                                            <option>Category 2</option>
                                                            <option>Category 3</option>
                                                            <option>Category 4</option>
                                                        </select>
                                                    </div>
                                                    <h6 className="text-black-50 pt-2 mb-3 fs-15">Viewing Date & Time</h6>
                                                    <div className="form-row mb-3">
                                                        <div className="form-group col-6">
                                                            <p className="postprop-label">Date</p>
                                                            <input className="form-control bg-white" type="date"/>
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <p className="postprop-label">Time</p>
                                                            <input className="form-control bg-white" type="time"/>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-5 border-md-left">
                                            <div className="d-flex justify-content-center align-items-center flex-column m-3 h-100">
                                                <img src={contactPersonImage} alt="Image" className="mb-3" width="150px"/>
                                                <h5>Doctor Name</h5>
                                                <p>Designation</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer bg-dark-blue">
                                    <button type="button" className="btn btn-secondary px-md-4" data-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-orange px-md-4 ml-3">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer/>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {

    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };
  
  export default connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(DoctorsNearYouDetailed));