import React,{Component} from 'react';
import './terms-conditions-affiliate.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

class termsConditionsAffiliate extends Component {

    render(){
        return(
            <div className="docs-pg">
                <Header/>
                    <div className="header-space">
                        <div className="docs-pg-title">
                            ShiftEasy™ Affiliate Terms and Conditions
                        </div>
                        <div className="container py-5 docs-pg-content">
                            <h5 className="mb-5">The following are the terms and conditions (this "Agreement" or the "Terms") for affiliates that direct potential customers to any ShiftEasy™ web site (the "Site") and where ShiftEasy™ has agreed to pay that affiliate ("you") for directing those potential customers to the Site.</h5>
                            <ol className="mb-4 pl-3 pl-md-0">
                                <li>
                                    <h6>Acceptance</h6>
                                    <p>By submitting your application for being an affiliate of the Site, you agree to be bound by the Terms as stated herein without modification. </p>
                                </li>
                                <li>
                                    <h6>Nature of the Relationship</h6>
                                    <p>The nature of the relationship between ShiftEasy™ and you will be that of independent contractors for all purposes and in no event will any person employed by you be held or construed to be employees of ShiftEasy™. Specifically, ShiftEasy™ is not a member of any partnership, joint venture or franchise arrangement with you. </p>
                                </li>
                                <li>
                                    <h6>Ownership and use of ShiftEasy™ Materials</h6>
                                    <p>"ShiftEasy™ Materials" include any legal materials, documents, contracts, software, products, web links, promotional material, email, information, partial ShiftEasy™ Material or any other materials provided by ShiftEasy™, the Site or any ShiftEasy™ service in any way. ShiftEasy™ retains complete ownership and all rights to the ShiftEasy™ Materials. You do not have the right to use or change any of the ShiftEasy™ Materials without prior written consent of ShiftEasy™. You do not have the right to use any ShiftEasy™ Materials for third party or competitive use. All use of ShiftEasy™ Materials, with or without prior written consent, will cease after termination of this agreement. </p>
                                </li>
                                <li>
                                    <h6>Term</h6>
                                    <p>This Agreement will be for an indefinite term and can be terminated immediately without cause by either ShiftEasy™ or you providing notice to the other party. Upon termination, you will stop using ShiftEasy™ Materials and you will discontinue any use of the ShiftEasy™ trademark. </p>
                                </li>
                                <li>
                                    <h6>Your Compensation</h6>
                                    <p>ShiftEasy™ agrees to pay you a commission of thirty percent (10%) of all sales (excluding fees paid to external service providers, fees paid to external suppliers and some subscription renewals) generated from the traffic of your site. This traffic will be given a tracking cookie provided the person has not previously visited our site and has an existing tracking cookie from another affiliate. You will get credit for such sales during the life of the tracking cookie. In lieu of a commission (or in lieu of part of it), you can provide a percent discount off applicable ShiftEasy™ products (discounts are not applicable to fees paid to external service providers, fees paid to external suppliers, and some subscription renewals) for visitors from your site. The total of the commission and discount will not be more than the thirty percent. Payments are calculated on the first business day of the month and payment will be sent within five business days when commissions payable total £50 or more. You will have access to sales and commission reports available through the ShiftEasy™ Affiliate Program. We will not provide any compensation for any referrals or traffic where the provision of any compensation for a referral is prohibited by law.</p>
                                </li>
                                <li>
                                    <h6>Content Development</h6>
                                    <p>You are solely responsible for all content development (and its cost) on your web site.</p>
                                </li>
                                <li>
                                    <h6>Confidentiality</h6>
                                    <p>You may be given or obtain access to non-public information of ShiftEasy™ that ShiftEasy™ considers to be of a confidential, proprietary, or trade secret nature, including, but not limited to, customer information, pricing, financial and operational information, business information and marketing information, in whatever form or media, whether or not marked as confidential (collectively "Confidential Information"). You agree not to use any Confidential Information disclosed to you by ShiftEasy™ for your own use or for any purpose other than to carry out your obligations under this Agreement. You will not disclose any Confidential Information to third-parties or to your employees or agents, other than employees and agents who are required to have the information in order to carry out your obligations under this Agreement. You agree to notify ShiftEasy™ immediately and in writing of any misuse or misappropriation of ShiftEasy™'s Confidential Information, which may come to your attention and to return ShiftEasy™'s Confidential Information upon the request of ShiftEasy™. Confidential Information shall not apply to: (a) information that is or becomes a matter of public knowledge through no fault of or action by you, (b) information that prior to disclosure was rightfully in your possession as a result of disclosure by a third-party under no obligation or restriction of confidentiality, (c) information that, subsequent to disclosure, is rightfully obtained by you from a third-party under no obligation or restriction of confidentiality, and (d) information that is independently developed by you without use, knowledge or access to the Confidential Information of ShiftEasy™.</p>
                                </li>
                                <li>
                                    <h6>Return of Confidential Property</h6>
                                    <p>Upon ShiftEasy™'s request (and upon termination of this Agreement), you will deliver to ShiftEasy™ all Confidential Information, memoranda, notes, records, drawings, manuals, disks, or other documents and media pertaining to ShiftEasy™'s business including all copies, extracts, summaries and analyses. </p>
                                </li>
                                <li>
                                    <h6>Indemnification</h6>
                                    <p>You agree to indemnify and hold ShiftEasy™, its directors, officers, shareholders, successor and predecessor companies, attorneys, agents and employees harmless from any claim, demand, or damage, including reasonable attorneys' fees and costs, asserted by any third party due to or arising out of the your actions (including but not limited to advertising, web site content or how you otherwise direct potential customers to our site). </p>
                                </li>
                                <li>
                                    <h6>Limitations of damages</h6>
                                    <p>ShiftEasy™ will not be liable to you for any exemplary, punitive, special or consequential damages, including lost revenues, lost profits or lost prospective economic advantage arising from any act or omission in performance or failure to perform under this Agreement, even if ShiftEasy™ is at fault and/or knew or should have known of the possibility thereof, and you hereby release and waive any claims against ShiftEasy™ regarding such damages. </p>
                                </li>
                                <li>
                                    <h6>Trademarks and other Intellectual Property</h6>
                                    <p>You acknowledge that all rights in any registered trademarks or any pending trademark registrations associated with the business of ShiftEasy™ (i.e., trademarks, service marks, slogans, logos, designs and other similar means of distinction), including all goodwill pertaining thereto, shall be the sole property of ShiftEasy™. You may use and display such trademarks only in the manner and for the purpose authorised by ShiftEasy™, and only during the Term of this Agreement. ShiftEasy™ reserves the right to add to, change or discontinue the use of any trademark it owns, on a selective or general basis, at any time. You shall not use any trademark or trade name of ShiftEasy™ in any corporate, partnership or business name without ShiftEasy™'s prior written consent. Yudox Inc. is the owner of the ShiftEasy™ trade name, brand and trademark. </p>
                                </li>
                                <li>
                                    <h6>Media</h6>
                                    <p>No press releases mentioning your affiliate program participation may be made without prior written consent of ShiftEasy™ to a release being made. You will provide a copy of any press releases to ShiftEasy™. </p>
                                </li>
                                <li>
                                    <h6>Conduct</h6>
                                    <p>You agree to conduct yourself with due regard to public conventions and morals and agree that you will not do or commit any act or thing that will tend to degrade ShiftEasy™ or bring it into public hatred, contempt, scorn or ridicule or that will tend to shock, insult or offend the community or ridicule public morals or decency. Further, you will not market ShiftEasy™'s Site or products using illegal, unethical, or misleading methods, or through inaccurate content. </p>
                                </li>
                                <li>
                                    <p>Modification to Terms to ShiftEasy™ reserves the right to change these terms at any time and to notify you by updating and posting these terms on its Site. ShiftEasy™ is not bound by any other modifications to these terms and conditions unless signed in writing by an authorised ShiftEasy™ officer. </p>
                                </li>
                                <li>
                                    <h6>Entire Agreement</h6>
                                    <p>This Agreement, the Site Terms and Conditions and the Site Legal Disclaimer, as amended from time to time, and located at https://www.ShiftEasy.com/terms and http://www.ShiftEasy.co.uk/disclaimer, respectively, constitute part of this Agreement. </p>
                                </li>
                                <li>
                                    <h6>Assignment</h6>
                                    <p>This Agreement cannot be assigned.</p>
                                </li>
                                <li>
                                    <h6>Waivers</h6>
                                    <p>No failure or delay, on the part of ShiftEasy™, in exercising any right or power under these Terms will operate as a waiver of such right or power. </p>
                                </li>
                                <li>
                                    <p>Severability If any term, covenant, condition or provision of this Agreement is held by a court of competent jurisdiction to be invalid, void or unenforceable, it is the parties' intent that such provision be reduced in scope by the court only to the extent deemed necessary by that court to render the provision reasonable and enforceable and the remainder of the provisions of this Agreement will in no way be affected, impaired or invalidated as a result. </p>
                                </li>
                                <li>
                                    <p>Governing Law and NoticesIn respect to construction, interpretation, validity and enforcement, these Terms are to be construed in accordance with and governed by the laws of the United Kingdom. You consent to the exclusive jurisdiction of the courts of United Kingdom. Any notice under this Agreement will be in writing and delivered in person or or private courier service. All notices will be addressed to ShiftEasy™ located at London UK. Any notices to you may be delivered to you at the address, email provided in your tender application or to any address later provided. </p>
                                </li>
                                <li>
                                    <h6>End User</h6>
                                    <p>The end user of ShiftEasy™'s Material will be subject to the Site's terms and conditions and disclaimers. </p>
                                </li>
                                <li>
                                    <h6>Binding Effect</h6>
                                    <p>This Agreement will pass to the benefit of and be binding upon the respective heirs, executors, administrators, successors of ShiftEasy™ and you. </p>
                                </li>
                                <li>
                                    <h6>Headings </h6>
                                    <p>The headings, captions, titles, and numbering system are inserted only as a matter of convenience and may under no circumstances be considered in interpreting the provisions of this Agreement.</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                <Footer/>
            </div>
        )
    }
}
export default termsConditionsAffiliate;