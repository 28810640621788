import React, { Component } from 'react';
import "./trades-logistics.css"
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

import wearHouse from '../../../../assets/images/services-page/16-trades-logistics/warehouse.png' ;
import Tools from '../../../../assets/images/services-page/16-trades-logistics/Flat.png' ;
import Flat from '../../../../assets/images/services-page/16-trades-logistics/Flat.png' ;
import DeliveryTruck from '../../../../assets/images/services-page/16-trades-logistics/delivery-truck.png' ;
import EcoCar from '../../../../assets/images/services-page/16-trades-logistics/eco-car.png' ;
import House from '../../../../assets/images/services-page/16-trades-logistics/house.png' ;
import SignInForm from '../../../../assets/images/services-page/16-trades-logistics/SignInForm.png' ;
import Maths from '../../../../assets/images/services-page/16-trades-logistics/maths.png' ;
import MoneyBag from '../../../../assets/images/services-page/16-trades-logistics/money-bag.png' ;
import Pointer from '../../../../assets/images/services-page/16-trades-logistics/pointer.png' ;
import Transperant from '../../../../assets/images/services-page/16-trades-logistics/undraw_heavy_box_agqi.png' ;

class trades extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="service-banner serv-sixteen page-top-banner banner-with-form mb-3 mb-lg-5">
                    <div className="service-banner-content text-center">
                          <h5>More Than 72000 Happy Clients Now a Part of Our Caravan</h5>
                        <h2 className="text-white mb-3">Save <span className="text-orange">Upto 20% </span>Than Market Price With </h2>
                        <h5>Best Logistic Transport Service By ShiftEasy Technology</h5>
                    </div>
                    <div className="banner-form">
                        <form>
                            <div className="mb-3">
                                <input type="text" className="form-control br-0" placeholder="Full Name"/>
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control br-0" placeholder="Email"/>
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Phone number"/>
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Shifting From"/>
                            </div>    <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Shifting To"/>
                            </div>    <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Date"/>
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control br-0" rows="2" placeholder="Message"></textarea>
                            </div>
                            <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                        </form>
                    </div>
                </div>
                <div className="container-fluid  mx-auto">
                    <h6 className="text-center mt-2 font-weight-lighter">Verified reviews and profile</h6>
                    <h6 className="text-center">Licensed Logistics Transporters with Detailed History</h6>
                    <div className="row pl-2 pr-2 d-flex justify-content-around">
          <div className="col-lg-2 col-md-4 mt-2 mb-2  logistics__card">
            <img src={Tools} alt="Tools"></img>
            <h4 className="logistics__cardTitle">Trained and skilled workforce</h4>
            <p>
              We have excellent professionals who look after loading of your
              vehicle into containers till door delivery
            </p>
          </div>
          <div className="col-lg-2 col-md-4 mt-2 mb-2  logistics__card">
            <img src={Flat} alt="Flat"></img>
            <h4>Closed Car Containers</h4>
            <p>
              shifteasy uses safest mode of car transportation, i.e. closed car
              containers to ensure your vehicle reached destination scratch
              free.
            </p>
          </div>
          <div className=" col-lg-2 col-md-4 mt-2 mb-2  logistics__card">
            <img src={DeliveryTruck} alt="DeliveryTruck"></img>
            <h4>Best Delivery Time</h4>
            <p>
              Whether you are looking for express or standard delivery, we have
              plenty of options as per different necessity
            </p>
          </div>
          <div className="col-lg-2 col-md-4 mt-2 mb-2  logistics__card">
            <img src={EcoCar} alt="EcoCar"></img>
            <h4>Shift Cars Easily</h4>
            <p>
              Whether you are looking for express or standard delivery, we have
              plenty of options as per different necessity
            </p>
          </div>
          <div className="col-lg-2 col-md-4 mt-2 mb-2  logistics__card">
            <img src={House} alt="House"></img>
            <h4>Shift House Easily</h4>
            <p>
              Whether you are looking for express or standard delivery, we have
              plenty of options as per different necessity
            </p>
          </div>
        </div>
        <div className="bg-dark-light w-100 mt-2 p-md-4">
        <h6 className="text-center  font-weight-lighter">Easy Booking Process</h6>
                    <h6 className="text-center mb-2">Book Logistic Service with a Tap on Your Phone</h6>
                    <div className="row d-flex justify-content-around mt-2">
                    <div className="logistics__bookingProcesscard col-lg-2 col-md-3 ">
              <img src={SignInForm} alt="SignInForm"></img>
              <p>
                Fill an easy online form with your basic goods details that you
                want to move
              </p>
            </div>
            <div className="logistics__bookingProcesscard col-lg-2 col-md-3 ">
              <img src={Maths} alt="Maths"></img>
              <p>
                Get fully negotiated economic to premium quotations directly on
                your mail
              </p>
            </div>
            <div className="logistics__bookingProcesscard col-lg-2 col-md-3 ">
              <img src={MoneyBag} alt="MoneyBag"></img>
              <p>
                Choose preferred option as per budget and quality and pay
                through online or cash
              </p>
            </div>
            <div className="logistics__bookingProcesscard col-lg-2 col-md-3 ">
              <img src={Pointer} alt="Pointer"></img>
              <p>
                Sit tight and track, let the customer success team do the rest
                of your work.
              </p>
            </div>

                    </div>
                    </div>
                    <h6 className="text-center mt-2 ">International Fleet And Transport Domet</h6>
                    <div className="row d-flex justify-content-center">
          <div className="logistics__transportDometColumns col-lg-3 col-md-4">
            <h2>RELIABLE CAPACITY & COMMITMENT</h2>
            <p>
              We treat your shipment like our own, securing capacity with our
              reliable carrier network.
            </p>
          </div>
          <div className="logistics__transportDometColumns col-lg-3 col-md-4">
            <h2>GREAT RATES, NO SURPRISES</h2>
            <p>
              Our instant rates are transparent through booking. We offer spot
              shipments and dedicated lanes.
            </p>
          </div>
          <div className="logistics__transportDometColumns col-lg-3 col-md-4">
            <h2>24/7 SERVICE</h2>
            <p>
              As earth's most customer-centric company, we provide the support
              and technology to make shipping easy with full shipment visibility
              and no blackout dates.
            </p>
          </div>
        </div>


                    <div className="row my-lg-5 my-4 ">
                        <div className="col-md-6">
                            <div className="service-content-box">
                                <h5 className="page-title">Our Export Services includes:</h5>
                                <ul className="pl-3 pt-2 list-style-dash">
                                    <li> Commercial & Personal Shipments</li>
                                    <li> Full range of container sizes.</li>
                                    <li> Direct access to preferential rates from selected shipping lines</li>
                                    <li> Shipped to arrival at port or inland destination by road haulage</li>
                                    <li> Marine Transit Insurance arranged for your protection and peaceofmind</li>
                                    <li> Door-to-door – any weight to any international destination</li>
                                    <li> Complete Documentation, labeling & marking</li>
                                    <li> Wooden packing, crating & case making for complete protection</li>
                                    <li> Local transportation & warehousing</li>
                                    <li> Secure warehousing with CCTV monitoring</li>
                                    <li> Pre-shipment inspections</li>
                                    <li> Insurance cover through Lloyds underwriters</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={wearHouse} className="img-fluid"  alt="img"/>
                        </div>
                    </div>
                    <div  className="row logistics__topRatedSection">
            <div className="col-lg-6 col-md-6 logistics__topRatedSectionText">
              <h5>
                Top Rated Logistic Service. Verified & Licensed Car Carriers.
                High Quality & Best Price Guaranteed. 100% safe & Reliable
              </h5>
            </div>
            <div className="col-lg-6 col-md-6 logistics__tasperantImg">
              <img src={Transperant} alt="Transperant"></img>
            </div>
            </div>
                    <h6 className="text-center mt-3 font-weight-lighter">Related Search</h6>
                    <h6 className="text-center mb-2">Shifteasy's presence across India</h6>
                        <div className="row mt-3 pb-4 ">
                            <div className="col-md-3">  
                                <span className="mb-3 trades-search-links">Car Transport Services in Hyderabad</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Kochi</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Kochi</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Ahmedabad</span>
                            </div>
                            <div className="col-md-3">  
                                <span className="mb-3 trades-search-links">Car Transport Services in Hyderabad</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Kochi</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Ahmedabad</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Ahmedabad</span>
                            </div>
                            <div className="col-md-3">  
                                <span className="mb-3 trades-search-links">Car Transport Services in Hyderabad</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Kochi</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Ahmedabad</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Ahmedabad</span>
                            </div>
                            <div className="col-md-3">  
                                <span className="mb-3 trades-search-links">Car Transport Services in Hyderabad</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Kochi</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Ahmedabad</span>
                                <span className="mb-3 trades-search-links">Car Transport Services in Ahmedabad</span>
                            </div>
                        </div>

                </div>
                <Footer/>

            </div>
        );
    }
}

export default trades;