import $ from 'jquery';
import {get} from '../../../services/REST-API/hyper-service';
import axios from 'axios';

const  GoogleMapsAPIKey  = "AIzaSyBO7GfpRUBOUWdwnmqSFbLSzLtvzdX8cMI"
export const scrollTop =()=> {
    $(document).ready(function(){
        $(window).scrollTop(0,0);
      });
}
export const getCurrentPosition=()=>{
    navigator.geolocation.getCurrentPosition(function(position) {
        console.log("getCurrentPosition:", position);
        getCurrentLocationDetails(position)
      });
}

export const getCurrentLocationDetails =(position)=>{
        let headers = {
            "Accept": "application/json",
        };
        let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.data.latitude},${position.data.longitude}&key=${GoogleMapsAPIKey}`;
        get(url, headers)
          .then((resp) => {
            console.log("getCurrentLocationDetails", resp);
          })
          .catch((err) => {
            console.log("Error", err);
          });
}

export const getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
            console.log(response) 
                    
                 }).catch((error) => {
                     console.log('location error', error);
                 });
};