import React, { Component } from 'react';
import './home-content-insurance.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import homeContServImg1 from '../../../../assets/images/services-page/5-home-content/image-1.svg';
import homeContServImg2 from '../../../../assets/images/services-page/5-home-content/image-2.svg';
import scott from '../../../../assets/images/scott.png';
import shield1 from '../../../../assets/images/shield1.png';
import why from '../../../../assets/images/why.png';
import couple from '../../../../assets/images/couple.jpg';
import beautiful from '../../../../assets/images/beautiful.jpg';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { scrollTop } from '../../common/common';
import { Helmet } from "react-helmet";
class HomeContentInsurance extends Component {

    componentDidMount() {
        scrollTop();
    }

    render() {
        return (
            <div className='HomeContentInsurance'>
                <Helmet>
                    <title>Home Insurance and House Content Insurance | ShiftEasy</title>
                    <meta name="description" content="ShiftEasy helps you to find the India's Best Doctors Near You. Just Visit the ShiftEasy and Find India's Top Rated & Trusted Doctors. " />
                    <meta name="keywords" content="Home Insurance & House Content Insurance" />
                </Helmet>
                <Header />
                <div className="service-banner serv-five page-top-banner banner-with-form mb-3 mb-lg-5">
                    <div className="service-banner-content text-center">
                        <h2 className="text-white mb-3"><span className="text-orange">Home</span> Insurance &amp; <span className="text-orange">House</span> Content Insurance</h2>
                        <h5>'Contents' As “The Sort Of Things You Would Take With You If You Were To Move House.</h5>
                    </div>
                    <div className="banner-form">
                        <form>
                            <div className="mb-3">
                                <input type="text" className="form-control br-0" placeholder="Full Name" />
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control br-0" placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Phone number" />
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control br-0" rows="4" placeholder="Message"></textarea>
                            </div>
                            <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                        </form>
                    </div>
                </div>
                <div className="container mx-auto">
                    <div className="row my-lg-5 my-4 ">
                        <div className="col-md-12">
                            <div className="service-content-box">
                                <h3 className="text-center">Home Content Insurance</h3><br />
                                <p>Luxury divided, life is for the most part dependent on a portion of these electronic things, which is the reason existence without this substance is by all accounts mind-blowing. Protection inclusion of house material isn't mandatory, yet most of us think it's hard to re-buy or replace these valuable things whenever taken or harmed by fire or a flood. Accordingly, it turns out to be extremely important to protect this material in your home along with your home protection. A similar report can find an ideal mix of home and element protect
                                    Despite getting any such home and substance protection correlation, realize the idea of content protection cover.</p>
                            </div>
                        </div>
                    </div>
                    <div id="need" className="row my-lg-5 my-4 align-items-center">
                        <div className="col-md-8 col-lg-6">
                            <div className="service-content-box">
                                <h5 className="page-title">How many contents covers do you need?</h5>
                                <p>According to Experts, One out of every five households are generally underinsured as they do not
                                    know the actual value of the contents in their house. Note that the greatest sum your protection
                                    strategy will repay won't exceed the aggregate safeguard in case of guaranteed settlement. For the right insured amount for your policy,
                                    it is vital to keep a record of all your contents and their values.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-6">
                            <img src={homeContServImg1} className="img-fluid serv-svg" alt="icon" />
                        </div>
                    </div>
                    <div id="home" className="row my-lg-5 my-4 align-items-center">
                        <div className="col-md-8 order-md-2 col-lg-6">
                            <div className="service-content-box">
                                <h5 className="page-title">Types of content protection cover:</h5>
                                <p> There are two kinds of content protection cover.</p>
                                <h6>'As new' or 'new for old':</h6>
                                <p> It shows that the protection policy will repay the total expense of fix. in the event of a damaged Though,
                                    if there should be an occurrence of robbery, the repayment will be adequate to purchase the qualified new element.
                                    Despite, things that are covered under this classification compare from one
                                    organization to another. For example, for the most part, garments and clothes
                                    are not considered in this type of protection.
                                </p>
                                <h6>Reimbursement policy:</h6>
                                <p>These plans are less expensive because the repayments in this class are chosen in the wake of thinking about the benefit or decline in the worth of the material.
                                    Like, guarantee on a five-year-old PC will rely upon its present worth and not the price tag.</p>
                            </div>
                        </div>
                        <div className="col-md-4 order-md-1 col-lg-6">
                            <img src={homeContServImg2} className="img-fluid serv-svg" alt="icon" />
                        </div>
                    </div>
                    <div id="content-insurence" className="row my-lg-5 my-4">
                        <div className="col-md-6 w-100">
                            <div className="service-content-box">
                                <h5 className="page-title">Types of Home Content Insurance:</h5>
                                <br />
                                <p><span>Home Content Insurance for Owners:</span></p>
                                <p>If you own a house and have electronic gear, furniture, and other significant family belongings, you should get your home substance. As a proprietor, you can get your home construction and ensure it against normal disasters and theft relatively, and you can also defend your
                                    significant home element. At an extraordinary, you can secure your jewels and pedal cycle as well. </p>

                                <p><span>Home Content Insurance for Renters:</span></p>
                                <p> Regardless of whether you don't possess the house, you do claim the assets. Thus, you need to
                                    purchase home protection that covers significant home substances. Picking a property protection cover
                                    that is ideal, you can be interesting.<br /><br />To facilitate your concerns and offer total assurance to your home
                                    element pick Shifteasy Home Shield Insurance as it gets the home property for occupants and tenants flawlessly.
                                    At an extra exceptional, you can secure your jewels, gadgets, and pedal cycle as well.</p>

                                <p><span>Home Content Insurance for New and Old:</span></p>
                                <p>This sort of home element protection will repay the total expense of fix if your guaranteed
                                    home property is harmed. Be that as it may, the repayment will be adequate to purchase a qualified
                                    new prop on account of the robbery.<br /><br /> In any case, the inventory of things covered under this home
                                    property protection might compare from one safety net provider to another. For instance,
                                    garments can't be covered under this sort of home substance protection.
                                    The charge for this kind of protection plan will stay on the higher side.</p>

                                <p><span>Home Content Insurance on Insurance premise:</span></p>
                                <p>This sort of home element protection plan is less expensive because repayments in this
                                    class are fixed. Like, guarantee on a five-year-old Digital Camera will rely upon its
                                    present market value and not the price tag or receipt value.<br /><br /> Shifteasy Home Shield protection
                                    plan covers fix expenses if there should be an incident of halfway harm and pays for
                                    the depreciated worth of your substance in the event of a complete misfortune.</p>
                            </div>
                        </div>
                        <div id="scott" className="col-md-6 col-xs-12">
                            <div className="service-content-box">
                                <img src={scott} alt="no image" className="image-fluid" />

                                <p></p>
                            </div>
                        </div>
                    </div>

                    <div id="cover" className="row my-lg-5 my-4 align-items-center">
                        <div className="col-md-4 col-lg-6">
                            <img src={shield1} className="img-fluid serv-svg" alt="icon" />
                        </div>
                        <div className="col-md-8 col-lg-6">
                            <div className="service-content-box">
                                <h4>Types of content protection cover:</h4>
                                <ul>
                                    <li>Trusted brand</li>
                                    <li>Offers all in 1 cover</li>
                                    <li>Discount* on Premiums</li>
                                    <li>Content covered up to ₹25 lakhs</li>
                                    <li>Attractive optional covers</li>
                                    <li>Complete protection</li>
                                    <li>Affordable premiums</li>
                                    <li>Peace of mind assured</li>
                                    <li>Low stress</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <h4 className="text-center page-title">Home Insurance</h4>

                    <div id="facilities" className="row my-lg-5 my-4">
                        <div className="col-md-6 w-100">
                            <div className="txt service-content-box">
                                <h5 className="page-title">Shifteasy Home Insurance facilities:</h5>
                                <br />
                                <p><span>No Depreciation:</span></p>
                                <p>No depreciation is deducted if there should take place an occurrence of an absolute breakdown of
                                    your electrical or electronic hardware that is under five years of age. </p>

                                <p><span>Pet Cover:</span></p>
                                <p>Shifteasy home protection covers your pets since they are important for your
                                    family and one of the numerous things that make a house a home.</p>

                                <p><span>Inclusion for Tenants: </span></p>
                                <p>You can return home protection that gives inclusion to the substance of your home along
                                    with different resources and liabilities regardless of whether you are on lease.</p>

                                <p><span>Inclusion for Students:</span></p>
                                <p> You can give total security to your youngsters' home substance when they avoid home anyplace in India.</p>

                                <p><span>Inadvertent Damages:</span></p>
                                <p>Inclusion to your substance from inadvertent harm is a one-of-a-kind contribution under the item, where you need
                                    not stress over harms to your effects any longer.</p>

                                <p><span>Moderate Charges:</span></p>
                                <p>Get best security for your home and your important effects at incredibly moderate yearly expenses.</p>

                                <p><span>Instant Strategy Protection:</span></p>
                                <p>You can purchase home protection online in only a couple of clicks and get a moment strategy. So why stand by? Get your home protected at this point with Shifteasy.</p>

                            </div>
                        </div>
                        <div id="scott" className="col-md-6 col-xs-12">
                            <div className="service-content-box">
                                <img src={couple} alt="no image" className="image-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="row my-lg-5 my-4 align-items-center">
                        <div className="col-md-4 col-lg-6">
                            <img src={why} className="img-fluid " alt="icon" />
                        </div>
                        <div className="col-md-8 col-lg-6">
                            <div className="service-content-box">
                                <h5 className="page-title">Why Do You Need Home Insurance?</h5>
                                <p>We need to protect our precious assets from damage. The same goes for our home, which is
                                    probably one of the most valuable assets. There's a lot you can do to protect your home -locks
                                    to keep out invaders, a spray system to put out fires, CCTV cameras to prevent untoward activity,
                                    and so much more. However, the ultimate backup plan is a house insurance plan.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row my-lg-5 my-4 align-items-center">
                        <div className="col-md-4 col-lg-6">
                            <h5 className="page-title">Types of Home Insurance Plans</h5>
                            <ul>
                                <li>Comprises only contents such as personal belongings within your home.</li>
                                <li>Comprises both the building and contents of your home.</li>
                                <li>Comprises your house's property and the contents within your home and Jewellery.</li>
                            </ul>
                        </div>
                        <div className="col-md-8 col-lg-6">
                            <div className="service-content-box">
                                <img src={beautiful} className="img-fluid " alt="icon" />
                            </div>
                        </div>
                    </div>

                    <div id="bgimg" className="row my-lg-5 my-4 align-items-center">
                        <div className="col-lg-12 col-xs-12 align-items-center">
                            <h5 className="page-title text-center">Shifteasy Digital Home Insurance Policy?</h5>
                        </div>
                        <div className=" row justify-conent-center">
                            <div className='col-md-6'>
                                <p><span className='text-orange'>An incentive for Money :</span></p>
                                <p>When we consider Home Insurance, we consider it a costly issue. It's tied in
                                    with securing one of your most valued belongings! Worry not; however,
                                    we guarantee your house is covered and ensure that it is reasonable for you and your pocket!</p>
                            </div>
                            <div className='col-md-6'>
                                <p><span className='text-orange'> Advanced Friendly :</span></p>
                                <p>One of the things individuals are frequently attentive to in protection is desk work, and that is
                                    what we've set out with our ShiftEasy online home protection. With Digit, everything from purchasing
                                    a home protection approach to making claims is simple, and it is possible on the site.</p>
                            </div>

                            <div className="row justify-content-center mx-1">
                                <div className='col-md-6'>
                                    <p><span className='text-orange'>Plans for Renters :</span></p>
                                    <p>Millennials are moulding the rental economy, and we get that! That is the reason it doesn't make any difference on
                                        the off chance that you own a home or not. Your place can be secure with our protection for home tenants.</p>
                                </div>
                                <div className='col-md-6'>
                                    <p><span className='text-orange'> 24x7 Customer Support :</span></p>
                                    <p>Emergencies come unexpected and can occur anytime! That is why we generally are only a ring constantly, regardless
                                        of time or day it is!</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="whoShould" className="row my-lg-5 my-4 align-items-center">
                        <div className="col-md-12 mb-5 col-lg-12 col-xs-12">
                            <h5 className="page-title">Who should get Home Insurance?</h5>
                            <p><span>New Landowners :</span></p>
                            <p>On the possibility that you've quite recently purchased a home as of late, getting home protection must be at the highest
                                point of your need list. You've effectively spent such a lot of cash on your recently discovered home; the least you can
                                do to remain got financially is get home protection. </p>

                            <p><span>Leaseholders :</span></p>
                            <p>No Matter What of whether you're leasing your home, it doesn't make any difference! Your house is still where every one of
                                your effects is. From your devices to your furnishings, all are in danger if there should arise incidents like flames, floods,
                                or theft.</p>

                            <br /><p> While not all backup plans offer home protection arrangements for residents, Shifteasy offers strategies for the equivalent so everybody can be covered.</p><br />

                            <p><span>Moderate Charges :</span></p>
                            <p>Get best security for your home and your important effects at incredibly moderate yearly expenses.</p>

                            <p><span> Instant Strategy Protection :</span></p>
                            <p>You can purchase home protection online in only a couple of clicks and get a moment strategy. So why stand by? Get your home protected at this point with Shifteasy.</p>
                        </div>
                    </div>

                </div>
                {/* </div>  */}
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HomeContentInsurance));