import React, { Component } from 'react';
import './rent-guarentee-insurance.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import rentGuarenteeServImg1 from '../../../../assets/images/services-page/6-rent-guarantee/image-1.svg';
import insurance from '../../../../assets/images/insurance.png';
import caucasian from '../../../../assets/images/caucasian.jpg';
import firms from '../../../../assets/images/firms.jpg';
import watch from '../../../../assets/images/watch.png';
import Service from '../../../../assets/images/service.png';
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { scrollTop } from '../../common/common';
import { Helmet } from "react-helmet";
class RentGuarenteeInsurance extends Component {

    componentDidMount() {
        scrollTop();
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>Benefits of Rent Guarantee Insurance for Landlord | ShiftEasy</title>
                    <meta name="description" content="Rent Guarantee Insurance is types of policy, which has been specially designed for landlords. This insurance will manage the rent for landlords, if the tenant fails to do on time. " />
                    <meta name="keywords" content="Rent Guarantee Insurance" />
                </Helmet>
                <Header />
                <div className="service-banner serv-six page-top-banner banner-with-form mb-3 mb-lg-5">
                    <div className="service-banner-content text-center">
                        <h2 className="text-white mb-3">Rent<span className="text-orange"> Guarentee</span> Insurance</h2>
                        <h5>Avoid expensive breakdown costs with Home Emergency Cover.</h5>
                    </div>
                    <div className="banner-form">
                        <form>
                            <div className="mb-3">
                                <input type="text" className="form-control br-0" placeholder="Full Name" />
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control br-0" placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Phone number" />
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control br-0" rows="4" placeholder="Message"></textarea>
                            </div>
                            <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                        </form>
                    </div>
                </div>
                <div className="container mx-auto">
                    <div className="row my-4 ">
                        <div className="col-md-12">
                            <div className="service-content-box pb-0">
                                <p className="text-center">Rent guarantee insurance is designed to protect you in the event your tenant fails to pay the rent.
                                    Rent guarantee insurance becomes an essential requirement for all the property owners who are not
                                    staying where their house has been rented out.<br /> Rent guarantee insurance is a form of Insurance
                                    where landlords can protect against loss of rent for a reasonably small fee.</p>
                            </div>
                        </div>
                    </div>
                    <div id="landlord" className="row my-4 align-items-center">
                        <div className="col-md-8 ">
                            <div className="service-content-box">
                                <h5 className="page-title">To the Landlord:</h5>
                                <ol className="pl-3">
                                    <li>The domestic goods and personal belongings represent many years of financial, emotional security and investment.
                                        Hence, it would be best if you safeguarded that your house or property and belongings are insured against fire, theft, damage etc.</li><br />
                                    <li>ShiftEasy's Rent guarantee Insurance is devised to protect you if your tenant fails to pay the rent. This rent guarantee insurance policy will also include the property owner's legal costs to carry out legal cases for damages
                                        or injunctions against renters who violate the terms and conditions of their tenancy agreement. </li><br />
                                    <li>ShiftEasy's Rent guarantee insurance can support you to keep protected against all types of risks that you may be liable to against tenants. Rent guarantee insurance covers the rent if your tenant defaults on payments.
                                        You can buy policies to cover 6 and 12 Months tenancy agreements.</li>
                                </ol>
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <img src={rentGuarenteeServImg1} className="img-fluid serv-svg" alt="img" />
                        </div>
                    </div>

                    <div className="row my-4 align-items-center">
                        <div className="col-md-12 col-xs-12">
                            <div className="service-content-box">
                                <p><b>Rent Guarantee insurance</b> is not a brand-new name or a new concept.
                                    This plan is available all over the globe, but insurance companies use various terms such as Rent Guarantee
                                    Insurance, Landlords Insurance, Landlord's rental property insurance etc. It is always better to have a rent
                                    guarantee insurance to avoid paying your tenant the compensation directly from your pocket for roof blow off,
                                    pipes burst, things break down hurting the tenant or damaging their assets.
                                    Rent Guarantee Insurance protects the rental building, personal properties, such as tools, appliances, furniture, put in storage on-site or provided by you for use by your tenants. Such rent guarantee insurance supports you during a natural calamity, loss of rental income and legal expenses incurred to fight the tenant.</p>
                            </div>
                        </div>
                    </div>

                    <div id="insurance" className="row my-4 align-items-center">
                        <div className="col-md-4">
                            <img src={insurance} className="img-fluid" alt="img" />
                        </div>
                        <div className="col-md-8">
                            <div className="service-content-box">
                                <h5>Steps to Claim: Rent Guarantee Insurance</h5>
                                <ul>
                                    <li>You should have a signed tenancy agreement.</li>
                                    <li>It should acquire two forms of tenant identification along with their photographs.</li>
                                    <li>Proof of the tenant's employment.</li>
                                    <li>It would be best if you collected one month's rent in advance and a dilapidation deposit before the tenant can occupy the property.</li>
                                    <li>Provide a record of those rents paid and those which are owed in due.</li>
                                    <li>An authorized tenant reference service must refer the guarantor if the tenant needs a surety.</li>
                                    <li>In countries like UK and USA, Complete tenant references should be obtained and verified by a professional certified referencing company.
                                        A licensed tenant referencing service will reference each tenant.
                                        In India, this concept will be creating a new revolution in property management and services.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div id="guaranteeplans" className="row my-4 align-items-center">
                        <div className="col-md-8">
                            <div className="service-content-box">
                                <h5 className="page-title">How to choose your rent guarantee plans:</h5>
                                <ol className="pl-3">
                                    <li>Your insurance protection plan may differ depending on the standard coverage included in your policy and the optional coverage plans you purchase.</li>
                                    <li>Property-owners who want nominal coverage/support to protect their property from major causes of loss, such as fire, wind, or explosion, should consider a Platinum policy.</li>
                                    <li>Pearl policy or the basic policy lists the minimum losses incurred that will cover in the policy. Whereas the Platinum Policy covers all the major causes of loss as mentioned above.</li>
                                    <li>Apart from the basic coverage within your policy, you will need to decide what optional coverage to include. These can consist of but are not limited to liability, loss of rents, other structures, theft, personal property, earthquake, vandalism and malicious nuisance (sometimes included in the policy).</li>
                                    <li>Decide what you want your policy to do for you in the aftermath of a claim. These are the common loss settlement methods: Actual Cash Value settlement and Replacement Cost settlement.</li>
                                </ol>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src={caucasian} className="img-fluid" alt="img" />
                        </div>
                    </div>


                    <div id="watch" className="row my-4 align-items-center">
                        <div className="col-md-4">
                            <img src={watch} className="img-fluid" alt="img" />
                        </div>
                        <div className="col-md-8">
                            <div className="service-content-box">
                                <h5>Actual Cash Value settlement:</h5>
                                <p>The Actual Cash Value settlement pays only for the present-day/current value of your land/ house/property,
                                    which is the cost of replacement minus depreciation based on age and wears, up to the limit in the policy.</p>
                                <br />
                                <h5>Replacement Cost settlement:</h5>
                                <p>In case of Replacement Cost settlement opted, it pays you the amount needed to replace your property, without depreciation deduction,
                                    with a new property of like nature, kind, quality, and subject to any applicable deductibles and coverage limits.</p>
                            </div>
                        </div>
                    </div>

                    <div id="firms" className="row my-4 align-items-center">
                        <div className="col-md-8">
                            <div className="service-content-box">
                                <h5 className="page-title">Firms and companies offering Insurance:</h5>
                                <ul className="pl-3">
                                    <li>We have partnered with reputed Insurance providing organizations, and they have pioneered the art of insurance plans. Would you please discuss with our policy providing agents to customize and tailor your plan according to your personalized liking?</li>
                                    <li>The General Insurance Corporation of India, through its subsidiaries/affiliates like United India Insurance, Oriental Insurance, the National Insurance Company, and New India Assurance, will give you a guarantee for your house and valuables. It gives you peace of mind from all the volatile or unpredictable risks.</li>
                                    <li>In Addition to the government companies, numerous private companies offer a range of Insurance schemes such as MetLife, Royal Sundaram Alliance, ICICI Lombard, Tata-AIG, Bajaj Allianz General Insurance Company etc.</li>
                                    <li>Evaluate / Review your Policy each year to make sure it is still supporting you with the coverage of your liking, and you meet your needs. Costs or values of properties can fluctuate each year due to your improvements or housing market conditions. Your insurance policy can be renewed annually, and you can cancel a policy at any point in time.</li>
                                    <li>Decide and determine what you want your policy to do for you in the occurrence of a claim. Contact our ShiftEasy Rent Guarantee Insurance providers <a href="#">contact@shifteasy.com</a>. Our franchisee and partners are available all over in your locality to help you know more details such as Insurance quotes, methods of loss settlement, optional coverage available, requirements to make a claim etc.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src={firms} className="img-fluid" alt="img" />
                        </div>
                    </div>

                    <div id="serviceCont" className="row my-4 align-items-center">
                        <div className="col-md-4 w-100 ">
                            <img src={Service} className="img-fluid" alt="img" />
                        </div>
                        <div className="col-md-8">
                            <div className="service-content-box">
                                <ul className="pl-3">
                                    <li>Book and assure your Rent Guarantee Insurance, now with ShiftEasy!!!</li>
                                    <li>Hassle-free Insurance assured with ShiftEasy; Book now.</li>
                                    <li>Think Safety, Think ShiftEasy!!!</li>
                                    <li>Prioritize your insurance plans with team ShiftEasy; hurry now and book your Safety.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                {/* </div> */}
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RentGuarenteeInsurance));