import React,{Component} from 'react';
import './terms-conditions.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

class termsConditions extends Component {

    render(){
        return(
            <div className="docs-pg">
                <Header/>
                    <div className="header-space">
                        <div className="docs-pg-title">
                            ShiftEasy Terms and Conditions
                        </div>
                        <div className="container py-5 docs-pg-content">
                            <ol className="mb-4 pl-3 pl-md-0">
                                <li>
                                    <h6>General</h6>
                                    <p>With each visit to the Site you signify your agreement to these Terms. These Terms were last modified on 31 Dec 2020. Indo UK Business Consortium and its suppliers own the ShiftEasy trade name, brand and trademark and websites. Hereafter, ShiftEasy means Indo UK Business Consoritum. ShiftEasy Material is defined as any intellectual property, legal materials, documents, web pages, software, products, web links, email, information, or any other materials provided by or previously provided by ShiftEasy or the Site, including any portion thereof. ShiftEasy Services is defined as any ShiftEasy service. If you do not wish to be bound by these Terms, you are not granted authority to use or access the Site or ShiftEasy Material.</p>
                                </li>
                                <li>
                                    <h6>Membership Terms</h6>
                                    <p>We will provide the Tender and contracts data services (as advertised on the www.ShiftEasy.co.uk website) ordered by you as part of your chosen membership option via personalised access to a secure area of our website using a unique user-name and password. Under the Electronic Commerce (EC Directive) Regulations 2002, you are deemed to have entered into a membership agreement with ShiftEasy (and agree to be bound by these terms and conditions) when you have submitted your completed membership form on this website. If you are ordering your membership on behalf of a business you also confirm that you have the authority to purchase said membership on behalf of your company, or employer, and understand that your company, or employer, is responsible for payment of the membership fee in full. We make every effort, but give no guarantees, to publish all available UK tender notices for our members.</p>
                                </li>
                                <li>
                                    <h6>Email Tender Alerts</h6>
                                    <p>As part of our service we have to email you to notify you that a new tender matching your chosen criteria has just been published and added to your account. You agree to receive these email tender alerts for the duration of your Free Trial and / or your subscription with ShiftEasy.</p>
                                </li>
                                <li>
                                    <h6>Cancellation Policy</h6>
                                    <p>12 months subscription package - no early cancellation option.</p>
                                </li>
                                <li>
                                    <p>Cancellation can only be undertaken online within your account. This easy-to-use facility is available 24/7. We do not accept telephone, email, or postal cancellations.</p>
                                </li>
                                <li>
                                    <h6>Data Protection & Policy</h6>
                                    <p>Your personal data is protected in the UK by the Data Protection Act. The personal information gathered from your registration or membership details is used for issuing usernames and passwords, emails advising you of the addition of new tenders and projects in your account, and the occasional despatch of information to further inform you of services provided by ShiftEasy, or other companies within the Group, which we believe may be of interest to you, and further communication relating to invoicing (where necessary).</p>
                                </li>
                                <li>
                                    <h6>Terms of Use </h6>
                                    <p>You shall not copy, use, modify, transmit, distribute, reverse engineer, or in anyway exploit copyrighted or proprietary materials available in the www.ShiftEasy.co.uk website, except as expressly permitted by ShiftEasy. Exporting, or extracting data from these websites into other databases is expressly prohibited as is the reselling of ShiftEasy information. All trademarks, service marks, and trade names in this site are the marks of the respective owner(s), and any unauthorised use thereof is strictly prohibited. All Web site design, text, graphics, the selection and arrangement thereof, and all software compilations, underlying source code, software (including applets) and all other material on this Web site are copyright ShiftEasy and its affiliates, or their content and technology providers. ALL RIGHTS RESERVED. </p>
                                </li>
                                <li>
                                    <p>ShiftEasy shall not be liable for any errors, inaccuracies or delays in content, or for any actions taken in reliance thereon. ShiftEasy expressly disclaims all warranties, expressed or implied, as to the accuracy of any of the content provided, or as to the fitness of the information for any purpose. Re-publication of content on the www.ShiftEasy.co.uk website is expressly prohibited without the prior written consent of Tenderers. Any reference to a linked site or any specific third party product or service by name does not constitute or imply its endorsement by us, and you assume all risk with respect to its use. Under no circumstances shall ShiftEasy, nor its directors, employees, affiliates, agents, and suppliers, be liable for any damages, including without limitation, direct, indirect, incidental, special, punitive, consequential, or other damages (including without limitation lost profits, lost revenues, or similar economic loss), whether in contract, tort, or otherwise, arising out of the use or inability to use the materials available in this site or any linked site, even if we are advised of the possibility thereof, nor for any claim by a third party. </p>
                                </li>
                                <li>
                                    <h6>Use of ShiftEasy Material and ShiftEasy Services</h6>
                                    <h6>Generally</h6>
                                    <p>You agree not to use any ShiftEasy Material and ShiftEasy Services for any purpose which is against any law in your jurisdiction or for any purpose which would not give full effect to the Terms even if that purpose is otherwise allowed under the Terms.</p>
                                    <p>Final Document is defined as the finished legal document for legal document products or other document created through the use of a ShiftEasy product. You are granted a Licence when you enter into a free trial, have a subscription or pay for access to a product. </p>
                                    <p>The content of our Website has been put together with care, but you are using our Website at your own risk. We will make every effort to keep the content of the Website as accurate as possible, however, we cannot always guarantee that our Website is correct or complete. Information, also information concerning our services, can be erroneous or outdated.</p>
                                    <p>Platform is not liable for damage or other adverse effects from using (information of) our Website or the inaccessibility thereof. Actions performed by you, based on our Website or information provided by us, are at your own expense and risk. No agreement can be concluded between you and Indo UK B2B Consortium, when based on faults or outdated information. </p>
                                    <p>ShiftEasy does also not accept liability for references to websites of third parties on our Website.</p>
                                    <p>Any paid or Free Trial Licence granted is subject to the Terms and entitles you to:</p>
                                    <ul className="mb-3">
                                        <li>Use Final Documents of the products as specified by the Terms only.</li>
                                        <li>Make five electronic copies of each Final Document for your personal use.</li>
                                        <li>Make necessary mechanical copies (e.g. printed or photocopied) of the Final Documents for your personal records and as required for completion of the specific transactions for which the Final Documents are intended.</li>
                                    </ul>
                                    <p>You may end a Licence in the manner specified on the order page, your account area or the help documentation. ShiftEasy retains the right to cancel any sale or Site access at any time, in which case you will only be charged for the time the sale or Site access was in effect.</p>
                                    <p>Your Licence is valid for the country of the document that you select when you purchase your Licence. You will be charged in the currency of that country.</p>
                                    <p>If you are not a party in transactions in which a Final Document is used or if you obtained the Final Document under a free trial, you are not allowed to enter into new transactions using the Final Document.</p>
                                    <p>To access products, you must provide credit card details to ShiftEasy as required by the Site. ShiftEasy will charge credit cards in accordance with displayed pricing in return for access to products and lawful services and if free trials are not cancelled before the specified time period. Subject to these Terms, if you are evaluating the Site strictly as a potential customer or to participate in our affiliate program, you are granted a licence to evaluate the Site for that purpose only. </p>
                                </li>
                                <li>
                                    <h6>Legal Advice, Information and Decision Making Responsibility </h6>
                                    <p>ShiftEasy is not a law firm and does not provide Legal Advice (as defined below). Your use of ShiftEasy Material or any ShiftEasy Services does not create a solicitor-client relationship between you and ShiftEasy. </p>
                                    <p>You agree that all decisions you make on legal matters are your full responsibility and you agree to retain legal counsel licensed to practice in your jurisdiction regarding any legal issue of enough importance to reasonably require it. </p>
                                    <p>You agree that ShiftEasy does not provide Legal Advice. If you receive any communication from ShiftEasy, its agents, its employees or any other associated entity, which is Legal Advice, you agree that it is NOT a communication authorised by ShiftEasy and you agree to immediately delete and disregard it.</p>
                                    <p>Except as expressly provided in these Terms, you agree to accept full responsibility for determining the value of and for any use you make of ShiftEasy Material, and for obtaining any needed assistance from a properly licensed attorney to assess the value of and appropriate uses for any ShiftEasy Material. </p>
                                    <p>For the purposes of these Terms, Legal Advice is defined to include the following:</p>
                                    <ol className="mb-3">
                                        <li>any legal related communication, work or service which, under the governing law of your jurisdiction, is only allowed to be performed by or under the supervision of a properly licensed attorney; </li>
                                        <li>advice on which legal document or documents you need or are best for your situation;</li>
                                        <li>determining the legal consequences that will or could result from how you have created your legal document;</li>
                                        <li>whether you have included inappropriate, conflicting, or ambiguous information in your legal documents;</li>
                                        <li>whether you have omitted any necessary provisions or details from your legal documents; and</li>
                                        <li>whether you require any additional legal documents or legal procedures.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h6>Programs</h6>
                                    <p>ShiftEasy retains the right to make changes to its Affiliate Program or other programs as it sees fit. Detailed Affiliate Program Terms and Conditions can be found at Affiliate Program Terms and Conditions.</p>
                                </li>
                                <li>
                                    <h6>Restrictions</h6>
                                    <p>To the maximum extent allowable under applicable law, except as explicitly identified in these terms, you agree not to publish, re-publish, lend, licence, give away, look at the software source code, modify the software source code, post to an Internet web site, or use in an automated system any ShiftEasy Material nor will you utilise ShiftEasy Material in any way for the creation of an automated system or website, nor will you allow or assist a third party to do so. </p>
                                    <p>You agree to only use ShiftEasy Material and the Site as a customer.</p>
                                    <p>Licences granted are subject to these Terms, and are non-exclusive, non-transferable, and revocable.</p>
                                    <p>The EULA document may be distributed on the internet without time limit by licensed ShiftEasy customers as part of a materially non-legal package adding significant value.</p>
                                    <p>The rights granted under these Terms are granted to you only.</p>
                                    <p>If you are an organisation, a purchase allows one individual within your organisation to exercise the rights granted by these Terms. If multiple individuals within your organisation require access, there must be a purchase for each individual. The appointed individual may not be changed.</p>
                                    <p>ShiftEasy retains the right to prohibit organisations, groups, or individuals, or yourself from using its websites or ShiftEasy Material or ShiftEasy Services at its discretion. </p>
                                </li>
                                <li>
                                    <h6>Assignment</h6>
                                    <p>This agreement cannot be assigned.</p>
                                </li>
                                <li>
                                    <h6>Intellectual Property</h6>
                                    <p>All copyright, trade marks (including its distinguishing guise and/or trade dress), and other intellectual property rights (registered and unregistered) of ShiftEasy Material belong to ShiftEasy and its suppliers. ShiftEasy reserves all of its rights in the ShiftEasy Material. Nothing in the Terms grants you a right or licence to use any intellectual property rights owned or controlled by ShiftEasy or any other third party except as expressly provided in these Terms. </p>
                                    <p>In addition, ShiftEasy Material, contains information and intellectual property that is selected and organised by ShiftEasy and represents significant work made by ShiftEasy. Nothing in the Terms should be construed as granting any licence or right to use any ShiftEasy Material or intellectual property displayed or used in any ShiftEasy Material except as expressly provided in the Terms. </p>
                                    <p>You agree to the following:</p>
                                    <ol className="mb-3">
                                        <li>the ShiftEasy Material is the property of ShiftEasy and its suppliers;</li>
                                        <li>you will not use the ShiftEasy Material for any purpose other than is expressly permitted in these Terms;</li>
                                        <li>you will not distribute in any medium any ShiftEasy Material without ShiftEasy prior written authorisation or as expressly provided these Terms.</li>
                                        <li>any distribution or past distribution of any ShiftEasy Material that violates these Terms is subject to liquidated damages in an amount equal to that which would be payable by a copyright infringer for knowingly copying a work, and based on each page of any website which uses ShiftEasy Material being a separate work under the Copyright Act and each access of a page of a website by a customer being a separate publication of a work. </li>
                                        <li>ShiftEasy shall have the right to represent its suppliers in any dispute.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h6>LIMITED WARRANTIES</h6>
                                    <p>Except as expressly provided in these Terms, to the maximum extent permitted under applicable law, ShiftEasy Material and ShiftEasy Services are provided "as is" without any kind of warranty. </p>
                                    <p>You accept full responsibility for determining whether ShiftEasy Material, tender, bid proposals and ShiftEasy Services are suitable for any particular purpose and for protecting yourself against any possible consequential damages. </p>
                                    <p>Except as expressly provided in these Terms or where prohibited by law, we are not responsible for any loss, injury, claim, liability, damage, or consequential damage related to your use of ShiftEasy Material and ShiftEasy Services, or for inaccessibility of ShiftEasy Material and ShiftEasy Services whether from errors or omissions in the content of ShiftEasy Material and ShiftEasy Services or any other linked sites or for any other reason. Use of ShiftEasy Material and ShiftEasy Services is at your own risk.</p>
                                    <p>ShiftEasy does not represent or warrant that ShiftEasy Material, the Site or any linked sites are free of any harmful materials.</p>
                                </li>
                                <li>
                                    <h6>Maximum Liability</h6>
                                    <p>Except as expressly provided in these Terms or where prohibited by law, the maximum liability of ShiftEasy is the amount paid to ShiftEasy by the customer. The maximum liability of ShiftEasy for any ShiftEasy Services is the portion of the amount paid to ShiftEasy by the customer specifically for the ShiftEasy Services as calculated by ShiftEasy. </p>
                                </li>
                                <li>
                                    <h6>Guarantees and Refunds</h6>
                                    <p>All guarantees are subject to any limitations specified in any ShiftEasy Material. Guarantees are only available to customers who paid on the product before the guarantee is claimed and are not available on free promotions. For a guarantee to apply to a product, it must be explicitly promoted in ShiftEasy Material for that product at the time of payment. A customer is only entitled to one Guarantee claim relating to one Guarantee category of one product. Should a customer accept a Guarantee related payment from ShiftEasy, they waive their right to any additional claims against ShiftEasy regarding any Guarantee unless otherwise agreed to in writing. ShiftEasy has no obligation to refund one time purchases. </p>
                                    <p>The following additional restrictions apply to the Guarantees payments for mistakes: </p>
                                    <ol className="mb-3 list-style-lower-alpha">
                                        <li>The mistake must be in the wording of the legal document and not in other text;</li>
                                        <li>The customer was the first person to mention that mistake for that Guarantee category;</li>
                                        <li>Guarantees only apply to ShiftEasy mistakes, not to customer mistakes;</li>
                                        <li>We are only required to pay a customer once for a mistake no matter how many mistakes they may bring to our attention. </li>
                                    </ol>
                                    <ol className="mb-3 pl-3 list-style-upper-alpha">
                                        <li>Free Trial Refund Policy</li>
                                        <p>The terms and conditions of the offer will govern any free trials. ShiftEasy has no obligation to refund for free trials properly converted to paid product access. </p>
                                        <li>Annual Subscriptions and Renewals</li>
                                        <p>ShiftEasy has no obligation to refund renewals unless you notify us before your renewal date that you want to cancel. Annual subscriptions are subject to a refund within 30 days of initial purchase. </p>
                                        <li>Legal Guarantee</li>
                                        <p>Subject to any limitations specified in any ShiftEasy Material, we will not cover any damage a customer suffers in a legal judgment. </p>
                                        <li>Other Guarantees and Refunds</li>
                                        <p>Our maximum liability for any Quality Guarantees specified on the Site is £100 in the currency of the product. Other guarantees and refund commitments specified in ShiftEasy Material are subject to a maximum of the amount paid by the customer and are subject to a claim being submitted within 30 days of purchase. </p>
                                    </ol>
                                </li>
                                <li>
                                    <h6>Client Privacy</h6>
                                    <p>You consent to ShiftEasy using your personal information according to its Privacy Policy that can be found at Privacy Policy.</p>
                                </li>
                                <li>
                                    <h6>Terms and Headings</h6>
                                    <p>All of the Terms are valid regardless of the heading. To the maximum extent possible under law, the Terms take precedence over any conflicting ShiftEasy Material should it be judicially found that the conflicting ShiftEasy Material is legally relevant to this Agreement under law. </p>
                                </li>
                                <li>
                                    <h6>Indemnification</h6>
                                    <p>Except where prohibited by law, you agree to indemnify and hold ShiftEasy, its directors, officers and employees, harmless from any actions, claims, losses, damages, liabilities and expenses including legal fees, asserted by any third party due to or arising out of your use of the Site or ShiftEasy Material or ShiftEasy Services.</p>
                                </li>
                                <li>
                                    <h6>Governing Law</h6>
                                    <p>Except where prohibited by law, the Terms are governed by the laws of the United Kingdom. Except where prohibited by law, you irrevocably attorn to the exclusive jurisdiction of the courts of United Kingdom.</p>
                                </li>
                                <li>
                                    <h6>Binding Arbitration</h6>
                                    <p>Subject to exceptions specified herein, if you and ShiftEasy are unable to resolve any dispute by informal negotiations, then any resolution of this dispute will be conducted exclusively by binding arbitration.</p>
                                    <p>A request for appointment of an arbitrator must be made in writing. Upon receipt of the written request, ShiftEasy will have 90 days to choose and appoint an independent and impartial arbitrator. The arbitration will be held in the United Kingdom. </p>
                                    <p>The cost of the binding arbitration proceedings and any proceeding in court to confirm or to vacate any arbitration award, including, without limitation, reasonable attorneys' fees and costs, will be borne by the unsuccessful party and will be determined and awarded by the arbitrator.</p>
                                    <p>Exceptions to the use of binding arbitration are as follows: ShiftEasy may bring forth a lawsuit, without using binding arbitration, should the lawsuit involve intellectual property infringement or injunctive relief. Also, either party may use small claims court. </p>
                                </li>
                                <li>
                                    <h6>Class Action Waiver</h6>
                                    <p>You will not seek to have the dispute heard as a class action, private attorney general action or in any other proceeding in which either party acts or proposes to act in a representative capacity. Arbitration or any other proceeding to resolve any dispute, in any forum, will be conducted solely on an individual basis and not combined with another without the prior written consent of all parties to all affected arbitrations or proceedings.</p>
                                </li>
                                <li>
                                    <h6>Modification of the Terms</h6>
                                    <p>Except that you must be informed in a reasonable manner regarding any pricing increase, ShiftEasy may, in its sole discretion, change these Terms at any time. Other terms and conditions are only valid when signed in writing by an authorised ShiftEasy officer.</p>
                                </li>
                                <li>
                                    <h6>Severability</h6>
                                    <p>If any term, covenant, condition or provision of these Terms is held by a court of competent jurisdiction to be invalid, void or unenforceable, such provision is to be excluded to the extent of such invalidity or unenforceability and all other provisions will remain in full force and effect; and, to the extent permitted and possible, the invalid or unenforceable provision will be deemed replaced by a provision that is valid and enforceable and that comes closest to expressing the intention of such invalid or unenforceable provision.</p>
                                </li>
                                <li>
                                    <h6>Waivers</h6>
                                    <p>No failure or delay, on the part of ShiftEasy, in exercising any right or power under these Terms will operate as a waiver of such right or power.</p>
                                </li>
                                <li>
                                    <h6>Whole Agreement</h6>
                                    <p>Except as explicitly set forth in this agreement, these Terms, the pricing displayed on the Site, the Site's Disclaimer, and, if you are an affiliate, the Affiliate Program Terms and Conditions, constitute the whole agreement between you and Indo UK B2B Consortium. notwithstanding any: ShiftEasy Material other than this Agreement, communication between you and ShiftEasy, including telephone, email and online chat assistance, or announcements, newsletters or promotional materials from ShiftEasy.</p>
                                </li>
                                <li>
                                    <h6>Third Party Services</h6>
                                    <p>This site uses the Google API and by using this site you are also bound by Google's Terms of Service.</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                <Footer/>
            </div>
        )
    }
}
export default termsConditions;