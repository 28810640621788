import React, { Component } from 'react';
import './facilities-near-you.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import search2 from '../../../../assets/images/search-w.svg';
import ItemsCarousel from 'react-items-carousel';
import leftarrow from '../../../../assets/images/services-page/15-facilities-near-you/left-arrow.svg';
import rightarrow from '../../../../assets/images/services-page/15-facilities-near-you/right-arrow.svg';
import food1 from '../../../../assets/images/services-page/15-facilities-near-you/food1.png';
import food2 from '../../../../assets/images/services-page/15-facilities-near-you/food2.png';
import food3 from '../../../../assets/images/services-page/15-facilities-near-you/food3.png';
import food4 from '../../../../assets/images/services-page/15-facilities-near-you/food4.png';
import school1 from '../../../../assets/images/services-page/15-facilities-near-you/school1.png';
import school2 from '../../../../assets/images/services-page/15-facilities-near-you/school2.png';
import school3 from '../../../../assets/images/services-page/15-facilities-near-you/school3.png';
import school4 from '../../../../assets/images/services-page/15-facilities-near-you/school4.png';
import edu1 from '../../../../assets/images/services-page/15-facilities-near-you/edu1.png';
import edu2 from '../../../../assets/images/services-page/15-facilities-near-you/edu2.png';
import edu3 from '../../../../assets/images/services-page/15-facilities-near-you/edu3.png';
import edu4 from '../../../../assets/images/services-page/15-facilities-near-you/edu4.png';
import buis1 from '../../../../assets/images/services-page/15-facilities-near-you/buis1.png';
import buis2 from '../../../../assets/images/services-page/15-facilities-near-you/buis2.png';
import buis3 from '../../../../assets/images/services-page/15-facilities-near-you/buis3.png';
import buis4 from '../../../../assets/images/services-page/15-facilities-near-you/buis4.png';
import { scrollTop } from '../../common/common';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
class FacilitiesNearYou extends Component {

    constructor(props) {
        super(props)
        this.state = {
            foodFacNearItemIndex: 0,
            schoolFacNearItemIndex: 0,
            eduFacNearItemIndex: 0,
            buisFacNearItemIndex: 0,
            width: 0
        }
        window.addEventListener("resize", this.diviceSizeUpdate);
    }
    foodFacNearActiveItem = (foodFacNearItemIndex) => {
        this.setState({ foodFacNearItemIndex });
    }
    schoolFacNearActiveItem = (schoolFacNearItemIndex) => {
        this.setState({ schoolFacNearItemIndex });
    }
    eduFacNearActiveItem = (eduFacNearItemIndex) => {
        this.setState({ eduFacNearItemIndex });
    }
    buisFacNearActiveItem = (buisFacNearItemIndex) => {
        this.setState({ buisFacNearItemIndex });
    }

    componentDidMount() {
        scrollTop();
    }

    render() {
        return (

            <div>
                <Helmet>
                    <title>Best Facilities Near You | Find Local Services Near You | ShiftEasy</title>
                    <meta name="description" content="Find Facilities Near you like Painter, Carpainter, Plumber, Taylor, Parlour, Salon and many more others." />
                    <meta name="keywords" content="Facilities Near You" />
                </Helmet>
                <Header />
                <main className="container-fluid px-0">
                    <div className="page-top-banner mb-3 mb-lg-5 serv-fifteen">
                        <h1 className="text-center text-white mb-3"><span className="text-orange">Facilities </span>Near You </h1>
                        <h5 className="text-center text-white mb-3">Find the expert Services near you</h5>
                        <div className="row justify-content-center mb-0 mb-lg-4">
                            <div className="btn-group col-md-6" role="group" aria-label="Button group with nested dropdown">
                                <input type="text" className="form-control h-100 br-0 border-0 d-mob-none bg-white" placeholder="Search for your services here" />
                                <button type="button" style={{ lineHeight: 2.5 }} className="btn btn-blue br-0 d-mob-none">Search</button>
                            </div>
                        </div>
                        <div className="d-web-none mt-2">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control br-0 border-0 bg-white" placeholder="Search for your services here" />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-orange br-0"><img src={search2} alt="Search" /></button>
                                </div>
                            </div>
                        </div>
                        <h6 className="text-center fs-14 text-white mt-2">Please type on the above field to search your service</h6>
                    </div>
                    <div className="row my-lg-5 my-4 ">
                        <div className="col-md-12">
                            <div className="service-content-box text-center">
                                <h5 className="page-title text-capitalize">Connect To Your Local Business Services</h5>
                                <h6 className="page-sub-title text-capitalize">Click on Get Quote so that you will get call from service provider</h6>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid px-2 px-lg-4">
                        <div className="px-3 corner-nav-inner my-5">
                            <h6 className="page-sub-title m-2">Food Near You </h6>
                            <ItemsCarousel
                                requestToChangeActive={this.foodFacNearActiveItem}
                                activeItemIndex={this.state.foodFacNearItemIndex}
                                numberOfCards={window.innerWidth > 550 ? 4 : 1}
                                gutter={20}
                                leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                                rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                                outsideChevron
                                chevronWidth={40} >
                                <div className="cus-card-item bg-light">
                                    <img src={food1} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Hotel</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={food2} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Restaurant</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={food3} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Food Delivery</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={food4} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Dark Kitchen</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={food1} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Hotel</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={food2} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Restaurant</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={food3} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Food Delivery</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                            </ItemsCarousel>
                        </div>
                    </div>
                    <div className="container-fluid px-2 px-lg-4">
                        <div className="px-3 corner-nav-inner my-5">
                            <h6 className="page-sub-title m-2">School Shop Facilities Near You</h6>
                            <ItemsCarousel
                                requestToChangeActive={this.schoolFacNearActiveItem}
                                activeItemIndex={this.state.schoolFacNearItemIndex}
                                numberOfCards={window.innerWidth > 550 ? 4 : 1}
                                gutter={20}
                                leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                                rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                                outsideChevron
                                chevronWidth={40} >
                                <div className="cus-card-item bg-light">
                                    <img src={school1} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Fire Station</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={school2} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Hospital</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={school3} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Shopping Mall</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={school4} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">School</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={school1} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Fire Station</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={school2} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Hospital</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={school3} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Shopping Mall</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                            </ItemsCarousel>
                        </div>
                    </div>
                    <div className="container-fluid px-2 px-lg-4">
                        <div className="px-3 corner-nav-inner my-5">
                            <h6 className="page-sub-title m-2">Education & Training </h6>
                            <ItemsCarousel
                                requestToChangeActive={this.eduFacNearActiveItem}
                                activeItemIndex={this.state.eduFacNearItemIndex}
                                numberOfCards={window.innerWidth > 550 ? 4 : 1}
                                gutter={20}
                                leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                                rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                                outsideChevron
                                chevronWidth={40} >
                                <div className="cus-card-item bg-light">
                                    <img src={edu1} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Entrance Exam Coaching</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={edu2} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Job Training</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={edu3} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Distance Education</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={edu4} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">School Tutions</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={edu1} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Distance Education</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={edu2} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Job Training</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={edu3} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Entrance Exam Coaching</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                            </ItemsCarousel>
                        </div>
                    </div>
                    <div className="container-fluid px-2 px-lg-4">
                        <div className="px-3 corner-nav-inner my-5">
                            <h6 className="page-sub-title m-2">Business Services</h6>
                            <ItemsCarousel
                                requestToChangeActive={this.buisFacNearActiveItem}
                                activeItemIndex={this.state.buisFacNearItemIndex}
                                numberOfCards={window.innerWidth > 550 ? 4 : 1}
                                gutter={20}
                                leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                                rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                                outsideChevron
                                chevronWidth={40} >
                                <div className="cus-card-item bg-light">
                                    <img src={buis1} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Web Designers</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={buis2} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Internet Service Provider</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={buis3} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Domestic Couriers</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={buis4} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Forex Services</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={buis1} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Domestic Couriers</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={buis2} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Internet Service Provider</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                                <div className="cus-card-item bg-light">
                                    <img src={buis3} alt="Card image" className="img-fluid cus-card-image p-2" />
                                    <div className="d-flex flex-column p-2">
                                        <div className="align-items-center d-flex justify-content-between">
                                            <div className="align-items-center d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 font-weight-bold mr-2">Web Designers</span>
                                            </div>
                                            <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                                <span className="fs-14 ml-2">500 Services</span>
                                            </div>
                                        </div>
                                        <div className="my-1">
                                            <p className="text-dark d-inline fs-13">We can connect you to the right Entrance Exam Coaching in Chennai</p>
                                        </div>
                                        <a href="/#" type="button" className="btn btn-block btn-orange mt-2">Get Quote</a>
                                    </div>
                                </div>
                            </ItemsCarousel>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FacilitiesNearYou));