// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.membership-toggle {
    background-color: #F1EFEF !important;
}

.membership-toggle .custom-switch {
    box-shadow: none;
    transform: scale(1.2);
    padding-left: 50px;
}

.membership-toggle .custom-switch .custom-control-input:checked~.custom-control-label::before,
.membership-toggle .custom-switch .custom-checkbox .custom-control-label::before {
    border-radius: 10px;
    transform: scale(1);
    box-shadow: none;
}

.membership-plans .col-md-4>div {
    box-shadow: 0 3px 6px #00000029;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
}

.membership-plans-title {
    color: #666666;
    text-align: center;
    font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/membership/membership.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;;IAEI,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".membership-toggle {\n    background-color: #F1EFEF !important;\n}\n\n.membership-toggle .custom-switch {\n    box-shadow: none;\n    transform: scale(1.2);\n    padding-left: 50px;\n}\n\n.membership-toggle .custom-switch .custom-control-input:checked~.custom-control-label::before,\n.membership-toggle .custom-switch .custom-checkbox .custom-control-label::before {\n    border-radius: 10px;\n    transform: scale(1);\n    box-shadow: none;\n}\n\n.membership-plans .col-md-4>div {\n    box-shadow: 0 3px 6px #00000029;\n    border-radius: 4px;\n    border: 1px solid #D9D9D9;\n}\n\n.membership-plans-title {\n    color: #666666;\n    text-align: center;\n    font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
