import React, { Component } from 'react';
import './virtual-conference.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import virtualServImg2 from '../../../../assets/images/services-page/11-professional-photos-video/image-2.png';
import virtualServImg3 from '../../../../assets/images/services-page/11-professional-photos-video/image-3.png';
import virtualServImg4 from '../../../../assets/images/services-page/11-professional-photos-video/image-4.png';
import virtualServImg6 from '../../../../assets/images/services-page/11-professional-photos-video/image-6.png';
import virtualServImg7 from '../../../../assets/images/services-page/11-professional-photos-video/image-7.png';
import virtualServImg8 from '../../../../assets/images/services-page/11-professional-photos-video/image-8.png';
import virtualServImg10 from '../../../../assets/images/services-page/11-professional-photos-video/image-10.jpg';
import virtualServImg11 from '../../../../assets/images/services-page/11-professional-photos-video/image-11.png';
import like from '../../../../assets/images/services-page/11-professional-photos-video/like.png';
import { scrollTop } from '../../common/common';
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
class VirtualConference extends Component {

    componentDidMount() {
        scrollTop();
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>Use 3D Virtual Tour, Professional Photography Services to Advertise Property</title>
                    <meta name="description" content="Use 3D Virtual Tour, Professional Photography Services to advertise your property in the market. A Professional photograpghy services increase the chance of selling the property upto 10X." />
                    <meta name="keywords" content="Professional Photograpgy Services, 3D Virtual Tour" />
                </Helmet>
                <Header />
                <div className="service-banner serv-eleven page-top-banner banner-with-form mb-3 mb-lg-5">
                    <div className="service-banner-content text-center">
                        <h2 className="text-white mb-3">Professional<span className="text-orange"> Photos,</span> 3D<span className="text-orange"> Videos,</span> Virtual Conference</h2>
                    </div>
                    <div className="banner-form">
                        <form>
                            <div className="mb-3">
                                <input type="text" className="form-control br-0" placeholder="Full Name" />
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control br-0" placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Phone number" />
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control br-0" rows="4" placeholder="Message"></textarea>
                            </div>
                            <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                        </form>
                    </div>
                </div>
                <div className="container mx-auto">
                    <div className="row my-lg-5 mt-4 mb-5 align-items-center">
                        <div className="order-lg-2 col-lg-6">
                            <div className="service-content-box px-0 px-lg-4">
                                <h5 className="page-sub-title">Virtual Tours:</h5>
                                <p>Build your venue in virtual space. Create to-scale diagrams in minutes, render them in 3D with a click, and take your clients on a virtual reality tour of your property.</p>
                                <h5 className="page-sub-title">Show the client YOUR PROPERTY in vivid detail.</h5>
                                <p>A single click is all it takes to bring thorough floor plans to life in your virtual property space. You can display how their living plans will fit your space ahead of your planned site visit to your client.</p>
                            </div>
                        </div>
                        <div className="order-lg-1 col-lg-6">
                            <img src={virtualServImg2} className="img-fluid resp-img-ht" alt="img" />
                        </div>
                    </div>
                    <div className="row my-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="service-content-box px-0 px-lg-4">
                                <h5 className="page-sub-title">Promote Your Rental Properties with Shift Easy Tales:</h5>
                                <p>At ShiftEasy Property Management, we manage properties all over &#123;India/UK&#125;. Not all are blessed with an eye for beauty, and not everyone is skilled at photography. We are talking about the prominence of photography and your video needs in this Digital Era. </p>
                                <p><strong>At ShiftEasy Tales,</strong> we have in-house video makers and photographers <strong>creating a Home Brand of creative minds worldwide</strong> to fulfil your creative needs. </p>
                                <p>With ShiftEasy Tales, create your brand, create new memories, and record the priceless moments with our creative partners and team. It creates a terrific point that we want to discuss with landlords and rental property owners. </p>
                                <p>Your real estate agent or manager is more than likely not a professional photographer, either. Most of us are dedicated to managing or selling your home. Here comes our <strong>ShiftEasy Tales,</strong> team to rescue us from our digital photography and video needs. </p>
                                <p>Remember this when it is time to take advertising photos. You want the pictures of your property to make a difference in your marketing strategy.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={virtualServImg3} className="img-fluid resp-img-ht" alt="img" />
                        </div>
                    </div>
                    <div className="row my-5 align-items-center">
                        <div className="order-lg-2 col-lg-6">
                            <div className="service-content-box px-0 px-lg-4">
                                <h5 className="page-sub-title">Photographs are worth an asset:</h5>
                                <p>Most prospective tenants today are using the internet to find their next home. When a tenant is online and scrolling through the various listings they find, they will focus on the pictures. You have probably heard that say – Picture is worth 1,000 words. </p>
                                <p>We understand your organisation is unique, so we do not believe in "one size fits all" solutions. We pride ourselves on providing you with the complete 360° Drone Solution through world-leading drones, cameras, software, training, and ongoing support from various global suppliers. </p>
                                <p>At ShiftEasy Tales, we believe it is true. Upcoming renters are connecting through tons of clicks on homes online, and you want them to choose your house long enough for them to understand and appreciate what your home offers. </p>
                                <p>Excellent images will help your listing stand out from all the other rental properties that occupants are looking for. We bring home the expertise touch you are looking for.</p>
                            </div>
                        </div>
                        <div className="order-lg-1 col-lg-6">
                            <img src={virtualServImg4} className="img-fluid resp-img-ht" alt="img" />
                        </div>
                    </div>
                    <div className="bg-dark-blue mb-5 p-3 p-lg-4 h-100">
                        <h5 className="text-white text-center mb-4">Our Franchise partners specialize in:</h5>
                        <ul className="row pl-5 text-white fs-15 mb-0 orange-disc col-lg-10 offset-lg-1">
                            <li className="col-lg-6 mb-2">Photogrammetry</li>
                            <li className="col-lg-6 mb-2">3D Modelling and 2D Mapping</li>
                            <li className="col-lg-6 mb-2">Photography / Videography/ All events coverage</li>
                            <li className="col-lg-6 mb-2">Construction Drone Services</li>
                            <li className="col-lg-6 mb-2">Complete AI solutions</li>
                            <li className="col-lg-6 mb-2">Create your 360° drone solution from the largest range of partners around the world.</li>
                            <li className="col-lg-6 mb-2">BIM: Building Information Management/ Modelling</li>
                            <li className="col-lg-6 mb-2">Reality Capture</li>
                            <li className="col-lg-6 mb-2">Lidar (Light Detection and Ranging)</li>
                            <li className="col-lg-6 mb-2">360 Estate Agency tours (High quality, easy to use Virtual Tours with tons of unique features specifically designed for Estate & Letting Agents)</li>
                            <li className="col-lg-6 mb-2">Measured Building Survey And 3d Laser Scanning Surveying</li>
                            <li className="col-lg-6 mb-2">3d laser scanning surveying</li>
                        </ul>
                    </div>
                    <div className="service-banner serv-eleven-middle1 page-middle-banner mb-3 mb-lg-5">
                        <div className="service-banner-content text-center">
                            <h2 className="text-white mb-3"><span className="text-orange"> 3D</span> Video Service</h2>
                        </div>
                    </div>
                    <div className="my-5">
                        <div className="service-content-box px-0 px-lg-4">
                            <h5 className="page-title text-center mb-4">3D Modelling and 2D Mapping:</h5>
                            <ul className="pl-4">
                                <li>Site inspections and land surveying before construction begin.</li>
                                <li>Generate collaborative maps and 3D models Create accurate contour maps.</li>
                                <li>Nevertheless, many property managers are not investing in some money to produce beautiful photography. We think it is worth the money to showcase your rental home and give potential residents a good idea of what your property is all about.</li>
                                <li>We suggest you opt for the exclusive services provided by ShiftEasy Tales tailored to meet your digital needs.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="my-5">
                        <img src={virtualServImg6} className="img-fluid mb-3" alt="img" />
                    </div>
                    <div className="my-5">
                        <div className="service-content-box px-0 px-lg-4">
                            <h6>3d laser scanning surveying: </h6>
                            <p>Our prices for measured building surveys and 3d laser scanning surveying depend on numerous factors – such as the type and size of the site and level of detail required. Measured building survey and 3d laser scanning surveying delivered seamlessly!!! </p>
                            <p>Building Information Management with ShiftEasy Partners and Home brands: </p>
                            <h6>What is BIM utilized for? </h6>
                            <p>BIM is used for creating, organizing, and managing data during the design, construction, and operations process. BIM integrates multi-disciplinary building construction data to create detailed digital representations managed in an open cloud platform for real-time collaboration. </p>
                            <h6>Commercial Drone Solutions: </h6>
                            <p>We are equipped with Drones! ShiftEasy Tales is associated with drone experts that exist to build your organisation a 360° drone solution that saves money, reduces risk, and improves business efficiency.</p>
                        </div>
                    </div>
                    <div className="mb-3">
                        <h5 className="page-title text-center text-orange w-100 mb-3">Get Friend Droned now with ShiftEasy Tales!</h5>
                    </div>
                    <div className="row mb-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="service-content-box px-0 px-lg-4">
                                <h5 className="page-sub-title">Creating Your 3D Environments with Drones: </h5>
                                <p>My experiences have shown that creating 3D environment models from drone images is straightforward and feasible for any architecture design visualization. </p>
                                <p>Our partnering Drone companies, each with their own individual set of skills, are equipped to meet your needs. We also work with other professionals in construction and 3D Modelling to create bespoke services for our clients.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={virtualServImg7} className="img-fluid" alt="img" />
                        </div>
                    </div>
                    <div className="row my-5 align-items-center">
                        <div className="order-lg-2 col-lg-6">
                            <div className="service-content-box px-0 px-lg-4">
                                <h5 className="page-sub-title">Hiring Professional Photographers: </h5>
                                <p>At ShiftEasy Property Management, our goal during the leasing process is to ensure we get your property rented to the best people in the shortest amount of time and for the most money. One of the ways we do this is by investing in professional photography. </p>
                                <p>We want your home to stand out. We have several photographers on speed dial that we use regularly. We do this with every single property from the low end to the high end because it does make a difference. Your home will rent better with professional photography. You do not have to be one of our clients to reach out and ask for help. Contact us at ShiftEasy Tales if you have any questions about photography, leasing, or managing your properties.</p>
                            </div>
                        </div>
                        <div className="order-lg-1 col-lg-6">
                            <img src={virtualServImg8} className="img-fluid" alt="img" />
                        </div>
                    </div>
                    <div className="service-banner serv-eleven-middle2 page-middle-banner mb-3 mb-lg-5">
                        <div className="service-banner-content text-center">
                            <h2 className="text-white mb-3"><span className="text-orange"> Video Conferencing</span> To Help Realtors Give Virtual Home Tours</h2>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-md-12">
                            <div className="service-content-box px-0 px-lg-4">
                                <p>It feels like a race to tour a home for many homebuyers in red-hot markets as soon as it goes on the market and put in a competitive offer before someone else swoops in. On the other hand, real estate agencies must develop increasingly innovative ways to serve their many clients as competition rises across the country. </p>
                                <p>Thanks to the best small business video conferencing, homebuyers can take virtual home tours wherever they are. Fortunately, they have developed a smart solution for their needs: using the best small business video conferencing to host virtual tours. </p>
                                <p>These tours are for potential homebuyers who are unable to come to the properties themselves. Here's how virtual home tours work and why they are becoming popular for agents to show and sell homes.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row my-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="service-content-box px-0 px-lg-4">
                                <h5 className="page-sub-title">Showing Homes in Competitive Housing Markets: </h5>
                                <p>Until recently, it was a given that prospective buyers would want to see houses in person. Who would feel comfortable signing on the dotted line without making sure their future castle is not a crooked fix that could cost them serious money in hidden repairs? </p>
                                <p>To move forward, buyers first need to picture themselves in the property. That is why one of the primary obligations of a real estate agent has always been to show homes to prospective clients in person.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={virtualServImg10} className="img-fluid" alt="img" />
                        </div>
                    </div>
                    <div className="row my-5 align-items-center">
                        <div className="order-lg-2 col-lg-6">
                            <div className="service-content-box px-0 px-lg-4">
                                <h5 className="page-sub-title">The Best Small Business Video Conferencing: </h5>
                                <p>Real estate agencies can bridge the gap by taking prospective homebuyers directly inside a house or condo with video conferencing. Homebuyers can join in from wherever they are — at work, at their current homes, or on the go — carefully examining properties and asking detailed questions before making their decision on whether to buy. </p>
                                <h5 className="page-sub-title">A class apart: Convenience </h5>
                                <p>It provides convenience and flexibility for the clients, but it also benefits realtors, as they can show multiple buyers a home at once via a video conference. </p>
                                <p>Real estate agents can take advantage of other cloud application benefits that streamline their day-to-day tasks, such as shared contact information. </p>
                                <p>Realtors can also record their virtual home tours in advance, sending them out to prospective buyers who show interest in a particular property so they can view it on-demand now that works best for them.</p>
                            </div>
                        </div>
                        <div className="order-lg-1 col-lg-6">
                            <img src={virtualServImg11} className="img-fluid" alt="img" />
                        </div>
                    </div>
                    <div className="row my-5 mb-md-4 mx-1 refer-sec p-2 p-lg-4 br-5 align-items-lg-center">
                        <div className="col-lg-8 my-3">
                            <div className="service-content-box px-0 px-lg-4 pb-0">
                                <h5>Win-Win solutions with ShiftEasy: </h5>
                                <p>Given that virtual home tours can be a win-win for both real estate professionals and homebuyers, it is no surprise they are becoming more common. </p>
                                <p>So, when real estate agents take advantage of the best small business video conferencing, they also meet the expectations of growing residents and idealistic clients who are willing to conduct important transactions like these in a sophisticated, technology-enabled way.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 my-3 mb-3 col-12 text-center">
                            <img src={like} className="img-fluid px-lg-5 resp-img-ht" alt='like'/>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="service-content-box px-0 px-lg-4 text-center">
                            <h5 className="page-title mb-4">ShiftEasy Tales where Timeless Homes meet Modern Lifestyle!!!</h5>
                            <p className="mb-1">Our partners specialize in Wedding and special event coverages, do connect with them to know more details. </p>
                            <p>Feel free to mail us at <a href="mailto:contact@shifteasy.com" className="text-orange">contact@shifteasy.com</a></p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VirtualConference));