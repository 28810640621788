import React, { Component } from 'react';
import './legal-services-near-you.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import search2 from '../../../../assets/images/search-w.svg';
import { scrollTop } from '../../common/common';
import ItemsCarousel from 'react-items-carousel';
import leftarrow from '../../../../assets/images/leftarrow.png';
import rightarrow from '../../../../assets/images/rightarrow.png';
import starNotFilled from '../../../../assets/images/svg-icons/star-notfilled.svg';
import starFilled from '../../../../assets/images/svg-icons/star-filled.svg';
import phone from '../../../../assets/images/services-page/9-legal-services/phone.svg';
import tags from '../../../../assets/images/services-page/9-legal-services/tags.svg';
import experiance from '../../../../assets/images/services-page/9-legal-services/experience.svg';
import locationSmall from '../../../../assets/images/svg-icons/location-small.svg';
import estate from '../../../../assets/images/services-page/9-legal-services/estate.svg';
import family from '../../../../assets/images/services-page/9-legal-services/family.svg';
import personal from '../../../../assets/images/services-page/9-legal-services/personal-injury.svg';
import malpractice from '../../../../assets/images/services-page/9-legal-services/malpractice.svg';
import labour from '../../../../assets/images/services-page/9-legal-services/labor.svg';
import tax from '../../../../assets/images/services-page/9-legal-services/tax.svg';
import immigration from '../../../../assets/images/services-page/9-legal-services/businessman-with-tie.svg';
import card1 from '../../../../assets/images/services-page/9-legal-services/card1.png';
import card2 from '../../../../assets/images/services-page/9-legal-services/card2.png';
import card3 from '../../../../assets/images/services-page/9-legal-services/card3.png';
import card4 from '../../../../assets/images/services-page/9-legal-services/card4.png';
import svg1 from '../../../../assets/images/services-page/9-legal-services/teamwork.svg';
import svg2 from '../../../../assets/images/services-page/9-legal-services/money.svg';
import svg3 from '../../../../assets/images/services-page/9-legal-services/customer-service.svg';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {searchLawyerAPI} from '../../../../services/querys/get-APIs';
class LegalServicesNearYou extends Component {

    constructor(props) {
        super(props)
        this.state = {
            popLawyersServicesItemIndex: 0,
            topLawyersItemIndex: 0,
            width: 0,
            height: 0
        }
        window.addEventListener("resize", this.diviceSizeUpdate);
    }
    popLawyersServicesActiveItem = (popLawyersServicesItemIndex) => {
        this.setState({ popLawyersServicesItemIndex });
    }
    topLawyersActiveItem = (topLawyersItemIndex) => {
        this.setState({ topLawyersItemIndex });
    }

    componentDidMount() {
        scrollTop();
    }
    diviceSizeUpdate = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    navToListPage = () => {
        this.props.history.push("/legal-services-near-you-search-list")
    }

    searchLawyer =(searchText)=>{
        searchLawyerAPI(searchText).then((res)=>{
            console.log("res",res)
        })
        .catch((error)=>{
            console.log('error',error)
        })
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>Legal Services Near You | Online Legal Services in India | ShiftEasy</title>
                    <meta name="description" content="ShiftEasy offers a platform, where you can find Online Legal Services in India. Visit the Website and find India's best Legal Services Near you" />
                    <meta name="keywords" content="Legal Services Near You, Legal Services in India" />
                </Helmet>
                <Header />
                <div className="page-top-banner service-banner mb-3 mb-lg-5 serv-nine">
                    <h1 className="text-center text-white mb-3">Lawyer <span className="text-orange">Services </span></h1>
                    <h5 className="text-center text-white mb-3">We are fast, we are digital, and will be proactive in progressing your property transaction</h5>
                    <h4 className="text-center text-white mb-3">Shifteasy <span className="text-orange">Platform </span>| Shifteasy <span className="text-orange">Partner App </span></h4>
                    <div className="row justify-content-center mb-0 mb-lg-4">
                        <div className="btn-group col-md-6" role="group" aria-label="Button group with nested dropdown">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control br-0 border-0 bg-white" placeholder="Search lawyers by Street/location or Type" />
                                <div className="input-group-append">
                                    <button onClick={this.navToListPage} type="button" className="btn btn-orange br-0"><img src={search2} alt="Search" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid px-4 px-lg-5">
                    <div className="px-4 corner-nav mb-5">
                        <h5 className="text-center page-title mb-4">Popular Services</h5>
                        <ItemsCarousel
                            requestToChangeActive={this.popLawyersServicesActiveItem}
                            activeItemIndex={this.state.popLawyersServicesItemIndex}
                            numberOfCards={window.innerWidth > 550 ? 7 : 2}
                            gutter={20}
                            leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                            rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                            outsideChevron
                            chevronWidth={40} >
                            <div className="popular-services-item">
                                <img src={estate} alt="Estate Planning Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Estate Planning Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={family} alt="Family Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Family Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={personal} alt="Personal Injury Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Personal Injury Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={malpractice} alt="Malpractice Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Malpractice Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={labour} alt="Labor Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Labor Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={tax} alt="Tax Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Tax Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={immigration} alt="Immigration Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Immigration Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={estate} alt="Estate Planning Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Estate Planning Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={family} alt="Family Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Family Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={personal} alt="Personal Injury Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Personal Injury Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={malpractice} alt="Malpractice Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Malpractice Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={labour} alt="Labor Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Labor Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={tax} alt="Tax Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Tax Lawyers</span>
                            </div>
                            <div className="popular-services-item">
                                <img src={immigration} alt="Immigration Lawyers Icon" height="50px" />
                                <span className="mt-2 text-center">Immigration Lawyers</span>
                            </div>
                        </ItemsCarousel>
                        <button className="btn btn-outline-orange mt-3 mb-5 w-auto mx-auto d-block">View All</button>
                    </div>
                </div>
                <div className="container-fluid px-4 px-lg-5">
                    <div className="px-4 corner-nav mb-5">
                        <h5 className="text-center page-title mb-4">Top Lawyers in India</h5>
                        <ItemsCarousel
                            requestToChangeActive={this.topLawyersActiveItem}
                            activeItemIndex={this.state.topLawyersItemIndex}
                            numberOfCards={window.innerWidth > 550 ? 4 : 1}
                            gutter={20}
                            leftChevron={<img src={leftarrow} alt="Left Arrow" style={{ width: '40px' }} className="carosel-control-left" />}
                            rightChevron={<img src={rightarrow} alt="Right Arrow" style={{ width: '40px' }} className="carosel-control-right" />}
                            outsideChevron
                            chevronWidth={40} >
                            <div className="cus-card-item">
                                <img src={card1} alt="Card image" className="img-fluid cus-card-image" />
                                <div className="d-flex flex-column p-2">
                                    <div className="mob-lh-12 my-1">
                                        <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                    </div>
                                    <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                        <span className="fs-14 mr-2">4.5</span>
                                        <span className="add-rating">
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starNotFilled} className="mr-1" />
                                        </span>&nbsp;&nbsp;
                                        <span className="fs-14">(100+ Users)</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={phone} className="mr-1" />&nbsp;
                                        <span className="fs-14">+91 994052370, +91 994052370</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={tags} className="mr-1" />&nbsp;
                                        <span className="fs-14">Merlyn Law Firm</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={experiance} className="mr-1" />&nbsp;
                                        <span className="fs-14">10 year Experiance</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={locationSmall} className="mr-1" />&nbsp;
                                        <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                    </div>
                                    <a type="button" href="/legal-services-near-you-detailed" className="btn btn-block btn-orange mt-2">View Details</a>
                                </div>
                            </div>
                            <div className="cus-card-item">
                                <img src={card2} alt="Card image" className="img-fluid cus-card-image" />
                                <div className="d-flex flex-column p-2">
                                    <div className="mob-lh-12 my-1">
                                        <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                    </div>
                                    <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                        <span className="fs-14 mr-2">4.5</span>
                                        <span className="add-rating">
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starNotFilled} className="mr-1" />
                                        </span>&nbsp;&nbsp;
                                        <span className="fs-14">(100+ Users)</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={phone} className="mr-1" />&nbsp;
                                        <span className="fs-14">+91 994052370, +91 994052370</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={tags} className="mr-1" />&nbsp;
                                        <span className="fs-14">Merlyn Law Firm</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={experiance} className="mr-1" />&nbsp;
                                        <span className="fs-14">10 year Experiance</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={locationSmall} className="mr-1" />&nbsp;
                                        <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                    </div>
                                    <a type="button" href="/legal-services-near-you-detailed" className="btn btn-block btn-orange mt-2">View Details</a>
                                </div>
                            </div>
                            <div className="cus-card-item">
                                <img src={card3} alt="Card image" className="img-fluid cus-card-image" />
                                <div className="d-flex flex-column p-2">
                                    <div className="mob-lh-12 my-1">
                                        <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                    </div>
                                    <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                        <span className="fs-14 mr-2">4.5</span>
                                        <span className="add-rating">
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starNotFilled} className="mr-1" />
                                        </span>&nbsp;&nbsp;
                                        <span className="fs-14">(100+ Users)</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={phone} className="mr-1" />&nbsp;
                                        <span className="fs-14">+91 994052370, +91 994052370</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={tags} className="mr-1" />&nbsp;
                                        <span className="fs-14">Merlyn Law Firm</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={experiance} className="mr-1" />&nbsp;
                                        <span className="fs-14">10 year Experiance</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={locationSmall} className="mr-1" />&nbsp;
                                        <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                    </div>
                                    <a type="button" href="/legal-services-near-you-detailed" className="btn btn-block btn-orange mt-2">View Details</a>
                                </div>
                            </div>
                            <div className="cus-card-item">
                                <img src={card4} alt="Card image" className="img-fluid cus-card-image" />
                                <div className="d-flex flex-column p-2">
                                    <div className="mob-lh-12 my-1">
                                        <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                    </div>
                                    <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                        <span className="fs-14 mr-2">4.5</span>
                                        <span className="add-rating">
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starNotFilled} className="mr-1" />
                                        </span>&nbsp;&nbsp;
                                        <span className="fs-14">(100+ Users)</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={phone} className="mr-1" />&nbsp;
                                        <span className="fs-14">+91 994052370, +91 994052370</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={tags} className="mr-1" />&nbsp;
                                        <span className="fs-14">Merlyn Law Firm</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={experiance} className="mr-1" />&nbsp;
                                        <span className="fs-14">10 year Experiance</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={locationSmall} className="mr-1" />&nbsp;
                                        <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                    </div>
                                    <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                </div>
                            </div>
                            <div className="cus-card-item">
                                <img src={card1} alt="Card image" className="img-fluid cus-card-image" />
                                <div className="d-flex flex-column p-2">
                                    <div className="mob-lh-12 my-1">
                                        <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                    </div>
                                    <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                        <span className="fs-14 mr-2">4.5</span>
                                        <span className="add-rating">
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starNotFilled} className="mr-1" />
                                        </span>&nbsp;&nbsp;
                                        <span className="fs-14">(100+ Users)</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={phone} className="mr-1" />&nbsp;
                                        <span className="fs-14">+91 994052370, +91 994052370</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={tags} className="mr-1" />&nbsp;
                                        <span className="fs-14">Merlyn Law Firm</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={experiance} className="mr-1" />&nbsp;
                                        <span className="fs-14">10 year Experiance</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={locationSmall} className="mr-1" />&nbsp;
                                        <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                    </div>
                                    <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                </div>
                            </div>
                            <div className="cus-card-item">
                                <img src={card2} alt="Card image" className="img-fluid cus-card-image" />
                                <div className="d-flex flex-column p-2">
                                    <div className="mob-lh-12 my-1">
                                        <h6 className="text-dark d-inline fs-15 font-weight-bold">Merlyn Law Firm</h6>
                                    </div>
                                    <div className="mb-1 mob-lh-12 d-flex align-items-center">
                                        <span className="fs-14 mr-2">4.5</span>
                                        <span className="add-rating">
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starFilled} className="mr-1" />
                                            <img src={starNotFilled} className="mr-1" />
                                        </span>&nbsp;&nbsp;
                                        <span className="fs-14">(100+ Users)</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={phone} className="mr-1" />&nbsp;
                                        <span className="fs-14">+91 994052370, +91 994052370</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={tags} className="mr-1" />&nbsp;
                                        <span className="fs-14">Merlyn Law Firm</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={experiance} className="mr-1" />&nbsp;
                                        <span className="fs-14">10 year Experiance</span>
                                    </div>
                                    <div className="align-items-baseline d-flex mb-1 mob-lh-12">
                                        <img src={locationSmall} className="mr-1" />&nbsp;
                                        <span className="fs-14">Basha Street Choolaimedu, Chennai</span>
                                    </div>
                                    <a type="button" className="btn btn-block btn-orange mt-2">View Details</a>
                                </div>
                            </div>
                        </ItemsCarousel>
                        <button className="btn btn-outline-orange mt-3 mb-5 w-auto mx-auto d-block">View All</button>
                    </div>
                </div>
                <div className="container">
                    <h5 className="text-center page-title mb-4">Find Lawyers by</h5>
                    <ul className="nav nav-pills serv-pg-tab" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="pills-city-tab" data-toggle="pill" href="#pills-city" role="tab" aria-controls="pills-city" aria-selected="true">City</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="pills-practice-tab" data-toggle="pill" href="#pills-practice" role="tab" aria-controls="pills-practice" aria-selected="false">Practice</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="pills-court-tab" data-toggle="pill" href="#pills-court" role="tab" aria-controls="pills-court" aria-selected="false">Court</a>
                        </li>
                    </ul>
                    <div className="tab-content serv-pg-tab-content p-3 py-md-5 mb-5" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-city" role="tabpanel" aria-labelledby="pills-city-tab">
                            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="btn btn-outline-default bg-white btn-city active">
                                    <input type="radio" name="options" id="optionA1" /> Chennai</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA2" /> Bengaluru</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA3" /> Jaipur</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA4" /> Kolkata</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA1" /> Chennai</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA2" /> Bengaluru</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA3" /> Jaipur</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA4" /> Kolkata</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA1" /> Chennai</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA2" /> Bengaluru</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA3" /> Jaipur</label>
                                <label className="btn btn-outline-default bg-white btn-city">
                                    <input type="radio" name="options" id="optionA4" /> Kolkata</label>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-practice" role="tabpanel" aria-labelledby="pills-practice-tab">...</div>
                        <div className="tab-pane fade" id="pills-court" role="tabpanel" aria-labelledby="pills-court-tab">...</div>
                    </div>
                    <h5 className="text-center page-title mb-4">Our Services</h5>
                    <div className="row mb-lg-5 mb-4">
                        <div className="col-md-4 mb-3 px-5">
                            <img src={svg1} alt="icon" className="my-3" />
                            <h5 className="page-sub-title">Dedicated conveyancer</h5>
                            <p className="mb-0">We will provide a dedicated conveyancer to your case, ensuring you get the right advice when you need it</p>
                        </div>
                        <div className="col-md-4 mb-3 px-5">
                            <img src={svg2} alt="icon" className="my-3" />
                            <h5 className="page-sub-title">Transparent fixed fees</h5>
                            <p className="mb-0">We work on a fixed fee basis with no hidden costs, so that you have financial peace of mind</p>
                        </div>
                        <div className="col-md-4 mb-3 px-5">
                            <img src={svg3} alt="icon" className="my-3" />
                            <h5 className="page-sub-title">Outstanding Customer Service</h5>
                            <p className="mb-0">We commit to provide a responsive service with regular updates, as set out in our Customer Charter</p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-light">
                    <h5 className="page-title text-center pt-4 pb-3">Related To Your Search</h5>
                    <div className="container">
                        <div className="row mx-3 pb-4">
                            <div className="col-md-4">
                                <a className="mb-2 docpg-search-links">Lawyer in Chennai</a>
                                <a className="mb-2 docpg-search-links">Lawyer in Jaipur</a>
                                <a className="mb-2 docpg-search-links">Lawyer in Bhopal</a>
                                <a className="mb-2 docpg-search-links">Lawyer in Pune</a>
                            </div>
                            <div className="col-md-4">
                                <a className="mb-2 docpg-search-links">Lawyer in Chennai</a>
                                <a className="mb-2 docpg-search-links">Lawyer in Jaipur</a>
                                <a className="mb-2 docpg-search-links">Lawyer in Bhopal</a>
                                <a className="mb-2 docpg-search-links">Lawyer in Pune</a>
                            </div>
                            <div className="col-md-4">
                                <a className="mb-2 docpg-search-links">Lawyer in Chennai</a>
                                <a className="mb-2 docpg-search-links">Lawyer in Jaipur</a>
                                <a className="mb-2 docpg-search-links">Lawyer in Bhopal</a>
                                <a className="mb-2 docpg-search-links">Lawyer in Pune</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LegalServicesNearYou));