import React, { Component } from 'react';
import './blogs_new.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import blog from '../../../../assets/images/blogs/image-1.png';
import blog1 from '../../../../assets/images/blogs/image-2.png';
import blog2 from '../../../../assets/images/blogs/image-3.png';
import blog3 from '../../../../assets/images/blogs/image-4.png';
import blog4 from '../../../../assets/images/blogs/image-5.png';
import blog5 from '../../../../assets/images/blogs/image-6.png';
import blog6 from '../../../../assets/images/blogs/image-7.png';
import blog7 from '../../../../assets/images/blogs/image-8.png';
import blog8 from '../../../../assets/images/blogs/image-9.png';
import { scrollTop } from '../../common/common';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactPaginate from 'react-paginate';
class Blogs extends Component {
    constructor() {
        super();
        this.state = {
            courses: [],
            activePage: 15,
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0
        };

    }
    componentDidMount() {
        scrollTop();
    }

    // handlePageClick = (e) => {
    //     const selectedPage = e.selected;
    //     const offset = selectedPage * this.state.perPage;

    //     this.setState({
    //         currentPage: selectedPage,
    //         offset: offset
    //     }, () => {
    //         this.receivedData()
    //     });

    // };
    // receivedData() {
    //     axios
    //         .get(`https://jsonplaceholder.typicode.com/photos`)
    //         .then(res => {
    //             const data = res.data;
    //             const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    //             const postData = slice.map(pd => <React.Fragment>
    //                 <p>{pd.title}</p>
    //                 <img src={pd.thumbnailUrl} alt=""/>
    //             </React.Fragment>)
    //             this.setState({
    //                 pageCount: Math.ceil(data.length / this.state.perPage),
    //                 postData
    //             })
    //         });
    // }
    componentDidMount() {
        // this.receivedData()
        window.scrollTo(0,0)
    }

    render() {

        return (
            <div>

                <div className="header">
                    <Header />
                </div>
                <div className=" serv-thirteen page-top-banner w-100  ">
                    <div className="service-banner-content justify-content-center blog-sec">
                        <h1 className="text-center text-white">Our Blogs & News</h1>
                    </div>
                </div>
                <br />
                <br />
                <div className="mx-auto container-fluid ">
                    <div id="blog_row" class="row  justify-content-around mx-auto px-lg-5 mb-5">
                        <div class="col-lg-4 col-xs-12 col-md-4 mb-5">
                            <Link style={{textDecoration:"none"}} to={{pathname:"/BlogDetailPage" , refreshpath:'yes'}}>
                            <div class="card card_image shadow h-auto ">
                                <img
                                    src={blog}
                                    class="card-img-top br-0 p-2  "
                                    alt="No-image"
                                />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-muted text-right"> by &nbsp;<span className="text-orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div class="col-lg-4 col-xs-12 col-md-4 mb-5">
                            <div class="card card_image shadow h-auto ">
                                <img
                                    src={blog1}
                                    class="card-img-top br-0 p-2 "
                                    alt="No-image"
                                />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-muted text-right"> by &nbsp;<span className="text-orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-xs-12 col-md-4 ">
                            <div class="card card_image shadow h-auto ">
                                <img
                                    src={blog2}
                                    class="card-img-top br-0 p-2 "
                                    alt="No-image"
                                />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-muted text-right"> by &nbsp;<span className="text-orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="blog_row" class="row  justify-content-around mx-auto px-lg-5 mb-5">
                        <div class="col-lg-4 col-xs-12 col-md-4 mb-5">
                            <div class="card card_image shadow h-auto ">
                                <img
                                    src={blog3}
                                    class="card-img-top br-0 p-2 "
                                    alt="No-image"
                                />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-muted text-right"> by &nbsp;<span className="text-orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-xs-12 col-md-4 mb-5">
                            <div class="card  card_image shadow h-auto">
                                <img
                                    src={blog4}
                                    class="card-img-top br-0 p-2 "
                                    alt="No-image"
                                />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-muted text-right"> by &nbsp;<span className="text-orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-xs-12 col-md-4 ">
                            <div class="card card_image shadow h-auto ">
                                <img
                                    src={blog5}
                                    class="card-img-top br-0 p-2 "
                                    alt="No-image"
                                />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-muted text-right"> by &nbsp;<span className="text-orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="blog_row" class="row justify-content-around mx-auto px-lg-5 mb-5">
                        <div class="col-lg-4 col-xs-12 col-md-4 mb-5">
                            <div class="card card_image shadow h-auto ">
                                <img
                                    src={blog6}
                                    class="card-img-top br-0 p-2 "
                                    alt="No-image"
                                />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-muted text-right"> by &nbsp;<span className="text-orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-xs-12 col-md-4 mb-5">
                            <div class="card  card_image shadow h-auto">
                                <img
                                    src={blog7}
                                    class="card-img-top br-0 p-2 "
                                    alt="No-image"
                                />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-muted text-right"> by &nbsp;<span className="text-orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-xs-12 col-md-4 ">
                            <div class="card card_image shadow  h-auto ">
                                <img
                                    src={blog8}
                                    class="card-img-top br-0 p-2 "
                                    alt="No-image"
                                />
                                <div class="card-body card_body  p-2">
                                    <h5 class="card-title mb-1 p-1">How To Become a Real Estate Agent in India?</h5>
                                    <p className="d-flex justify-content-between mb-0 mt-2 p-1"> <h6>4th Dec 2021 </h6> <span className="text-muted text-right"> by &nbsp;<span className="text-orange text-right">Antim Panday</span></span> </p>
                                    <p class="card-text p-1">
                                        Real Estate Agents Are The Link Between a Property Buyer And a Seller If u Wonder How To Become A Real Estate Agent In India Nature,
                                        culture, camping: One year on, how have Australians coped with the border closures...
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="pagination justify-content-center">

                    <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        // onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />



                </div>

                <br /><br />
                <Footer />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Blogs));