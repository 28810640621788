import { Types } from '../actions/actionTypes';

const initialState = {
    propId:null,
    errorMessage: null,
    propTypes:{}

}
export const propertyAdd = (state = initialState, action) => {
    switch (action.type) {

        case Types.ADD_PROPERTY_SUCCESS:
            return {
                ...state,
                propId: action.payload,
            };
        case Types.ADD_PROPERTY_FAILURE:
            return {
                ...state,
                errorMessage: action.payload
            };
            case Types.PROPERTY_TYPE:
                return {
                    ...state,
                    propTypes: action.payload
                };
                case Types.REMOVE_PROPERTY_ID:
                    return {
                        ...state,
                        propId: action.payload
                    };
        default:
            return state
    }
}