import React,{Component} from 'react';
import './investor-services.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import investorServImg1 from '../../../../assets/images/services-page/12-investor-services/image-1.jpg' ;
import investorServImg2 from '../../../../assets/images/services-page/12-investor-services/image-2.png' ;
import investorServImg3 from '../../../../assets/images/services-page/12-investor-services/image-3.png' ;
import investorServImg4 from '../../../../assets/images/services-page/12-investor-services/image-4.png' ;
import investorServImg5 from '../../../../assets/images/services-page/12-investor-services/image-5.png' ;
import investorServImg6 from '../../../../assets/images/services-page/12-investor-services/image-6.png' ;
import {scrollTop} from '../../common/common';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
class InvestorServices extends Component {

    componentDidMount(){
        scrollTop();
     }

    render(){
        return(
            <div>
            <Header/>
                <div className="service-banner serv-twelve page-top-banner banner-with-form mb-3 mb-lg-5">
                    <div className="service-banner-content text-center">
                        <h2 className="text-white mb-3"><span className="text-orange"> Investor</span> Services</h2>
                        <h5>The smarter alternative to buy-to-let</h5>
                    </div>
                    <div className="banner-form">
                        <form>
                            <div className="mb-3">
                                <input type="text" className="form-control br-0" placeholder="Full Name"/>
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control br-0" placeholder="Email"/>
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Phone number"/>
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control br-0" rows="4" placeholder="Message"></textarea>
                            </div>
                            <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                        </form>
                    </div>
                </div>
                
                <div className="container mx-auto service-content-box">
                    <div className="row ">
                        <div className="col-md-12 ">
                            <div>
                                <h5 className="page-sub-title text-center">What's a REIT (Real Estate Investment Trust)?</h5>
                                <p>Real Estate Investment Trust or REITs are companies that maintain or finance revenue-producing real estates across a range of property and housing sector that is its possession on income-producing real estate in which it allows anyone to invest in the portfolios of real estate assets and a chance to the investors to acquire precious assets.</p>
                                <img src={investorServImg1} className="img-fluid mb-3" alt="icon"/>
                                <p>REITs help in providing an opportunity to access dividend-based income and total returns. To become a REIT, a company should fulfill or accomplish a predetermined necessity under SEBI or Securities and Exchange Board of India. The stockholders of a REIT earn a share of the income produced through real estate investment without having to go out and buy, manage, or finance the property. REITs buy and Develop properties for income generation but not developing and selling them; hence REITs are both equity and debt in nature.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h5 className="page-sub-title">REITs are of three types</h5><br/>
                            <p><span className="h6 text-dark">Equity, Mortgage</span> and <span className="h6 text-dark">Hybrid,</span> among which only equity is in operation under SEBI in India. Equity REITs – The majority of REITs are publicly traded equity REITs. Equity REITs own or operate income-producing real estate.<br/><br/>
                             The market and Nareit (National Association of real estate Investment Trusts) often refer to equity REITs simply as REITs. The main feature of REITs includes Competitive long-term performance, Substantial Stable dividend yields, Liquidity Transparency, and portfolio diversification.<br/><br/>
                              Our ShiftEasy comes into action here to help you with your REIT investments and give you the best returns out of it. Working Customer-oriented is providing deep insights into the ongoing business and making the process easier and delightful.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="service-content-box px-0">
                            <img src={investorServImg2} className="img-fluid p-0 mb-3" alt="icon"/>
                                <h6 className="page-sub-title">What do REITs do to make money ? </h6>
                                <p> Most REITs operate along with a straightforward and easily understandable business model by leasing space and collecting rent on its real estate; the company generates income paid out to shareholders in dividends.<br/><br/> REITs must payout at least 90% of their taxable income to shareholders, according to SEBI. In turn, shareholders pay the income taxes on those dividends. REITs (or mortgage REITs) do not own real estate directly. Instead, they finance real estate and earn income from the interest on these investments.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="service-content-box px-0">
                            <img src={investorServImg3} className="img-fluid p-0 mb-3" alt="icon"/>
                                <h6 className="page-sub-title">Why invest in Shifteasy REITs ? </h6>
                                <p> REITs historically have delivered total competitive returns based on high, steady dividend income and long-term capital appreciation. Their comparatively low correlation with other assets also makes them an excellent portfolio diversifier that can help reduce overall portfolio risk and increase returns.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12 ">
                            <div>
                                <h5 className="page-sub-title">How have REITs performed in the past ?</h5>
                                <p>REITs track record of reliable and growing dividends, combined with long-term capital appreciation through stock price increases, has provided investors with attractive total return performance for most periods over the past 45 years in the US compared to the broader stock market and bonds other assets.<br/><br/> Listed REITs are professionally managed, publicly traded companies that work their businesses to maximize shareholder value. REITs have demonstrated strong performance offering a capital appreciation of at least 7 percent compound annual growth rates and quarterly dividend payouts in the range of 1.8 percent to 2.3 percent until the last quarter of 2020.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12 ">
                            <div>
                                <h5 className="page-sub-title">Benefits of Shifteasy Real Estate Investment Trusts (REITs)</h5>
                                <p>Property Management Without Headaches. REITs allow the average investor to own commercial real estate. Returns Through Dividends. With equity stocks, management decides whether to pay dividends or reinvest profits back into the company. Returns Through Appreciation.<br/> Shift Easy makes the investment very easy, reducing the burden on your shoulders and getting the task done precisely in your interest with strength.</p>
                            </div>
                        </div>
                    </div>
                    <div  className="rent_market row ">
                        <div  className="index col-md-6 col-xs-12 ">
                           
                                <h5 className="page-sub-title  mb-1">Rent market index</h5>
                                <p>Rent index is an important statistic for tracking the space market and supporting asset allocation and performance measurement. Shifteasy was intended to offer greater transparency, flexibility, selection within the rent market index.<br/><br/> The Investment sector requires high levels of transparency, including carefully enforced laws and regulations, high quality, easily accessible market information and performance standards, clear and fair practices, and high professional standards. It allows businesses and investors to make decisions with confidence while enabling governments to function effectively, providing long-term benefits to local communities and the environment.</p>
                            
                        </div>
                        <div className="col-md-6 col-xs-12 ">
                            <div>
                               <img src={investorServImg4}  alt="image" className="img-fluid d-none d-sm-block p-4 mb-4"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h5>Why can we help predict voids?</h5><br/>
                            <h6>Shifteasy Property Data Analysis :</h6>
                            <p> Shifteasy Property Data provides you with the tools to make better decisions when buying property.</p>
                            <h6>Property data for Shifteasy Investors</h6>
                            <p> Be a smarter property investor using data to enhance your returns. Shifteasy helps to select a region to invest in used to involve diligence and speculation. Property data points such as prices, rents, demographics, and market structure help you decide a part that fits your investment profile.<br/><br/> Shifteasy analyzes the local market property, finds comparable, compares areas side-by-side, average prices, 3-year capital growth, and lists specialist property sourcing.</p>
                            <h6>Property data for Shifteasy Developers</h6>
                            <p> Property developers can use market research in decision-making. Shifteasy property data contains a wide range of market research to help you identify regions that connect your property development standards. Shifteasy knows your potential buyers and ensures improvements are equipped at the right level using local demographic data and understanding the local stock.</p>
                            <h6>Property data for ShiftEasy Agent:</h6>
                            <p> ShiftEasy Property Data provides a value properties model, supervises your region, finds quick comparable, keeps track of the competition, creates exclusive reports and inserts all the data on the agent tab.</p>
                            <h6>Valuation:</h6>
                            <p> Use Shifteasy market data & analytics to get a fast and precise valuation for a variety of properties.</p>
                            <h6>Value a house or apartment:</h6>
                            <p> ShiftEasy's programmed valuation model for houses and apartments uses primary data, including an incomparable database, to instantly tell you what any urban or residential property is worth. Apartment property comes with added values, such as security, enough parking spaces, power back-up, water systems and fire safety systems in the location.<br/><br/> Independent property needs additional effort to set up these services; ShiftEasy helps to conquer all the tasks.</p>
                            <h6>Valuation of development property:</h6>
                            <p> ShiftEasy valuation of development property. Shifteasy has a complete understanding of the process; it includes instructions and terms of commitment, site inquiries, data collection, managing, understanding and appliance to the valuation and reporting.</p>
                      </div>
                    </div>
                    <div className="row">  
                    <div className="col-md-6" style={{marginTop:"20px"}}> <img src={investorServImg5}  alt="image" className="img-fluid p-4"/></div>
                           <div id="return" className=" col-md-6">
                            <h5>Return On Investment</h5>
                            <p>Understanding the ROI (Return on Investment) allows you to be a more informed investor. Shifteasy helps you to understand the ROI before you buy, estimate your costs and expenses, as well as your rental income.<br/><br/> It gives you a chance to evaluate it to other comparable properties. Shifteasy calculates the percentage ROI for a cash purchase, takes the net profit or net gain on the investment, and divides it by the original cost.</p>
                           </div>
                    </div>
                    <div className="col-md-8">
                           <h6>How to invest in a particular country:</h6>
                           <p> Investing is essential to achieve your goals. It is the only way to make your future better. By making investments, you are also saving an improving amount. Making regular investments forces you to set aside a sum regularly, thus helps you inspire a sense of financial discipline in the long run.</p>
                           <h6>Foreigner investment hurdles – REIT: </h6>
                           <p>Foreigner investment hurdles in REITdetermines global risk factors and calculates hurdle rates across countries with those risk factors.<br/><br/> It evaluates a range of variables that may affect hurdle rates of return on real estate; they estimate a two-equation model that attempts to explain both economic and real estate market risk using a subset of these variables. The subset of variables used is determined analytically.<br/><br/> Using a simulation approach with parameters derived analytically from their model, it calculates hurdle rates of return by country, assuming that variations in the hurdle return due to appreciation are proportional to the variations due to income.</p>
                    </div>
                    <div className="row mb-0">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                        <h6 className="text-center">A Glimpse History</h6>
                        <p className="text-center"> The first REIT was started in the US in 1960 and was designed for small investors for owning commercial real estate properties and was modelled like mutual funds where the rents collected on the properties are distributed to shareholders as dividends.<br/><br/> In India, REITs were introduced in 2019. Investing in Property development will be a profitable idea for those who have a deep understanding and interest in property development or Real Estate Investment Trust. We at SHIFT EASY are among the top developers in the field since its establishment for Investing in Property Development.</p>
                        <div className="col-md-1"></div>
                        </div>
                    </div>
                    <div className="col-md-12">
                    <img src={investorServImg6}  alt="image" className="img-fluid p-0"/>
                    </div>
                </div>
            <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };
  
  export default connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(InvestorServices));