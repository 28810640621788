// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer */
.borderline{
max-width: 150px;
}
.footer-list {
    list-style-type: none;
    padding: 0px;
}
.lineHeight{
line-height: 26px;
}
.footer-list li {
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
}

.footer-list li:hover {
    color: #E16021;
    cursor: pointer;
}


/* Custom Scrollbar for Web */

/* @media screen and (min-width: 768px) {
    .store-badge {
        max-width: 150px;
    }
} */


/* Responsive CSS */

@media only screen and (max-width:767px) {
    .px-30 {
        padding-left: 15px;
        padding-right: 15px;
    }
    /* .store-badge {
        margin: 0 5px
    } */
}`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/Footer/Footer-style.css"],"names":[],"mappings":"AAAA,WAAW;AACX;AACA,gBAAgB;AAChB;AACA;IACI,qBAAqB;IACrB,YAAY;AAChB;AACA;AACA,iBAAiB;AACjB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;;AAGA,6BAA6B;;AAE7B;;;;GAIG;;;AAGH,mBAAmB;;AAEnB;IACI;QACI,kBAAkB;QAClB,mBAAmB;IACvB;IACA;;OAEG;AACP","sourcesContent":["/* Footer */\n.borderline{\nmax-width: 150px;\n}\n.footer-list {\n    list-style-type: none;\n    padding: 0px;\n}\n.lineHeight{\nline-height: 26px;\n}\n.footer-list li {\n    margin-bottom: 10px;\n    font-size: 14px;\n    cursor: pointer;\n}\n\n.footer-list li:hover {\n    color: #E16021;\n    cursor: pointer;\n}\n\n\n/* Custom Scrollbar for Web */\n\n/* @media screen and (min-width: 768px) {\n    .store-badge {\n        max-width: 150px;\n    }\n} */\n\n\n/* Responsive CSS */\n\n@media only screen and (max-width:767px) {\n    .px-30 {\n        padding-left: 15px;\n        padding-right: 15px;\n    }\n    /* .store-badge {\n        margin: 0 5px\n    } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
