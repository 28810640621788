// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/services-page/10-open-banking/header-banner-serv-10.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serv-ten {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.color-box{
    display: flex;
    justify-content: space-between;
    
}
.pad-cont{
    background-color: #06394C;
}
.color-box p{
    color: white;
}
li::before{
    color: red;
}
@media only screen and (max-width: 767px) {
    .color-box{
        display: flex;
        flex-direction: column-reverse;
    }
    p{
        text-align: justify;
    }
    ul{
        text-align: justify;
    }
    .pad-cont{
        margin-top: 16px;
    }
    .img-cont img{
       padding: 40px;
    }
    .img-cont{
        display: flex;
        flex-direction: column-reverse;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/shifteasy-services/open-banking/open-banking.css"],"names":[],"mappings":"AAAA;IACI,yDAAwG;AAC5G;AACA;IACI,aAAa;IACb,8BAA8B;;AAElC;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;AAChB;AACA;IACI,UAAU;AACd;AACA;IACI;QACI,aAAa;QACb,8BAA8B;IAClC;IACA;QACI,mBAAmB;IACvB;IACA;QACI,mBAAmB;IACvB;IACA;QACI,gBAAgB;IACpB;IACA;OACG,aAAa;IAChB;IACA;QACI,aAAa;QACb,8BAA8B;IAClC;EACF","sourcesContent":[".serv-ten {\n    background-image: url(../../../../assets/images/services-page/10-open-banking/header-banner-serv-10.jpg);\n}\n.color-box{\n    display: flex;\n    justify-content: space-between;\n    \n}\n.pad-cont{\n    background-color: #06394C;\n}\n.color-box p{\n    color: white;\n}\nli::before{\n    color: red;\n}\n@media only screen and (max-width: 767px) {\n    .color-box{\n        display: flex;\n        flex-direction: column-reverse;\n    }\n    p{\n        text-align: justify;\n    }\n    ul{\n        text-align: justify;\n    }\n    .pad-cont{\n        margin-top: 16px;\n    }\n    .img-cont img{\n       padding: 40px;\n    }\n    .img-cont{\n        display: flex;\n        flex-direction: column-reverse;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
