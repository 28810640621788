// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/services-page/7-gas-safety/header-banner-serv-7.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serv-seven {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@media only screen and (max-width:767px) {
    #swap {
       display: flex;
       flex-direction: column-reverse;
    }  
    #invol{
        margin-right: 4px;
    }
        
}
`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/shifteasy-services/gas-safety/gas-safety.css"],"names":[],"mappings":"AAAA;IACI,yDAAoG;AACxG;;AAEA;IACI;OACG,aAAa;OACb,8BAA8B;IACjC;IACA;QACI,iBAAiB;IACrB;;AAEJ","sourcesContent":[".serv-seven {\n    background-image: url(../../../../assets/images/services-page/7-gas-safety/header-banner-serv-7.png);\n}\n\n@media only screen and (max-width:767px) {\n    #swap {\n       display: flex;\n       flex-direction: column-reverse;\n    }  \n    #invol{\n        margin-right: 4px;\n    }\n        \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
