// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/services-page/15-facilities-near-you/header-banner-serv-15.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serv-fifteen {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    height: 498px;
    padding-top: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/shifteasy-services/facilities-near-you/facilities-near-you.css"],"names":[],"mappings":"AAAA;IACI,yDAA+G;IAC/G,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".serv-fifteen {\n    background-image: url(../../../../assets/images/services-page/15-facilities-near-you/header-banner-serv-15.jpg);\n    height: 498px;\n    padding-top: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
