// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/services-page/12-investor-services/header-banner-serv-12.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serv-twelve {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
p{
    font-size: 14px;
}
.index{
    margin-top: 76px;
}
#return{
    
    margin-top: 90px;

}


@media only screen and(max-width :767px){
    .rent_Market{
        display: flex;
        flex-direction: column-reverse;
    }
    #return{
    
        margin-top: 10px;
    
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/shifteasy/shifteasy-services/investor-services/investor-services.css"],"names":[],"mappings":"AAAA;IACI,yDAA6G;AACjH;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;;IAEI,gBAAgB;;AAEpB;;;AAGA;IACI;QACI,aAAa;QACb,8BAA8B;IAClC;IACA;;QAEI,gBAAgB;;IAEpB;AACJ","sourcesContent":[".serv-twelve {\n    background-image: url(../../../../assets/images/services-page/12-investor-services/header-banner-serv-12.jpg);\n}\np{\n    font-size: 14px;\n}\n.index{\n    margin-top: 76px;\n}\n#return{\n    \n    margin-top: 90px;\n\n}\n\n\n@media only screen and(max-width :767px){\n    .rent_Market{\n        display: flex;\n        flex-direction: column-reverse;\n    }\n    #return{\n    \n        margin-top: 10px;\n    \n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
