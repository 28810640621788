import React from 'react';
import './Header-style.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { LazyLoadImage } from 'react-lazy-load-image-component';


import MenuDrawer from '../drawer/drawer';
import { userLogout } from '../../../redux/actions/user';
import {Button} from "@material-ui/core";

import  {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { getCitySearchResult
} from "../../../services/querys/get-APIs";
//.import { setCustomer } from "../../redux/actions/index";
import { Link } from "react-router-dom";
import axios from 'axios';
// import LogAndRegComponent from "../registration/login-regModal";
import { List, ListItem, ListItemText, MenuItem, Menu } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import PropertyForm from '../../propertyForm/propForm';

import LocationSearchInput from "./LocationSearchInput"

import shifteasylogo from '../../../assets/images/newlogose.svg';
import usericon from '../../../assets/images/user.svg';
import indianflag from '../../../assets/images/indiaflag.png';
import gpslogo2 from '../../../assets/images/gps.svg'
import search from '../../../assets/images/search.svg';
import residential from '../../../assets/images/SubNavIcons/residential.svg';
import commercialProperty from '../../../assets/images/SubNavIcons/Commercial.svg';
import pg from '../../../assets/images/SubNavIcons/PG.svg';
import tradesmen from '../../../assets/images/SubNavIcons/tradesmen.svg';
import business from '../../../assets/images/SubNavIcons/Business.svg';
import bike from '../../../assets/images/SubNavIcons/bike.svg';
import cars from '../../../assets/images/SubNavIcons/car.svg';
import logistic from '../../../assets/images/SubNavIcons/trade logistic.svg';
import market from '../../../assets/images/SubNavIcons/rent market.svg';
import tenant from '../../../assets/images/tenant-referencing.png';
import deposit from '../../../assets/images/deposit-replacements.png';
// import photovideo from '../../../assets/images/photo-video.png';
import inventory from '../../../assets/images/inventory.png';
// import insurance from '../../../assets/images/home-content-insurance.png';
import rent from '../../../assets/images/rent-guarante-insurance.png';
import gassafety from '../../../assets/images/gas-safety.png';
// import energy from '../../../assets/images/energy.png';
import banking from '../../../assets/images/open-banking.png';
import propertymanagement from '../../../assets/images/SubNavIcons/Property management.svg';
import doctor from '../../../assets/images/SubNavIcons/doctor.svg';
import legal from '../../../assets/images/legal.png';
import facilities from '../../../assets/images/facilities.png';
const location_key = `AIzaSyA0NlHUVwsk3eeXOPw3lrDEXpzpTnayswI`;
const submenu = [
  {
    name: "Residential Property",
    link: "https://residential.shifteasy.com/",
    icon: residential,
  },
  {
    name: "Commercial Property",
    link: "https://commercial.shifteasy.com/",
    icon: commercialProperty,
  },
  {
    name: "PG/Co-Living",
    link: "https://pg.shifteasy.com/",
    icon: pg,
  },
  {
    name: "Tradesman Services",
    link: "https://tradesmen.shifteasy.com/",
    icon: tradesmen,
  },
  {
    name: "Business/ Agencies",
    link: "https://business.shifteasy.com/",
    icon: business,
  },
  {
    name: "Bikes",
    link: "https://bikes.shifteasy.com/",
    icon: bike,
  },
  {
    name: "Cars",
    link: "https://cars.shifteasy.com/",
    icon: cars,
  },
  {
    name: "Manage Property ",
    link: "https://propertymanagement.shifteasy.com/",
    icon: propertymanagement,
  },
  {
    name: "Trades & Logistics",
    link: "https://tradelogistics.shifteasy.com/",
    icon: logistic,
  },
  {
    name: "Sales/Rent Market Index",
    link: "https://rmi.shifteasy.com/",
    icon: market,
  },
  {
    name: "Doctors Near You",
    link: "https://doctors.shifteasy.com/",
    icon: doctor,
  },
  {
    name: "Legal Services ",
    link: "https://legals.shifteasy.com/",
    icon: legal,
  },
 
  {
    name: "Property Management",
    link: "https://propertymanagement.shifteasy.com/",
    icon: propertymanagement,
    submenu: true,
    submenuList: [
      {
        name: "Tenant Referencing", //property managemnt
        link: "https://propertymanagement.shifteasy.com/",
        icon: tenant,
      },
      {
        name: "Open Banking", //property managemnt
        link: "https://propertymanagement.shifteasy.com/",
        icon: banking,
      },
      {
        name: "Gas Safety", //property managemnt
        link: "https://propertymanagement.shifteasy.com/",
        icon: gassafety,
      },
      {
        name: "Rent Guarantee Insurance", //property managemnt
        link: "https://propertymanagement.shifteasy.com/",
        icon: rent,
      },
      {
        name: "Inventory Check-in/Out", //property managemnt
        link: "https://propertymanagement.shifteasy.com/",
        icon: inventory,
      },
      {
        name: "Deposit Replacements", //property managemnt
        link: "https://propertymanagement.shifteasy.com/",
        icon: deposit,
      },
    ],
  },
  // {
  //   name: "Home Contents Insurance",
  //   link: "https://residential.shifteasy.com/",
  //   icon: insurance,
  // },
  // {
  //   name: "Energy Performance Certificate",
  //   link: "https://residential.shifteasy.com/",
  //   icon: energy,
  // },
  // {
  {
    name: "Facilities Near You",
    link: "https://localfacilities.shifteasy.com/",
    icon: facilities,
  },
  // {
  //   name: "ProPhotos ,3DVideo, VirtualConference",
  //   link: "https://residential.shifteasy.com/",
  //   icon: photovideo,
  // },
  // {
  //   name: "Investor services",
  //   link: "https://residential.shifteasy.com/",
  //   icon:investor,
  // },
];
// const useStyles = makeStyles((theme) => ({
//   tabs: {

//     "& .MuiTabs-indicator": {
//       display: "none"
//       //backgroundColor: "orange"
//     }

//   }

// }));
const Header = (props) => {
  
  const {listFeaturedProperties}=props
  const history = useHistory();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const [open, setOpen] = React.useState(false);

  // const [Loading, setLoading] = React.useState(false);

  // keycloak.onAuthSuccess = () => {
  //   loadData();
  //   toast.success("Welcome, " + keycloak.idTokenParsed?.name, {
  //     position: toast.POSITION.TOP_RIGHT,
  //     autoClose: 2000
  //   });

  // };

  // keycloak.onTokenExpired = () => {
  //   keycloak.updateToken(30).then(function () {
  //     loadData();
  //   }).catch(function () {
  //     alert('Failed to refresh token');
  //   });
  //   // toast.error("Session Timed Out", {
  //   //     position: toast.POSITION.TOP_RIGHT,
  //   //     autoClose: 3000
  //   // });
  // };
  // const loadData = () => {
  //   dispatch(userLogin(keycloak.tokenParsed.sub));
  //   return
  //   getCustomersAPI(keycloak.tokenParsed.sub)
  //     .then(({ data }) => {
  //       //console.log("customerData", data);
  //       getUserProfileAPI(keycloak.token)
  //         .then((response) => {
  //           console.log("user details", response.data);
  //           dispatch(userDetails(response.data?.getUserProfile));
  //         })
  //         .catch((error) => {
  //           console.log("error geting user details", error);
  //         });
  //       // dispatch(setCustomer(data.getCustomers));
  //     })
  //     .catch((error) => console.log("error", error));
  // }
  // keycloak.onAuthLogout = () => {
  //   console.log("logout");
  //   toast.success("Logged Out Successfully", {
  //     position: toast.POSITION.TOP_RIGHT,
  //     autoClose: 3000
  //   });
  // };
  // window.sessionStorage.clear();
  // console.log("window.sessionStorage.getItem",JSON.parse(window.sessionStorage.getItem("selectedLoction")))
  const [headerState, setHeaderState] = React.useState({
    // indianFlagSelected: false,
    // ukFlagSelected: false,
    isActive: -1,
    drawerMenu: false,
    currentCountry: "",
    cityName: "",
    cityHeader: "Chennai",
    selectedCity: [],
    latitude: "",
    longitude: "",
    location: "",
    selectedLoction: window.sessionStorage.getItem("selectedLoction") === null ? undefined : JSON.parse(window.sessionStorage.getItem("selectedLoction")),
    country: "",
  });

  const [citydetails, setCityDetails] = React.useState({
    popularCity: [],
    nearByCities: [],
    topLocalities: [],
    suggesedDetails: [],
    latlongdata: [],
    tempDatas: [],
  })

  // const [temp, settemp] = React.useState([])
  // const [flag, setFlag] = React.useState()
  // const indianFlagClicked = () => {
  //   setHeaderState({
  //     ...headerState,
  //     indianFlagSelected: true,
  //     ukFlagSelected: false
  //   })
  // };

  // const ukFlagClicked = () => {
  //   setHeaderState({
  //     ...headerState,
  //     indianFlagSelected: false,
  //     ukFlagSelected: true
  //   });
  // };

  // const buttonSelected = (e, id) => {
  //   setHeaderState({ ...headerState, isActive: id });
  // };

  const drawerShowHide = () => {
    setOpen(!open)
  }

  const addActiveClass = (e) => {
    // e.preventDefault();
    // $(".btn-blue").on('click', function(){
    //     $(this).removeClass('btn-blue')
    //     $(this).addClass('btn-orange');
    //     $(this).siblings().removeClass('btn-orange');
    // })

  };

  const handleCallback = () => {
    setOpen(false)
  };

  const controlPlusEnter = (e, url) => {
    e.preventDefault();

    if (e.ctrlKey) {
      drawerShowHide();
      window.open(url, '_blank');
    } else {
      drawerShowHide();
      history.push(url);
    }
  };

  const login = (e) => {
    e.preventDefault();
    keycloak.login().then((data)=>{
      console.log("data",data)
      // dispatch(userLogin(keycloak.tokenParsed.sub))
    })
    .catch(error=>console.error("error",error))
    ;
  };

  const logout = (e) => {
    e.preventDefault();
    dispatch(userLogout());
    keycloak.logout();
  };


  const selectCity = (e, res) => {
    e.preventDefault();

    let details = {
      latitude: res.latitude,
      longitude: res.longitude
    }
    getFeturedProperties(res.latitude, res.longitude)
    getCitySearchResult(details).then((res) => {
      setCityDetails({
        nearByCities: res.data.getCitySearchResult.nearByCities,
        popularCity: res.data.getCitySearchResult.popularCities,
        topLocalities: res.data.getCitySearchResult.topLocalities,
      })
    }).catch((error) => {
      console.log(error)
    })
    //console.log(details)
    setHeaderState({
      selectedLoction: res
    })
    window.sessionStorage.setItem("selectedLoction", JSON.stringify(res))
  }
  const searchIcon = () => {
    // getFeturedProperties(locationObj.latitude,locationObj.longitude)
    getCitySearchResult({
      latitude: "",
      longitude: ""
    }).then((getcity) => {
      setCityDetails({
        popularCity: getcity.data.getCitySearchResult.popularCities,
        nearByCities: getcity.data.getCitySearchResult.nearByCities,
        topLocalities: getcity.data.getCitySearchResult.topLocalities,
      })
    }).catch((error) => {
      console.log(error)
    })
    // console.log("headerState", headerState)

  }
//   const collectData = (value) => {
//     setLoading(true);
//     getCityAutoSuggestion(value).then((data)=>
//   {
//     settemp(data.data.getCityAutoSuggestion)
//     console.log("data",data)
//     setLoading(false);
//   }
//   )
//   .catch((error)=>{console.log("error",error)
//   setLoading(false);
// })


//   }
  // const { ref } = usePlacesWidget({
  //   apiKey: location_key,
  //   onPlaceSelected: (place) => {
  //     console.log(place.geometry.location);
  //     //     var res = {
  //                 //       cityName:newValue.placeName,
  //                 //       latitude:newValue.latitude,
  //                 //       longitude:newValue.longitude
  //                 //     }
  //                 //     selectCity(event,res)
  //   },
  //   options: {
  //     types: ["(regions)"],
  //     // componentRestrictions: { country: "ru" },
  //   },
  // });
  const getFeturedProperties=(latitude,longitude)=>{
    var featuredProperty={
      latitude:String(latitude),
      longitude:String(longitude),
     }
     if(listFeaturedProperties!==undefined)
     {
       listFeaturedProperties(featuredProperty);
      }
  }

  // const apicall = () => {
  //   getCityAutoSuggestion("").then((res) => {
      
  //     // setCityDetails({suggesedDetails: res.data.getCityAutoSuggestion })
  //     settemp(res.data.getCityAutoSuggestion)
  //   }).catch((error) => {
  //     console.log(error)
  //   })
  // }
  //console.log(citydetails.suggesedDetails)
  //console.log(temp)

  const intialApi = (latitude,longitude) => {

      var locationRequest = {
        latitude,
        longitude,
      }
     
      getCitySearchResult(locationRequest).then(getcity => {
        setCityDetails({
          popularCity: getcity.data.getCitySearchResult.popularCities,
          nearByCities: getcity.data.getCitySearchResult.nearByCities,
          topLocalities: getcity.data.getCitySearchResult.topLocalities,
        })
        console.log("intialAopi")
      }).catch(error => {
        console.log(error)
      })

  }
  

  // https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&amp;key=YOUR_API_KEY

  const getLocation = (geomentry) => {
    var {latitude,longitude}=geomentry
    intialApi(latitude,longitude)
    let apiPoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${geomentry.latitude}+${geomentry.longitude}&key=${location_key}`
    axios.get(apiPoint).then((res) => {
      console.log("resPonse",res.data)
      // const cityname = res.data.plus_code.compound_code
      const cityName = res.data.results[res.data.results.length-3].address_components[0].long_name
      console.log("cityName",cityName)
      if(headerState.selectedLoction===undefined)
      {
        var res2={
          cityName,
          latitude,
          longitude,
        }
        setHeaderState({ selectedLoction: res2 })
        console.log("getlocation")
        window.sessionStorage.setItem("selectedLoction", JSON.stringify(res2))
      }
      // const {lat,lng}=res.data.results[3].geometry.location
      
      // setFlag(res.data.results[res.data.results.length-1].formatted_address)
      
    }).catch(error => {
      console.log(error)
    })
  }
  
  React.useEffect(() => {
    window.scrollTo(0, 0)
    // apicall();
    if(headerState.selectedLoction!==undefined){
      const {latitude,longitude}=headerState.selectedLoction
      const pos = {latitude,longitude}
        getFeturedProperties(latitude,longitude) 
        getLocation(pos);
    }
    else{
      navigator?.geolocation.getCurrentPosition(({coords}) => {
        const {latitude, longitude}=coords
        const pos = {latitude, longitude}
        getFeturedProperties(latitude,longitude) 
        getLocation(pos);
      })
  }
}, [])
  
const handleSelect = (event,address) => {
  console.log("props",address)
  var cityName=address.structured_formatting.main_text
  
  geocodeByAddress(address.description)
    .then(results => getLatLng(results[0]))
    .then(latLng => {
      console.log('Success', latLng)
      var res={
        cityName,
        latitude:latLng.lat,
        longitude:latLng.lng,
      }
      getFeturedProperties(latLng.lat,latLng.lng) 
      intialApi(latLng.lat,latLng.lng)
      setHeaderState({ selectedLoction: res })
        window.sessionStorage.setItem("selectedLoction", JSON.stringify(res))
  })
    .catch(error => console.error('Error', error));
};
console.log("header")
  return (
    <div>
      {/* <!-- Header --> */}
      <div className="header-part">
        {/* <!-- Top section start --> */}
        {/* {headerState.drawerMenu &&
          (window.innerWidth > 768 || history.location.pathname === "/") && ( */}
            <MenuDrawer
            open={open}
          setOpen={setOpen}
          login={login}
          logout={logout}
              submenu={submenu}
              keycloak={keycloak}
              {...props}
              parentCallback={handleCallback}
        
            />
          {/* )} */}
         <PropertyForm/>
        <nav className="navbar fixed-top navbar-dark bg-dark-blue">
          {/* <!-- Left portion --> */}
          <div className="mob-view-left-header">
            <button
              onClick={drawerShowHide}
              className="navbar-toggler border-0 collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand mx-2" href='https://shifteasy.com/'>
              <LazyLoadImage
                src={shifteasylogo}
                alt="ShiftEasy Technologies"
                className="logo-image"
              />
            </a>
          </div>
          {/* <!-- Right portion --> */}
          <div id="header-buttons" >
            <div className="btn-group ">
              <button
                type="button"
                className="border-0 btn-bg-transparent mx-5 p-0"
              >
                {<LazyLoadImage src={indianflag} alt="Flag" />}
              </button>
              {/* <button type="button" className="btn btn-sm dropdown-toggle dropdown-toggle-split text-blue nation-list" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="sr-only">Toggle Dropdown</span>
                        </button> */}
              {/* <div className="dropdown-menu dropdown-menu-right py-1 drop-down-shadow">
                            <button type="button" className={headerState.ukFlagSelected ? "dropdown-item active" : "dropdown-item"} href="javascript:void(0)" onClick={ukFlagClicked}>England</button>
                            <button type="button" className={headerState.indianFlagSelected ? "dropdown-item active" : "dropdown-item"} href="javascript:void(0)" onClick={indianFlagClicked}>India</button>
                        </div> */}
            </div>
            <a
              href="https://shifteasy.com/"
              id="header-button"
              type="button"
              className="btn d-mob-lg-none mr-2 mr-lg-3 btn-blue"
              onClick={addActiveClass}
            >
              Home
            </a>
            <a
            data-toggle="modal" data-target="#freeValForm"
            id="header-button"
              type="button"
              className="btn d-mob-lg-none mr-2 mr-lg-3 btn-blue"
              onClick={addActiveClass}
            >
              Value Your Property
            </a>
             
            <Link
              to="/"
              id="header-button"
              type="button"
              className="btn d-mob-lg-none mr-2 mr-lg-3 btn-blue"
              onClick={addActiveClass}
            >
              List Your Buisness Free
            </Link>
            {/* <Link to="/post-property" 
            id="header-button" type="button"
             className="btn d-mob-lg-none mr-2 mr-lg-3 btn-blue"
              onClick={addActiveClass}>
              Post Your AD Free
                        </Link> */}
            <div className="btn-group">
              <button
                type="button"
                className="btn dropdown-toggle d-mob-lg-none mr-2 mr-lg-3 btn-blue"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{paddingTop:"6px"}}
              >
                Post Your AD Free
              </button>
              <div className="dropdown-menu dropdown-menu-right ml-3">
                <a
                  className="dropdown-item drop-hover"
                  type="button"
                  href='https://propertymanagement.shifteasy.com/admin/post-property'
                >
                  Post Property
                </a>
                <a
                  className="dropdown-item drop-hover"
                  type="button"
                  href="https://cars.shifteasy.com/"
                >
                  Post Car
                </a>
                <a
                  className="dropdown-item drop-hover"
                  type="button"
                  href="https://cars.shifteasy.com/"
                >
                  Post Bike
                </a>
                <a
                  className="dropdown-item drop-hover"
                  type="button"
                  href="/"
                >
                  Post Business
                </a>
              </div>
            </div>
            {keycloak && !keycloak.authenticated ? (
              // <Link to="/register" id="header-button" type="button" className="btn d-mob-lg-none mr-2 ml-3 mr-lg-3 btn-blue" onClick={addActiveClass}>

              //     <LazyLoadImage src={usericon} alt="icon" className="mr-1" />Login/Register
              // </Link>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn d-mob-lg-none mr-1 mr-lg-1 btn-blue"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={login}
                >
                  <LazyLoadImage src={usericon} alt="icon" className="mr-1" />
                  Login
                </button>
                <button
                  type="button"
                  className="btn d-mob-lg-none mr-2 mr-lg-3 btn-blue"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => history.push("/register")}
                >
                  Register
                </button>
              </div>
            ) : null}
            {keycloak && keycloak.authenticated ? (
              <div className="btn-group">
                <button
                  type="button"
                  className="btn dropdown-toggle d-mob-lg-none mr-2 mr-lg-3 btn-blue"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <LazyLoadImage src={usericon} alt="icon" className="mr-1" />
                  {keycloak.idTokenParsed?.name}
                </button>
                <div className="dropdown-menu dropdown-menu-right ml-3">
                  {/* <button className="dropdown-item" type="button">My Profile</button> */}
                  <a
                    className="dropdown-item"
                    type="button"
                    href="/my-profile"
                  >
                    My Profile
                  </a>
                  <a
                    className="dropdown-item"
                    type="button"
                    href="/change-password"
                  >
                    Change Password
                  </a>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={logout}
                  >
                    Logout
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          {/* <!-- Menu --> */}
          {/* <div
            className="collapse navbar-collapse bg-white mob-menu d-web-none"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link
                  to="/post-property"
                  className="nav-link btn btn-orange text-white m-3"
                  type="button"
                >
                  Post Your Property Free
                </Link>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link fs-15 px-3 text-orange">
                  List Your Business Free
                </a>
              </li>
              <li className="nav-item">
                <a data-toggle="modal" data-target="#freeValForm" className="nav-link fs-15 px-3 text-orange">
                  Value Your Property
                </a>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link fs-15 px-3 text-orange">
                  Post a Free Ad
                </a>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link fs-15 px-3">
                  About Us
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  type="button"
                  className="nav-link fs-15 px-3 dropdown-toggle"
                  // href="javascript:void(0)"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Services2
                </a>
                <div
                  className="dropdown-menu mb-2 border-0 services-menu"
                  aria-labelledby="navbarDropdown"
                >
                  <Link
                    id="site-url"
                    to="/tenant-referencing"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) =>
                      controlPlusEnter(e, "/tenant-referencing")
                    }
                  >
                    <LazyLoadImage
                      src={tenant}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Tenant Referencing
                  </Link>
                  <Link
                    to="/deposit-replacements"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) =>
                      controlPlusEnter(e, "/deposit-replacements")
                    }
                  >
                    <LazyLoadImage
                      src={deposit}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Deposit Replacements
                  </Link>
                  <Link
                    to="/virtual-conference"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) =>
                      controlPlusEnter(e, "/virtual-conference")
                    }
                  >
                    <LazyLoadImage
                      src={photovideo}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Pro-Photos, 3D Video, Virtual Conference
                  </Link>
                  <Link
                    to="/inventory-check"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) => controlPlusEnter(e, "/inventory-check")}
                  >
                    <LazyLoadImage
                      src={inventory}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Inventory Check-in
                  </Link>
                  <Link
                    to="/home-content-insurancegas"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) =>
                      controlPlusEnter(e, "/home-content-insurancegas")
                    }
                  >
                    <LazyLoadImage
                      src={insurance}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Home Contents Insurance
                  </Link>
                  <Link
                    to="/rent-guarentee-insurance"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) =>
                      controlPlusEnter(e, "/rent-guarentee-insurance")
                    }
                  >
                    <LazyLoadImage
                      src={rent}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Rent Guarantee Insurance
                  </Link>
                  <Link
                    to="/gas-safety"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) => controlPlusEnter(e, "/gas-safety")}
                  >
                    <LazyLoadImage
                      src={gassafety}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Gas Safety
                  </Link>
                  <Link
                    to="/energy-performance-certificate"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) =>
                      controlPlusEnter(e, "/energy-performance-certificate")
                    }
                  >
                    <LazyLoadImage
                      src={energy}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Energy Performance Certificate
                  </Link>
                  <Link
                    to="/open-backing"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) => controlPlusEnter(e, "/open-backing")}
                  >
                    <LazyLoadImage
                      src={banking}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Open Banking
                  </Link>
                  <Link
                    to="/property-management"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) =>
                      controlPlusEnter(e, "/property-management")
                    }
                  >
                    <LazyLoadImage
                      src={propertymanagement}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Property Management
                  </Link>
                  <Link
                    to="/doctors-near-you"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) => controlPlusEnter(e, "/doctors-near-you")}
                  >
                    <LazyLoadImage
                      src={doctor}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Doctors Near You
                  </Link>
                  <Link
                    to="/legal-services-near-you"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) =>
                      controlPlusEnter(e, "/legal-services-near-you")
                    }
                  >
                    <LazyLoadImage
                      src={legal}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Legal Services Near You
                  </Link>
                  <Link
                    to="/facilities-near-you"
                    className="dropdown-item mb-2 text-blue pl-3 pr-2 fs-14"
                    onClick={(e) =>
                      controlPlusEnter(e, "/facilities-near-you")
                    }
                  >
                    <LazyLoadImage
                      src={facilities}
                      alt="icon"
                      style={{ width: "25px" }}
                      className="mr-2"
                    />{" "}
                    Facilities Near You
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link fs-15 px-3">
                  Blog
                </a>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link fs-15 px-3">
                  <LazyLoadImage src={logout} alt="Icon" /> Logout
                </a>
              </li>
            </ul>
          </div> */}
        </nav>
        <div
          className="d-web-none p-2 bg-orange mob-loc-user align-items-center justify-content-between"
        // style={{ width: "96vw" }}
        >
          <div className="dropdown mx-2 location-dropdown h-auto">
            <a
            // href="https://shifteasy.com/"
              className="text-white dropdown-toggle text-decoration-none  d-flex pt-2"
              // href="javascript:void(0);"
              role="button"
              id="dropdownMenuLink23"
              data-bs-auto-close="outside"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {/* <a href="https://shifteasy.com/"> */}

                <LazyLoadImage
                  src={gpslogo2}
                  alt="GPS"
                  style={{ width: "auto", marginBottom: "0", backgroundColor: "white" }}
                  className="align-bottom mr-1 mb-1"

                />
              {/* </a> */}
              <p className="text-decoration-none  overflow-hidden" > {headerState.selectedLoction!==undefined ?headerState.selectedLoction.cityName:null}</p>
              {/* <i className="fas fa-caret-down pl-1"></i> */}
            </a>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuLink23"
            >
              <div className="row d-flex">
                <div className="d-web-none col-9">
<LocationSearchInput handleSelect={handleSelect}/>
{/* <TextField
            fullWidth
            // color="secondary"
            variant="outlined"
            inputRef={ref}
          /> */}
                {/* <Autocomplete
                ref={ref}
                  autoSelect
                  // onInputChange={(event, newInputValue) => {
                  //   console.log("value",newInputValue,event)
                  //   setstatedata(newInputValue); 
                  //   if (event!==null && newInputValue !== null && newInputValue !== "") {
                  //     collectData(newInputValue);                    }
                  // }}
                  // onChange={(event, newValue) => {
                  //   console.log("value",newValue)
                  //   if (newValue != null) {
                  //     var res = {
                  //       cityName:newValue.placeName,
                  //       latitude:newValue.latitude,
                  //       longitude:newValue.longitude
                  //     }
                  //     selectCity(event,res)
                  //   }
                  //   else{
                  //   //   CountryDetails("")
                  //   //   setState({ ...state, state: "" });
                  //   }
                  // }}
                  // inputValue={statedata}
                  id="combo-box-demo"
                  className="form-control p-0  h-100 border-0 bg-white"
                  // options={statedata!=="" && !Loading ?temp:[]}
                  autoHighlight
                  // getOptionLabel={(option) => option.placeName}
                  sx={{'& .MuiInputBase-root':{
                      paddingBottom:"3px"
                  }}}
                  renderInput={(params) => (
                    <TextField {...params}
                    placeholder="Type your city to search"
                    />
                  )}
                /> */}
                </div>
                <div className=" col-3">
                  <button
                    onClick={searchIcon}
                    className="btn input-group-text bg-white border-0"
                    id="basic-addon22"
                  >
                    <LazyLoadImage src={search} alt="Search" />
                  </button>
                </div>
              </div>
              {/* </div> */}
              <div className="container">
                <h4 className="font-weight-bold mx-lg-1 text-orange fs-13 mt-3">
                  Popular Cities
                </h4>
                <div className="row d-flex location-items">
                  {citydetails.popularCity &&
                    citydetails.popularCity.map((res, i) => (
                      <div className="col-md-3  mb-1 px-3">
                        <a style={{ color: "black", textDecoration: "none" }} className='no-underline_1' onClick={e => selectCity(e,res)}  >{res.cityName}</a>
                      </div>
                    ))}
                </div>
                <h4 className="font-weight-bold mx-lg-1 text-orange fs-13 mt-3">
                  Top Localities
                </h4>
                <div className="row d-lg-flex location-items">
                  {citydetails.topLocalities &&
                    citydetails.topLocalities.map((res, i) => (
                      <div className="col-md-5  mb-1 px-3">
                        <a style={{ color: "black", textDecoration: "none" }} className='no-underline_1' onClick={e => selectCity(e, res)} >{res.cityName}</a>
                      </div>
                    ))}
                </div>
                <h4 className="font-weight-bold mx-lg-1 text-orange fs-13 mt-3">
                  Nearby Cities{" "}
                </h4>
                <div className="row d-lg-flex location-items">
                  {citydetails.nearByCities &&
                    citydetails.nearByCities.map((res, i) => (
                      <div className="col-md-3  mb-1 px-3">
                        <a style={{ color: "black", textDecoration: "none" }} className='no-underline_1' onClick={e => selectCity(e, res)} >{res.cityName}</a>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {keycloak && !keycloak.authenticated ? (
              <div className="btn-group">
                <button type="button" className="btn btn-orange"
                onClick={login}
                >
                  <LazyLoadImage src={usericon} alt="icon" className="mr-1" />
                  Login
                </button>
                <button
                  type="button" className="btn btn-orange"
                  onClick={() => history.push("/register")}
                >
                  Register
                </button>
              </div>
            ) : null}
            {keycloak && keycloak.authenticated ? (
              <div className="btn-group">
                <Button
                  variant='contained'
                  className="btn-dark-blue"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <LazyLoadImage src={usericon} alt="icon" className="mr-1" />
                  {keycloak.idTokenParsed?.name}
                </Button>
                <div className="dropdown-menu dropdown-menu-right ml-3">
                  {/* <button className="dropdown-item" type="button">My Profile</button> */}
                  <a
                    className="dropdown-item"
                    type="button"
                    href="/admin/profile"
                  >
                    My Profile
                  </a>
                  <a
                    className="dropdown-item"
                    type="button"
                    href="/change-password"
                  >
                    Change Password
                  </a>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={logout}
                  >
                    Logout
                  </button>
                </div>
              </div>
            ) : null}
        </div>
        {/* <!-- Top section end -->
    <!-- Bottom section start --> */}
        <div className="nav-scroller px-30 h-25" id="gototop">
          <nav className="row align-items-baseline nav-underline">
            {/* <!-- Location selector --> */}
            <div className="col-sm-1 p-0 dropdown location-dropdown d-mob-none">
              <a
                className="text-orange  dropdown-toggle text-decoration-none d-flex"
                // href="javascript:void(0);"
                role="button"
                id="dropdownMenuLink22"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-haspopup="true"
                aria-expanded="false"
                data-toggle="tooltip" data-placement="bottom"
                title={headerState.selectedLoction!==undefined ?headerState.selectedLoction.cityName:null}
              >
                {/* <a href="https://shifteasy.com/"> */}
                  <LazyLoadImage
                    src={gpslogo2}
                    alt="GPS"
                    style={{ width: "auto" }}
                    className="align-bottom mr-1 mb-1"
                  />
                {/* </a> */}
                {/* <marquee scrollamount="3" className="text-decoration-none  overflow-hidden" style={{marginTop:"1px"}}> */}
                  {headerState.selectedLoction!==undefined ?headerState.selectedLoction.cityName:null}
                  {/* </marquee> */}
                {/* <i className="fas fa-caret-down pl-2"></i> */}
              </a>
              <div
                className="dropdown-menu"
                style={{ zIndex: "7" }}
                aria-labelledby="dropdownMenuLink22"
              >
                <div className="row align-items-center p-lg-3">
                  <div className="col-md-3 text-orange font-weight-bold">
                    INDIA
                  </div>
                  <div className="d-mob-none col-md-9">
                  <LocationSearchInput handleSelect={handleSelect}/>

                  {/* <Autocomplete
                  autoSelect
                  onInputChange={(event, newInputValue) => {
                    console.log("value",newInputValue,event)
                    setstatedata(newInputValue); 
                    if (event!==null && newInputValue !== null && newInputValue !== "") {
                      collectData(newInputValue);                    }
                  }
                }
                  onChange={(event, newValue) => {
                    console.log("value",newValue)
                    if (newValue != null) {
                      var res = {
                        cityName:newValue.placeName,
                        latitude:newValue.latitude,
                        longitude:newValue.longitude
                      }
                      selectCity(event,res)
                    }
                    else{
                    //   CountryDetails("")
                    //   setState({ ...state, state: "" });
                    }
                  }}
                  id="combo-box-demo-22"
                  className="form-control p-0 h-100 border-0 d-mob-none bg-white"
                  options={statedata!=="" && !Loading ?temp:[]}
                  autoHighlight
                  getOptionLabel={(option) => option.placeName}
                  sx={{'& .MuiInputBase-root':{
                      paddingBottom:"3px"
                  }}}
                  renderInput={(params) => (
                    <TextField {...params}
                    placeholder="Type your city to search"
                    />
                  )}
                /> */}
                    {/* <div className="input-group search-city"> */}
                      {/* <input
                        type="text"
                        name="text"
                        list="lst_userIdTypes"
                        value={headerState.cityName}
                        id="DataList"
                        onBlur={collectData}
                        className="form-control border-0 bg-white"
                        placeholder="Type your city to search"
                        aria-label="Type your city to search"
                        aria-describedby="basic-addon22"
                      />
                      <datalist id="lst_userIdTypes">
                        {temp
                          ? temp.map((res, i) => (
                            <option key={i}>{res.cityName}</option>
                          ))
                          : ""}
                      </datalist> */}
                      {/* <div className="input-group-append">
                        <button
                          onClick={searchIcon}
                          className="btn input-group-text bg-white border-0"
                          id="basic-addon22"
                        >
                          <LazyLoadImage src={search} alt="Search" />
                        </button>
                      </div> */}
                    {/* </div> */}
                  </div>
                </div>

                <div className="container container_a_tag mx-lg-5">
                  <h4 className="font-weight-bold mx-lg-3 text-orange fs-13 mt-3">
                    Popular Cities
                  </h4>
                  <div className="row d-lg-flex mx-auto  location-items">
                    {citydetails.popularCity &&
                      citydetails.popularCity.map((res, i) => (
                        <div className="col-md-3 w-25   mb-1 px-3">
                          <a className='no-underline' onClick={e => selectCity(e, res)} >{res.cityName}</a>
                        </div>
                      ))}
                  </div>

                  <h4 className="font-weight-bold mx-lg-3 text-orange fs-13 mt-3">
                    Top Localities
                  </h4>

                  <div className="row d-lg-flex mx-auto location-items">
                    {citydetails.topLocalities &&
                      citydetails.topLocalities.map((res, i) => (
                        <div className="col-md-3 w-25   mb-1 px-3">
                          <a className='no-underline' onClick={e => selectCity(e, res)}  >{res.cityName}</a>
                        </div>
                      ))}
                  </div>
                  <h4 className="font-weight-bold mx-lg-3 text-orange fs-13 mt-3">
                    Nearby Cities{" "}
                  </h4>
                  <div className="row d-lg-flex mx-auto location-items">
                    {citydetails.nearByCities &&
                      citydetails.nearByCities.map((res, i) => (
                        <div className="col-md-3 w-25   mb-1 px-3">
                          <a className='no-underline' onClick={e => selectCity(e, res)} >{res.cityName}</a>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Sub Categories --> */}
            <div className='col-sm-11 p-0' >
              <ScrollableTabsButtonAuto submenu={submenu} />
              {/* <ItemsCarousel
            requestToChangeActive={projectsChangeActiveItemfn}
            activeItemIndex={projectsChangeActiveItem}
            numberOfCards={window.innerWidth < 2000?6:10}
            gutter={20}
            // outsideChevron
            leftChevron={
              <LazyLoadImage
                src={leftarrow}
                alt="Left Arrow"
                style={{ width: "20px" ,position:"absolute",left:"-40%"}}
                // className="carosel-control-left"
              />
            }
            rightChevron={
              <LazyLoadImage
                src={rightarrow}
                alt="Right Arrow"
                style={{ width: "20px" ,position:"absolute",right:"-40%"}}
                // className="carosel-control-right"
              />
            }
            // outsideChevron
            chevronWidth={60}
          >
            {submenu.map((item) => {
              // window.location.href
              if (item.link === window.location.href) {
                return;
              }
              // if (item.submenuList !== undefined) {
              //   return <Submenus item={item} />;
              // }
               else {
                return (
                  <a
                    href={item.link}
                    target={"_blank"}
                    rel="noreferrer"
                    className="nav-link p-0 text-center d-flex flex-column align-items-center"
                  >
                    <LazyLoadImage
                      src={item.icon}
                      alt={item.name}
                      className="d-block mx-auto mb-1"
                    />
                    {item.name}
                  </a>
                );
              }
            })}
          </ItemsCarousel> */}
              {/* <Subdomain/> */}
            </div>
            {/* <div className=" subcategories d-flex align-items-center">
            {submenu.map((item) => {
              // window.location.href
              if (item.link === window.location.href) {
                return;
              }
              if (item.submenuList !== undefined) {
                return <Submenus item={item} />;
              } else {
                return (
                  <a
                    href={item.link}
                    target={"_blank"}
                    rel="noreferrer"
                    className="nav-link"
                  >
                    <LazyLoadImage
                      src={item.icon}
                      alt={item.name}
                      className="d-block mx-auto mb-1"
                    />
                    {item.name}
                  </a>
                );
              }
            })}
          </div> */}

          </nav>
        </div>

        {/* <!-- Bottom section end --> */}
      </div>
    </div>
  );
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: "50%",
      width: 15,
      height: 15,
      backgroundColor: '#fff',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const TabNext = withStyles((theme) => ({
  root: {
    '& .MuiSvgIcon-root': {
      background: "#E1601F ",
      borderRadius: "13px",
      color: "#fff",
      width: "26px",
      height: "26px"

    },
  },
}))(Tabs);
function Submenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { item } = props;
  const { submenuList } = item;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {/* <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Open Menu
      </Button> */}
      <a
        // href={item.link}
        target={"_blank"}
        rel="noreferrer"
        className="nav-link"
        onClick={handleClick}
      >
        <LazyLoadImage src={item.icon} alt={item.name} className="d-block mx-auto mb-1" />
        {item.name}
      </a>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            marginTop: 1.5,
          },
        }}
      >
        {submenuList.map((items,key) => (
          <List component="div" disablePadding key={key}>
            <a
              href={items.link}
              target={"_blank"}
              rel="noreferrer"
              className="nav-link"
            >
              <ListItem button>
                <LazyLoadImage
                  className="servicesImg"
                  src={items.icon}
                  alt={items.name}
                />
                <ListItemText secondary={items.name} />
              </ListItem>
            </a>
          </List>
        ))}
      </StyledMenu>
    </div>
  );
}
export default Header;
function ScrollableTabsButtonAuto(props) {
  // const classes = useStyles();
  const { submenu } = props
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabNext
        value={value}
        onChange={handleChange}
        selectionFollowsFocus={true}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{ style: { background: "transparent" } }}
        // className={classes.tabs}
        aria-label="scrollable auto tabs example"
      >
        {submenu.map((item,key) => {
          // window.location.href
          if (item.link === window.location.href) {
            return;
          }
          // if (item.submenuList !== undefined) {
          //   return <Submenus item={item} />;
          // }
          else {
            return (
              <Tab
                sx={{ textTransform: 'none'}}
                key={key}
                // sx={{ textTransform: 'none',padding:"12px 16px !important" }}
                label={
                  <a
                    href={item.link}
                    // target={"_blank"}
                    rel="noreferrer"
                    // style={{ color: "#E1601F" }}
                    className="nav-link p-0 text-center d-flex flex-column align-items-center"
                  >
                    <LazyLoadImage
                      src={item.icon}
                      alt={item.name}
                      className="d-block mx-auto mb-1"
                    />
                    {item.name}
                  </a>
                }
              />
            );
          }
        })}
      </TabNext>
    </Box>
  );
}

