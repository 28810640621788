import axios from 'axios';

   export const get = (url, headers)=>{
        return axios.request({
            url: url,
            method: "get",
            headers: headers
        });
    }
   export const post = (url, data, headers) => {
        return axios.request({
            url: url,
            method: "post",
            data: data,
            headers: headers
        });
    };

