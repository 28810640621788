import React, {  useState,lazy } from "react";
import './Home-style.css';
import $ from 'jquery';
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
//Import the css file if using blur, black and white, or opacity effects.
import search2 from '../../../assets/images/search-w.svg';
import leftarrow from '../../../assets/images/leftarrow.png';
import toparrow from '../../../assets/images/uparrow.png';
import rightarrow from '../../../assets/images/rightarrow.png';
import featured1 from '../../../assets/images/featured-image-1.png';
import featured2 from '../../../assets/images/cards/card2.png';
import featured3 from '../../../assets/images/cards/card3.png';
import featured4 from '../../../assets/images/cards/card4.png';
import featured5 from '../../../assets/images/cards/card5.png';
import featured6 from '../../../assets/images/cards/card6.png';
import featured7 from '../../../assets/images/cards/card7.png';
import featured9 from '../../../assets/images/cards/card9.png';
import featured10 from '../../../assets/images/featured-image-2.jpg';
import tenant from '../../../assets/images/tenant-referencing.png';
import deposit from '../../../assets/images/deposit-replacements.png';
import photovideo from '../../../assets/images/photo-video.png';
import inventory from '../../../assets/images/inventory.png';
import insurance from '../../../assets/images/home-content-insurance.png';
import rent from '../../../assets/images/rent-guarante-insurance.png';
import gassafety from '../../../assets/images/gas-safety.png';
import energy from '../../../assets/images/energy.png';
import banking from '../../../assets/images/open-banking.png';
import movers from '../../../assets/images/movers.png';
import propertymanagement from '../../../assets/images/property-management.png';
import facilities from '../../../assets/images/facilities.png';
import doctor from '../../../assets/images/doctor.png';
import legal from '../../../assets/images/legal.png';
import evaluaton from '../../../assets/images/Evaluation.png';
import placement from '../../../assets/images/placement.png';
import valuate from '../../../assets/images/valuate.png';
import investor from '../../../assets/images/investor.png';
import shield from '../../../assets/images/shield.png';
import rowan from '../../../assets/images/rowan.png';
import qualityassurance from '../../../assets/images/qualityassurance.png';
import technicalsupport from '../../../assets/images/technicalsupport.png';
import verifiedUser from '../../../assets/images/verifiedUser.png';
import sidekix from '../../../assets/images/sidekix.png';

import Ahmedabad from '../../../assets/images/NewHome/places/Ahmedabad.png'
import banglore from '../../../assets/images/NewHome/places/banglore.png'
import chennai from '../../../assets/images/NewHome/places/chennai.png'
import delhi from '../../../assets/images/NewHome/places/delhi.png'
import Ghaziabad from '../../../assets/images/NewHome/places/Ghaziabad.png'
import hydrabad from '../../../assets/images/NewHome/places/hydrabad.png'
import kolkata from '../../../assets/images/NewHome/places/kolkata.png'
import konark from '../../../assets/images/NewHome/places/konark.png'
import mumbai from '../../../assets/images/NewHome/places/mumbai.svg'
import Noida from '../../../assets/images/NewHome/places/Noida.png'
import patna from '../../../assets/images/NewHome/places/patna.png'
import pune from '../../../assets/images/NewHome/places/pune.png'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ItemsCarousel from 'react-items-carousel';
// import { scrollTop, getCurrentPosition } from '../common/common';
import { toast } from 'react-toastify';
import { vipAccessAPI } from '../../../services/mutations/mutation-API';
import { getBlogsMain } from '../../../services/querys/get-APIs';
import { Helmet } from "react-helmet";
import {  listFeaturedPropertiesAPI } from '../../../services/querys/get-APIs';
import { getAutoSuggestions } from '../../../services/querys/get-APIs'
import Card from "./card";
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
import './newhome.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import {TextField,Button} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
// const Header = lazy(() => import('../Header/Header'));
// const Footer = lazy(() => import('../Footer/Footer'));

const image_show = `https://cms.shifteasy.com`

const Home = (props) => {
    const matches = useMediaQuery('(min-width:500px)');
    const matches2 = useMediaQuery('(min-width:900px)');
    let categoryId=window.location.host === "residential.shifteasy.com" ? 1 : 2
    const [preferredFor,setpreferredFor] = useState([])
    const [toggle,settoggle] = useState(false)
    const [checkboxData, setcheckboxData] = useState(
      categoryId === 1
        ? [
            {
              id: 4,
              name: "Studio Apartment",
              checked: false,
            },
            {
              id: 1,
              name: "Pent House",
              checked: false,
            },
            {
              id: 5,
              name: "Service Apartment",
              checked: false,
            },
            {
              id: 7,
              name: "Independent House/Villa",
              checked: false,
            },
          ]
        : [
            {
              id: 8,
              name: "Office Space",
              checked: false,
            },
            {
              id: 14,
              name: " Commercial Shop",
              checked: false,
            },
            {
              id: 27,
              name: "Marriage Hall",
              checked: false,
            },
            {
              id: 28,
              name: "Hotals/PG/Co-living",
              checked: false,
            },
            {
              id: 29,
              name: "Old Age Homes",
              checked: false,
            },
            {
              id: 30,
              name: "Creche",
              checked: false,
            },
            {
              id: 18,
              name: "Hospitals",
              checked: false,
            },
            {
              id: 21,
              name: "Storeroom",
              checked: false,
            },
          ]
    );
    const selectPreferedFor = (e,checked) => {
      console.log("e", !checked);
      var temp = checkboxData;
      var copyData = preferredFor;
      let index2 = temp.findIndex((el) => el.id === Number(e.target.value));
      if (!checked === true) {
        temp[index2].checked = true;
        copyData.push(Number(e.target.value));
      } else {
        let index = copyData.findIndex((el)=>el === Number(e.target.value))
      copyData.splice(index, 1);
        temp[index2].checked = false;
      }
      // setCheckData(temp);
      //     setamenitiesList( copyData )
      //     SetToggle(!toggle);
      setcheckboxData(temp);
      setpreferredFor(copyData);
      settoggle(!toggle);
      // this.setState({
      //     checkboxData:temp,
      //     preferredFor: copyData
      // },
      // () => {
      //   this.listFeaturedProperties();
      // })
    };
    const history = useHistory();
    const [homeData, setHomeData] = useState({
        showHideCity: false,
        showHidePropertyType: false,
        vipFullName: "",
        vipEmail: "",
        vipPhone: "",
        vipCity: "",
        vipCountry: "",
        vipDescription: "",
        getFreeValuationFullName: "",
        getFreeValuationEmail: "",
        getFreeValuationPhone: "",
        getFreeValuationCity: "",
        getFreeValuationCountry: "",
        getFreeValuationDescription: "",
        projectsActiveItemIndex: 0,
        propertiesActiveItemIndex: 0,
        businessActiveItemIndex: 0,
        height: 0,
        width: 0,
        searchText: "",
        residentialPropertyTypeId: "",
        residentialPropertyTypeValue: '',
        PropertyType: "",
        propertyTypeId: "",
        rent: true,
        sell: false,
        lease: false
    });
    const [FeaturedProperties, setFeaturedProperties] = useState([])
    const [CountryDetails, setCountryDetails] = useState([])
    const [statedata, setstatedata] = useState("")
    const [AutoSuggestions,SetAutoSuggestions]=useState([]);
    const [geoLocation, setJiolocation] = useState({
        latitude: "",
        longitude: "",
        cityName: "",
    })
    const [projectsActiveItemIndex, setProjectsActiveItemIndex] = useState(0);
    const [propertiesActiveItemIndex, setPropertiesActiveItemIndex] = useState(0);
    const [businessActiveItemIndex, setBusinessActiveItemIndex] = useState(0);
    // const [propertyCountry, setPropertyCountry] = useState({
    //     country: []
    // })
    // const [propertyState, setPropertyState] = useState({
    //     state: []
    // })
    // const [propertyCity, setPropertyCity] = useState({
    //     city: []
    // })
    // const deviceSizeUpdate = () => {
    //     setHomeData({ ...homeData, height: window.innerHeight, width: window.innerWidth });
    // };



    // const getCountriesLocal = () => {
    //     getCountriesAPI('').then(res => {
    //         setPropertyCountry({
    //             country: res.data.getCountries
    //         })
    //     }).catch(error => {
    //         console.log(error)
    //     })
    // }



    const listFeaturedProperties = (featuredProperty) => {
        featuredProperty.categoryId=categoryId
        featuredProperty.propertyTypeId = null;
        featuredProperty.rent = false;
        featuredProperty.sell = false;
        featuredProperty.lease = false;
        featuredProperty.bedroom = null;
        featuredProperty.furnishingId = null;
        featuredProperty.priceStart = null;
        featuredProperty.priceEnd = null;
        featuredProperty.amenityId = null;
        featuredProperty.sortId = null;
        featuredProperty.userId = null;
        featuredProperty.limit = 6;
        featuredProperty.page = 1;
        console.log("featuredProperty", featuredProperty)
        setJiolocation(featuredProperty)
        listFeaturedPropertiesAPI(featuredProperty)
            .then(({ data }) => {
                console.log("data", data)
                setFeaturedProperties(data.listFeaturedProperty.response)
            })
            .catch((error) => console.log("Error", error))
    }
    const enterSearch = (value) => {
        console.log("value",value)
        setstatedata(value); 
        getAutoSuggestions({
            latitude: geoLocation.latitude,
            longitude: geoLocation.longitude,
            cityName: value,
        }).then((data)=>
        {
          var array1 = []
          data.data.getAutoSuggestions.locations.forEach(element => {
            const {latitude,longitude}=element
            var object ={
              name:element.locationName1,
              name2:element.locationName2,
              latitude,longitude,
              type:"Location"
            }
            array1.push(object)
          });
          data.data.getAutoSuggestions.projects.forEach(element => {
            const {latitude,longitude}=element
            var object ={
              name:element.projectName,
              name2:element.projectName,
              latitude,longitude,
              type:"Projects"
            }
            array1.push(object)
          });
          console.log("data",data,array1)
          SetAutoSuggestions(array1)
        console.log("data",data)}
        )
        .catch((error)=>console.log("error",error))

    }
    const projectsChangeActiveItem = (projectsActiveItemIndex) => {
        setProjectsActiveItemIndex(projectsActiveItemIndex);
    };

    const propertiesChangeActiveItem = (propertiesActiveItemIndex,waa) => {
        console.log("waa",waa)
        setPropertiesActiveItemIndex(propertiesActiveItemIndex);
    };

    const businessChangeActiveItem = (businessActiveItemIndex) => {
        setBusinessActiveItemIndex(businessActiveItemIndex);
    };

    const goToSearch = () => {
        history.push('/search-list');
    };

    const submitVIPAccess = () => {
        let vipData = {
            "fullName": homeData.vipFullName,
            "email": homeData.vipEmail,
            "phoneNumber": homeData.vipPhone.toString(),
            "countryId": homeData.vipCountry,
            "cityId": homeData.vipCity,
            "description": homeData.vipDescription
        }

        vipAccessAPI(vipData).then((res) => {
            $('.modal').modal('hide')

            toast.success("VIP User registed successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            setHomeData({
                vipFullName: "",
                vipEmail: "",
                vipPhone: "",
                vipCountry: "",
                vipCity: "",
                vipDescription: ""
            })
        })
            .catch((error) => {
                toast.error("VIP Registation failed", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            })

    };



    // const formik = useFormik({
    //     initialValues: {
    //         fullName: "",
    //         email: "",
    //         country: "",
    //         state: "",
    //         city: "",
    //         description: "",


    //     },
    //     //    validate,
    //     validationSchema: Yup.object({
    //         fullName: Yup.string()
    //             // .max(15, 'Must be 15 characters or less')
    //             .required('Enter Your Name'),
    //         email: Yup.string().email('Invalid email address').required('Enter Valid Email'),
    //         // phoneNumber: Yup.number()
    //         //     // .max(20, 'Must be 20 characters or less')
    //         //     .required('Title'),
    //         country: Yup.string().required("Enter Your Message"),
    //         state: Yup.string().required("Enter Your Message"),
    //         city: Yup.string().required("Enter Your Message"),
    //         city: Yup.string().required("Enter Your Message"),
    //         description: Yup.string().required("enter your message"),
    //     }),
    //     onSubmit: (value) => {
    //             savePropertyValuationRequestAPI(value).then(res => {

    //                 $('.modal').modal('hide')
    //                 toast.success("Property valuvation registation successfull", {
    //                     position: toast.POSITION.TOP_RIGHT,
    //                     autoClose: 3000
    //                 });
    //                 console.log(res)
    //             }).catch((error) => {
    //                 console.log("error", error)
    //                 toast.error("VIP Registation failed", {
    //                     position: toast.POSITION.TOP_RIGHT,
    //                     autoClose: 3000
    //                 });
    //             })
    //     }
    // })
    // const [phonestate ,setphoneState]=useState(false)
    // const handleMobileNumberChange = (value, data, event, formattedValue) => {
    //     // formik.setFieldValue('countryCode', data.dialCode);
    //     formik.setFieldValue('phoneNumber', formattedValue);
    //     if(formattedValue.length <9){
    //         setphoneState(true)
    //     }
    // };
   
    // const searchPropertyByCity = (city) => {
    //     searchPropertiesByCityAPI(city).then((res) => {
    //         console.log("res", res);
    //     })
    //         .catch((error) => {
    //             console.log("error", error)
    //         })
    // };

    const selectPropType = (id, value) => {
        //$('.dropdown-menu').dropdown('show')


        if (value === 'rent') {
            setHomeData({
                ...homeData,
                rent: true,
                sell: false,
                lease: false
            })
        }
        if (value === 'sell') {
            setHomeData({
                ...homeData,
                rent: false,
                sell: true,
                lease: false
            })
        }

        if (value === 'lease') {
            setHomeData({
                ...homeData,
                rent: false,
                sell: false,
                lease: true
            })
        }
    };
 

 

    const [moreActiveItemIndex, setmoreActiveItemIndex] = useState(0);


    const moreChangeActiveItem = (moreActiveItemIndex) => {
        setmoreActiveItemIndex(moreActiveItemIndex);
    }


    const [blogs, setBlogs] = useState()
    // console.log(blogs)

    const getBlogs = () => {
        getBlogsMain(3).then(res => {
            // let img = res.data.blogs && res.data.blogs.map(res => `${image_show}${res.featureImage.url}`)
            setBlogs(res.data.blogs)
        }).catch(error => {
            console.log(error)
        })
    }

    // React.useEffect(() => {
    //     console.log("hi")
    //     if (formik.values.country) {
    //         let selectObj = propertyCountry.country && propertyCountry.country.find((item, i) => {
    //             return item.countryName.toLowerCase() === formik.values.country.toLowerCase()
    //         })
    //         if (selectObj) {
    //             getStatesByCountryAPI(selectObj.countryId).then(res => {
    //                 setPropertyState({
    //                     state: res.data.getStatesByCountry
    //                 })
    //             }).catch(error => {
    //                 console.log(error)
    //             })
    //         }
    //     } else {
    //         console.log("hi")
    //     }

    // }, [formik.values.country])

    // React.useEffect(() => {
    //     if (formik.values.state) {
    //         let selectObj = propertyState.state && propertyState.state.find((item, i) => {
    //             return item.stateName.toLowerCase() === formik.values.state.toLowerCase()
    //         })
    //         if (selectObj) {
    //             getCityByStateAPI(selectObj.stateId).then(res => {
    //                 setPropertyCity({
    //                     city: res.data.getCityByState
    //                 })
    //             }).catch(error => {
    //                 console.log(error)
    //             })
    //         }
    //     } else {
    //         console.log("hi")
    //     }

    // }, [formik.values.state])

    React.useEffect(() => {
        // scrollTop();
        // deviceSizeUpdate();
        // getCurrentPosition();
        // getCountriesLocal();
   
        getBlogs();
          



        // axios.get('https://ipapi.co/json/').then((response) => {
        //     setJiolocation({
        //         latitude: response.data.latitude,
        //         longitude: response.data.longitude,
        //     })
        //     var featuredProperty = {
        //         latitude: response.data.latitude,
        //         longitude: response.data.longitude,
        //         city: true
        //     }

        //     listFeaturedProperties(featuredProperty)

        // }).catch(error => {
        //     console.log(error)
        // })
    }, []);
    // const selectPropTypeId = (id) => {
    //     setHomeData({
    //         ...homeData,
    //         propertyTypeId: id
    //     })
    // };
    var switchList = "";
    var switchHeader = ""
    switch (window.location.host) {
        case "shifteasy.com":
            switchList = "pg page-top-banner  .mb-3 .mb-lg-5";
            switchHeader="Find Best Residential Property in India, UK & Abroad"
            break;
        case "commercial.shifteasy.com":
            switchList = "rs page-top-banner mb-3 mb-lg-5 ";
            switchHeader="Find Best Commercial Property in India, UK & Abroad"
            break;
        case "residential.shifteasy.com":
            switchList = "pg page-top-banner mb-3 mb-lg-5 ";
            switchHeader="Find Best PG/Paying Guest Near you"
            break;
        case "pg.shifteasy.com":
            switchList = "pg page-top-banner  .mb-3 .mb-lg-5";
            switchHeader=""
            break;
        case "doctors.shifteasy.com":
            switchList = "serv-four pg page-top-banner  .mb-3 .mb-lg-5";
            switchHeader=""
            break;
        default: switchList = "pg page-top-banner  .mb-3 .mb-lg-5";switchHeader="Find Best Residential Property in India, UK & Abroad";

    }
    const handleChange = (e) =>{
        console.log("asdfghjkl",e)
    }
    console.log("resdededededhhhdhdh")
    return (
      <div className="homapage-body w-100">
        <Helmet>
          <title>
            #1 Real Estate Property Management in India | Buy/Sell/Rent/Lease
          </title>
          <meta
            name="description"
            content="ShiftEasy is India's end to end Real Estate Property Management Platform. Find the Best Property in India, UK & Abroad. Buy/Sell/Rent/Lease your Property."
          />
          <meta
            name="keywords"
            content="Real Estate Company in India | Property in India | Real Estate Property Management"
          />
        </Helmet>

        {/* <!-- Header --> */}
        <Header listFeaturedProperties={listFeaturedProperties} />

        {/* <!-- Main --> */}
        <main className="container-fluid px-0">
          {/* <!-- Banner --> */}
          <div className={switchList}>
            <h1 className="text-center text-white pt-4 mb-3 h2">{switchHeader}</h1>
            <h6 className="text-center d-mob-none text-white fs-15">
              All in one search and property management, made easy for business
              clients, tenants, landlord and agencies
            </h6>
            <h6 className="text-center d-mob-none text-white fs-15 mb-4">
              Select by type and enter the location details you need to search
              below
            </h6>
            <div className="row justify-content-center mb-0 mb-md-4">
              <div
                className="btn-group col-12 col-lg-6"
                role="group"
                aria-label="Button group with nested dropdown"
              >
                <div
                  className="btn-group border-right property-type"
                  role="group"
                >
                  <button
                    id="btnGroupDrop11"
                    type="button"
                    className="btn btn-outline-orange br-0 border-0 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Property Type
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupDrop11"
                  >
                    <h5>
                      <small className="text-white font-weight-bold fs-13">
                        Select Type
                      </small>
                    </h5>
                    {/* <!-- Buttons - add className 'active' for selected button --> */}
                    <div
                      className="btn-group property-rsl mb-3"
                      role="group"
                      aria-label="Basic example"
                    >
                      {/* <!-- Class 'active' highlights the selected button --> */}
                      <button
                        type="menu"
                        id="propType"
                        className={`br-0 btn btn-outline-orange  mr-2 mr-md-4 px-2 px-md-3 px-xl-5 fs-15 ${
                          homeData.rent === true ? "active" : ""
                        }`}
                        onClick={() => selectPropType(1, "rent")}
                      >
                        Rent
                      </button>
                      <button
                        type="button"
                        id="propType"
                        className={`btn btn-outline-orange px-2 px-md-3 px-xl-5 fs-15 ${
                          homeData.sell === true ? "active" : ""
                        }`}
                        onClick={() => selectPropType(2, "sell")}
                      >
                        Buy
                      </button>
                      <button
                        type="button"
                        id="propType"
                        className={`br-0 btn btn-outline-orange ml-2 ml-md-4 px-2 px-md-3 px-xl-5 fs-15 ${
                          homeData.lease === true ? "active" : ""
                        }`}
                        onClick={() => selectPropType(3, "lease")}
                      >
                        {" "}
                        Lease
                      </button>
                    </div>
                    <h5>
                      <small className="text-white font-weight-bold fs-13">
                        {categoryId === 1 ? "Residential" : "Commertial"}
                      </small>
                    </h5>
                    {/* <!-- Checkboxes web --> */}
                    <div className="d-flex flex-wrap d-mob-none">
                      {checkboxData.map((item) => {
                        return (
                          <div className="custom-control custom-checkbox d-inline-flex mr-3">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`${item.id}`}
                              checked={item.checked}
                              value={item.id}
                              onChange={(e) =>
                                selectPreferedFor(e, item.checked)
                              }
                            />
                            <label
                              className="custom-control-label text-white fs-13 pt-1"
                              htmlFor={`${item.id}`}
                            >
                              {item.name}
                            </label>
                          </div>
                        );
                      })}{" "}
                    </div>
                    {/* <!-- Checkboxes mob --> */}
                    <div className="d-flex flex-wrap d-web-none">
                      {checkboxData.map((item) => {
                        return (
                          <button
                            value={item.id}
                            checked={item.checked}
                            onClick={(e) => selectPreferedFor(e, item.checked)}
                            className={`btn btn-outline-orange b-rad px-3 mx-1 mb-2 ${
                              item.checked ? "active" : ""
                            }`}
                          >
                            {item.name}
                          </button>
                        );
                      })}{" "}
                    </div>
                  </div>
                </div>
                <Autocomplete
                  autoSelect
                  onInputChange={(event, newInputValue) => {
                    if (event!==null && newInputValue !== null) {
                        enterSearch(newInputValue);                    }
                  }}
                  onChange={(event, newValue) => {
                    console.log("value",newValue)
                    if (newValue != null) {
                        setCountryDetails(newValue);
                    }
                  }}
                  inputValue={statedata}
                  id="combo-box-demo"
                  className="form-control p-0 h-100 br-0 border-0 d-mob-none bg-white"
                  options={AutoSuggestions}
                  autoHighlight
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option.name}
                  sx={{'& .MuiInputBase-root':{
                      paddingBottom:"3px"
                  }}}
                  renderOption={(props, option) => {
                    var icon = LocationOnIcon
                    switch(option.type){
                      case "Location":
                        icon=LocationOnIcon
                        break;
                      case "Projects":
                        icon=HomeIcon
                        break;
                      case "builders":
                        icon=HomeIcon
                        break;
                      default:
                        break;
                    }
            
                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Box
                              component={icon}
                              sx={{ color: 'text.secondary', mr: 2 }}
                            />
                          </Grid>
                          <Grid item xs>
                            {/* {parts.map((part, index) => ( */}
                              <span
                                // key={index}
                                // style={{
                                //   fontWeight: part.highlight ? 700 : 400,
                                // }}
                              >
                                {option.name}
                              </span>
                            {/* ))} */}
            
                            <Typography variant="body2" color="text.secondary">
                              {option.name2}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params}
                    placeholder="Enter Location, Project or Landmark & Hit enter"
                    />
                  )}
                />
                <Button
                //   type="button"
                  style={{
                    lineHeight: 2.5,
                    backgroundColor: "#E1601F",
                    width: "125px",
                  }}
                  className="btn  br-0 d-mob-none d-flex"
                  onClick={()=>props.history.push({
                    pathname: "/search-list",
                    state: {
                      rent: homeData.rent,
                      sell: homeData.sell,
                      lease: homeData.lease,
                      preferredFor,
                      CountryDetails
                    }})}
                >
                  {/* <LazyLoadImage effect="blur" src={search2} style={{width:"25px"}} alt="Search" />&nbsp; */}
                  <span className="text-white text-center w-100">Search</span>
                </Button>
              </div>
            </div>
            <div className="d-web-none mt-2">
              <div className="input-group mb-3">
              <Autocomplete
                  autoSelect
                  inputValue={statedata}
                  onInputChange={(event, newInputValue) => {
                    if (event!==null && newInputValue !== null) {
                        enterSearch(newInputValue);                    }
                  }}
                  onChange={(event, newValue) => {
                    console.log("value",newValue)
                    if (newValue != null) {
                        setCountryDetails(newValue);
                    }
                    else{
                    //   CountryDetails("")
                    //   setState({ ...state, state: "" });
                    }
                  }}
                  id="combo-box-demo"
                  className="form-control p-0 br-0 border-0 bg-white"
                  options={AutoSuggestions}
                  size="small"
                  autoHighlight
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option.name}
                  sx={{'& .MuiInputBase-root':{
                      paddingBottom:"3px"
                  }}}
                  renderOption={(props, option) => {
                    // const matches = option.structured_formatting.main_text_matched_substrings;
                    // const parts = parse(
                    //   option.structured_formatting.main_text,
                    //   matches.map((match) => [match.offset, match.offset + match.length]),
                    // );
                    var icon = LocationOnIcon
                    switch(option.type){
                      case "Location":
                        icon=LocationOnIcon
                        break;
                      case "Projects":
                        icon=HomeIcon
                        break;
                      case "builders":
                        icon=HomeIcon
                        break;
                      default:
                        break;
                    }
            
                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Box
                              component={icon}
                              sx={{ color: 'text.secondary', mr: 2 }}
                            />
                          </Grid>
                          <Grid item xs>
                            {/* {parts.map((part, index) => ( */}
                              <span
                                // key={index}
                                // style={{
                                //   fontWeight: part.highlight ? 700 : 400,
                                // }}
                              >
                                {option.name}
                              </span>
                            {/* ))} */}
            
                            <Typography variant="body2" color="text.secondary">
                              {option.name2}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params}
                    placeholder="Enter Location, Project or Landmark & Hit enter"
                    />
                  )}
                />
                {/* <input
                  type="text"
                  className="form-control br-0 border-0 bg-white"
                  placeholder="Enter Location, Project or Landmark to search"
                /> */}
                <div className="input-group-append">
                  <Button
                    type="button"
                    className="btn btn-orange br-0"
                    onClick={()=>props.history.push({
                        pathname: "/search-list",
                        state: {
                          rent: homeData.rent,
                          sell: homeData.sell,
                          lease: homeData.lease,
                          preferredFor,
                          CountryDetails
                        }})}
                  >
                    <LazyLoadImage effect="blur" src={search2} alt="Search" />
                  </Button>
                </div>
              </div>
            </div>
            <h6 className="text-center text-white fs-14">
              One-stop directory to meet your day-to-day requirements. Free
              listing and premium end-to-end support for builders, landlords
              agents, tenants and services
            </h6>
            <div className="align-items-center d-flex justify-content-center">
              <p className="text-white mb-0 mr-2">For early VIP access</p>
              <button
                type="button"
                className="btn btn-orange ml-2"
                data-toggle="modal"
                data-target="#homeForm"
              >
                Register
              </button>
            </div>
          </div>
          {/* <GoogleLocation/> */}
          {/* <!-- Section 1 --> */}
          <div className="col-11 mx-auto section-one">
            <h4 className="section-title">
              ShiftEasy Sales and Rent Market Index
            </h4>
            <p className="section-des">
              Over 10 years of Sales and Rental Market data, accurate index and
              AI price prediction
            </p>
            <div className="row mb-4 px-2 overflow-auto ">
              <div className="col-sm-12 col-md-4 mt-5 card-section2 mb-3 mb-2">
                <div className="shadow-cus p-3 h-100 w-100 br-5 ">
                  <div className="index-icons-box">
                    <LazyLoadImage effect="blur" src={evaluaton} alt="Image" className="index-icons" />
                  </div>
                  <h6 className="text-orange font-weight-bold mt-4">
                    End to End Exection
                  </h6>
                  <ul className="custom-list mb-0">
                    <li className="d-flex">
                      End to end support for your dream property, cars, bikes,
                      jobs and beyond
                    </li>
                    <li className="d-flex">
                      Listing to final possession in one platform
                    </li>
                    <li className="d-flex">
                      Background checks, property management, inventory check,
                      contract management, blockchain logistics and services
                    </li>
                    <li className="d-flex">
                      Search legal services, doctors, medical services and
                      facilities near you
                    </li>
                    <li className="d-flex">
                      ShiftEasy can provide you a wide selection of properties,
                      cars, bikes and tradesmen in your locality
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 mt-5 card-section2 mb-3 mb-2">
                <div className="shadow-cus p-3 h-100 w-100 br-5 ">
                  <div className="index-icons-box">
                    <LazyLoadImage effect="blur" src={placement} alt="Image" className="index-icons" />
                  </div>
                  <h6 className="text-orange font-weight-bold mt-4">
                    Placement
                  </h6>
                  <ul className="custom-list mb-0">
                    <li className="d-flex">
                      ShiftEasy is the world’s first integrated AI driven
                      properties management and housing needs platform
                    </li>
                    <li className="d-flex">
                      Get membership now for right leads for better rental, sale
                      and service
                    </li>
                    <li className="d-flex">
                      In person services, virtual viewings, 3D tours, transport
                      with movers & packers - ShiftEasy Ninjas will support you
                      end to end
                    </li>
                    <li className="d-flex">
                      Property management, rental collections, Card payments,
                      International and multi-currency support
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 mt-5 card-section2 mb-3 mb-2">
                <div className="shadow-cus p-3 h-100 w-100 br-5 ">
                  <div className="index-icons-box">
                    <LazyLoadImage effect="blur" src={valuate} alt="Image" className="index-icons" />
                  </div>
                  <h6 className="text-orange font-weight-bold mt-4">
                    Valuation
                  </h6>
                  <ul className="custom-list mb-0">
                    <li className="d-flex">
                      AI based real-time validation and property price data
                    </li>
                    <li className="d-flex">
                      ShiftEasy Rent Market Index - Best pricing service for
                      your dream home and property investments
                    </li>
                    <li className="d-flex">
                      First Blockchain based property management, pricing, home
                      loan, REIT and finance services platform
                    </li>
                    <li className="d-flex">
                      Watch your investments grow with ShiftEasy
                    </li>
                    <li className="d-flex">
                      Connect ziffy with our ShiftEasy team, we are happy to
                      help you!!!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="top-right-nav justify-content-center ">
              <h4 className="mt-2 mt-md-3 mb-0 text-dark-blue font-weight-bold">
                Featured Projects
              </h4>
              <ItemsCarousel
                requestToChangeActive={projectsChangeActiveItem}
                isLastScroll={true}
                activeItemIndex={projectsActiveItemIndex}
                numberOfCards={matches ? (matches2 ? 4 : 2) : 1}
                gutter={20}
                leftChevron={
                  <LazyLoadImage effect="blur"
                    src={leftarrow}
                    alt="Left Arrow"
                    style={{ width: "40px" }}
                    className="carosel-control-left"
                  />
                }
                rightChevron={
                  <LazyLoadImage effect="blur"
                    src={rightarrow}
                    alt="Right Arrow"
                    style={{ width: "40px" }}
                    className="carosel-control-right"
                  />
                }
                outsideChevron
                width={341.5}
                chevronWidth={40}
              >
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured1}
                      className="img-fluid  p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured2}
                      className="img-fluid p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured3}
                      className="img-fluid p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured4}
                      className="img-fluid p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured5}
                      className="img-fluid p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured6}
                      className="img-fluid p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
              </ItemsCarousel>
            </div>
            <div className="top-right-nav justify-content-center ">
              <div
                className="d-flex justify-content-between"
                style={{ marginRight: "105px" }}
              >
                <h4 className="mb-0 text-dark-blue font-weight-bold">
                  Featured Properties
                </h4>
                <a href="/search-list" className="btn btn-orange ml-3">
                  View All
                </a>
              </div>
              {FeaturedProperties && FeaturedProperties.length !== 0 ? (
                <ItemsCarousel
                  requestToChangeActive={propertiesChangeActiveItem}
                  onStateChange={handleChange}
                  activeItemIndex={propertiesActiveItemIndex}
                  numberOfCards={matches ? (matches2 ? 4 : 2) : 1}
                  gutter={20}
                  leftChevron={
                    <LazyLoadImage effect="blur"
                      src={leftarrow}
                      alt="Left Arrow"
                      style={{ width: "40px" }}
                      className="carosel-control-left"
                    />
                  }
                  rightChevron={
                    <LazyLoadImage effect="blur"
                      src={rightarrow}
                      alt="Right Arrow"
                      style={{ width: "40px" }}
                      className="carosel-control-right"
                    />
                  }
                  outsideChevron
                  chevronWidth={40}
                >
                  {FeaturedProperties.map((item) => {
                    return (
                      <div
                      className="h-100 pb-1"
                        // className="col-item shadow h-100 mb-5 bg-white rounded"
                        onClick={() => {
                          // console.log("porp", data, this.props);
                          // this.props.setProperty(data);
                          props.history.push(`search-detail/${item.propertyCategoryId===2?"commercial#":"residential#"}${item.propertyId}`);
                        }}
                      >
                        <Card {...item} emptydata={true} />
                      </div>
                    );
                  })}
                </ItemsCarousel>
              ) : (
                <div
                  className="d-flex align-items-center"
                  style={{ height: "100px" }}
                >
                  No Property Realted To Your Location
                </div>
              )}
            </div>
            <div className="top-right-nav justify-content-center ">
              <h4 className="mt-2 mt-md-3 mb-2 text-dark-blue font-weight-bold">
                Featured Business/Agencies
              </h4>
              <ItemsCarousel
                requestToChangeActive={businessChangeActiveItem}
                activeItemIndex={businessActiveItemIndex}
                numberOfCards={matches ? (matches2 ? 4 : 2) : 1}
                gutter={20}
                leftChevron={
                  <LazyLoadImage effect="blur"
                    src={leftarrow}
                    alt="Left Arrow"
                    style={{ width: "40px" }}
                    className="carosel-control-left"
                  />
                }
                rightChevron={
                  <LazyLoadImage effect="blur"
                    src={rightarrow}
                    alt="Right Arrow"
                    style={{ width: "40px" }}
                    className="carosel-control-right"
                  />
                }
                outsideChevron
                chevronWidth={40}
              >
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured3}
                      className="img-fluid p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured2}
                      className="img-fluid p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured6}
                      className="img-fluid p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured9}
                      className="img-fluid p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured10}
                      className="img-fluid p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
                <div className="col-item shadow  mb-5 bg-white rounded">
                  <div className="card ">
                    <LazyLoadImage effect="blur"
                      src={featured7}
                      className="img-fluid p-1 card-img-top h-100 "
                      alt="Featured Image"
                    />
                  </div>
                  <div className="card-body">
                    <p className="fs-13 mb-1 text-dark">
                      3 BHK Villa for rent in Chennai
                    </p>
                    <p className="font-weight-bold fs-14 mb-0 text-dark">
                      Rs. 3000
                    </p>
                  </div>
                </div>
              </ItemsCarousel>
            </div>
          </div>
          <div className="container section-two ">
            {/* section 2  */}
            <h4 className="section-title">ShiftEasy Services</h4>
            <p className="section-des">
              Sell your home with us for a fraction of the price charged by high
              street estate agents without compromising on the services you get.
              Everything you need to sell your property with ease is included.
            </p>
            <div className="row mb-4 ">
              <div className="col-md-2 col-sm-6">
                <a href="/tenant-referencing">
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={tenant} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Tenant Referencing</small>
                    </h5>
                  </div>
                </a>
              </div>

              <div className="col-md-2 col-sm-6">
                <Link
                  to={{ pathname: "/deposit-replacements", refreshpath: "yes" }}
                >
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={deposit} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Deposit Replacements</small>
                    </h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-2 col-sm-6">
                <Link to={{ pathname: "/inventory-check", refreshpath: "yes" }}>
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={inventory} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Inventory Check-In and Check-Out</small>
                    </h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-2 col-sm-6">
                <a href={"https://doctors.shifteasy.com/"} target={"_blank"}>
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={doctor} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Doctors Near You</small>
                    </h5>
                  </div>
                </a>
              </div>
              <div className="col-md-2 col-sm-6">
                <Link
                  to={{
                    pathname: "/home-content-insurancegas",
                    refreshpath: "yes",
                  }}
                >
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={insurance} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Home Content Insurance</small>
                    </h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-2 col-sm-6">
                <Link to="/rent-guarentee-insurance">
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={rent} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Rent Guarentee Insurance</small>
                    </h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-2 col-sm-6">
                <Link to="/gas-safety">
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={gassafety} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Gas Safety</small>
                    </h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-2 col-sm-6">
                <Link to="/energy-performance-certificate">
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={energy} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Energy Performance Certificate (EPC)</small>
                    </h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-2 col-sm-6">
                <a href="https://legals.shifteasy.com/" target={"_blank"}>
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={legal} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Legal Services Near You</small>
                    </h5>
                  </div>
                </a>
              </div>
              <div className="col-md-2 col-sm-6">
                <Link to="/open-backing">
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={banking} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Open Banking</small>
                    </h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-2 col-sm-6">
                <Link to="/virtual-conference">
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={photovideo} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>
                        Professional Photos, 3D Videos, Virtual Conference
                      </small>
                    </h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-2 col-sm-6">
                <a href="https://investors.shifteasy.com/" target={"_blank"}>
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={investor} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Investor Services</small>
                    </h5>
                  </div>
                </a>
              </div>
              <div className="col-md-2 col-sm-6">
                <Link to="/movers-packers-logistics">
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={movers} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Movers, Packers & Logistics</small>
                    </h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-2 col-sm-6">
                <a
                  href="https://propertymanagement.shifteasy.com/"
                  target={"_blank"}
                >
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={propertymanagement} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Property Management</small>
                    </h5>
                  </div>
                </a>
              </div>
              <div className="col-md-2 col-sm-6">
                <a
                  href="https://localfacilities.shifteasy.com/"
                  target={"_blank"}
                >
                  <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                    <LazyLoadImage effect="blur" src={facilities} alt="icon" className="mb-3" />
                    <h5 className="mb-0">
                      <small>Facilities Near You</small>
                    </h5>
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* <!-- Property Valuation --> */}
          <div className="page-middle-banner mb-3 mb-lg-5">
            <div className="row h-100 align-content-around">
              <div className="col-md-5 col-sm-6 offset-md-7 offset-sm-6 bg-white p-4">
                <h4 className="text-dark-blue">
                  How much is your property worth?
                </h4>
                <p className="text-dark">
                  Our specially selected local experts can value your home for
                  free with no strings attached.
                </p>
                <button
                  type="button"
                  className="btn btn-orange"
                  data-toggle="modal"
                  data-target="#freeValForm"
                >
                  Get your free property valuation
                </button>
              </div>
            </div>
          </div>
          {/* <!-- Section 3 --> */}
          <div className="col-lg-10 col-12 mx-auto areas_label_select  mb-5 p-0">
            <h3 className="text-center mb-5">Popular Cities</h3>
            <div className="row mb-lg-3 justify-content-around row_1 ">
              <div className="rounded-circle py-2 mt-0 mb-3">
                <a>
                  <LazyLoadImage effect="blur"
                    src={Ahmedabad}
                    alt="no-img"
                    className="img-fluid mx-auto d-block"
                  />
                  <p className="text-center ">Ahmedabad </p>
                </a>
              </div>
              <div className="rounded-circle py-2 mt-0 mb-3">
                <a>
                  <LazyLoadImage effect="blur"
                    src={chennai}
                    alt="no-img"
                    className=" img-fluid mx-auto d-block"
                  />
                  <p className="text-center ">Chennai</p>
                </a>
              </div>
              <div className="rounded-circle py-2 mt-0 mb-3 mb-lg-3 ">
                <a>
                  <LazyLoadImage effect="blur"
                    src={banglore}
                    alt="no-img"
                    className="img-fluid mx-auto d-block"
                  />{" "}
                  <p className="text-center ">Banglore</p>
                </a>
              </div>
              <div className="rounded-circle py-2 mt-0 mb-3">
                <a>
                  <LazyLoadImage effect="blur"
                    src={delhi}
                    alt="no-img"
                    className="img-fluid mx-auto d-block"
                  />
                  <p className="text-center ">Delhi</p>
                </a>
              </div>
              <div className="rounded-circle py-2 mt-0 mb-3 ">
                <a>
                  <LazyLoadImage effect="blur"
                    src={Ghaziabad}
                    alt="no-img"
                    className="img-fluid mx-auto d-block"
                  />
                  <p className="text-center ">Ghaziabad</p>
                </a>
              </div>
              <div className="rounded-circle py-2 mt-0 mb-3 ">
                <a>
                  <LazyLoadImage effect="blur"
                    src={hydrabad}
                    alt="no-img"
                    className="img-fluid mx-auto d-block"
                  />
                  <p className="text-center ">Hyderabad</p>
                </a>
              </div>
            </div>
            <div className="row row_2 justify-content-around ">
              <div className="rounded-circle py-2 mt-0 mb-3 ">
                <a>
                  <LazyLoadImage effect="blur"
                    src={kolkata}
                    alt="no-img"
                    className="img-fluid mx-auto d-block"
                  />{" "}
                  <p className="text-center ">Kolkata</p>
                </a>
              </div>
              <div className="rounded-circle py-2 mt-0 mb-3">
                <a>
                  <LazyLoadImage effect="blur"
                    src={konark}
                    alt="no-img"
                    className="img-fluid mx-auto d-block"
                  />{" "}
                  <p className="text-center ">Konark</p>
                </a>
              </div>
              <div className="rounded-circle py-2 mt-0 mb-3 mb-lg-3 ">
                <a>
                  <LazyLoadImage effect="blur"
                    src={mumbai}
                    alt="no-img"
                    className="img-fluid mx-auto d-block"
                  />{" "}
                  <p className="text-center ">Mumbai</p>
                </a>
              </div>
              <div className="rounded-circle py-2 mt-0 mb-3">
                <a>
                  <LazyLoadImage effect="blur"
                    src={Noida}
                    alt="no-img"
                    className="img-fluid mx-auto d-block"
                  />{" "}
                  <p className="text-center ">Noida</p>
                </a>
              </div>
              <div className="rounded-circle py-2 mt-0 mb-3 ">
                <a>
                  <LazyLoadImage effect="blur"
                    src={patna}
                    alt="no-img"
                    className="img-fluid mx-auto d-block"
                  />{" "}
                  <p className="text-center ">Patna</p>
                </a>
              </div>
              <div className="rounded-circle py-2 mt-0 mb-3">
                <a>
                  <LazyLoadImage effect="blur"
                    src={pune}
                    alt="no-img"
                    className="img-fluid mx-auto d-block"
                  />
                  <p className="text-center ">Pune</p>
                </a>
              </div>
            </div>
            <div className="row justify-content-center ">
              <button className="btn btn-outline-orange mt-lg-3 ">View All</button>
            </div>
          </div>
        </main>
        {/* <Register/> */}

        {/* <!-- Section 4 --> */}
        <div className="container">
          <div className="top-right-nav  justify-content-center mt-4">
            <div className="title_style_all d-lg-flex d-md-flex justify-content-between col-md-10 col-lg-11 mt-2 pt-1  pl-0 px-0">
              <div className=" col-md-7 col-xs-12 px-0  mt-lg-0 mt-md-0">
                <h4 className=" mb-0 text-dark-blue font-weight-bold">
                  Latest News & Blogs
                </h4>
              </div>
              <div className="col-md-2  p-0  d-none d-sm-block ">
                <button
                  id="v_btn"
                  onClick={() => window.open("https://shifteasy.com/BlogsPage")}
                  className="btn btn-outline-orange position-lg-absolute float-right btn-sm ml-3"
                >
                  View All
                </button>
              </div>
            </div>
            <ItemsCarousel
              requestToChangeActive={moreChangeActiveItem}
              activeItemIndex={moreActiveItemIndex}
              numberOfCards={window.innerWidth > 924 ? 3 : 1}
              gutter={20}
              leftChevron={
                <LazyLoadImage effect="blur"
                  src={leftarrow}
                  alt="Left Arrow"
                  style={{ width: "40px" }}
                  className="carosel-control-left"
                />
              }
              rightChevron={
                <LazyLoadImage effect="blur"
                  src={rightarrow}
                  alt="Right Arrow"
                  style={{ width: "40px" }}
                  className="carosel-control-right"
                />
              }
              outsideChevron
              chevronWidth={40}
            >
              {blogs &&
                blogs.map((item, i) => (
                  <div
                    id="id_width1"
                    className="col-item shadow  mb-5 bg-white rounded"
                  >
                    <div
                      onClick={() => {
                        props.history.push({
                          pathname: "/BlogDetailPage",
                          state: { item: item },
                        });
                      }}
                      className="card card_body  p-2 "
                    >
                      <LazyLoadImage effect="blur"
                        src={`${image_show}${item.featureImage.url}`}
                        className="card-img-top p-1 br-0"
                        alt="no-image"
                      />
                      {item.author.map((detail) => (
                        <div className="card-body card_body  p-2">
                          <div id="title">
                            <h5 className="card-title mb-1 p-1">{item.title}</h5>
                          </div>
                          <span className="d-flex justify-content-between mb-0 mt-2 p-1">
                            {" "}
                            <h6>{detail.name} </h6>{" "}
                            <span className=" text-right">
                              {" "}
                              by &nbsp;
                              <span className="text_orange text-right">
                                {detail.__typename}
                              </span>
                            </span>{" "}
                          </span>
                          <p className="card-text card_disc p-1">
                            {item.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </ItemsCarousel>
            <button
              onClick={() => window.open("/BlogsPage")}
              className="btn btn-outline-orange btn-block d-block d-sm-none mb-5"
              id="d_btn"
            >
              View All
            </button>
          </div>
        </div>
        {/* new residential structure */}
        <main className="container-fluid px-0">
          {window.location.host === "residential.shifteasy.com" && (
            <div id="newSection" className="px-30 section-four pb-2">
              <h4 className="section-title py-4">
                How Shifteasy Residential Rental Property Works :
              </h4>
              <div
                id="swap"
                className="row mx-auto mb-4 mx-lg-5 flex-nowrap overflow-auto"
              >
                <div className="col-md-6 col-xs-12 col-lg-6  text-center fs-15">
                  <p className="justify-content-first text-dark">
                    Residential real estate can be single-family homes,
                    apartment block units, apartments, townhouses, duplexes, and
                    so on. The term residential rental property recognizes this
                    class of rental real estate investment from commercial
                    properties. The renter will generally be a corporate entity
                    rather than a person or family, as well as hotels and motels
                    where a tenant does not live in the property long term.
                    <br />
                    <br />
                    Residential rental property can be an appealing investment.
                    In contrast to stocks, futures, and other financial
                    investments, many individuals have direct involvement in
                    both the rental market as residents and the residential real
                    estate market as landowners. This experience with the
                    process and the investment makes residential rental
                    properties less terrifying than other investments. On top of
                    the commonality factor, residential rental properties can
                    offer monthly cash flow, long-term appreciation, leverage
                    using borrowed money, and the tax advantages on the
                    investment's income.
                    <br />
                    <br />
                    Our <span>Shifteasy Ninjas</span> are trained professionals
                    who will take you through the rental agreement/registration
                    / rental management procedures with end-to-end guidance and
                    make it look like a cakewalk.
                  </p>
                </div>
                <div className="col-md-6 col-xs-6 col-lg-6 fs-15">
                  <LazyLoadImage effect="blur"
                    src={rowan}
                    alt="no image"
                    className="d-block mx-auto mb-2"
                  />
                </div>
              </div>
            </div>
          )}
          {/* new residential structure */}

          {/* new pg&co structure */}
          {window.location.host === "pg.shifteasy.com" && (
            <div id="newSection" className="px-30 section-four pb-2">
              <h4 className="section-title py-4">About PG & Co Living :</h4>
              <div
                id="swap"
                className="row mx-auto mb-4 mx-lg-5 flex-nowrap overflow-auto"
              >
                <div className="col-md-6 col-xs-12 col-lg-6  text-center fs-15">
                  <p className="text-dark">
                    Co-living spaces, workers, and student housing have emerged
                    as practical alternatives for those looking for rental
                    spaces in metro cities. With the help of reliable partners,
                    ShiftEasy helps you choose and prioritize the security,
                    amenities, surrounding, food, and other factors that
                    students, working men/ women, or anybody should be aware of
                    before choosing such shared accommodations.
                    <br />
                    <br />
                    <span>
                      Thinking about Co-living spaces, we are here to guide you!
                    </span>
                    <br />
                    <br />
                    Moving to a new co-living space? Don't feel lost; contact
                    our ShiftEasy Ninjas team for immediate assistance.
                    <br />
                    <br />
                    Tired of having no time for yourself, if you like to hang
                    out with like-minded people, enjoying some peaceful,
                    comforting activities, and postponing adult responsibilities
                    for some time, Our PG and Co-Living Homes are the best bet
                    for you.
                    <br />
                    <br />
                    Think of this, you're home after a tiring day at work and
                    find your room clean, bed made, your clothes back from the
                    laundry. You put them aside in your wardrobe and jump
                    directly into bed.
                  </p>
                </div>
                <div className="col-md-6 col-xs-6 col-lg-6 fs-15">
                  <LazyLoadImage effect="blur"
                    src={sidekix}
                    alt="no image"
                    className="d-block mx-auto mb-2"
                  />
                </div>
              </div>
            </div>
          )}
          {/* new pg&co structure */}

          <br />
          {/* <RegistrationModel/> */}
          {/* <Login/> */}
          {/* <!-- Section 5 --> */}
          <div className="container section-four pb-2">
            <h4 className="section-title py-4">Our Promise</h4>
            <div className="row mx-auto mb-4 mx-lg-5 flex-nowrap overflow-auto">
              <div className="col-md-3 col-5 text-center fs-15">
                <LazyLoadImage effect="blur"
                  src={shield}
                  alt="icon"
                  className="d-block mx-auto mb-2"
                  height="80px"
                />{" "}
                Verified Properties
              </div>
              <div className="col-md-3 col-5 text-center fs-15">
                <LazyLoadImage effect="blur"
                  src={qualityassurance}
                  alt="icon"
                  className="d-block mx-auto mb-2"
                  height="80px"
                />{" "}
                Re-work Assurance
              </div>
              <div className="col-md-3 col-5 text-center fs-15">
                <LazyLoadImage effect="blur"
                  src={technicalsupport}
                  alt="icon"
                  className="d-block mx-auto mb-2"
                  height="80px"
                />{" "}
                Professional Support
              </div>
              <div className="col-md-3 col-5 text-center fs-15">
                <LazyLoadImage effect="blur"
                  src={verifiedUser}
                  alt="icon"
                  className="d-block mx-auto mb-2"
                  height="80px"
                />{" "}
                Verified Business Leads
              </div>
            </div>
          </div>
          {/* <!-- Section 6 --> */}
          <div className=" section-five bg-light city-bg">
            <div className="container">
              <h4 className="section-title py-4">
                Properties in Popular Cities
              </h4>
              <div className="row mx-auto pb-4 pb-md-3">
                <div className="col-md-3 col-sm-6 col-12 fs-15">
                  <ul className="mb-0 pl-0">
                    <li>Real Estate in Chennai</li>
                    <li>Real Estate in Mumbai</li>
                    <li>Real Estate in Bangalore</li>
                    <li>Real Estate in Kolkata</li>
                    <li>Real Estate in Ahmedabad</li>
                    <li>Real Estate in Trivandrum</li>
                    <li>Real Estate in Noida</li>
                    <li>Real Estate in Patna</li>
                    <li>Real Estate in Hyderabad</li>
                    <li>Real Estate in Ghaziabad</li>
                    <li>Real Estate in Delhi</li>
                    <li>Real Estate in Pune</li>
                  </ul>
                </div>
                <div className="col-md-3 col-sm-6 col-12 fs-15">
                  <ul className="mb-0 pl-0">
                    <li>Property in Chennai</li>
                    <li>Property in Mumbai</li>
                    <li>Property in Bangalore</li>
                    <li>Property in Kolkata</li>
                    <li>Property in Ahmedabad</li>
                    <li>Property in Trivandrum</li>
                    <li>Property in Noida</li>
                    <li>Property in Patna</li>
                    <li>Property in Hyderabad</li>
                    <li>Property in Ghaziabad</li>
                    <li>Property in Delhi</li>
                    <li>Property in Pune</li>
                  </ul>
                </div>
                <div className="col-md-3 col-sm-6 col-12 fs-15">
                  <ul className="mb-0 pl-0">
                    <li>Flats in Chennai</li>
                    <li>Flats in Mumbai</li>
                    <li>Flats in Bangalore</li>
                    <li>Flats in Kolkata</li>
                    <li>Flats in Ahmedabad</li>
                    <li>Flats in Trivandrum</li>
                    <li>Flats in Noida</li>
                    <li>Flats in Patna</li>
                    <li>Flats in Hyderabad</li>
                    <li>Flats in Ghaziabad</li>
                    <li>Flats in Delhi</li>
                    <li>Flats in Pune</li>
                  </ul>
                </div>
                <div className="col-md-3 col-sm-6 col-12 fs-15">
                  <ul className="mb-0 pl-0">
                    <li>Builders in Chennai</li>
                    <li>Builders in Mumbai</li>
                    <li>Builders in Bangalore</li>
                    <li>Builders in Kolkata</li>
                    <li>Builders in Ahmedabad</li>
                    <li>Builders in Trivandrum</li>
                    <li>Builders in Noida</li>
                    <li>Builders in Patna</li>
                    <li>Builders in Hyderabad</li>
                    <li>Builders in Ghaziabad</li>
                    <li>Builders in Delhi</li>
                    <li>Builders in Pune</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="go-to-top">
            <a href="/#">
              <LazyLoadImage effect="blur" src={toparrow} alt="Top" className="shadow br-50p" />
            </a>
          </div>
        </main>

        {/* Modal for VIP access*/}
        <div
          className="modal fade"
          id="homeForm"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-labelledby="homeFormLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="homeFormLabel">
                  Early VIP Access
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="px-1 px-lg-3">
                  <input
                    type="text"
                    className="form-control border-0 br-0 mb-3"
                    placeholder="Full Name"
                    value={homeData.vipFullName}
                    onChange={(e) => setHomeData(e.target.value)}
                  />
                  <input
                    type="email"
                    className="form-control border-0 br-0 mb-3"
                    placeholder="Email"
                    value={homeData.vipEmail}
                    onChange={(e) => setHomeData(e.target.value)}
                  />
                  <input
                    type="number"
                    className="form-control border-0 br-0 mb-3"
                    placeholder="Phone Number"
                    value={homeData.vipPhone}
                    onChange={(e) => setHomeData(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control border-0 br-0 mb-3"
                    placeholder="City"
                    value={homeData.vipCity}
                    onChange={(e) => setHomeData(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control border-0 br-0 mb-3"
                    placeholder="Country"
                    value={homeData.vipCountry}
                    onChange={(e) => setHomeData(e.target.value)}
                  />
                  <textarea
                    rows="4"
                    className="form-control border-0 br-0"
                    placeholder="Description"
                    value={homeData.vipDescription}
                    onChange={(e) => setHomeData(e.target.value)}
                  ></textarea>
                </form>
              </div>
              <div className="modal-footer pt-0 px-1 px-lg-3">
                {/* <button type="button" className="btn" data-dismiss="modal">Close</button> */}
                <button
                  type="button"
                  className="btn btn-orange"
                  onClick={submitVIPAccess}
                >
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for property valuation*/}
        {/*  */}

        {/* <!-- Footer --> */}
        <Footer />
      </div>
    );
}
const mapStateToProps = (state) => {
    return {
        Login: state.login,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);