import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function RecipeReviewCard(props) {
  // console.log("props",props)
  const [expanded, setExpanded] = React.useState(false);
  return (
    <Card className="d-flex flex-column"
    sx={{ height: "100%" }}>
      {/* <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            R
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={props.propertyName}
        // subheader={moment(props.createAt).format('MMMM Do YYYY')}
      /> */}
      <CardMedia
        component="img"
        height="300"
        image={props.propertyImageUrl!==null && props.propertyImageUrl.length!==0?props.propertyImageUrl[0].imageUrl:null}
        alt={props.projectName}
      />
      <CardContent className='pb-0'>
        <h6> {window.location.host === 'residential.shifteasy.com'?`${props.bedroom} BHK`:null} {props.propertyTypeName} for {props.type} in {props.cityName},{props.localityName}</h6>
      </CardContent>
      <CardContent className="pt-0 mt-auto align-items-start" >
        <span style={{color:"#E1601F",fontWeight:"bold"}}>₹ {props.price}</span>
      </CardContent>
    </Card>
  );
}
const mapStateToProps = (state) => {
  return {
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RecipeReviewCard));