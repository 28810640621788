import React, { Component } from 'react';
import './energy-performance-certificate.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import energyPerfServImg1 from '../../../../assets/images/services-page/8-energy-performance/image-1.png';
import energyPerfServImg2 from '../../../../assets/images/services-page/8-energy-performance/image-2.png';
import energyPerfServImg3 from '../../../../assets/images/services-page/8-energy-performance/image-3.png';
import energyPerfServImg4 from '../../../../assets/images/services-page/8-energy-performance/image-4.png';
import energyPerfServImg5 from '../../../../assets/images/services-page/8-energy-performance/image-5.png';
import energyPerfServImg6 from '../../../../assets/images/services-page/8-energy-performance/image-6.png';
import energyPerfServImg7 from '../../../../assets/images/services-page/8-energy-performance/image-7.png';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { scrollTop } from '../../common/common';
import { Helmet } from "react-helmet";
class EnergyPerformanceCertificate extends Component {

    componentDidMount() {
        scrollTop();
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Energy Performance Certificates (EPCs) for Buying or Selling Property</title>
                    <meta name="description" content="Energy Performance Certificates is important to know the consuption of energy in your property. In UK's Market, This is one of the important factor while buying or selling the property." />
                    <meta name="keywords" content="Energy Performance Certificates" />
                </Helmet>
                <Header />
                <div className="service-banner serv-eight page-top-banner banner-with-form mb-3 mb-lg-5">
                    <div className="service-banner-content text-center">
                        <h2 className="text-white mb-3">Energy<span className="text-orange"> performance</span> Certificates</h2>
                        <h5>Service Center Certificate Professionals For Commercial Iiot (Industrial Internet Of Things) <br />And Smart Home Iot (Internet Of Things)</h5>
                    </div>
                    <div className="banner-form">
                        <form>
                            <div className="mb-3">
                                <input type="text" className="form-control br-0" placeholder="Full Name" />
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control br-0" placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control br-0" placeholder="Phone number" />
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control br-0" rows="4" placeholder="Message"></textarea>
                            </div>
                            <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                        </form>
                    </div>
                </div>
                <div className="container mx-auto">
                    <div className="row my-5 align-items-lg-center">
                        <div className="col-lg-6">
                            <div className="service-content-box">
                                <h5 className="page-title">Energy performance certificates and IoT certificates: </h5>
                                <p>Energy performance certificates are a measuring system to review the energy efficiency of buildings. It is a legal requirement to have a valid Energy Performance Certificate (EPC) when a building is sold, rented, or constructed.</p>
                                <h5 className="page-title">How do I get an energy performance certificate EPC? </h5>
                                <p>The property's Energy Performance Certificates must be available to potential buyers as soon as they start to market their property for sale or rent. ShiftEasy will get the approved domestic energy assessor to produce the EPC. An energy performance certificate allows you to evaluate different properties' energy efficiency for buying or renting a property.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={energyPerfServImg1} className="img-fluid resp-img-ht" alt="img" />
                        </div>
                    </div>
                    <div className="row my-5 align-items-lg-center">
                        <div className="order-lg-2 col-lg-6">
                            <div className="service-content-box">
                                <h5 className="page-title">Why do I need an EPC? </h5>
                                <p>It is a legal requirement to have a valid Energy Performance Certificate (EPC) when a building is sold, rented, or constructed. The property's EPC needs to be available to potential buyers as soon as they market the property for sale or rent. It will inform people how energy efficient the property is, including any potential savings on fuel payments. </p>
                                <p>ShiftEasy partners help you get an approved /certified Domestic Energy Assessor to produce the EPC, providing information about a property's energy use and average energy costs. </p>
                                <p>Failure to have a valid EPC may result in a penalty, although there are exemptions, e.g., listed properties. Shifteasy notifies to renew the EPC before the terminated period.</p>
                            </div>
                        </div>
                        <div className="order-lg-1 col-lg-6">
                            <img src={energyPerfServImg2} className="img-fluid resp-img-ht" alt="img" />
                        </div>
                    </div>
                    <div className="row my-5">
                        <h5 className="page-title text-center w-100 mb-3">Types of Energy Performance Certificate (EPC):</h5>
                        <div className="col-lg-6 order-lg-2">
                            <img src={energyPerfServImg3} className="img-fluid resp-img-ht" />
                        </div>
                        <div className="col-lg-6 order-lg-1 px-1">
                            <div className="service-content-box">
                                <h6>Commercial EPC: </h6>
                                <p>It is a legal requirement to have a valid EPC assessment if you are selling or leasing commercial property. A commercial energy performance certificate (EPC) is a report which shows how energy efficient a building is. The energy certificate gives the building an energy efficiency grade on a scale. Having a good energy efficiency grade can be suitable for drawing buyers or tenants as it means lower fuel costs for them in the future.</p>
                                <h6>Domestic EPC: </h6>
                                <p>A domestic EPC Energy Performance Certificate is a report which shows how energy efficient a domestic building is. All residential buildings must have had an EPC assessment carried out to be rented out or sold. If the customer is planning on selling or renting out the residential property, it is legal to have a domestic EPC. It has been the law since 2008, and it is designed to help potential buyers or tenants make an informed decision about buying or renting the property.</p>
                                <h6>Air conditioning Inspection: </h6>
                                <p className="mb-2">Air conditioning inspection surveys permit us to determine whether the Air conditioning unit is being used for complete efficiency. If the air conditioning unit does not meet energy guidelines, it could cause problems for the business down the line, which could harm the environment. </p>
                                <p>By having a survey performed on the premises at regular intervals, you can avoid all the problems causing.</p>
                            </div>
                        </div>
                        <div className="col-12 order-lg-3 px-1">
                            <div className="service-content-box">
                                <h6 className="w-100">Green Commercial Energy Audits: </h6>
                                <p className="mb-2 w-100">ShiftEasy energy experts offer a variety of green energy measurements. </p>
                                <p>Our green energy evaluation and audit services include:</p>
                                <ul className="orange-disc">
                                    <li>Energy Performance Certificates (EPC), including energy efficiency and environmental impact ratings, for domestic and commercial properties.</li>
                                    <li>Targeted proposals reports specifying cost-effective measures which can improve buildings energy performance.</li>
                                    <li>Energy usage auditing and assessment</li>
                                    <li>Expert advice on enhancements to the energy efficiency of properties</li>
                                    <li>Heating requirement and performance audits, including a review of boiler efficiency, size and recommendations for improvement or replacement, for example, biomass or ground-source heat pump systems.</li>
                                    <li>Renewable energy systems and site suitability assessments.</li>
                                </ul>
                                <h6>What is ESOS? </h6>
                                <p className="mb-0">ESOS Regulation was initiated to promote and enhance energy efficiency within a large organization. Under the scheme, any succeeding organization must assess their energy usage at least once every four years and detect any energy-saving opportunities. ShiftEasy agency will notify this action by a certain closing date to validate that succeeding organizations have met their ESOS commitments.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row my-5 align-items-lg-center">
                        <div className="order-lg-2 col-lg-6 px-1">
                            <div className="service-content-box">
                                <h6>Step to follow: </h6>
                                <p>If an organization meets the ESOS standards, then you will need to carry out an energy assessment. </p>
                                <h6><span className="text-orange">Step 1: </span>Measure total energy usage: </h6>
                                <p>Primarily, you must measure the total energy consumption within your organization. It takes over a 12-month period and involves all energy usage, including energy used by transport, buildings, and industrial processes. </p>
                                <h6><span className="text-orange">Step 2: </span>Identify areas of consumption: </h6>
                                <p>Identify the areas of substantial energy consumption. The minimum of total energy usage within the organization must account for 90% </p>
                                <h6><span className="text-orange">Step 3: </span>Conduct an energy audit: </h6>
                                <p>The areas of significant energy consumption must then be subject to an ESOS compliant energy audit. </p>
                                <h6><span className="text-orange">Step 4: </span>Report compliance: </h6>
                                <p>The final step is to report compliance to the Shifteasy Agency. To keep records to provide evidence of how you have met your ESOS obligations.</p>
                            </div>
                        </div>
                        <div className="order-lg-1 col-lg-6">
                            <img src={energyPerfServImg4} className="img-fluid resp-img-ht" alt="img" />
                        </div>
                    </div>
                    <div className="row my-5 align-items-lg-center">
                        <h5 className="page-title col-12 text-center mb-3">IOT and Smart Home Certificate:</h5>
                        <div className="col-lg-6 pl-0">
                            <div className="service-content-box">
                                <ul className="orange-disc pl-4">
                                    <li><b>ShiftEasy</b> partner provides data protection and safety for your smart home products. A smart home is a home where home appliances and multimedia tools interact with each other.</li>
                                    <li><b>ShifEasy</b> partner allows the customers to control the lighting, heating, or safety mechanisms safely from anywhere on a computer or smartphone.</li>
                                    <li><b>Shifteasy</b> experts test your smart home products with data protection guidelines.</li>
                                    <li>Warranties can be a complicated concept. Any product company or seller of products targeted to consumers or commercial users not only sells the physical widgets but often provides some level of assurance to the buyer of product performance for a period.</li>
                                    <li>Warranty services effectively fulfil the promise a warranty company like a retailer or service provider makes when selling a product with a warranty or an extended warranty or service contract.</li>
                                    <li>Service Fulfilment provides warranty services, including repair, maintenance, delivery, and installation of products.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={energyPerfServImg5} className="img-fluid resp-img-ht" alt="img" />
                        </div>
                    </div>
                    <div className="my-4">
                        <div className="service-content-box px-0">
                            <h6>Shifteasy Test for your Smart home products. </h6>
                            <p>ShiftEasy partner helps you maintain your smartphone devices, thereby improving gas safety and offers test programs based on the data protection regulations and Certification. </p>
                            <p className="mb-0">Smart home product and function testing - Shifteasy test products such as motion sensors and smoke alarms for mechanical and electrical safety. Protected privacy for the smart home -Shifteasy developed test program focuses on data protection and information security. The Protected Privacy test program involves several tests, some of which are assigned by the data protection regulation.</p>
                        </div>
                    </div>
                    <div className="row my-5 align-items-lg-center">
                        <div className="order-lg-2 col-lg-6 px-1">
                            <div className="service-content-box">
                                <h6 className="mb-3">ShiftEasy Experts check whether smart home products effectively protect user privacy.</h6>
                                <ul className="orange-disc pl-4">
                                    <li>Devices: Is the device produced with privacy-friendly default settings, is it proper for updates and can it be reset to its factory settings.</li>
                                    <li>Local communication: Data transferred to the access in coded form and are done manually.</li>
                                    <li>Online communication: individual and robust passwords are supported. The IP protocol is encrypted.</li>
                                    <li>Apps: Apps have privacy-friendly default settings and is protected by Shifteasy.</li>
                                    <li>Documentation: Documentation meets user expectations and legal requirements and contains hints for the user on improving privacy.</li>
                                    <li>Data use: Children protected and tests in the Protected Privacy test program, other tests for smart home and IoT products are possible.</li>
                                    <li>Product testing: Mechanical and electrical safety.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="order-lg-1 col-lg-6">
                            <img src={energyPerfServImg6} className="img-fluid resp-img-ht" alt="img" />
                        </div>
                    </div>
                    <div className="row my-5 align-items-lg-center">
                        <div className="col-lg-6">
                            <div className="service-content-box">
                                <h6 className="mb-3">Shifteasy IOT in smart building & houses: </h6>
                                <p>Smart buildings with connected sensors are becoming increasingly popular. ShiftEasy focuses on developing innovative technology products and the best solution in the era of IOT.</p>
                                <h6 className="mb-3">Shifteasy IOT Services for smart homes:</h6>
                                <ul className="orange-disc pl-4">
                                    <li>Body Temperature Detection</li>
                                    <li>Access Control</li>
                                    <li>Smart Vehicle & Inspection</li>
                                    <li>Video Door Phone</li>
                                    <li>Smart Security Gate</li>
                                    <li>Video Surveillance</li>
                                    <li>Smart Lock</li>
                                    <li>Security Inspection</li>
                                    <li>HVAC (heating, ventilation, and air conditioning)</li>
                                    <li>Lawn/Gardening management</li>
                                    <li>Clever domestic appliances</li>
                                    <li>Improved home safety and protection</li>
                                    <li>Home Air and water fine tracking</li>
                                    <li>Herbal Language-primarily based voice assistants.</li>
                                    <li>Higher Infotainment shipping</li>
                                    <li>Humidity sensor</li>
                                    <li>Smart switches</li>
                                    <li>Sound Detection</li>
                                    <li>Video Cameras for Surveillance and Analytics</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={energyPerfServImg7} className="img-fluid resp-img-ht" alt="img" />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EnergyPerformanceCertificate));