import React,{Component} from 'react';
import './movers-packers-logistics.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import moversServImg1 from '../../../../assets/images/services-page/13-movers-n-packers/trained.svg' ;
import moversServImg2 from '../../../../assets/images/services-page/13-movers-n-packers/closed-car.svg' ;
import moversServImg3 from '../../../../assets/images/services-page/13-movers-n-packers/best-delivery-truck.svg' ;
import moversServImg4 from '../../../../assets/images/services-page/13-movers-n-packers/shift-car.svg' ;
import moversServImg5 from '../../../../assets/images/services-page/13-movers-n-packers/shift-house.svg' ;
import moversServImg6 from '../../../../assets/images/services-page/13-movers-n-packers/fill.svg' ;
import moversServImg7 from '../../../../assets/images/services-page/13-movers-n-packers/get.svg' ;
import moversServImg8 from '../../../../assets/images/services-page/13-movers-n-packers/choose.svg' ;
import moversServImg9 from '../../../../assets/images/services-page/13-movers-n-packers/sit.svg' ;
import moversServImg10 from '../../../../assets/images/services-page/13-movers-n-packers/image-1.png' ;
import moversServImg11 from '../../../../assets/images/services-page/13-movers-n-packers/movers-graphics.svg' ;
import moversServImg12 from '../../../../assets/images/services-page/13-movers-n-packers/image-2.png' ;
import moversServImg13 from '../../../../assets/images/services-page/13-movers-n-packers/image-3.png' ;
import moversServImg14 from '../../../../assets/images/services-page/13-movers-n-packers/image-4.png' ;
import moversServImg15 from '../../../../assets/images/services-page/13-movers-n-packers/image-5.png' ;
import moversServImg16 from '../../../../assets/images/services-page/13-movers-n-packers/image-6.png' ;
import moversServImg17 from '../../../../assets/images/services-page/13-movers-n-packers/image-7.png' ;



import {scrollTop} from '../../common/common';
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
class MoversPackersLogistics extends Component {

    componentDidMount(){
        scrollTop();
     }
    render(){
        return(
            <div>
                
                <Header/>
                    <div className="service-banner serv-thirteen page-top-banner banner-with-form mb-3 mb-lg-5">
                        <div className="service-banner-content">
                            <h5 className="mx-0">More Than 72000 Happy Clients Now a Part of Our Caravan</h5>
                            <h2 className="text-white">Save <span className="text-orange">Upto 20%</span> Than Market Price With</h2>
                            <h5 className="mx-0">Best Logistic Transport Service By ShiftEasy Technology</h5>
                        </div>
                        <div className="banner-form">
                            <form>
                                <div className="mb-3">
                                    <input type="text" className="form-control br-0" placeholder="Full Name"/>
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control br-0" placeholder="Email"/>
                                </div>
                                <div className="mb-3">
                                    <input type="number" className="form-control br-0" placeholder="Phone number"/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control br-0" placeholder="Shifting From"/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control br-0" placeholder="Shifting To"/>
                                </div>
                                <div className="mb-3">
                                    <input type="date" className="form-control br-0"/>
                                </div>
                                <div className="mb-3">
                                    <textarea className="form-control br-0" placeholder="Message"></textarea>
                                </div>
                                <button className="btn btn-orange btn-block">Contact Us Now For Free</button>
                            </form>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row movers_row my-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="service-content-box px-0">
                                    <h5 className="page-title mb-3">What is Trade?</h5>
                                    <p>The basic concept of trade is buying and selling goods or services for compensation paid in return. It can happen between within an economy, buyers, or sellers.</p>
                                    <h5 className="page-title mb-3">What is Logistics?</h5>
                                    <p>It can easily be explained through the line of course of action. Organise the transportation movement, accommodate it until the desired delivery date, and make sure it has reached the address. So, in simple terms, it is a commercial transporting activity carried out by separate dealers.</p>
                                    <h5 className="page-title mb-3">What is the relationship between them?</h5>
                                    <p>Trade and logistics are interrelated, and it deals mainly with purchasing, materials management, inventory control, warehousing, transport, and distribution. Buying 
                                         accommodating the goods 
                                         finding the right buyer  agreement on sale  transporting the goods.</p>
                                </div>
                            </div>
                            <div className="col-md-6 mx-auto">
                                <img src={moversServImg10} className="img-fluid" alt="img"/>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row movers_row mt-5">
                            <div className="col-md-12">
                                <div className="service-content-box text-center">
                                    <h5 className="page-sub-title text-capitalize">Verified reviews and profile</h5>
                                    <h5 className="page-title text-capitalize">Licensed Logistics Transporters with Detailed History</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row movers_row mb-4 mx-md-5 service-tiles">
                            <div className="col-md-2 col-sm-6 mb-3">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={moversServImg1} alt="icon" className="my-3 w-100 service-tiles-icon" />
                                    <h6 className="page-sub-title">Trained and skilled workforce</h6>
                                    <p className="fs-13">We have excellent professionals who look after loading of your vehicle into containers till door delivery</p>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-6 mb-3">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={moversServImg2} alt="icon" className="my-3 w-100 service-tiles-icon" />
                                    <h6 className="page-sub-title">Closed Car Containers</h6>
                                    <p className="fs-13">shifteasy uses safest mode of car transportation, i.e. closed car containers to ensure your vehicle reached destination scratch free.</p>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-6 mb-3">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={moversServImg3} alt="icon" className="my-3 w-100 service-tiles-icon" />
                                    <h6 className="page-sub-title">Best Delivery Time</h6>
                                    <p className="fs-13">Whether you are looking for express or standard delivery, we have plenty of options as per different necessity</p>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-6 mb-3">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={moversServImg4} alt="icon" className="my-3 w-100 service-tiles-icon" />
                                    <h6 className="page-sub-title">Shift Cars Easily</h6>
                                    <p className="fs-13">Whether you are looking for express or standard delivery, we have plenty of options as per different necessity</p>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-6 mb-3">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-2 h-100 br-5 text-center">
                                    <img src={moversServImg5} alt="icon" className="my-3 w-100 service-tiles-icon" />
                                    <h6 className="page-sub-title">Shift House Easily</h6>
                                    <p className="fs-13 mb-0">Whether you are looking for express or standard delivery, we have plenty of options as per different necessity</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row movers_row my-5 align-items-center">
                            <h5 className="col-12 page-title mb-4 text-center">Packing and Moving services:</h5>
                            <div className="col-lg-6">
                                <div className="shadow-cus">
                                    <img src={moversServImg12} className="img-fluid" />
                                    <p className="p-2 fs-14">Shifteasy partners are experts in Packing and Moving Services. We take the best care offering high-end packing and moving services at reasonable prices. All ShiftEasy staffs are well-mannered and experienced.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="shadow-cus">
                                    <img src={moversServImg13} className="img-fluid" />
                                    <p className="p-2 fs-14">ShiftEasy Partners are dedicated to creating value for customers, and through our skilled team, we provide excellent quality shifting, transportation, and storage services at the most reasonable prices.</p>
                                </div>
                            </div>
                        </div>
                        <div id="swap_img" className="row movers_row my-5 p-2 p-lg-4">
                            <div className="col-lg-6 order-lg-2 order-xs-2">
                                <h5 className="col-12 page-title px-0">Shifteasy services</h5>
                                <div className="service-content-box px-0">
                                    <ul className="pl-4 orange-disc text-justify">
                                        <li className="fs-14">
                                            <strong>House Relocations: </strong>
                                            <span>Shifeasy Home relocation is the progression of moving home from one location to another. There are two main ways for a home to be moved disassembling and reassembling it at the required target or transporting.</span>
                                        </li>
                                        <li className="fs-14">
                                            <strong>International Relocation: </strong>
                                            <span>Shifteasy international relocation leads international relocation specialists with operations in other countries, including all Indian major cities. All our moving services function through a combination of precise coordination, providing clients with reliable deadlines and comprehensive assistance, including help with language barriers, customs regulations, global security requirements and more.</span>
                                        </li>
                                        <li className="fs-14">
                                            <strong>Transport Insurance Services: </strong>
                                            <span>Shifteasy Partner provides the most satisfactory Transit Insurance coverage and claims. Shifteasy maintains a permanent and personal relationship with customers by understanding their needs.</span>
                                        </li>
                                        <li className="fs-14">
                                            <strong>Loading and unloading Services: </strong>
                                            <span>Shifteasy Loading and un-loading Services offers thought-out, organized, and reliable Loading and Unloading services are avoiding goods from any scratches and damage during the process of Loading and Unloading.</span>
                                        </li>
                                        <li className="fs-14">
                                            <strong>Corporate Relocations: </strong>
                                            <span>Shifteasy Corporate Relocations facilitate individuals and businesses to move their goods from one place to another. It offers services for relocations like packing, loading, moving, unloading, unpacking, arranging of items to be shifted. Supplementary services include cleaning services for houses, offices, or warehousing facilities.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="img_box col-lg-6 order-lg-1 order-xs-1 mb-3">
                                <img src={moversServImg14} className="img-fluid" />
                            </div>
                        </div>
                        <div className="row movers_row my-5 align-items-center">
                            <div className="col-lg-6">
                                <h5 className="col-12 page-title px-0">Scale your business and sell globally:</h5>
                                <div className="service-content-box px-0">
                                    <p>ShiftEasy's global network of warehouses offers multi-location storage options for all customers. Store your merchandise in a few warehouse areas, sell your items in numerous online stores and utilize one stock to oversee everything with one request the board framework.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img src={moversServImg15} className="img-fluid" alt="img"/>
                            </div>
                        </div>
                        <div className="row movers_row my-5 align-items-center">
                            <div className="col-lg-6 order-lg-2">
                                <h5 className="col-12 page-title px-0">VAT e-commerce business</h5>
                                <div className="service-content-box px-0">
                                    <p>ShiftEasy VAT e-commerce business bundle work with crossline exchange, battle VAT extortion and guarantee reasonable contest for EU organizations. It incorporates Improvements to the current MOSS. Unique arrangements relevant to provisions of products worked with by electronic interfaces.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1">
                                <img src={moversServImg16} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row movers_row mb-4 mx-2 mx-md-5 bg-dark-light">
                            <div className="col-md-12 mt-3 mt-md-5">
                                <div className="service-content-box text-center">
                                    <h5 className="page-sub-title text-capitalize">Easy Booking Process</h5>
                                    <h5 className="page-title text-capitalize">Book Logistic Service with a Tap on Your Phone</h5>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mt-3 mb-md-5 mb-3 px-4 booking-process">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-3 h-100 br-5 text-center bg-white">
                                    <img src={moversServImg6} alt="icon" className="mb-3 w-100 service-tiles-icon" />
                                    <p className="fs-13 mb-0">Fill an easy online form with your basic goods details that you want to move</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mt-3 mb-md-5 mb-3 px-4 booking-process">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-3 h-100 br-5 text-center bg-white">
                                    <img src={moversServImg7} alt="icon" className="mb-3 w-100 service-tiles-icon" />
                                    <p className="fs-13 mb-0">Get fully negotiated economic to premium quotations directly on your mail</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mt-3 mb-3 mb-md-5 px-4 booking-process">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-3 h-100 br-5 text-center bg-white">
                                    <img src={moversServImg8} alt="icon" className="mb-3 w-100 service-tiles-icon" />
                                    <p className="fs-13 mb-0">Choose preferred option as per budget and quality and pay through online or cash</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mt-3 mb-3 mb-md-5 px-4 booking-process">
                                <div className="shadow-cus p-sm-3 p-lg-4 p-3 h-100 br-5 text-center bg-white">
                                    <img src={moversServImg9} alt="icon" className="mb-3 w-100 service-tiles-icon" />
                                    <p className="fs-13 mb-0">Sit tight and track, let the customer success team do the rest of your work.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row movers_row mb-4 mx-2 mx-md-5 mt-5">
                            <div className="col-md-12 mt-lg-5">
                                <div className="service-content-box text-center">
                                    <h5 className="page-title text-capitalize">International Fleet And Transport Domet</h5>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 px-3 px-md-5 my-2">
                                <h5 className="text-orange text-uppercase">RELIABLE CAPACITY & COMMITMENT</h5>
                                <p className="fs-13">We treat your shipment like our own, securing capacity with our reliable carrier network.</p>
                            </div>
                            <div className="col-md-4 col-sm-12 px-3 px-md-5 my-2">
                                <h5 className="text-orange text-uppercase">GREAT RATES, NO SURPRISES</h5>
                                <p className="fs-13">Our instant rates are transparent through booking. We offer spot shipments and dedicated lanes.</p>
                            </div>
                            <div className="col-md-4 col-sm-12 px-3 px-md-5 my-2">
                                <h5 className="text-orange text-uppercase">24/7 SERVICE</h5>
                                <p className="fs-13">As earth's most customer-centric company, we provide the support and technology to make shipping easy with full shipment visibility and no blackout dates.</p>
                            </div>
                        </div>
                        <div className="row movers_row my-4 align-items-center">
                            <div className="col-md-6">
                                <div className="service-content-box">
                                    <h5 className="page-sub-title">Our Export Services include:</h5>
                                    <ul className="pl-3 pt-2 list-hashtag">
                                        <li>Commercial & Personal Shipments</li>
                                        <li>Full range of container sizes </li>
                                        <li>Direct access to preferential rates from selected shipping lines </li>
                                        <li>Shipped to arrival at port or inland destination by road haulage </li>
                                        <li>Marine Transit Insurance arranged for your protection and peace of mind</li>
                                        <li>Door-to-door – any weight to any international destination</li>
                                        <li>Complete Documentation, labeling & marking</li>
                                        <li>Wooden packing, crating & case making for complete protection</li>
                                        <li>Local transportation & warehousing</li>
                                        <li>Dangerous & Hazardous goods transport </li>
                                        <li>Secure warehousing with CCTV monitoring</li>
                                        <li>Pre-shipment inspections</li>
                                        <li>Insurance cover through Lloyds underwriters</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={moversServImg17} className="img-fluid" alt="img"/>
                            </div>
                        </div>
                        <div className="row movers_row bg-dark-blue m-4 p-4 align-items-center">
                            <div className="col">
                                <h4 className="text-white font-weight-normal mb-3 lh-15">Top Rated Logistic Service. Verified & Licensed Car Carriers. High Quality & Best Price Guaranteed. 100% safe & Reliable</h4>
                            </div>
                            <div className="col-12 col-md-4">
                                <img className="img-fluid serve-svg" src={moversServImg11} alt="img"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-11 mb-3 mt-5">
                                <div className=" text-center">
                                    <h5 className="page-sub-title text-capitalize">Related Search</h5>
                                    <h5 className="page-title text-capitalize">Shifteasy's presence across India</h5>
                                </div>
                            </div>
                    <div id="Rel_list" className="container-fluid ">
                        <div  className="row movers_row justify-content-center ">
                            <ul>
                                <li>Car Transliort Services in Hyderabad</li>
                                <li>Car Transliort Services in Kochi</li>
                                <li>Car Transliort Services in Kochi</li>
                                <li>Car Transliort Services in Kochi</li>
                            </ul>
                            <ul>
                                <li>Car Transliort Services in Chennai</li>
                                <li>Car Transliort Services in Ahmedabad</li>
                                <li>Car Transliort Services in Ahmedabad</li>
                                <li>Car Transliort Services in Ahmedabad</li>
                            </ul>
                            <ul>
                                <li>Car Transliort Services in liune</li>
                                <li>Car Transliort Services in Hyderabad</li>
                                <li>Car Transliort Services in Hyderabad</li>
                                <li>Car Transliort Services in Hyderabad</li>
                            </ul>
                            <ul>
                                <li>Car Transliort Services in Chandigarh</li>
                                <li>Car Transliort Services in Hyderabad</li>
                                <li>Car Transliort Services in Hyderabad</li>
                                <li>Car Transliort Services in Hyderabad</li>
                            </ul>
                        </div>
                    </div>
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };
  
  export default connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(MoversPackersLogistics));