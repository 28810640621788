
import { Types } from './actionTypes';
//import { history } from '../../components/common/history';
import { loginAPI } from '../../services/mutations/mutation-API';
import { getUserProfileAPI } from '../../services/querys/get-APIs';
import { toast } from 'react-toastify';
export const request = (user) => {
    return {
        type: Types.LOGIN_REQUEST,
        user
    }
}
export const success = (loginSuccessData) => {
    return {
        type: Types.LOGIN_SUCCESS,
        payload: loginSuccessData
    }
}
export const errorMsg = (errorMsg) => {
    return {
        type: Types.LOGIN_ERROR,
        payload: errorMsg
    }
}
export const failure = (error) => {
    return {
        type: Types.LOGIN_FAILURE,
        error
    }
}

export const resetLogin = (params) => {
    return {
        type: Types.LOGIN_SUCCESS_REST,
        params
    }

}
export const userDetails = (userData) => {
    return {
        type: Types.GET_USER_DETAILS,
        payload: userData
    }
}
export const getToken = (token) => {
    return {
        type: Types.GET_TOKEN,
        payload: token
    }
}
export const getRefreshToken = (refreshToken) => {
    return {
        type: Types.GET_REFRESH_TOKEN,
        payload: refreshToken
    }
}



export const userLogin = (loginData, loginProps) => {
    return (dispatch) => {
        dispatch(request(loginData.userName));
        loginAPI(loginData).then((response) => {
            toast.success("Login Success", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            console.log("login response", response)
            dispatch(success(response.data));
            dispatch(getToken(response.data.login.token));
            dispatch(getRefreshToken(response.data.login.refreshToken));
            setTimeout(() => {
                loginProps.history.push('/');
            }, 1000);
            console.log("login props", loginProps)
            // setInterval(() => {
            //     refreshTokenAPI(loginProps?.refreshToken).then((res) => {
            //         console.log("referesh res", res)
            //         dispatch(getToken(response.data?.refreshAccessToken?.token))
            //         dispatch(getRefreshToken(response.data?.refreshAccessToken?.refreshToken))
            //     })
            //         .catch((error) => {
            //             console.log("refresh token error", error)
            //         })

            // }, 300000);
            getUserProfileAPI(response?.data?.login?.token).then((response) => {
                console.log("user details", response.data)
                dispatch(userDetails(response.data?.getUserProfile));
            })
                .catch((error) => {
                    console.log("error geting user details", error)
                })
        })
            .catch((err) => {
                console.log('Error In Login', err);
                toast.error("login failed", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                dispatch(failure(err));

            });
    };

}






