import { createAction, createReducer } from '@reduxjs/toolkit';
import { Types } from '../actions/actionTypes';

const userLogin = createAction(Types.USER_LOGIN);
const userLogout = createAction(Types.USER_LOGOUT);

export const userReducer = createReducer(
    {
        user: null
    },
    (builder) => {
        builder
            .addCase(userLogin, (state, action) => {
                return {
                    ...state,
                    user: action.payload.data.getCustomers
                };
            })
            .addCase(userLogout, (state, action) => {
                return {
                    ...state,
                    user: action.payload
                };
            })
            .addDefaultCase((state) => state);
    }
);
