import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store/store";
import { PersistGate } from "redux-persist/lib/integration/react";
import {
  ApolloProvider
} from "@apollo/client";
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { userLogout,userLogin } from './redux/actions/user';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
// import 'bootstrap/dist/js/bootstrap.bundle';
import 'react-lazy-load-image-component/src/effects/blur.css';





const initOptions = {
  url: "https://iam.shifteasy.com/auth/",
  realm: "shifteasy",
  clientId: "shifteasy",
  onLoad: "check-sso",
  checkLoginIframe: true,
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
};
let keycloak = Keycloak(initOptions);

const getUser = (event,error) => {
  console.log('onKeycloakEvent', event, error)
  switch (event) {
    case "onReady":
      // console.log("onReady");
      break;
    case "onInitError":
      store.dispatch(userLogout());
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      break;
    case "onAuthSuccess":
      // console.log("onAuthSuccess");
      store.dispatch(userLogin(keycloak.tokenParsed.sub));
      break;
    case "onTokenExpired":
      keycloak.updateToken(30).then(function() {
        store.dispatch(userLogin(keycloak.tokenParsed.sub));
        // loadData();
    }).catch(function() {
        alert('Failed to refresh token');
        store.dispatch(userLogout());
        window.location.reload();    
        // dispatch(resetLogin());
    });
      break;
    case "onAuthRefreshSuccess":
      // console.log("onAuthRefreshSuccess ");
      store.dispatch(userLogin(keycloak.tokenParsed.sub));
      break;
    case "onAuthLogout":
      // console.log("onAuthLogout");
      store.dispatch(userLogout());
      setTimeout(() => {
        window.location.reload();
      }, 1000); 
      break;
    default:
      break;
  }
};


ReactDOM.render(
  <React.Fragment>
    <ApolloProvider client>
      <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions} onEvent={getUser}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
          <BrowserRouter>
            <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ReactKeycloakProvider>
    </ApolloProvider>
  </React.Fragment>,
  document.getElementById('root')
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
